import * as React from "react";

import { Drawer as MuiDrawer, Box, DrawerProps, ModalProps } from "@mui/material";

import clsx from "clsx";

import { Header } from "components";

import useStyles from "./drawer.styles";

export type Props = DrawerProps & {
  isOpen: boolean;
  classesPaper?: string;
  modalProps?: Partial<ModalProps>;
  title: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  isBackdropEnabled?: boolean;
  onBackdropClick?: () => any;
};

const Drawer: React.FC<Props> = ({
  anchor = "right",
  children,
  onClose,
  isOpen,
  variant = "temporary",
  classesPaper,
  modalProps,
  title,
  size = "xs",
  isBackdropEnabled = false,
  onBackdropClick,
  ...drawerProps
}) => {
  const classes = useStyles();

  return (
    <>
      <MuiDrawer
        {...drawerProps}
        anchor={anchor}
        variant={variant}
        open={isOpen}
        onClose={onClose}
        onBackdropClick={isBackdropEnabled ? () => onBackdropClick?.() : undefined}
        ModalProps={{
          ...modalProps,
          onClose: (event, reason) => {
            if (!isBackdropEnabled || reason !== "backdropClick") {
              onClose?.(event, reason);
            }
          },
        }}
        BackdropProps={{ classes: { root: classes.backdrop } }}
        classes={{
          paper: clsx(classes.paper, Boolean(classesPaper) && classesPaper, classes[size]),
        }}
        PaperProps={{ role: "dialog" }}
      >
        <Box mb={3}>
          <Header title={title} variant="form" aria-label="drawer-title" />
        </Box>

        {children}
      </MuiDrawer>
    </>
  );
};

export default Drawer;
