import { useState } from "react";

import ProfileSettings from "modules/settings/components/account-settings/account-settings";
import { SECTIONS } from "modules/settings/components/account-settings/types/account-settings.types";

import { Modal } from "components";

import { useHeaderModalContext } from "../context/header-modal.context";

const HeaderManageAccountModal: React.FC = () => {
  const { open, handleClose } = useHeaderModalContext();
  const [visibleSection, setVisibleSection] = useState<SECTIONS>(SECTIONS.overview);

  const handleModalClose = (_e: {}, reason: string) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  return (
    <Modal
      title={visibleSection === SECTIONS.overview ? "MANAGE ACCOUNT" : "CHANGE PASSWORD"}
      maxWidth={700}
      open={open}
      onClose={handleModalClose}
    >
      <ProfileSettings {...{ visibleSection, setVisibleSection }} />
    </Modal>
  );
};

export default HeaderManageAccountModal;
