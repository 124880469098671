import Timeline, {
  ReactCalendarTimelineProps,
  TimelineGroupBase,
  TimelineHeaders,
  TimelineItemBase,
  TimelineMarkers,
} from "react-calendar-timeline";

import ChartDayMarker from "../chart-day-marker/chart-day-marker";
import { defaultKeys } from "../types/types";
import useStyle from "./chart-gantt.style";

type ItemBase = TimelineItemBase<any>;
type GroupBase = TimelineGroupBase;

type Props<T extends ItemBase, K extends GroupBase> = ReactCalendarTimelineProps<T, K> & {
  children: React.ReactNode;
  classes?: { root: string };
  markerDate?: string;
};

const ChartGantt = <T extends ItemBase, K extends GroupBase>({
  children,
  classes: parentClasses,
  markerDate,
  ...props
}: Props<T, K>) => {
  const classes = useStyle({ classes: parentClasses });

  return (
    <div className={classes.root}>
      <Timeline
        timeSteps={{
          second: 0,
          minute: 0,
          hour: 0,
          day: 1,
          month: 1,
          year: 1,
        }}
        itemTouchSendsClick={false}
        stackItems
        canMove={false}
        canResize={false}
        canChangeGroup={false}
        keys={defaultKeys}
        verticalLineClassNamesForTime={() => [` ${classes.line}`]}
        horizontalLineClassNamesForGroup={() => [` ${classes.line}`]}
        lineHeight={62}
        itemHeightRatio={0.32}
        {...props}
      >
        <TimelineMarkers>
          <ChartDayMarker date={markerDate} />
        </TimelineMarkers>
        <TimelineHeaders className={classes.topHeader} calendarHeaderClassName={classes.rightTopHeaderContent}>
          {children}
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};

export default ChartGantt;
