import { useEffect, useRef } from "react";

import { Typography } from "@mui/material";

import { ReactComponent as AcaisoftLogo } from "assets/images/svg/acaisoft.svg";

import { useAssessmentSurveyContext } from "modules/evaluation/context/assessment-survey-context";

import { Button } from "components";

import useStyles from "./assessment-survey-initial-screen.styles";

type Props = {
  setShowWelcomeScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AssessmentSurveyInitialScreen: React.FC<Props> = ({ setShowWelcomeScreen }) => {
  const classes = useStyles();
  const { data: assessment } = useAssessmentSurveyContext();
  const divRef = useRef<HTMLDivElement>(null);

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setShowWelcomeScreen(false);
    }
  };

  useEffect(() => {
    divRef.current?.focus({ preventScroll: true });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.window} ref={divRef} onKeyPress={handleEnterPress} tabIndex={0}>
        <AcaisoftLogo />
        <Typography className={classes.title}>SELF ASSESSMENT</Typography>
        <Typography className={classes.welcomeTitle}>
          Hello {assessment && assessment.selfAssessmentEmployee.firstName}!
        </Typography>
        <Typography className={classes.text}>
          Please help us evaluate your technical and soft skills.
          <br />
          It will only take a few minutes.
        </Typography>
        <Button
          label="Start"
          className={classes.startButton}
          onClick={() => {
            setShowWelcomeScreen(false);
          }}
        />
        <Typography className={classes.tipTop}>or</Typography>
        <Typography className={classes.tipBottom}>
          Press <span className={classes.tipBottomBold}>ENTER</span>
        </Typography>
      </div>
    </div>
  );
};
export default AssessmentSurveyInitialScreen;
