import * as React from "react";
import { Form, Field } from "react-final-form";
import { Link as RouterLink } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";

import { AxiosError } from "axios";
import { FORM_ERROR } from "final-form";

import { routes } from "config";

import { useAuth } from "services/auth";
import { useTenantContext } from "services/tenant/context/tenant.context";

import Content from "../components/content/content";
import GuestLayout from "../components/guest-layout/guest-layout";
import { Button, TextControl, FormError } from "components";

import { createValidator } from "utils/forms";

import useStyles from "../guest.styles";

const validator = createValidator({
  email: { presence: { allowEmpty: false }, email: true },
});

const ForgotPasswordView: React.FC = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const tenant = useTenantContext();

  return (
    <GuestLayout>
      <Content title="Reset your password / Activate account">
        {!isFormSubmitted ? (
          <Form
            onSubmit={async ({ email }) => {
              try {
                await auth?.sendVerificationEmail({
                  email,
                  tenantId: tenant.data,
                  forgotPassword: true,
                });
                setIsFormSubmitted(true);
              } catch (ex) {
                Sentry.captureException(ex);
                const error: AxiosError = (ex as any).error;
                return {
                  [FORM_ERROR]: error?.response?.data?.message ?? "Sorry, something went wrong.",
                };
              }
            }}
            validate={validator}
            initialValues={{ email: "" }}
          >
            {({ handleSubmit, submitting, hasValidationErrors, pristine, submitError }) => (
              <form onSubmit={handleSubmit}>
                {submitError && <FormError>{submitError}</FormError>}

                <Field name="email" label="Email address" margin="dense" component={TextControl} />

                <Button
                  type="submit"
                  color="primary"
                  className={classes.submitButton}
                  disabled={submitting || pristine || hasValidationErrors}
                  label="Send"
                />
              </form>
            )}
          </Form>
        ) : (
          <>
            <Typography>A link to the password reset form was sent to your e-mail address.</Typography>
            <Typography>Please check your mailbox.</Typography>
            <Box mt={4}>
              <Typography>Have you changed your password already?</Typography>
            </Box>
            <Button
              component={RouterLink}
              to={routes.auth.login}
              className={classes.submitButton}
              color="primary"
              label="Sign in"
            />
          </>
        )}
      </Content>
    </GuestLayout>
  );
};

export default ForgotPasswordView;
