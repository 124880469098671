import { Breakpoint, Breakpoints, useMediaQuery, useTheme } from "@mui/material";

type BreakpointsKey = keyof Omit<Breakpoints, "keys" | "unit" | "values" | "between">;

// we want to pass start and end only if breakpointOption is set to between
type Props =
  | {
      start: Breakpoint;
      end: Breakpoint;
      breakpointOption: "between";
    }
  | {
      breakpointName: Breakpoint;
      breakpointOption: BreakpointsKey;
    };

function useBreakpoint(options: Props) {
  const theme = useTheme();

  const { breakpointOption } = options;
  const breakpointRes =
    breakpointOption === "between"
      ? theme.breakpoints.between(options.start, options.end)
      : theme.breakpoints[breakpointOption](options.breakpointName);

  return useMediaQuery(breakpointRes);
}

export default useBreakpoint;
