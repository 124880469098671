import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  leftTopHeaderContent: {
    borderRight: `1px solid ${palette.background.lightGray2}`,
    display: "flex",
    alignItems: "flex-start",
    padding: spacing(2.5, 1, 2.5, 0),
  },
  leftTopHeaderTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(20),
    fontWeight: 500,
    color: palette.text.n4,
    marginRight: spacing(1),
  },
  leftTopHeaderImg: {
    cursor: "pointer",
    color: palette.text.n4,
  },
}));
