import { useRef, useState } from "react";

const usePopup = () => {
  const [show, setShow] = useState(false);
  const anchor = useRef<HTMLButtonElement | null>(null);

  const toggle = () => {
    setShow(!show);
  };

  return {
    show,
    setShow,
    anchor,
    toggle,
  };
};

export default usePopup;
