import React, { useCallback } from "react";
import { Form } from "react-final-form";

import { ErrorResponse } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

import { backendEndpoints } from "config/routes";

import { API } from "services/api";
import { transformError } from "services/graphql/errorTransformers";

import { useEvaluationSurveyContext } from "modules/evaluation/context/evaluation-survey-context";

import { EvaluationMatrixAnswer, EvaluationStatusType } from "components/evaluations/graphql/evaluations-graphql";

import { useToast } from "hooks";

import { getInitialValues } from "./utils/evaluation-survey-utils";

import { EvaluationSurveyFormType } from "../../../../types/evaluation-survey-types";
import useStyles from "./evaluation-survey-form.style";
import EvaluationSurveyFormRenderer from "./form-renderer/form-renderer";

type Props = {
  permission: "READ" | "READ_WRITE";
  setIsFormCompleted: (isCompleted: boolean) => void;
};

const EvaluationSurveyForm: React.FC<Props> = ({ permission, setIsFormCompleted }) => {
  const classes = useStyles();
  const { showToast } = useToast();

  const { data: evaluation, refetch, headers } = useEvaluationSurveyContext();

  const handleSubmit = useCallback(
    async (values: EvaluationSurveyFormType) => {
      const data = {
        id: evaluation?.id,
        answers: values.answers.map((ans) => ({
          id: ans.id,
          answer: ans.answer,
          type: ans.type,
          matrixAnswers: ans.matrixQuestions?.reduce((arr, item) => {
            arr.push({ matrixQuestionId: item.id, selectedOptionId: item.selectedOptionId });
            return arr;
          }, [] as EvaluationMatrixAnswer[]),
        })),
        isCompleted: values.isCompleted,
      };

      if (permission === "READ") {
        showToast("Unauthorized action", "error");
        return;
      }

      try {
        await API.post(backendEndpoints.evaluationUpdate, data, {
          headers,
        });
      } catch (ex) {
        Sentry.captureException(ex);

        const { error, isFormError } = transformError(ex as ErrorResponse);
        if (isFormError) {
          return error;
        } else {
          showToast(error as string, "error");
        }
      } finally {
        refetch();
        if (evaluation?.status !== EvaluationStatusType.COMPLETE && values.isCompleted && permission === "READ_WRITE") {
          setIsFormCompleted(true);
        }
      }
    },
    [evaluation, headers, permission, refetch, setIsFormCompleted, showToast],
  );

  const initialValues = getInitialValues({ evaluation });

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          component={EvaluationSurveyFormRenderer}
        />
      </div>
    </div>
  );
};

export default EvaluationSurveyForm;
