import _ from "lodash";

import { JwtTokenPayload, parseJwt } from "../utils/jwt";

import {
  IAuthClient,
  LoginWithEmailAndPasswordParams,
  IUser,
  CheckAccessOptions,
  OnUpdateHandler,
  SendVerificationEmailParams,
  ResetPasswordParams,
} from "../types";

export interface IApiResponse<T, E = Error> {
  data: T | null;
  error?: E;
}

export type RefreshTokenOptions = {
  checkIntervalSeconds: number;
  remainingTimeLimitSeconds: number;
};

export type IdentityResponse = IUser;
export type LoginWithEmailAndPasswordResponse = {
  accessToken: string;
  refreshToken: string;
};
export type SendVerificationEmailResponse = {
  status: boolean;
};
export type RefreshTokenResponse = {
  accessToken: string;
  refreshToken: string;
};
export interface RefreshTokenError extends Error {
  isExpired: boolean;
}

export interface IRestAuthEndpoints {
  identity: (token: string) => Promise<IApiResponse<IdentityResponse>>;

  loginWithEmailAndPassword: (
    params: LoginWithEmailAndPasswordParams,
  ) => Promise<IApiResponse<LoginWithEmailAndPasswordResponse>>;

  sendVerificationEmail: (params: SendVerificationEmailParams) => Promise<IApiResponse<SendVerificationEmailResponse>>;
  resetPassword: (params: ResetPasswordParams) => Promise<IApiResponse<any>>;

  refreshToken: (
    refreshToken: string,
    tenantId: string,
  ) => Promise<IApiResponse<RefreshTokenResponse, RefreshTokenError>>;
}

export default class RestAuthClient implements IAuthClient {
  private endpoints: IRestAuthEndpoints;
  private refreshTokenOptions: RefreshTokenOptions;
  private refreshIntervalHandle: NodeJS.Timeout | null = null;
  private onUpdateHandler: OnUpdateHandler = () => {};

  storage: Storage;
  isInitialized: boolean = false;
  isAuthenticated: boolean = false;
  user: IUser | null = null;

  constructor(endpoints: IRestAuthEndpoints, storage: Storage, refreshTokenOptions: Partial<RefreshTokenOptions> = {}) {
    this.endpoints = endpoints;
    this.storage = storage;
    this.refreshTokenOptions = {
      remainingTimeLimitSeconds: refreshTokenOptions.remainingTimeLimitSeconds || 10,
      checkIntervalSeconds: refreshTokenOptions.checkIntervalSeconds || 5,
    };
  }

  get refreshToken() {
    return this.storage.getItem("refreshToken") ?? "";
  }

  set refreshToken(newValue: string) {
    if (newValue) {
      this.storage.setItem("refreshToken", newValue);
    } else {
      this.storage.removeItem("refreshToken");
    }
  }

  get token() {
    return this.storage.getItem("accessToken") ?? "";
  }

  set token(newValue: string) {
    if (newValue) {
      this.storage.setItem("accessToken", newValue);
    } else {
      this.storage.removeItem("accessToken");
    }
  }
  get accessTokenObject() {
    return this.parseAccessToken(this.token);
  }

  private parseAccessToken(accessToken: string): JwtTokenPayload {
    const parsedToken = parseJwt(accessToken);
    return parsedToken.payload;
  }

  private setTokens(accessToken: string, refreshToken: string) {
    this.token = accessToken;
    this.refreshToken = refreshToken;
  }

  private clearTokens() {
    this.token = "";
    this.refreshToken = "";
  }

  private retrieveTokensCookies() {
    const accessToken =
      document?.cookie
        ?.split("; ")
        ?.find((row) => row.startsWith("Jwt-Token"))
        ?.split("=")[1] ?? "";

    const refreshToken =
      document?.cookie
        ?.split("; ")
        ?.find((row) => row.startsWith("Refresh-Token"))
        ?.split("=")[1] ?? "";

    this.setTokens(accessToken, refreshToken);
    document.cookie = "Jwt-Token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    document.cookie = "Refresh-Token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }

  async init() {
    if (document.cookie.includes("Jwt-Token") && document.cookie.includes("Refresh-Token")) {
      this.retrieveTokensCookies();
    }

    if (this.refreshToken && this.token) {
      try {
        if (this.shouldRefreshTokens()) {
          await this.refreshTokens();
        }
        await this.checkIdentity();
        this.startCheckingTokens();
      } catch (ex) {
        await this.logout();
      }
    } else {
      await this.logout();
    }

    this.isInitialized = true;
    this.onUpdateHandler();
  }

  async loginWithEmailAndPassword(params: LoginWithEmailAndPasswordParams) {
    const { email, password, tenantId } = params;

    const { data, error } = await this.endpoints.loginWithEmailAndPassword({
      email,
      password,
      tenantId,
    });

    if (error) {
      throw error;
    }

    this.isAuthenticated = true;
    this.setTokens(data!.accessToken, data!.refreshToken);

    await this.checkIdentity();
    this.startCheckingTokens();
    this.onUpdateHandler();
  }

  async logout() {
    this.isAuthenticated = false;
    this.user = null;
    localStorage.removeItem("tenantId");
    this.clearTokens();
    this.stopCheckingTokens();
    this.onUpdateHandler();
  }

  async checkIdentity() {
    const { data, error } = await this.endpoints.identity(this.token);

    if (error) {
      this.isAuthenticated = false;
      this.token = "";
      this.user = null;

      throw error;
    }

    this.isAuthenticated = true;
    this.user = data;

    localStorage.setItem("tenantId", data?.tenantId ?? "");
  }

  shouldRefreshTokens() {
    // JWT tokens have 'exp' field set in seconds, but Date.now() returns milliseconds, so we need to adjust.
    const currentTimeInSeconds = Date.now() / 1000;
    const remainingTimeSeconds = this.accessTokenObject.exp - currentTimeInSeconds;

    return remainingTimeSeconds <= this.refreshTokenOptions.remainingTimeLimitSeconds;
  }

  async refreshTokens() {
    if (this.refreshToken && this.user?.tenantId) {
      const { data, error } = await this.endpoints.refreshToken(this.refreshToken, this.user?.tenantId);

      if (error) {
        if (error.isExpired) {
          this.logout();
        }

        throw error;
      }

      this.setTokens(data!.accessToken, data!.refreshToken);
      this.startCheckingTokens();
      this.onUpdateHandler();
    }
  }

  startCheckingTokens() {
    this.stopCheckingTokens();

    this.refreshIntervalHandle = setInterval(async () => {
      if (this.shouldRefreshTokens()) {
        try {
          await this.refreshTokens();
        } catch (ex) {
          // Ignore any errors, because we will retry if necessary after a few seconds
        }
      }
    }, this.refreshTokenOptions.checkIntervalSeconds * 1000);
  }

  stopCheckingTokens() {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
      this.refreshIntervalHandle = null;
    }
  }

  async sendVerificationEmail(params: SendVerificationEmailParams) {
    const { email, tenantId, forgotPassword } = params;

    const { error } = await this.endpoints.sendVerificationEmail({
      email,
      tenantId,
      forgotPassword,
    });

    if (error) {
      throw error;
    }
  }

  async resetPassword(params: ResetPasswordParams) {
    const { token, tenantId, newPassword } = params;

    const { error } = await this.endpoints.resetPassword({
      token,
      tenantId,
      newPassword,
    });

    if (error) {
      throw error;
    }
  }

  getIsInitialized() {
    return this.isInitialized;
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getUser() {
    return this.user;
  }

  updateUser(newUserData: Partial<IUser>) {
    // don't merge but overwrite array properties in user {}
    const mergeCustomizer = (prevValue: IUser, newValue: Partial<IUser>) => {
      if (_.isArray(prevValue)) {
        return newValue;
      }
    };
    this.user = _.mergeWith<IUser, Partial<IUser>>(this.user!, newUserData, mergeCustomizer);
    this.onUpdateHandler();
  }

  async getFreshToken() {
    if (this.shouldRefreshTokens()) {
      await this.refreshTokens();
    }

    return this.token;
  }

  checkAccess(options: CheckAccessOptions) {
    console.warn("RestApiClient.checkAccess should be implemented by extending this class in your project.");
    return this.isAuthenticated;
  }

  registerOnUpdateHandler(handler: OnUpdateHandler) {
    this.onUpdateHandler = handler;
  }
}
