import { routes } from "config";

import { TableSidebarOptions } from "components/kendo-table/types/kendo-table.types";
import { ToolbarTabs } from "components/toolbar/types/toolbar.types";

export enum EmployeesTabsNames {
  LIST = "list",
  ARCHIVED = "archived",
  CANDIDATES = "candidates",
  CONTRACTS = "contracts",
}
export const EMPLOYEES_TABS: ToolbarTabs<EmployeesTabsNames> = {
  list: {
    path: routes.employees.list,
    label: "List",
  },
  archived: {
    path: routes.employees.archived,
    label: "Archived",
  },
  candidates: {
    path: routes.employees.candidates.list,
    label: "Candidates",
  },
  // chart: {
  //   path: routes.employees.chart,
  //   label: "Gantt chart",
  // },
  contracts: {
    path: routes.employees.contracts,
    label: "Contracts",
  },
  // metrics: {
  //   path: routes.employees.metrics.replace("/:type?", ""),
  //   label: "Metrics",
  // },
};

export enum EmployeeDetailsTabsNames {
  OVERVIEW = "overview",
  HISTORY = "history",
  EXPERIENCE = "experience",
  RESUME = "resume",
  EVALUATIONS = "evaluations",
  // METRICS = "metrics",
  LOGS = "logs",
  DOCUMENTS = "documents",
  NOTES = "notes",
}
export const EMPLOYEE_DETAILS_TABS: ToolbarTabs<EmployeeDetailsTabsNames> = {
  overview: {
    path: routes.employees.details.replace("/:tab?", "").replace("/:assetId?", ""),
    label: "Overview",
  },
  history: {
    path: routes.employees.details.replace(":tab?", "history").replace("/:assetId?", ""),
    label: "Project history",
  },
  experience: {
    path: routes.employees.details.replace(":tab?", "experience-education").replace("/:assetId?", ""),
    label: "Experience & Education",
  },
  resume: {
    path: routes.employees.details.replace(":tab?", "resume"),
    label: "Resume",
  },
  notes: {
    path: routes.employees.details.replace(":tab?", "notes"),
    label: "Notes",
  },
  evaluations: {
    path: routes.employees.details.replace(":tab?", "evaluations").replace("/:assetId?", ""),
    label: "HR Evaluations",
  },
  // metrics: {
  //   path: routes.employees.details.replace(":tab?", "metrics").replace("/:assetId?", ""),
  //   label: "Metrics",
  // },
  logs: {
    path: routes.employees.details.replace(":tab?", "transaction-log").replace("/:assetId?", ""),
    label: "Transaction log",
  },
  documents: {
    path: routes.employees.details.replace(":tab?", "accounting-documents"),
    label: "Accounting documents",
  },
};

export enum CandidateDetailsTabsNames {
  OVERVIEW = "overview",
  EXPERIENCE = "experience",
  RESUME = "resume",
}
export const CANDIDATE_DETAILS_TABS: ToolbarTabs<CandidateDetailsTabsNames> = {
  overview: {
    path: routes.employees.candidates.details.replace("/:tab?", ""),
    label: "Overview",
  },
  experience: {
    path: routes.employees.candidates.details.replace(":tab?", "experience-education"),
    label: "Experience & Education",
  },
  resume: {
    path: routes.employees.candidates.details.replace(":tab?", "resume"),
    label: "Resume",
  },
};

export const EMPLOYEES_METRICS_TABS: TableSidebarOptions = {
  summary: { label: "Overview", path: routes.employees.metrics.replace("/:type?", "") },
  git: { label: "GIT", path: routes.employees.metrics.replace(":type?", "git") },
  slack: { label: "Slack", path: routes.employees.metrics.replace(":type?", "slack") },
  jira: { label: "Jira", path: routes.employees.metrics.replace(":type?", "jira") },
};
