import React from "react";

import { Box } from "@mui/material";

import clsx from "clsx";

import { EmployeeDetailsTabsNames } from "modules/employees/constants/employees-tabs";

import { EmployeeLink, EmploymentStatusChip, UserAvatar } from "components";

import { EmployeeTimelineGroup } from "../../types/types";
import useStyle from "./chart-group-renderer-employee.style";

type Props = {
  group: EmployeeTimelineGroup;
};

const ChartGroupRendererEmployee: React.FC<Props> = ({ group }) => {
  const classes = useStyle();

  return (
    <div className={clsx(classes.groupContainer, group.parent && classes.isNested, group.isActive && classes.isActive)}>
      <UserAvatar
        fullName={group.title}
        size="md"
        imgUrl={group.photo}
        badge={{ bottom: group.alerts, top: group.isTreatedAsBillable }}
      />
      <Box display="flex" flexDirection="column" className={classes.groupTitle}>
        {group.employeeId ? (
          <EmployeeLink
            employee={{ id: group.employeeId, name: group.title }}
            tabName={EmployeeDetailsTabsNames.HISTORY}
          />
        ) : null}
        {group.position || group.currentEmploymentStatus ? (
          <span className={classes.groupDetails}>
            {group.currentEmploymentStatus && group.currentEmploymentStatus !== "ACTIVE" ? (
              <EmploymentStatusChip size="small" status={group.currentEmploymentStatus} />
            ) : null}
            {group.position ? <span className={classes.groupPosition}>{group.position}</span> : null}
          </span>
        ) : null}
      </Box>
    </div>
  );
};

export default ChartGroupRendererEmployee;
