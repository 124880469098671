import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { EvaluationQuestion, EvaluationType } from "./evaluations-graphql";

export type EvaluationTemplate = {
  id: string;
  type: EvaluationType;
  updatedAt: Date;
  createdAt: Date;
  questions: EvaluationQuestion[];
  name: string;
  emailContent: string;
  emailTitle: string;
  emailTopic: string;
  status?: EvaluationTemplateStatusType;
};

export enum EvaluationTemplateStatusType {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export enum EvaluationTemplateStatusTypeLabel {
  DRAFT = "Draft",
  PUBLISHED = "Published",
  ARCHIVED = "Archived",
}

export const evaluationTemplateDetailsFragment = gql`
  fragment EvaluationTemplateDetailsData on EvaluationTemplateDto {
    id
    type
    name
    emailContent
    emailTitle
    emailTopic
    questions {
      id
      question
      type
      options {
        id
        name
      }
      matrixQuestions {
        id
        name
      }
    }
    status
  }
`;

export const evaluationTemplateListFragment = gql`
  fragment EvaluationTemplateListData on EvaluationTemplateDto {
    id
    type
    name
    emailContent
    emailTitle
    emailTopic
    questions {
      id
      question
      type
      options {
        id
        name
      }
      matrixQuestions {
        id
        name
      }
    }
    updatedAt
    createdAt
    status
  }
`;

export type EvaluationTemplatesList = {
  data?: EvaluationTemplate[];
  metadata?: Metadata;
};
export type GetEvaluationTemplatesData = {
  evaluationTemplates: EvaluationTemplatesList;
};

export type GetEvaluationTemplatesVars = {
  limit: number;
  page?: number;
  search?: string;
  sort?: string;
  types: EvaluationType[];
  excludedStatuses?: EvaluationTemplateStatusType[];
};

export const GET_EVALUATION_TEMPLATES = gql`
  query evaluationTemplates(
    $limit: Int!
    $types: [EvaluationType!]!
    $excludedStatuses: [EvaluationTemplateStatusType!]
  ) {
    evaluationTemplates: getEvaluationTemplates(
      limit: $limit
      types: $types
      excludedStatuses: $excludedStatuses
    ) {
      data {
        ...EvaluationTemplateListData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${evaluationTemplateListFragment}
`;

export type GetEvaluationTemplateData = {
  evaluationTemplate: EvaluationTemplate;
};

export type GetEvaluationTemplateVars = {
  id: string;
};

export const GET_EVALUATION_TEMPLATE = gql`
  query evaluationTemplate($id: String!) {
    evaluationTemplate(id: $id) {
      ...EvaluationTemplateDetailsData
    }
  }
  ${evaluationTemplateDetailsFragment}
`;

export type CreateEvaluationTemplateInput = Pick<
  EvaluationTemplate,
  "type" | "name" | "status" | "emailContent" | "emailTitle" | "emailTopic" | "questions"
>;

export type CreateEvaluationTemplateVars = {
  createEvaluationTemplateInput: CreateEvaluationTemplateInput;
};

export type CreateEvaluationTemplateData = {
  createEvaluationTemplate: EvaluationTemplate;
};

export const CREATE_EVALUATION_TEMPLATE = gql`
  mutation createEvaluationTemplate($createEvaluationTemplateInput: CreateEvaluationTemplateInput!) {
    createEvaluationTemplate(createEvaluationTemplateInput: $createEvaluationTemplateInput) {
      ...EvaluationTemplateDetailsData
    }
  }
  ${evaluationTemplateDetailsFragment}
`;

export type UpdateEvaluationTemplateVars = {
  updateEvaluationTemplateInput: Pick<EvaluationTemplate, "id"> & Partial<CreateEvaluationTemplateInput>;
};

export type UpdateEvaluationTemplateData = {
  updateEvaluationTemplate: EvaluationTemplate;
};

export const UPDATE_EVALUATION_TEMPLATE = gql`
  mutation updateEvaluationTemplate($updateEvaluationTemplateInput: UpdateEvaluationTemplateInput!) {
    updateEvaluationTemplate(updateEvaluationTemplateInput: $updateEvaluationTemplateInput) {
      ...EvaluationTemplateDetailsData
    }
  }
  ${evaluationTemplateDetailsFragment}
`;
