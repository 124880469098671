import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  header: {
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  button: {
    marginRight: spacing(3),
    color: palette.text.blue,
  },
  description: {
    marginTop: spacing(0.5),
    color: palette.text.grey3,
    fontSize: typography.pxToRem(14),
  },
  divider: {
    margin: spacing(3, 0),
  },
}));
