import * as React from "react";
import { useDropzone } from "react-dropzone";
import { FieldRenderProps } from "react-final-form";

import { AttachFile } from "@mui/icons-material";
import { Typography, FormControl, FormHelperText, Box } from "@mui/material";

import _ from "lodash";

import { Button, LoadingPlaceholder } from "components";

import useStyles from "./file-control.styles";
import { getFileErrorMessage } from "./utils";

type Props = FieldRenderProps<File[], HTMLElement> & {
  acceptTypes?: string[];
  acceptTypesText?: string;
  maxSize?: number;
  isLoading?: boolean;
  forcedError?: string;
};

const MultipleFilesControl: React.FC<Props> = ({
  input: { value, onChange },
  meta: { submitError, dirtySinceLastSubmit, error: metaError },
  acceptTypes,
  acceptTypesText,
  maxSize,
  isLoading,
  children,
  forcedError,
}) => {
  const classes = useStyles({});

  const onDropRejected = () => onChange(value);
  const onDropAccepted = (files: File[]) => {
    const uniqueNewValue = _.uniqBy([...value, ...files], (file) => file.name);
    onChange(uniqueNewValue);
  };

  const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize,
    accept: acceptTypes,
  });

  const [isDirty, setIsDirty] = React.useState(false);
  React.useEffect(() => {
    setIsDirty(isDirty || typeof value === "object");
  }, [isDirty, value]);

  const error = metaError || forcedError;
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  return (
    <FormControl error={showError}>
      <div {...getRootProps()} className={classes.dropContainer}>
        <input {...getInputProps()} />
        {children}
      </div>

      <Box mt={2}>
        {value &&
          value.map((file) => (
            <div className={classes.container} key={file.name}>
              <AttachFile color="primary" />
              <Typography color="primary" className={classes.text}>
                {file.name}
              </Typography>
              {isLoading ? (
                <LoadingPlaceholder inline message="" />
              ) : (
                <Button
                  label="Delete"
                  color="error"
                  variant="text"
                  onClick={() => {
                    const indexOfRemovedFile = value?.findIndex(({ name }) => file.name === name);
                    value.splice(indexOfRemovedFile, 1);
                    onChange([...value]);
                  }}
                />
              )}
            </div>
          ))}
      </Box>

      {rejectedFiles.length ? (
        <div className={classes.container}>
          <Typography color="error" variant="h5" className={classes.error}>
            {getFileErrorMessage(rejectedFiles, {
              acceptTypes,
              acceptTypesText,
              maxSize,
              isMultiple: true,
            })}
          </Typography>
        </div>
      ) : (
        showError && <FormHelperText error>{error || submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultipleFilesControl;
