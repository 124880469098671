import axios from "axios";
import createRefreshTokenInterceptor from "axios-auth-refresh";
import { authClient } from "services/auth";
import { config } from "config";

const API = axios.create({
  responseType: "json",
  baseURL: config.backendUrl,
});

const refreshTokenHandler = (_: unknown) => authClient.refreshTokens();
createRefreshTokenInterceptor(API, refreshTokenHandler);

export default API;
