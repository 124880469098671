import { useState } from "react";

const useExpand = () => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleExpand = (id: string) => {
    setExpandedItems((prev) => (prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]));
  };

  const expand = (id: string) => {
    setExpandedItems((prev) => (prev.includes(id) ? prev : [...prev, id]));
  };

  return {
    expandedItems,
    toggleExpand,
    expand,
  };
};

export default useExpand;
