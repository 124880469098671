import React from "react";
import { Typography } from "@mui/material";
import useStyles from "./content.styles";

type Props = {
  title: string;
};

const Content: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <Typography variant="h1" color="textPrimary" classes={{ root: classes.title }}>
          {title}
        </Typography>
        {children}
      </div>
    </div>
  );
};

export default Content;
