import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  moreButton: {
    height: 42,
    padding: spacing(1),
    border: `1px solid ${palette.secondary.main}`,
    "&:hover": {
      backgroundColor: palette.secondary.light,
    },
  },
  moreIcon: {
    fill: palette.secondary.main,
    paddingTop: spacing(1.5),
    fontSize: "2rem",
    width: 26,
  },
  menuItem: {
    border: "none",
  },
  menuItemIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: spacing(1),

    "& svg": {
      width: 20,
      height: 20,
    },
  },
}));
