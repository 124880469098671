import clsx from "clsx";

import React from "react";

import useStyles from "./placeholder.styles";

type Props = {
  image?: React.ReactNode;
  message?: React.ReactNode;
  inline?: boolean;
  color?: "default" | "hint";
  centered?: boolean;
};

const Placeholder: React.FC<Props> = ({ image, message, inline = false, color, centered = false }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, inline && classes.inline)}>
      <div className={clsx(classes.content, inline && classes.inline)}>
        {image && <div className={classes.image}>{image}</div>}
        {message && (
          <div
            className={clsx(
              classes.message,
              inline && classes.inline,
              color && classes[color],
              centered && classes.centered,
            )}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Placeholder;
