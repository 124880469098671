import { useMemo } from "react";

import { StatusChip } from "components";
import {
  EvaluationTemplateStatusType,
  EvaluationTemplateStatusTypeLabel,
} from "components/evaluations/graphql/evaluations-templates-graphql";

type Props = {
  status: EvaluationTemplateStatusType;
};

const TemplateStatusChip: React.FC<Props> = ({ status }) => {
  const chipProps = useMemo(
    () =>
      (() => {
        const flatTypes = Object.entries(EvaluationTemplateStatusType).map((el) => el);
        const foundType = flatTypes.find((el) => el[0] === status);

        switch (foundType?.[1]) {
          case EvaluationTemplateStatusType.PUBLISHED:
            return { label: EvaluationTemplateStatusTypeLabel.PUBLISHED, variant: "success" };
          case EvaluationTemplateStatusType.DRAFT:
            return { label: EvaluationTemplateStatusTypeLabel.DRAFT, variant: "warning" };
          case EvaluationTemplateStatusType.ARCHIVED:
            return { label: EvaluationTemplateStatusTypeLabel.ARCHIVED, variant: "error" };
          default:
            return { label: "Unknown", variant: "error" };
        }
      })(),
    [status],
  );

  return <StatusChip {...chipProps} />;
};

export default TemplateStatusChip;
