import { Dispatch, SetStateAction, useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { State } from "@progress/kendo-data-query";

import { isEmpty } from "lodash";

import { TableData } from "components/kendo-table/types/kendo-table.types";

import {
  checkFiltersEmpty,
  getFiltersConfigFromLS,
  removeConfigFiltersFromLS,
  saveConfigFiltersToLS,
  transformStateForLS,
  transformStateForTable,
} from "./utils/utils";

type Params<T, F> = {
  data?: T[];
  setKendoColumnsFilters?: Dispatch<SetStateAction<State>>;
  setExternalFilters?: (filters: F) => void;
};

const useFiltersLSConfig = <T extends TableData, F extends Record<string, any>>({
  data,
  setKendoColumnsFilters,
  setExternalFilters,
}: Params<T, F>) => {
  const location = useLocation();

  // default as false as it doesn't count default filters, sort and group
  const [isAnyFilterAppliedByUser, setIsAnyFilterAppliedByUser] = useState<boolean>(false);

  // KENDO COLUMNS FILTERS - read from ls
  useEffect(() => {
    if (setKendoColumnsFilters) {
      const configForCurrentLocation = getFiltersConfigFromLS(location.pathname);
      const { externalFilters, ...dataStateFilters } = configForCurrentLocation;

      if (!checkFiltersEmpty(dataStateFilters)) {
        setKendoColumnsFilters(transformStateForTable(dataStateFilters, data ?? []));
        setIsAnyFilterAppliedByUser(true);
      }
    }
  }, [location.pathname, setKendoColumnsFilters, data]);

  // KENDO COLUMNS FILTERS - save to ls
  const saveKendoColumnsFiltersConfig = useCallback(
    (dataState: State) => {
      saveConfigFiltersToLS(location.pathname, transformStateForLS(dataState));
      setIsAnyFilterAppliedByUser(true);
    },
    [location.pathname],
  );

  // EXTERNAL FILTERS - filters other than kendo column filters
  // read from ls
  useEffect(() => {
    if (setExternalFilters) {
      const configForCurrentLocation = getFiltersConfigFromLS(location.pathname);

      if (!isEmpty(configForCurrentLocation?.externalFilters)) {
        setExternalFilters(configForCurrentLocation.externalFilters);
        setIsAnyFilterAppliedByUser(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // EXTERNAL FILTERS - save to ls
  const saveExternalFiltersConfig = useCallback(
    (externalFilters?: F) => {
      if (externalFilters) {
        saveConfigFiltersToLS(location.pathname, { externalFilters });
        setIsAnyFilterAppliedByUser(true);
      }
    },
    [location.pathname],
  );

  // RESET - revert to default settings (default filters, sort and group) only for current location
  const setClearAllFilters = useCallback(
    ({
      defaultStateKendoColumnsFilters,
      defaultStateExternalFilters,
    }: {
      defaultStateKendoColumnsFilters?: State;
      defaultStateExternalFilters?: F;
    }) => {
      removeConfigFiltersFromLS(location.pathname);
      setKendoColumnsFilters?.(defaultStateKendoColumnsFilters ?? {});
      if (defaultStateExternalFilters) {
        setExternalFilters?.(defaultStateExternalFilters);
      }
      setIsAnyFilterAppliedByUser(false);
    },
    [location.pathname, setKendoColumnsFilters, setExternalFilters],
  );

  return {
    saveKendoColumnsFiltersConfig,
    saveExternalFiltersConfig,
    setClearAllFilters,
    isAnyFilterAppliedByUser,
  };
};

export default useFiltersLSConfig;
