import makeStyles from "@mui/styles/makeStyles";

type Params = {
  headerHeight: number;
};

export default makeStyles<CustomTheme, Params>(({ palette, typography, spacing }) => ({
  root: {
    background: palette.background.paper,
    padding: 0,
    minWidth: spacing(20),
    marginTop: ({ headerHeight }) => (headerHeight ? headerHeight : 55),
    borderTopLeftRadius: 10,
    boxShadow: "0 0 10px #f1f1f1",
    borderRight: `1px solid ${palette.text.grey2}`,
  },
  button: {
    position: "relative",
    zIndex: 10,
  },
  itemLabel: {
    fontSize: typography.pxToRem(14),
    fontWeight: typography.fontWeightBold,
  },
}));
