import { ReactElement, useEffect, useMemo, useState } from "react";

import { State } from "@progress/kendo-data-query";

import { isColumnFilterActive } from "./utils/utils";

import { ColumnsVisibilityConfig } from "../../types/kendo-table.types";

type Params = {
  children: ReactElement[];
  dataState: State;
  pickedGroupBy?: string;
};

const useTableColumns = ({ children, dataState, pickedGroupBy }: Params) => {
  const [columnsConfig, setColumnsConfig] = useState<ColumnsVisibilityConfig[]>(
    children.map((el) => {
      return {
        title: el.props.title,
        isVisible: !el.props.defaultHidden,
        defaultHidden: !!el.props.defaultHidden,
        isFilterable: el.props.filterable,
        field: el.props.field,
      };
    }),
  );

  useEffect(() => {
    if (pickedGroupBy) {
      setColumnsConfig((prev) =>
        prev.map((el) => {
          if (el.field === pickedGroupBy) {
            return { ...el, isVisible: false, groupedBy: true };
          } else if (el.groupedBy) {
            return { ...el, isVisible: true, groupedBy: false };
          } else {
            return el;
          }
        }),
      );
    }
  }, [pickedGroupBy]);

  const visibleColumns = useMemo(
    () =>
      children
        .map((child) => {
          const isVisible = columnsConfig.find((el) => el.title === child.props.title)?.isVisible;
          if (!isVisible) {
            return undefined;
          }
          // check if any of the filters in popup is applied
          const isActive = isColumnFilterActive(child.props.field, dataState);
          return isActive ? { ...child, props: { ...child.props, headerClassName: "active" } } : child;
        })
        .filter(Boolean),
    [children, columnsConfig, dataState],
  );

  return {
    columnsConfig,
    setColumnsConfig,
    visibleColumns,
  };
};

export default useTableColumns;
