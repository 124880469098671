import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { TokenRefreshLink } from "apollo-link-token-refresh";
import decodeToken from "jwt-decode";

import { config } from "config";

import { API } from "services/api";
import { authClient } from "services/auth";

import { typePolicies } from "./apollo-type-policies";

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",

  isTokenValidOrUndefined: () => {
    const accessToken = localStorage.getItem("accessToken");
    return !accessToken || decodeToken<Record<string, any>>(accessToken)?.exp > Date.now() / 1000;
  },

  fetchAccessToken: () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const tenantId = localStorage.getItem("tenantId");
    delete API.defaults.headers.Authorization;
    return API.post("/auth/refreshToken", {
      refreshToken,
      tenantId,
    });
  },

  handleFetch: (accessToken) => {
    API.defaults.headers.Authorization = `Bearer ${accessToken}`;
    localStorage.setItem("accessToken", accessToken);
  },

  handleError: (_) => {
    authClient?.logout();
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: `${config.backendUrl}graphql`,
  credentials: "same-origin",
});

const composedLink = ApolloLink.from([tokenRefreshLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  link: composedLink,
  cache: new InMemoryCache({
    typePolicies,
  }),
});

export default apolloClient;
