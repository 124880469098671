import React, { useCallback, useRef } from "react";

import { ConfirmationModal } from "components";

type RenderFunctionProps = {
  setHasFormChanged: (isDirty: boolean) => void;
  handleClose: (data?: Record<string, boolean>) => void;
};

export type Props = {
  render: ({ setHasFormChanged, handleClose }: RenderFunctionProps) => React.ReactNode;
  onClose?(): void;
};

const DirtyFormCloseConfirmation: React.FC<Props> = ({ render, onClose }) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const formDirty = useRef(false);

  const setHasFormChanged = useCallback((isDirty) => {
    formDirty.current = isDirty;
  }, []);

  const handleClose = useCallback(
    (data) => {
      if (!formDirty.current || (formDirty.current && data?.forceClose)) {
        setIsModalOpen(false);
        onClose?.();
      } else {
        setIsModalOpen(true);
      }
    },
    [onClose],
  );

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Are you sure you close this form? All changes will be lost."
        onConfirm={() => handleClose({ forceClose: true })}
        onAbort={() => setIsModalOpen(false)}
        confirmText="Close"
        abortText="Cancel"
        confirmColor="primary"
      />
      {render({ setHasFormChanged, handleClose })}
    </>
  );
};

export default DirtyFormCloseConfirmation;
