import { makeStyles } from "@mui/styles";

type Params = {
  status: boolean | null;
};

export default makeStyles<CustomTheme, Params>(({ palette, typography }) => ({
  itemText: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    fontWeight: typography.fontWeightBold,
    color: palette.text.mercury,
    overflow: "hidden",
    textOverflow: "ellipsis",
    border: 0,
  },
  customItem: {
    transition: "opacity .3s",
    backgroundColor: ({ status }) =>
      typeof status === "boolean"
        ? status
          ? `${palette.background.pink} !important`
          : `${palette.background.green3} !important`
        : `${palette.text.grey4} !important`, // fallback color when no access
    borderRadius: 12,
    color: `${palette.text.mercury} !important`,
    borderColor: "transparent !important",
  },
}));
