import * as React from "react";
import { Typography } from "@mui/material";

import useStyles from "./empty-placeholder.styles";

type Props = {
  text: string;
};

const EmptyPlaceholder: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

export default EmptyPlaceholder;
