import React, { useEffect, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { routes } from "config";
import { DEFAULT_ROUTE } from "config/routes";

import { useAuth } from "services/auth";
import usePermissionsAccess from "services/permissions-access/hooks/usePermissionsAccessContext/usePermissionsAccess";

import GuestRouter from "modules/guest/guest.router";

import { LoadingPlaceholder } from "components";

const App: React.FC = () => {
  const auth = useAuth();
  const LoadingComponent = <LoadingPlaceholder fullscreen message="Loading" />;
  const canProjectRead = usePermissionsAccess(["project.read"]);

  useEffect(() => {
    if (!auth?.isInitialized) {
      auth?.init();
    } else if (!auth?.isAuthenticated) {
      auth?.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated]); // There was a change from [auth] to [auth?.isAuthenticated], because It was creating re-render loop

  if (!auth?.isInitialized) {
    return LoadingComponent;
  }
  // unauthorized routes
  if (!auth?.isAuthenticated) {
    return <GuestRouter />;
  }

  const redirectRoute = canProjectRead ? DEFAULT_ROUTE : routes.timesheets.meta.basePath;

  return (
    <Suspense fallback={LoadingComponent}>
      <Switch>
        {Object.values(routes).map((route) => {
          const Comp = route.meta.routerComponent;
          if (!Comp) return null;

          return <Route key={route.meta.basePath} path={route.meta.basePath} component={Comp} />;
        })}
        <Redirect to={redirectRoute} />
      </Switch>
    </Suspense>
  );
};

export default App;
