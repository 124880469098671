import { useCallback, useEffect, useRef, useState } from "react";

import { ColumnProps } from "../../components/kendo-table/types/kendo-table.types";

const ADJUST_PADDING: number = 4;
const COLUMN_MIN: number = 4;

const useAutoWidth = (columns: ColumnProps[]) => {
  const minGridWidth = useRef<number>(0);
  const grid = useRef<any>(null);
  const [applyMinWidth, setApplyMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);

  const handleResize = useCallback(() => {
    if (grid.current?.offsetWidth < minGridWidth && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current?.offsetWidth > minGridWidth) {
      setGridCurrent(grid.current?.offsetWidth);
      setApplyMinWidth(false);
    }
  }, [applyMinWidth]);

  useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    columns.map((item: ColumnProps) =>
      item.minWidth !== undefined ? (minGridWidth.current += item.minWidth) : minGridWidth.current,
    );
    setGridCurrent(grid.current?.offsetWidth);
    setApplyMinWidth(grid.current?.offsetWidth < minGridWidth.current);
  }, [columns, handleResize]);

  const setWidth = (minWidth = 0) => {
    let width = applyMinWidth ? minWidth : minWidth + (gridCurrent - minGridWidth.current) / columns.length;
    if (width < COLUMN_MIN) {
      width -= ADJUST_PADDING;
    }
    return width;
  };

  return [setWidth];
};

export default useAutoWidth;
