import React from "react";
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from "@mui/material";

import { DropResult, DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useStyles from "./table.styles";

type Props = {
  data: string[];
  name: string;
  handleDragEnd(result: DropResult): void;
};

const ReorderControl: React.FC<Props> = ({ data, name, handleDragEnd }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.root} component={Box}>
      <Table size="small" aria-label={`${name} reorder table`}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`${name}-droppable`} direction="vertical">
            {(provided) => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {data.map((val, index) => (
                    <Draggable draggableId={"index" + index} index={index} key={index}>
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.row}
                        >
                          <TableCell component="td" scope="row" className={classes.cell}>
                            {val}
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export default ReorderControl;
