import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";

import { ErrorResponse } from "@apollo/client/link/error";
import { FormHelperText, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";

import clsx from "clsx";
import { sortBy } from "lodash";

import { backendEndpoints } from "config/routes";

import { API } from "services/api";
import { transformError } from "services/graphql/errorTransformers";

import { AssessmentStatusType } from "modules/employees/graphql/details/employee-details-assessments-graphql";
import { Technology } from "modules/employees/graphql/employees.graphql";
import { useAssessmentSurveyContext } from "modules/evaluation/context/assessment-survey-context";
import { SkillsCategory } from "modules/settings/graphql/configurations/configurations.graphql";

import { Button, Field, TextControl } from "components";

import { useToast } from "hooks";

import { dateToString } from "utils/dateFormat";

import useStyles from "./assessment-survey-matrix-table.style";
import AssessmentSurveyMatrixTableRow from "./row/assessment-survey-matrix-table-row";

type Props = {
  permission: "READ" | "READ_WRITE";
  setIsSubmitted: (submitted: boolean) => void;
};

// group skills by categories and then sort the categories by its order
const prepareSkills = (assessmentSkills: Technology[]) => {
  const skillsWithCategory: SkillsGrouped[] = [];
  const skillsWithoutCategory: SkillsGrouped[] = [];

  (assessmentSkills || [])?.forEach((skill) => {
    if (skill.skillCategory?.id) {
      const foundCategory = skillsWithCategory.find(({ id }) => id === skill.skillCategory?.id);
      if (foundCategory) {
        const index = skillsWithCategory.indexOf(foundCategory);
        skillsWithCategory[index] = {
          ...foundCategory,
          order: foundCategory.order ?? skill.skillCategory.order,
          items: [...foundCategory.items, skill],
        };
      } else {
        skillsWithCategory.push({ ...skill.skillCategory, items: [skill] });
      }
    } else {
      const uncategorized = skillsWithoutCategory.find(({ id }) => id === "uncategorized");

      if (uncategorized) {
        const index = skillsWithoutCategory.indexOf(uncategorized);
        skillsWithoutCategory[index] = { ...uncategorized, items: [...uncategorized.items, skill] };
      } else {
        skillsWithoutCategory.push({ id: "uncategorized", name: "", order: undefined, items: [skill] });
      }
    }
  });

  const orderedSkillsWithCategory = sortBy(skillsWithCategory, ["order"]);
  return [...orderedSkillsWithCategory, ...skillsWithoutCategory];
};

export type SkillsGrouped = SkillsCategory & { items: Technology[] };

type FormType = {
  skills: SkillsGrouped[];
  isCompleted: boolean;
  note: string;
};

const AssessmentSurveyMatrixTable: React.FC<Props> = ({ permission, setIsSubmitted }) => {
  const classes = useStyles();
  const { showToast } = useToast();
  const { data: assessment, headers } = useAssessmentSurveyContext();
  const [isNoteInputError, setIsNoteInputError] = useState(false);

  const handleSubmit = useCallback(
    async (values: FormType) => {
      if (assessment?.id) {
        const assessmentLevelSkills: { level: number | null; id: string }[] = [];
        values.skills.forEach((item) => {
          const skills = item.items.map(({ id, level }) => ({ id, level: level >= 0 ? level : null }));
          assessmentLevelSkills.push(...skills);
        });

        const data = {
          id: assessment.id,
          assessmentLevelSkills,
          isCompleted: values.isCompleted,
          note: values.note,
        };

        try {
          if (permission === "READ_WRITE") {
            await API.post(backendEndpoints.assessmentUpdate, data, {
              headers,
            });
          } else {
            showToast("Unauthorized action", "error");
          }
        } catch (ex) {
          Sentry.captureException(ex);

          const { error, isFormError } = transformError(ex as ErrorResponse);
          if (isFormError) {
            return error;
          } else {
            showToast(error as string, "error");
          }
        } finally {
          if (values.isCompleted) {
            setIsSubmitted(true);
          }
        }
      }
    },
    [assessment, headers, permission, setIsSubmitted, showToast],
  );

  return (
    <Form
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={{
        skills: prepareSkills(assessment?.assessmentSkills || []),
        isCompleted: false,
        note: assessment?.note,
      }}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit: innerSubmit, values, form }) => (
        <form onSubmit={innerSubmit}>
          <div className={classes.container}>
            {values.skills.map((category: SkillsGrouped, index: number) => (
              <AssessmentSurveyMatrixTableRow
                category={category}
                key={index}
                disabled={
                  assessment?.status === AssessmentStatusType.COMPLETE ||
                  permission !== "READ_WRITE" ||
                  assessment?.isCompleted === true
                }
                name={`skills[${index}]`}
              />
            ))}

            <div className={clsx(classes.inputSection, isNoteInputError && classes.hasInputError)}>
              <label htmlFor="note" className={classes.inputLabel}>
                Please let us know, if there is any particular technology that you would like to work in (technology
                areas, languages or frameworks). *
              </label>
              <Field
                name="note"
                margin="dense"
                disabled={assessment?.status === AssessmentStatusType.COMPLETE}
                multiline
                rows={5}
                InputLabelProps={{ shrink: false }}
                render={({ input: { onBlur, onChange, value, ...restInput }, ...rest }) => {
                  const customOnBlur = () => {
                    onBlur();
                    if (!value) {
                      setIsNoteInputError(true);
                    }
                  };
                  const customOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    if (event.target.value) {
                      setIsNoteInputError(false);
                    }
                    onChange(event);
                  };
                  // used custom error setting because when using standard validation,
                  // the field shows error on every checkbox click (when each submit request is run)
                  return (
                    <TextControl
                      input={{ onBlur: customOnBlur, onChange: customOnChange, value, ...restInput }}
                      {...rest}
                    />
                  );
                }}
              />
              {isNoteInputError && <FormHelperText error>Required</FormHelperText>}
            </div>

            <div className={classes.bottomSection}>
              <Typography className={classes.saveDateText}>
                Form autosaved: {dateToString(assessment?.updatedAt, "DD.MM.YY HH:mm")}
              </Typography>
              <Button
                className={classes.submitButton}
                label={"Submit"}
                type="submit"
                onClick={() => {
                  form.mutators.setValue("isCompleted", true);
                }}
                disabled={
                  !values.note || assessment?.status === AssessmentStatusType.COMPLETE || permission !== "READ_WRITE"
                }
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AssessmentSurveyMatrixTable;
