type EnvironmentName = "production" | "development" | "staging" | "developmentAzure";
type EnvironmentConfiguration = {
  tokenReplace?: string;
  backendUrl: string;
  reportGeneratorUrl: string;
  sentrDSN: string;
  employeePortalApi: string
};

const environment: EnvironmentName = process.env.REACT_APP_ENV as EnvironmentName || "development";

const fullConfiguration: Record<EnvironmentName, EnvironmentConfiguration> = {
  developmentAzure: {
    backendUrl: "https://api.cv.azure.erp.acaisoft.dev/",
    reportGeneratorUrl: "https://api.cv.azure.erp.acaisoft.dev/",
    sentrDSN: "https://5d702841dc024a5bab071f68913ab189@sentry2.tools.acaisoft.io/165",
    employeePortalApi: "https://api.employee.azure.erp.acaisoft.dev/",
  },
  development: {
    backendUrl: "https://acai-erp.acaisoft.dev/api/",
    reportGeneratorUrl: "https://acai-erp.acaisoft.dev/api/",
    sentrDSN: "https://5d702841dc024a5bab071f68913ab189@sentry2.tools.acaisoft.io/165",
    employeePortalApi: "https://api.erp-employee.acaisoft.dev/",
  },
  staging: {
    tokenReplace: "token=Token was provided",
    backendUrl: "https://erp-stage.acaisoft.dev/api/",
    reportGeneratorUrl: "https://erp-stage.acaisoft.dev/api/",
    sentrDSN: "https://5d702841dc024a5bab071f68913ab189@sentry2.tools.acaisoft.io/165",
    employeePortalApi: "https://api.employee.erp-stage.acaisoft.dev/",
  },
  production: {
    tokenReplace: "token=Token was provided",
    backendUrl: "https://erp.acaisoft.io/api/",
    reportGeneratorUrl: "https://erp.acaisoft.io/api/",
    sentrDSN: "https://0bdcb26df29b4e85b2d0136c923df51e@sentry2.tools.acaisoft.io/242",
    employeePortalApi: "https://api.employee.erp.acaisoft.io/",
  },
};

export default fullConfiguration[environment];
