import { StylesConfig } from "react-select/src/styles";

import makeStyles from "@mui/styles/makeStyles";

type Params = {
  showError?: boolean;
  isMouseOver?: boolean;
  isDisabled?: boolean;
};

export default makeStyles<CustomTheme, Params>(({ spacing, palette }) => ({
  label: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    left: 1,
    width: "calc(100% - 2px)",
    transform: "unset !important",

    "&:before": {
      borderRadius: "3px 0 0 0",
      display: "block",
      content: "''",
      flex: "0 0 7px",
      height: 2,
      borderTop: "1px solid",
      borderColor: (params) => getBorderColor(palette, params),
    },
    "&:after": {
      borderRadius: "0 3px 0 0",
      display: "block",
      content: "''",
      flex: "2 0 0",
      height: 2,
      borderTop: "1px solid",
      borderColor: (params) => getBorderColor(palette, params),
    },

    "& span": {
      flex: "0 1 auto",
      marginTop: -9,
      padding: spacing(0, 1.5, 0, 0.5),
    },
  },

  errorText: {
    marginLeft: spacing(1),
  },

  helperText: {
    marginTop: spacing(-0.5),
  },
}));

const getBorderColor = (palette: CustomTheme["palette"], { showError, isMouseOver, isDisabled }: Params) => {
  switch (true) {
    case isDisabled:
      return "#e6e6e6";
    case showError && !isMouseOver:
      return palette.error.main;
    case isMouseOver:
      return palette.primary.main;
    default:
      return palette.text.grey2;
  }
};

export function getStyles({ theme, showError }: { theme: CustomTheme; showError: boolean }): StylesConfig {
  const { palette, spacing, shape, typography } = theme;
  return {
    valueContainer: (styles) => ({
      ...styles,
      padding: spacing(0.25, 0.5),
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      padding: spacing(0.75),
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.02)",
      borderRadius: shape.borderRadius,
      backgroundColor: palette.hint.light,
      border: `1px solid ${showError ? palette.error.main : "#b8b7b9"}`,
      borderTop: "unset",
      ":hover": {
        borderColor: palette.primary.main,
      },
      ":focus-within": {
        borderColor: palette.primary.main,
      },
      opacity: isDisabled ? 0.5 : 1,
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: spacing(0.5),
      color: showError ? palette.error.main : palette.hint.main,
      ":hover": {
        color: showError ? palette.error.main : palette.hint.dark,
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: spacing(0.5),
      color: palette.hint.main,
      ":hover": {
        color: palette.hint.dark,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: typography.pxToRem(16),
      color: palette.text.hint,
    }),
    input: (styles) => ({
      ...styles,
      fontSize: typography.pxToRem(16),
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: typography.pxToRem(16),
      "& > *": {
        display: "inline",
        alignItems: "baseline",
        fontSize: typography.pxToRem(16),
        fontWeight: 400,
      },
      color: palette.text.primary,
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: typography.pxToRem(16),
      color: palette.text.default,
      backgroundColor: isFocused && palette.hint.light,
      wordBreak: "break-word",
      ":active": {
        backgroundColor: palette.primary.light,
      },
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: 0,
      zIndex: 2,
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 2000,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: palette.primary.light,
      margin: spacing(0.5),
      fontWeight: "bold",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: palette.primary.main,
      padding: 0,
      margin: spacing(0.5),
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      backgroundColor: palette.primary.light,
      color: palette.primary.main,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      ":hover": {
        backgroundColor: palette.primary.light,
        color: palette.primary.main,
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      width: 0,
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: palette.text.hint,
    }),
    loadingMessage: (styles) => ({
      ...styles,
      color: palette.text.hint,
    }),
  };
}
