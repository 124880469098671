import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, shape, typography }) => ({
  container: {
    width: 645,
    margin: "0 auto",
    marginTop: spacing(14.5),

    "& :focus-visible": {
      outline: 0,
    },
  },
  window: {
    background: palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: spacing(6, 4),
    borderRadius: shape.borderRadius,
  },
  title: {
    color: palette.text.n3,
    fontSize: typography.pxToRem(11),
    lineHeight: typography.pxToRem(13),
    marginTop: spacing(1),
    marginBottom: spacing(6),
    fontWeight: typography.fontWeightBold,
  },
  welcomeTitle: {
    fontSize: typography.pxToRem(34),
    lineHeight: typography.pxToRem(41),
    marginBottom: spacing(1.25),
    color: palette.text.n2,
    fontWeight: typography.fontWeightMedium,
    wordBreak: "break-word",
    textAlign: "center",
  },
  text: {
    color: palette.text.coal,
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(21),
    marginBottom: spacing(5),
    fontWeight: typography.fontWeightMedium,
    textAlign: "center",
    wordBreak: "break-word",
  },
  startButton: {
    background: palette.background.brickPinkE1,
    color: palette.text.contrast,
    padding: spacing(1.5, 5.25),
  },
  tipTop: {
    color: palette.text.grey3,
    fontSize: typography.pxToRem(10),
    lineHeight: typography.pxToRem(13),
    margin: spacing(2, 0),
  },
  tipBottom: {
    fontSize: typography.pxToRem(10),
    lineHeight: typography.pxToRem(13),
  },
  tipBottomBold: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.pxToRem(13),
  },
}));
