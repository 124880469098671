import { routes } from "config";

export const logEntityRouter = {
  projects: routes.projects.list,
  contracts: routes.employees.contracts,
  employees: routes.employees.list,
  projectsEmployeeAssignment: routes.assignments.overview,
  employeeResumes: routes.employees.list,
  documents: routes.projects.list,
  users: routes.settings.users,
  comments: routes.employees.list,
};
