export type JwtTokenPayload = {
  exp: number;
  iat?: number;
  aud?: string;
  iss?: string;
  sub?: string;
  [key: string]: any;
};

export const createFakeJwtToken = (payload: JwtTokenPayload) => {
  const header = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9";
  const signature =
    "POstGetfAytaZS82wHcjoTyoqhMyxXiWdR7Nn7A29DNSl0EiXLdwJ6xC6AfgZWF1bOsS_TuYI3OG85AmiExREkrS6tDfTQ2B3WXlrr-wp5AokiRbz3_oB4OxG-W9KcEEbDRcZc0nH3L7LzYptiy1PtAylQGxHTWZXtGz4ht0bAecBgmpdgXMguEIcoqPJ1n3pIWk_dUZegpqx0Lka21H6XxUTxiy8OcaarA8zdnPUnV6AmNP3ecFawIFYdvJB_cm-GvpCSbr8G8y_Mllj8f4x9nBH8pQux89_6gUY618iYv7tuPWBFfEbLxtF2pZS6YC1aSfLQxeNe8djT9YjpvRZA";
  const encodedPayload = btoa(JSON.stringify(payload));

  return `${header}.${encodedPayload}.${signature}`;
};

export const createFakeRefreshToken = (uuid: string) => btoa(uuid);

const parseJwtPart = (jwtPart: string) => JSON.parse(atob(jwtPart));

export const parseJwt = (token: string) => {
  const [header, payload] = token.split(".");

  return {
    header: parseJwtPart(header),
    payload: parseJwtPart(payload),
  };
};
