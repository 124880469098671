import { Box, Toolbar as MuiToolbar } from "@mui/material";

import useStyles from "./toolbar.styles";

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  tabs?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
};

const Toolbar: React.FC<Props> = ({ left, right, tabs, breadcrumbs }) => {
  const classes = useStyles();

  return (
    <MuiToolbar classes={{ root: classes.root }}>
      <div className={classes.container}>
        {breadcrumbs && <Box mb={3}>{breadcrumbs}</Box>}

        <div className={classes.optionsContainer}>
          <div className={classes.options}>{left}</div>
          {right && <div className={classes.options}>{right}</div>}
        </div>
      </div>

      {tabs && <div className={classes.tabs}> {tabs} </div>}
    </MuiToolbar>
  );
};

export default Toolbar;
