import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing, typography }) => ({
  title: {
    lineHeight: typography.pxToRem(21),
    fontSize: typography.pxToRem(14),
    color: palette.text.blackPearl,
    fontWeight: 700,
    margin: spacing(0, 0, 3, 0),
  },

  section: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(2),
  },

  tile: {
    flexDirection: "column",
    backgroundColor: palette.background.paper,
    padding: spacing(2),
    borderRadius: shape.borderRadius,
    position: "relative",
  },

  empty: {
    textAlign: "center",
    color: palette.text.grey1,
    fontSize: typography.pxToRem(14),
  },
}));
