import { useState } from "react";
import { useHistory } from "react-router";

import { Settings } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

import { routes } from "config";

import { LogoutIcon, PersonCircleIcon } from "assets/images/icons";

import { useAuth } from "services/auth";
import HasAccess from "services/permissions-access/components/has-access/has-access";

import { UserAvatar } from "components";

import HeaderManageAccountModal from "./account-modal/header-account-modal";
import { HeaderModalProvider } from "./context/header-modal.context";
import useStyles from "./header.style";

const Header = () => {
  const auth = useAuth();
  const history = useHistory();

  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const handleLogout = () => {
    auth?.logout();
  };

  const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAccountModalOpen = () => {
    handleMenuClose();
    setAccountModalOpen(true);
  };

  const handleAccountModalClose = () => {
    setAccountModalOpen(false);
  };

  const handleSettingsClick = () => {
    history.push(routes.settings.meta.basePath);
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      <Box className={classes.root}>
        {/* <IconButton>
          <Badge className={classes.notification} variant="dot" color="error">
            <NotificationsNone />
          </Badge>
        </IconButton> */}

        <HasAccess permissions={["configuration.read"]}>
          <IconButton onClick={handleSettingsClick} size="large" aria-label="settings">
            <Settings />
          </IconButton>
        </HasAccess>

        <IconButton onClick={handleMenuOpen} data-cy="logoutMenuButton" size="large" aria-label="user profile">
          <UserAvatar
            imgUrl={auth?.user?.photo && auth?.user?.photo}
            fullName={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
            size="md"
            withPreview={false}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={menuAnchor}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={!!menuAnchor}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAccountModalOpen}>
          <PersonCircleIcon className={classes.menuIcon} />
          <span>Manage account</span>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon className={classes.menuIcon} />
          <span>Log out</span>
        </MenuItem>
      </Menu>
      <HeaderModalProvider
        open={accountModalOpen}
        handleOpen={handleAccountModalOpen}
        handleClose={handleAccountModalClose}
      >
        <HeaderManageAccountModal />
      </HeaderModalProvider>
    </div>
  );
};

export default Header;
