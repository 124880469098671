import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ spacing, typography, palette }) => ({
  container: {
    background: palette.primary.main,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  textUpper: {
    color: palette.text.contrast,
    fontSize: typography.pxToRem(34),
    lineHeight: typography.pxToRem(41),
    marginBottom: spacing(1),
  },
  textBottom: {
    color: palette.text.contrast,
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(21),
  },
  icon: {
    marginBottom: spacing(3),
  },
}));
