import { gql } from "@apollo/client";

import { permissionFragment, Permission } from "./permissions.graphql";

import { Metadata } from "services/graphql/types";

export type Role = {
  id: string;
  name: string;
  key: string;
  permissionIds: string[];
  description?: string;
  isDeleted?: boolean;
  isSystem?: boolean;
  logoSmUrl?: string;
};

export const roleFragment = gql`
  fragment RoleData on Role {
    id
    name
    key
    permissionIds
    description
    isDeleted
    isSystem
    logoSmUrl
  }
`;

export type GetRolesData = {
  roles: {
    data: Role[];
    metadata?: Metadata;
  };
};

export type GetRolesVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export const GET_ROLES = gql`
  query roles($limit: Int!, $page: Int!, $sort: String, $search: String) {
    roles(limit: $limit, page: $page, sort: $sort, search: $search) {
      data {
        ...RoleData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${roleFragment}
`;

export type GetRolesAndPermissionsData = {
  roles: {
    data: Role[];
    metadata?: Metadata;
  };
  permissions: {
    data: Permission[];
    metadata?: Metadata;
  };
};

export type GetRolesAndPermissionsVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export const GET_ROLES_AND_PERMISSIONS = gql`
  query rolesAndPermissions($limit: Int!, $page: Int!, $sort: String, $search: String) {
    roles(limit: $limit, page: $page, sort: $sort, search: $search) {
      data {
        ...RoleData
      }
      metadata {
        count
        limit
        page
      }
    }
    permissions(limit: 0, page: 1) {
      data {
        ...PermissionData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${roleFragment}
  ${permissionFragment}
`;

export type CreateRoleData = {
  role: Role;
};

export type CreateRoleVars = {
  createRoleInput: Omit<Role, "id">;
};

export const CREATE_ROLE = gql`
  mutation createRole($createRoleInput: CreateRoleInput!) {
    role: createRole(createRoleInput: $createRoleInput) {
      ...RoleData
    }
  }
  ${roleFragment}
`;

export type UpdateRoleData = {
  role: Role;
};

export type UpdateRoleVars = {
  updateRoleInput: Omit<Role, "key">;
};

export const UPDATE_ROLE = gql`
  mutation updateRole($updateRoleInput: UpdateRoleInput!) {
    role: updateRole(updateRoleInput: $updateRoleInput) {
      ...RoleData
    }
  }
  ${roleFragment}
`;
