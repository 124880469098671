import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { Employee, Technology } from "../employees.graphql";

export type Assessment = {
  id: string;
  requester?: Pick<Employee, "id" | "firstName" | "lastName">;
  selfAssessmentEmployee: { firstName: Employee["firstName"]; lastName: Employee["lastName"] };
  status: AssessmentStatusType;
  createdAt: Date;
  updatedAt: Date;
  assessmentSkills: Technology[];
  viewerToken: string;
  isCompleted?: boolean;
  note?: string;
};

export enum AssessmentStatusType {
  SENT = "SENT",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export const assessmentFragment = gql`
  fragment SelfAssessmentData on SelfAssessmentDto {
    id
    status
    viewerToken
    requester {
      id
      firstName
      lastName
    }
    selfAssessmentEmployee {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
    note
  }
`;

export type AssessmentsList = {
  data: Assessment[];
  metadata: Metadata;
};
export type GetAssessmentsData = {
  selfAssessments: AssessmentsList;
};

export type GetAssessmentsVars = {
  selfAssessmentEmployeeId: string;
  limit: number;
  recipientEmail?: string;
  search?: string;
  sort?: string;
};

export const GET_ASSESSMENTS = gql`
  query getSelfAssessments($limit: Int!, $selfAssessmentEmployeeId: String!) {
    selfAssessments: getSelfAssessments(limit: $limit, selfAssessmentEmployeeId: $selfAssessmentEmployeeId) {
      data {
        ...SelfAssessmentData
      }
    }
  }
  ${assessmentFragment}
`;

export type GetAssessmentsSkillsData = {
  assessmentsSkills: AssessmentsList;
};
export type GetAssessmentsSkillsVars = {
  limit: number;
};

export const GET_ASSESSMENTS_SKILLS = gql`
  query getSelfAssessments($limit: Int!) {
    assessmentsSkills: getSelfAssessments(limit: $limit) {
      data {
        id
        selfAssessmentEmployee {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
        assessmentSkills {
          id
          name
          level
        }
        note
      }
    }
  }
`;

export type RequestEmployeeSelfAssessmentData = {
  requestSelfAssessment: Assessment;
};

export type CreateSelfAssessmentInput = {
  assessmentSkillsIds: string[];
  recipientEmail: string;
  selfAssessmentEmployeeId: string;
};

export type RequestEmployeeAssessmentVars = {
  createSelfAssessmentInput: CreateSelfAssessmentInput;
};

export const REQUEST_EMPLOYEE_ASSESSMENT = gql`
  mutation requestSelfAssessment($createSelfAssessmentInput: CreateSelfAssessmentInput!) {
    requestSelfAssessment(createSelfAssessmentInput: $createSelfAssessmentInput) {
      ...SelfAssessmentData
    }
  }
  ${assessmentFragment}
`;
