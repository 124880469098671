import makeStyles from "@mui/styles/makeStyles";

type Params = {
  hasValue?: boolean;
};

export default makeStyles<CustomTheme, Params>(({ typography, shape, spacing, palette }) => ({
  dialogContent: {
    maxHeight: "70vh",
  },
  filter: {
    background: palette.background.paper,
    padding: `${spacing(1.5, 5.5, 1.5, 2)} !important`,
    paddingRight: ({ hasValue }) => `${hasValue ? spacing(7) : spacing(4.5)}px !important`,
    color: ({ hasValue }) => (hasValue ? palette.text.primary : palette.text.grey1),
    borderRadius: shape.borderRadius,
    border: `1px solid black`,
    textDecoration: "none !important",
    fontSize: typography.pxToRem(14),

    "& *": {
      fontSize: "inherit !important",
    },
  },
  arrow: {
    position: "absolute",
    right: 13,
    top: "50%",
    transform: "translateY(-50%)",
  },
  columnNameRow: {
    padding: spacing(1, 0),
  },
  columnNameCheckbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  columnsButton: {
    background: palette.background.paper,
  },
}));
