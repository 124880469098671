import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette }) => ({
  paginationContainer: {
    position: "relative",
    zIndex: 1,
    backgroundColor: palette.background.default,
    height: 60,
    boxShadow: `0px 20px 40px ${palette.hint.main + "20"}`,
  },
}));
