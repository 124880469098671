import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ breakpoints, palette, spacing }) => ({
  container: {
    right: 0,
    top: 0,
    marginTop: spacing(-1),
  },
  toast: {
    borderRadius: 0,
    paddingRight: 20,
    [breakpoints.up("sm")]: {
      borderRadius: "0px 0px 2px 2px",
    },
  },
  success: {
    backgroundColor: palette.secondary.main,
  },
  error: {
    backgroundColor: palette.error.main,
  },
  closeButton: {
    marginTop: spacing(-2),
  },
  closeIcon: {
    fontSize: "1rem",
  },
}));
