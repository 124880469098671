import React from "react";

import { Box } from "@mui/material";

import { AssessmentStatusType } from "modules/employees/graphql/details/employee-details-assessments-graphql";
import { useAssessmentSurveyContext } from "modules/evaluation/context/assessment-survey-context";

import EvaluationSurveyCompletedScreen from "../evaluation/components/completed-screen/evaluation-survey-completed-screen";
import SurveyFormTopSection from "./components/form-top-section/survey-form-top-section";
import AssessmentSurveyInitialScreen from "./components/initial-screen/assessment-survey-initial-screen";
import AssessmentSurveyMatrixTable from "./components/matrix-table/assessment-survey-matrix-table";
import { ErrorPlaceholder, LoadingPlaceholder } from "components";

type Props = {
  permission: "READ" | "READ_WRITE";
};

const AssessmentSurveyContainer: React.FC<Props> = ({ permission }) => {
  const [showWelcomeScreen, setShowWelcomeScreen] = React.useState(permission === "READ_WRITE");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { data: assessment, loading, error, veryFirstLoad } = useAssessmentSurveyContext();

  if (veryFirstLoad && veryFirstLoad.current && loading) {
    return <LoadingPlaceholder />;
  }

  if (error) {
    return <ErrorPlaceholder message={error} />;
  }

  return (
    <>
      {showWelcomeScreen && assessment?.status === AssessmentStatusType.SENT ? (
        <AssessmentSurveyInitialScreen setShowWelcomeScreen={setShowWelcomeScreen} />
      ) : !isSubmitted ? (
        <Box width="100%">
          <SurveyFormTopSection employee={assessment?.selfAssessmentEmployee} version="assessment" />
          <AssessmentSurveyMatrixTable permission={permission} setIsSubmitted={setIsSubmitted} />
        </Box>
      ) : (
        <EvaluationSurveyCompletedScreen type="assessment" />
      )}
    </>
  );
};

export default AssessmentSurveyContainer;
