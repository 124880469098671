import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ spacing, typography, palette, breakpoints: mq, shape }) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(4, 0, 4, 0),
    margin: 0,
    width: "100%",
    [mq.up("lg")]: {
      padding: spacing(3, 4, 10, 4),
    },
  },
  container: {
    color: palette.text.black,
    width: "100%",
    height: "100%",
    maxWidth: 870,
    padding: spacing(0, 2),
    [mq.up(870)]: {
      padding: spacing(0),
    },
  },
  box: {
    alignItems: "center",
    marginBottom: spacing(2.5),
    [mq.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  formGroup: {
    width: "100%",
    margin: spacing(3.5, 0),
    background: palette.background.default,
    padding: spacing(3, 2.5, 3),
    borderRadius: shape.borderRadius,
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
    "&:first-child": {
      marginTop: 0,
    },
    "& .MuiInputLabel-outlined": {
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(18),
      color: palette.text.black,
      fontWeight: typography.fontWeightBold,
    },
  },
  autosaveDate: {
    fontWeight: "normal",
    marginTop: spacing(2.25),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.grey3,
    [mq.up("sm")]: {
      marginTop: 0,
      display: "block",
    },
  },
  submitButton: {
    background: palette.secondary.main,
    color: palette.text.contrast,
    marginTop: spacing(2.25),
    width: "100%",
    [mq.up("sm")]: {
      marginTop: 0,
      width: "auto",
    },
  },
  completedContainer: {
    background: palette.primary.main,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  completedText: {
    color: palette.text.contrast,
  },
  infoBox: {
    display: "block",
    textAlign: "center",
    [mq.up("sm")]: {
      textAlign: "left",
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));
