import { Box, Typography } from "@mui/material";

import { Employee } from "modules/employees/graphql/employees.graphql";

import UserAvatar from "components/user-avatar/user-avatar";

import useStyles from "./kendo-table-user-cell.style";

export type KendoTableUserCellProps = Pick<
  Employee,
  "photo" | "firstName" | "lastName" | "alerts" | "isTreatedAsBillable"
> & {
  subtitle?: string;
};

const KendoTableUserCell: React.FC<KendoTableUserCellProps> = ({
  photo,
  firstName,
  lastName,
  alerts,
  isTreatedAsBillable,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <UserAvatar
        imgUrl={photo}
        fullName={`${firstName} ${lastName}`}
        badge={{ bottom: alerts ?? [], top: isTreatedAsBillable }}
      />
      <div>
        <Typography component="div" className={classes.employeeName}>
          {lastName}
        </Typography>
        {subtitle ? <Typography component="div" className={classes.subtitle}>{`${subtitle}`}</Typography> : null}
      </div>
    </Box>
  );
};

export default KendoTableUserCell;
