import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  avatarContainer: {
    marginRight: spacing(1),
    "&$lg": {
      marginRight: spacing(5),
    },
  },
  avatar: {
    border: `2px solid ${palette.hint.light}`,
    width: 24,
    height: 24,

    "&$md": {
      width: 35,
      height: 35,
    },
    "&$lg": {
      width: 100,
      height: 100,
    },
    "&$hasAvatar": {
      backgroundColor: "transparent",
      border: "none",
    },
  },
  userIcon: {
    fontSize: "2rem",
    marginTop: spacing(1),

    "&$md": {
      fontSize: "2.5rem",
      marginTop: spacing(1.2),
    },
    "&$lg": {
      fontSize: "7rem",
      marginTop: spacing(4),
    },
  },
  initials: {
    fontWeight: "bold",
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    textTransform: "uppercase",
    fontSize: "0.6rem",
    borderColor: palette.hint.light,
    borderStyle: "solid",
    borderWidth: 1,

    "&$md": {
      fontSize: "0.9rem",
      borderWidth: 2,
    },
    "&$lg": {
      fontSize: "2.25rem",
      borderWidth: 3,
    },
  },
  hasAvatar: {},
  sm: {},
  md: {},
  lg: {},
}));
