import makeStyles from "@mui/styles/makeStyles";

type Params = {
  hasLabel: boolean;
};

export default makeStyles<CustomTheme, Params>(({ spacing, typography, palette, breakpoints: mq }) => ({
  fieldLabel: {},
  infoBox: {
    [mq.up("md")]: {
      display: "block",
    },
  },
  formControlLabel: {
    marginRight: spacing(1),
  },
  formControlLabelRoot: {
    marginLeft: spacing(-0.75),
  },
  radioGroup: {
    flexDirection: "row",
    marginTop: ({ hasLabel }) => (hasLabel ? spacing(1) : 0),
  },
  radioInput: {
    padding: spacing(0.5),
    marginTop: 0,
    "& .MuiSvgIcon-root": {
      fontSize: typography.pxToRem(25),
    },
    color: palette.text.grey5,
  },
}));
