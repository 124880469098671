import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ breakpoints, palette, shape, spacing, typography }) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    borderRadius: shape.borderRadius,
    backgroundColor: palette.background.default,

    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root: {
    backgroundColor: palette.background.default,
    textDecoration: "none",
    width: "100%",
    minHeight: "unset",
    height: 40,
    borderRadius: shape.borderRadius,
  },
  input: {
    padding: spacing(1),
    fontSize: typography.pxToRem(14),
    "&::-ms-clear": {
      display: "none",
    },
  },
  normal: {
    margin: spacing(2, 1),
  },
  outlined: {
    border: `1px solid ${palette.text.n5}`,
    padding: 0,
  },
  inputAdornment: {
    color: palette.text.n2,
    marginLeft: 0,
    marginRight: 0,
  },
  resetIconButton: {
    padding: spacing(0.5),
    marginRight: spacing(0.5),
  },
  resetIcon: {
    height: 18,
    width: 18,
  },
  searchIcon: {
    height: 18,
    width: 18,
    "&$start": {
      margin: spacing(0.75, 0, 0, 1),
    },
    "&$end": {
      margin: spacing(0.75, 1, 0, 0),
    },
  },
  start: {},
  end: {},
  hidden: {
    opacity: 0,
    visibility: "hidden",
  },
}));
