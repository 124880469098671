import { useState } from "react";

import { Typography } from "@mui/material";

import { GIT_COMMIT_HASH } from "config/git";

import InfoModal from "../sidebar/info-modal/info-modal";
import useStyles from "./version-info.style";

const VersionInfo = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      {GIT_COMMIT_HASH && (
        <div>
          <Typography className={classes.version}>Version: {GIT_COMMIT_HASH}</Typography>
          <Typography className={classes.info} onClick={() => setModalOpen(true)}>
            More info
          </Typography>
        </div>
      )}

      <InfoModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default VersionInfo;
