import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing, palette, breakpoints }) => ({
  croppContainer: {
    position: "relative",
    padding: 0,
    minHeight: 200,
    marginTop: spacing(3),
    width: "100%",

    [breakpoints.up("lg")]: {
      minHeight: 400,
    },
  },

  zoomContainer: {
    padding: spacing(3, 4, 0),
    overflow: "hidden",

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },

  slider: {
    color: palette.secondary.light,
    height: 3,
    padding: "13px 0",
  },

  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -2,
    marginLeft: -9,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },

  active: {},

  track: {
    height: 3,
  },

  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },

  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },

  formError: {
    paddingTop: spacing(1),

    "& p": {
      textAlign: "center",
      margin: 0,
    },
  },
}));
