import React from "react";
import { Field as FinalFormField, FieldProps } from "react-final-form";

// replace empty string and undefined values to null when passing the value to form
const identity = (value: any, props: any) => {
  if (props.type === "number" && typeof Number(value) === "number") {
    return Number(value);
  }
  return value === "" || value === undefined ? null : value;
};

const Field: React.FC<FieldProps<any, any>> = (props) => (
  <FinalFormField
    parse={(value, name) => (props.parse ? props.parse(value, name) : identity(value, props))}
    {...props}
  />
);

export default Field;
