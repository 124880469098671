import { makeStyles } from "@mui/styles";

type Props = {
  maxWidth?: number;
  maxWidthContent?: number;
};

export default makeStyles<CustomTheme, Props>(({ breakpoints, palette, spacing }) => ({
  dialogPaper: {
    margin: spacing(2),
    padding: spacing(3),
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: ({ maxWidth }) => maxWidth ?? "unset",
    [breakpoints.up("sm")]: {
      margin: spacing(4),
      padding: spacing(3, 5),
    },
  },
  closeButton: {
    position: "absolute",
    top: spacing(1.5),
    right: spacing(1.5),
    padding: spacing(0.5),
  },
  closeIcon: {
    fill: palette.text.n4,
  },
  dialogTitle: {
    marginBottom: spacing(4),
  },
  dialogContent: {
    margin: "auto",
    maxWidth: ({ maxWidthContent }) => maxWidthContent ?? "unset",
  },
}));
