import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing, typography }) => ({
  groupRowContainer: {
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
    marginTop: spacing(3),
    borderRadius: shape.borderRadius,
  },
  groupRow: {
    padding: spacing(2.5, 4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 870,
    borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0px 0px`,
    background: palette.background.default,
    boxShadow: "0px 2px 1px rgba(84, 84, 84, 0.05)",
    marginBottom: spacing(0.25),
  },
  groupTitle: {
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.grey7,
    fontWeight: typography.fontWeightBold,
  },

  expandedButton: {
    padding: spacing(0.5),
  },
  expandedIcon: {
    transform: "rotate(270deg)",
    color: palette.text.n4,
  },
}));
