import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, typography }) => ({
  customTimeHeader: {
    lineHeight: typography.pxToRem(27),
    textAlign: "center",
    cursor: "pointer",
    background: palette.background.default,
    color: palette.text.n4,
    borderLeft: `1px solid ${palette.text.n5}`,
    borderBottom: `1px solid ${palette.text.n5}`,
  },
}));
