import * as React from "react";
import { IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

import useStyles from "./removable-field.styles";

type Props = {
  inputField: React.ReactNode;
  onDelete: () => void;
  iconDisabled?: boolean;
};

const RemovableField: React.FC<Props> = ({ inputField, onDelete, iconDisabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.questionWrapper}>
      {inputField}
      <IconButton
        disabled={iconDisabled}
        aria-label="delete"
        onClick={onDelete}
        className={classes.removeQuestionIcon}
        size="large">
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default RemovableField;
