import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { transformError } from "services/graphql/errorTransformers";

import {
  GetEmployeesNamesData,
  GetEmployeesNamesVars,
  GET_EMPLOYEES_NAMES,
} from "modules/employees/graphql/employees.graphql";

import { KendoTableSelectFilter } from "components";
import { TableGroupByOptions } from "components/kendo-table/types/kendo-table.types";

type Props = {
  value: string[] | string | undefined;
  onChange(val: string[] | string): void;
  isMulti?: boolean;
  isClearable?: boolean;
};

const EmployeeFilter: React.FC<Props> = ({ value, onChange, isMulti = true, isClearable = true }) => {
  const {
    data: employees,
    loading,
    error,
  } = useQuery<GetEmployeesNamesData, GetEmployeesNamesVars>(GET_EMPLOYEES_NAMES, {
    variables: {
      limit: 0,
      page: 1,
      sort: "lastName, firstName",
    },
    fetchPolicy: "cache-and-network",
  });

  const options = useMemo(() => {
    if (error) {
      return [{ label: transformError(error).error as string, value: "", disabled: true }];
    } else {
      return (
        employees?.employees?.data?.reduce((obj, employee) => {
          if (employee) {
            obj.push({ value: employee.id, label: employee.lastName + " " + employee.firstName });
          }

          return obj;
        }, [] as TableGroupByOptions["options"]) || []
      );
    }
  }, [employees, error]);

  return (
    <KendoTableSelectFilter
      onChange={onChange}
      isMulti={isMulti}
      placeholder="Employees"
      value={value}
      isSearchable
      isLoading={loading}
      options={options}
      isClearable={isClearable}
    />
  );
};

export default EmployeeFilter;
