import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles<CustomTheme>(({ palette, typography }) =>
  createStyles({
    root: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },

    label: {
      fontSize: typography.pxToRem(15),
    },
  })
);
