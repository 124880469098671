import { makeStyles } from "@mui/styles";

type Params = {
  disableBottomMargin: boolean;
};

export default makeStyles<CustomTheme, Params>(({ spacing, palette, typography, breakpoints: mq }) => ({
  titleContainer: {
    textAlign: "center",
    [mq.up("md")]: {
      display: "flex",
    },
  },
  title: {
    "&$withSubtitle": {
      marginRight: spacing(2),
      fontWeight: 400,
    },
  },
  subtitle: {
    fontWeight: 500,
    margin: spacing(0, 2),
  },
  withSubtitle: {},
  arrowIcon: {
    color: palette.text.primary,
    marginTop: spacing(0.5),
    fontSize: "1.6rem",
  },

  // header variants
  main: {
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  form: {
    marginBottom: spacing(3),
  },
  section: {
    marginBottom: ({ disableBottomMargin }) => (disableBottomMargin ? 0 : spacing(3)),
  },
  evaluation: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(26),
    marginBottom: 0,
  },
}));
