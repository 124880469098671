import { ErrorResponse } from "@apollo/client/link/error";
import { ApolloError } from "@apollo/client";

type MessageObject = {
  statusCode: number;
  error: string;
  message: string;
};

export type ConstraintObject = {
  property: string;
  constraints: { [key: string]: string };
};

export const transformError = (
  errors: ErrorResponse | ApolloError,
  fallbackMessage: string | undefined = "Sorry, something went wrong"
) => {
  const parsedErrors = errors?.graphQLErrors?.map((error) => {
    // Official typings are different to what we have, hence these assertions
    const messageObject = (error.message as unknown) as MessageObject;

    // If message object is array, it's indication of different shape than general errors
    if (Array.isArray(messageObject)) {
      return getConstraints(messageObject);
    }
    if (typeof messageObject === "string") {
      return messageObject;
    }

    return messageObject.message;
  });

  // If elements of parsed errors array are objects instead of strings, then it's a sign
  // that I can assume that these are errors in different shape that's dedicated for forms
  // I don't handle possibility of form errors array having more than one element
  // One element from this array is supposedly the whole package of form errors
  if (typeof parsedErrors?.[0] === "object") {
    return { error: parsedErrors?.[0], isFormError: true };
  }

  // If array of errors is empty or undefined but we're calling this function, then something is wrong
  // and some sort of error happened but backend didn't return any information on this
  if (parsedErrors?.length === 0 || !parsedErrors) {
    return { error: fallbackMessage, isFormError: false };
  }

  const uniqueParsedErrors = [...new Set(parsedErrors)];
  const coalescedMessage = uniqueParsedErrors.join(". ");

  const withDot = uniqueParsedErrors.length > 1;
  return {
    error: `${coalescedMessage}${withDot ? "." : ""}`,
    isFormError: false,
  };
};

export const getConstraints = (errorsArray: Array<ConstraintObject>) => {
  let constraintsObject: Record<any, string> = {};
  errorsArray.forEach(({ constraints, property }) => {
    constraintsObject = {
      ...constraintsObject,
      [property]: Object.values(constraints)?.[0],
    };
  });

  return constraintsObject;
};
