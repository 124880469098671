import React from "react";
import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { routes } from "config";

import { BillableStatusChip, UserAvatar } from "components";

import useStyles from "./details-element.style";

type DetailsLayoutProps = {
  type?: "employee" | "project";
  id: string;
  name: string;
  photo?: string;
  billableStatus?: boolean | null;
  availability?: number;
  role?: string;
};

const DetailsElement: React.FC<DetailsLayoutProps> = ({
  id,
  name,
  photo,
  billableStatus,
  availability,
  role,
  type = "employee",
}) => {
  const classes = useStyles({ type });

  return (
    <Box className={classes.root}>
      {type === "employee" && (
        <Link to={routes.employees.details.replace(":id", id)}>
          <UserAvatar size="md" imgUrl={photo} />
        </Link>
      )}
      <Box className={classes.section}>
        <Box className={classes.nameContainer}>
          <Link
            to={
              type === "employee"
                ? routes.employees.details.replace(":id", id)
                : routes.projects.details.replace(":id", id)
            }
          >
            <Typography className={classes.name}>{name}</Typography>
          </Link>
          {billableStatus !== undefined && <BillableStatusChip isBillable={billableStatus} />}
        </Box>
        <Box className={classes.detailsContainer}>
          {role ? (
            <Typography component="p" className={classes.role}>
              {role}
            </Typography>
          ) : null}
          {availability !== undefined ? <Typography component="p">{availability}% FTE</Typography> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsElement;
