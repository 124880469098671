import { Employee, ExpirableDocument, Technology } from "../graphql/employees.graphql";
import {
  EmployeePersonalFormType,
  EmployeeContractFormType,
  EmployeeAssignmentFormType,
  EmployeeDocumentFormType,
  Contract,
} from "./employee-contract.types";

export type EmployeeFormType = {
  personal: EmployeePersonalFormType;
  contract: EmployeeContractFormType;
  assignment: EmployeeAssignmentFormType;
  documents: EmployeeDocumentFormType;
};

export type ExpirableDocumentStatus = "Active" | "Overdue";

export type ExpirableDocumentTableData = ExpirableDocument & {
  status: ExpirableDocumentStatus;
};

export type EmployeeTableData = Employee & {
  category?: string | null;
  candidateSource?: string | null;
  onboardingBuddyLabel?: string | null;
  currentEmploymentStatusLabel?: EmploymentStatusTypeLabel | null;
  effigyApprovalLabel?: string | null;
  startDateLabel?: string;
  supervisorsLabel?: string | null;
  managersLevel1Label?: string | null;
  managersLevel2Label?: string | null;
  projectsLabel?: string | null;
  kidsBelow8YearsOldLabel?: string | null;
};

export type ContractTableData = Contract & {
  firstName?: string | null;
  lastName?: string | null;
};

export enum Operator {
  EQUAL_TO = "eq",
  LESS_THAN = "lt",
  LESS_THAN_OR_EQUAL = "lte",
  GREATER_THAN = "gt",
  GREATER_THAN_OR_EQUAL = "gte",
}

export enum OperatorSign {
  "eq" = "=",
  "lt" = "<",
  "lte" = "<=",
  "gt" = ">",
  "gte" = ">=",
}

export const skillsFilterInitialValues = {
  skills: undefined,
};

export const projectsFilterInitialValues = {
  project: undefined,
  supervisor: undefined,
  managerLevel2: undefined,
  managerLevel1: undefined,
  notFullBillable: false,
  notAssignedEmployeesOnly: false,
  pastAssignments: false,
  employee: undefined,
  employeeRole: undefined,
  employeeCategory: undefined,
  treatedAsBillable: false,
};

export type SkillsFilterType = {
  skills?: { level: number; skillId: Technology["id"]; operator: Operator }[];
};

export type ProjectsFilterType = {
  project?: string[];
  supervisor?: string[];
  managerLevel2?: string[];
  managerLevel1?: string[];
  notFullBillable?: boolean;
  notAssignedEmployeesOnly?: boolean;
  pastAssignments?: boolean;
  employee?: string[];
  employeeRole?: string[];
  employeeCategory?: string[];
  treatedAsBillable?: boolean;
};

export type ProjectsAndSkillsFilterType = ProjectsFilterType & SkillsFilterType;

export enum EmploymentStatusTypeLabel {
  ACTIVE = "Active",
  MATERNITY_LEAVE = "Maternity leave",
  STARTING = "Starting",
  TERMINATION = "Termination",
  LONG_HOLIDAYS = "Long holidays",
  ARCHIVED = "Archived",
}

export enum EvaluationStatusLabel {
  SENT = "Sent",
  IN_PROGRESS = "In progress",
  COMPLETE = "Complete",
}

export enum EvaluationTypeLabel {
  HR = "Manager Evaluation",
  PROJECT = "Project",
  TALK_TO_GROW_EMPLOYEE = "Employee feedback",
  TALK_TO_GROW_MANAGER = "Manager Summary",
}

export enum AssessmentStatusLabel {
  SENT = "Sent",
  IN_PROGRESS = "In progress",
  COMPLETE = "Complete",
}

export enum BillableStatus {
  BILLABLE = "Billable",
  NON_BILLABLE = "Non billable",
}

export enum EmployeeAlertType {
  CONTRACT_ENDS_BEFORE_ASSIGNMENT = "CONTRACT_ENDS_BEFORE_ASSIGNMENT",
  NOT_FULLY_BILLABLE = "NOT_FULLY_BILLABLE",
  NO_ASSIGNMENT = "NO_ASSIGNMENT",
  NO_ASSIGNMENT_AT_START_DATE = "NO_ASSIGNMENT_AT_START_DATE",
  NO_SELF_ASSESSMENT = "NO_SELF_ASSESSMENT",
}

export enum EmployeeAlertTypeLabel {
  CONTRACT_ENDS_BEFORE_ASSIGNMENT = "Contract expires before last assignment",
  NOT_FULLY_BILLABLE = "Employee is not fully billable",
  NO_ASSIGNMENT = "Employee has no assignment",
  NO_ASSIGNMENT_AT_START_DATE = "No project assigned at starting date",
  NO_SELF_ASSESSMENT = "Employee didn't complete self-assessment",
}

export enum EmployeeEnglishLevelLabel {
  A1 = "A1 (Beginner)",
  A2 = "A2 (Beginner +)",
  B1 = "B1 (Intermediate)",
  B2 = "B2 (Intermediate +)",
  C1 = "C1 (Advanced)",
  C2 = "C2 (Advanced +)",
}
