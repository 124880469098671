import _ from "lodash";

import { BLANK_LABEL } from "../../../utils/kendo-table.utils";

import { TableData } from "../../../types/kendo-table.types";

export const CHECK_ALL_VALUE = "check-all";

export const isAllVisibleAreSelected = (itemsVisible: string[], itemsSelected: string[]) => {
  return (
    (itemsVisible.length > 0 && itemsVisible.every((i) => Boolean(itemsSelected.find((s) => s === i)))) ||
    (itemsVisible.length === 0 && itemsSelected.length > 0)
  );
};

export const prepareItemsList = (data: TableData[], fieldName: string) => {
  let isBlankLabelVisible = false;

  let allItems = _.uniq(
    data
      .map((item) => {
        const fieldNames = fieldName.split("."); // split fieldName to get to nested value
        const value = fieldNames.reduce((obj, key) => obj?.[key as any], item);
        const isEmpty = !(value || value === 0);
        if (isEmpty) {
          isBlankLabelVisible = true;
        }
        return isEmpty ? "" : `${value}`.split(", ");
      })
      .filter(Boolean)
      .flat(),
  );

  // add "Blank" option only if at least one value is empty
  if (isBlankLabelVisible) {
    allItems = [BLANK_LABEL].concat(allItems);
  }

  return allItems;
};

export const handleSetOperatorFn = (
  fieldValue: string | number | undefined | null,
  checkedValues: string[],
  allItems: any[],
) => {
  if (checkedValues.length === 0) {
    return allItems; // if all items are unchecked -> return all
  }
  return checkedValues
    .map((val) => {
      if (fieldValue === undefined || fieldValue === null) {
        return val === BLANK_LABEL;
      }
      if (typeof fieldValue === "number") {
        return fieldValue === +val;
      }
      return fieldValue.includes(val);
    })
    .some((val) => !!val);
};
