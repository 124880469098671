import clsx from "clsx";

import * as React from "react";

import { FormHelperText } from "@mui/material";

import useStyles from "./form-error.styles";

type Props = {
  center?: boolean;
  className?: string;
};

const FormError: React.FC<Props> = ({ children, className, center = false }) => {
  const classes = useStyles();

  return (
    <FormHelperText
      error
      classes={{
        root: classes.root,
        error: clsx(center ? classes.centeredError : undefined, className),
      }}
    >
      {children}
    </FormHelperText>
  );
};

export default FormError;
