import {
  Evaluation,
  EvaluationMatrixQuestion,
  EvaluationStatusType,
} from "components/evaluations/graphql/evaluations-graphql";

import {
  EvaluationSurveyFormType,
  EvaluationSurveyQuestionAndAnswerType,
} from "../../../../../types/evaluation-survey-types";

export const getInitialValues = ({ evaluation }: { evaluation?: Evaluation }) =>
  ({
    answers: evaluation
      ? evaluation?.status === EvaluationStatusType.SENT
        ? evaluation?.questions
        : evaluation?.questions?.reduce((arr, item) => {
            const foundAns = evaluation.answers.find((ans) => ans.id === item.id);
            arr.push({
              ...item,
              answer: foundAns?.answer,
              matrixQuestions: item.matrixQuestions?.reduce((arr, matrixAns) => {
                const foundMatrixAns = foundAns?.matrixAnswers?.find((ans) => ans.matrixQuestionId === matrixAns.id);
                arr.push({
                  id: matrixAns.id,
                  name: matrixAns.name,
                  selectedOptionId: foundMatrixAns?.selectedOptionId,
                });
                return arr;
              }, [] as EvaluationMatrixQuestion[]),
            });
            return arr;
          }, [] as EvaluationSurveyQuestionAndAnswerType[])
      : [],
    isCompleted: evaluation?.status === EvaluationStatusType.COMPLETE,
  } as EvaluationSurveyFormType);
