import clsx from "clsx";

import * as React from "react";

import { Typography } from "@mui/material";
import { TypographyProps, TypographyClassKey } from "@mui/material/Typography";
import { KeyboardArrowRight } from "@mui/icons-material";

import useStyles from "./header.styles";

type Variant = "main" | "form" | "section" | "evaluation";

type TypographyPropsMap = {
  [key in Variant]: {
    variant: TypographyProps["variant"];
    component: TypographyClassKey;
  };
};
const typographyPropsMap: TypographyPropsMap = {
  main: {
    variant: "h1",
    component: "h1",
  },
  form: {
    variant: "h2",
    component: "h2",
  },
  section: {
    variant: "h4",
    component: "h4",
  },
  evaluation: {
    variant: "h1",
    component: "h1",
  },
};

type Props = {
  title: React.ReactNode;
  variant: Variant;
  className?: string;
  subtitle?: string;
  disableBottomMargin?: boolean;
  titleAvatar?: React.ReactNode;
};

const Header: React.FC<Props> = ({
  className,
  title,
  variant,
  subtitle,
  disableBottomMargin = false,
  titleAvatar,
  ...props
}) => {
  const classes = useStyles({ disableBottomMargin });

  return (
    <div className={clsx(classes.titleContainer, className)}>
      {titleAvatar}
      <Typography
        color={subtitle ? "textSecondary" : "textPrimary"}
        {...typographyPropsMap[variant]}
        className={clsx(classes.title, classes[variant], subtitle && classes.withSubtitle)}
        {...props}
      >
        {title}
      </Typography>

      {subtitle && (
        <>
          <KeyboardArrowRight className={classes.arrowIcon} />
          <Typography color="textPrimary" {...typographyPropsMap[variant]} className={classes.subtitle}>
            {subtitle}
          </Typography>
        </>
      )}
    </div>
  );
};

export default Header;
