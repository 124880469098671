import { useSnackbar, VariantType } from "notistack";

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = (message: string, variant: VariantType) =>
    enqueueSnackbar(message, {
      variant,
    });

  return { showToast };
};

export default useToast;
