import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { User } from "modules/settings/graphql/users.graphql";

export type LogItem = {
  logType?: LogItemType;
  changes: Change;
  user: User;
  id: string;
};

export type Change = {
  from: string;
  to: string;
  entity:
    | "projects"
    | "contracts"
    | "employees"
    | "projectsEmployeeAssignment"
    | "employeeResumes"
    | "documents"
    | "users"
    | "comments";
  fields: string;
};

export enum LogItemType {
  CREATE = "CREATE",
  REMOVE = "REMOVE",
  UPDATE = "UPDATE",
}

export type GetLogListData = {
  auditLogs: {
    data: LogItem[];
    metadata: Metadata;
  };
};

export type GetLogListVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
  resourceId?: string;
};

export const GET_LOG_LIST = gql`
  query auditLogs($limit: Int!, $page: Int!, $sort: String, $search: String, $resourceId: String) {
    auditLogs(limit: $limit, page: $page, sort: $sort, search: $search, resourceId: $resourceId) {
      data {
        id
        logType
        changes {
          entity
          from
          to
        }
        user {
          firstName
          lastName
          employeeId {
            id
            firstName
            lastName
          }
        }
      }
      metadata {
        count
        limit
        page
      }
    }
  }
`;
