import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ typography, palette }) => ({
  version: {
    fontSize: typography.pxToRem(16),
    color: palette.text.hint,
  },

  info: {
    fontWeight: typography.fontWeightBold,
    color: palette.text.hint,

    fontSize: typography.pxToRem(14),

    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
