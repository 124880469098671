import _ from "lodash";
import { MomentInput, utc } from "moment";
import validate from "validate.js";

import { capitalizeFirstLetter, pluralize, regex } from "./strings";

type FinalFormValues = {
  [key: string]: any;
};

validate.extend(validate.validators.datetime, {
  parse: (value: MomentInput) => utc(value),
  format: (value: MomentInput) => utc(value).format("L"),
});

validate.validators.noSpace = function (value: string, options: any, key: string) {
  const regexp = new RegExp(regex.noSpace);

  if (value === undefined || value === null || value === "") {
    return null;
  }

  if (!regexp.test(value)) {
    return `^${capitalizeFirstLetter(options?.key ?? key)} can't have spaces`;
  }

  return null;
};

validate.validators.website = function (value: string, options: any, key: string) {
  const re_weburl = new RegExp(regex.weburl);

  if (value === undefined || value === null || value === "") {
    return null;
  }

  if (!re_weburl.test(value)) {
    return (
      options?.message ??
      `^${options?.key || capitalizeFirstLetter(key)} must be a valid URL like: https://acaisoft.com`
    );
  }

  return null;
};

validate.validators.arrayLength = function (value: Array<any>, options: any, key: string) {
  const preparedKey =
    options.minLength === 1
      ? `${pluralize(options.key ?? key, 1)} is`
      : `${pluralize(options.key ?? key, options.minLength)} are`;

  if (typeof options.minLength !== "undefined" && value?.length < options.minLength) {
    return `^Minimum ${options.minLength} ${preparedKey} required`;
  }

  if (typeof options.maxLength !== "undefined" && value?.length > options.minLength) {
    return `^Maximum ${options.minLength} ${preparedKey} allowed`;
  }
  return null;
};

validate.validators.arrayUniqField = function (value: Array<any>, options: any, key: any, attributes: any) {
  if (typeof options.field !== "undefined") {
    const uniqueCount = [...new Set(value.map((item) => item[options.field]))].length;

    if (uniqueCount !== value.length) {
      return `^Field ${options.field} must be unique`;
    }
  }

  return null;
};

validate.validators.phoneNumber = function (value: string, options: any, key: string) {
  const re_phone = new RegExp(regex.phone);

  if (value === undefined || value === null || value === "") {
    return null;
  }

  const valueLength = value?.match(/\d/g)?.length || 0;

  if (valueLength < 9 || valueLength > 12 || !re_phone.test(value)) {
    return options?.message ?? `^${options?.key || capitalizeFirstLetter(key)} must be a valid phone number`;
  }

  return null;
};

export const createValidator = (schema: object) => {
  const validator = (values: FinalFormValues) => {
    const errors = validate(values, schema);

    if (!errors) {
      return {};
    }

    return _.mapValues(errors, (err) => (Array.isArray(err) && err.length > 0 ? err[0] : err));
  };

  return validator;
};
