import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { ProjectEmployeeAssignment } from "modules/assignments/graphql/project-employee-assignment.graphql";
import { Office, Grade, SkillsCategory, Role } from "modules/settings/graphql/configurations/configurations.graphql";
import { ExpirableDocumentType } from "modules/settings/graphql/configurations/expirable-document-type-config.graphql";

import { Project } from "../../projects/graphql/projects.graphql";
import {
  Contract,
  EmployeeAssignmentFormType,
  EmployeeContractFormType,
  EmployeePersonalFormType,
  EmployeeDocumentFormType,
} from "../types/employee-contract.types";
import { EmployeeAlertType, EmployeeFormType, Operator } from "../types/employees.types";
import { employeeFragment } from "./details/employee-details-graphql";
import { Certificate, Education, Experience } from "./employee-resume-graphql";

export const employeeExpirableDocumentsFragment = gql`
  fragment EmployeeExpirableDocumentsData on Employee {
    expirableDocuments {
      createdAt
      expirationDate
      expiring
      id
      type {
        id
        name
      }
      typeId
      url
      validFrom
    }
  }
`;

export const employeeListFragment = gql`
  fragment EmployeeListData on Employee {
    currentEmploymentStatus
    firstName
    id
    companyEmail
    privateEmail
    country
    location
    lastName
    photo
    phone
    startDate
    endDate
    linkedinLink
    resumeLink
    github
    accountingId
    effigyApproval
    role {
      id
      name
    }
    grade {
      id
      name
    }
    office {
      id
      name
    }
    onboardingBuddy {
      id
      firstName
      lastName
    }
    categoryId
    candidateSourceId
    alerts
    projects {
      id
      name
    }
    kidsBelow8YearsOld
    isTreatedAsBillable
  }
`;

export const employeeArchivedListFragment = gql`
  fragment EmployeeArchivedListData on Employee {
    currentEmploymentStatus
    firstName
    id
    lastName
    photo
    endDate
    skills {
      id
      name
      level
      visible
      skillCategory {
        id
        name
      }
    }
    role {
      id
      name
    }
    grade {
      id
      name
    }
    isTreatedAsBillable
  }
`;

const managersFragment = gql`
  fragment ManagersData on Employee {
    managersLevel1 {
      id
      firstName
      lastName
    }
    managersLevel2 {
      id
      firstName
      lastName
    }
    supervisors {
      id
      firstName
      lastName
    }
  }
`;

export type SkillsFilter = {
  skillId: string;
  skillLevel: number;
  skillOperator: Operator;
};

export type GetEmployeesVars = {
  page?: number;
  limit?: number;
  sort?: string;
  search?: string;
  skills?: SkillsFilter[];
};

export type GetEmployeesData = {
  employees: {
    data: Employee[];
    metadata: Metadata;
  };
};

export const GET_EMPLOYEES = (canCostActiveContractRead?: boolean) => gql`
  query employees(
    $limit: Int
    $page: Int
    $sort: String
    $search: String
    $skills: [skillsArgs!]
 ) {
    employees: employeesTestVersion(
      limit: $limit
      page: $page
      sort: $sort
      search: $search
      skills: $skills
    ) {
      data {
        ...EmployeeListData
        ${canCostActiveContractRead ? "costActiveContract" : ""}
        ...ManagersData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${employeeListFragment}, ${managersFragment}
`;

export type GetArchivedEmployeesVars = GetEmployeesVars;
export type GetArchivedEmployeesData = {
  archivedEmployees: {
    data: Employee[];
    metadata: Metadata;
  };
};

export const GET_ARCHIVED_EMPLOYEES = gql`
  query archivedEmployees($limit: Int, $page: Int, $sort: String, $search: String) {
    archivedEmployees(limit: $limit, page: $page, sort: $sort, search: $search) {
      data {
        ...EmployeeArchivedListData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${employeeArchivedListFragment}
`;

export type CreateEmployeeData = {
  employee: Employee;
};

export type CreateEmployeeVars = {
  createEmployeeInput: Omit<Employee, "id">;
};

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($createEmployeeInput: CreateEmployeeInput!) {
    employee: createEmployee(createEmployeeInput: $createEmployeeInput) {
      id
    }
  }
`;

export type UpdateEmployeeAssociatedEmailsVars = {
  updateEmployeeInput: Pick<Employee, "id" | "associatedEmails">;
};

export type UpdateEmployeeData = {
  updateEmployee: Employee;
};

export type UpdateEmployeeVars = {
  updateEmployeeInput: Partial<Omit<EmployeeFormType["personal"], "associatedEmails" | "skills">> & {
    skills?: Omit<Technology, "name">[];
    id: Employee["id"];
    associatedEmails?: Employee["associatedEmails"];
  };
};

export type UpdateEmployeeSkillsVars = {
  updateEmployeeInput: Pick<Employee, "id"> & {
    skills: Omit<Technology, "name">[];
  };
};

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($updateEmployeeInput: UpdateEmployeeInput!) {
    updateEmployee(updateEmployeeInput: $updateEmployeeInput) {
      ...EmployeeData
      ...EmployeeExpirableDocumentsData
    }
  }
  ${employeeFragment}, ${employeeExpirableDocumentsFragment}
`;

export type UpdateEmployeeResumeData = {
  employeeResume: {
    experiences?: Experience[];
    education?: Education[];
    certificates?: Certificate[];
  };
};

export type UpdateEmployeeResumeVars = {
  employeeResumeInput: {
    id: Employee["id"];
    experiences?: Experience[];
    education?: Education[];
    certificates?: Certificate[];
  };
};
// update resume (experiences, education and certificates) assigned directly to Employee
export const UPDATE_EMPLOYEE_RESUME = gql`
  mutation updateResume($employeeResumeInput: EmployeeResumeInput!) {
    employeeResume: updateResume(EmployeeResumeInput: $employeeResumeInput) {
      experiences {
        company
        position
        grade
        project
        description
        technologies
        start
        end
        isCurrent
        visible
      }
      certificates {
        name
        start
        visible
      }
      education {
        name
        fieldOfStudy
        start
        end
        isCurrent
        visible
      }
    }
  }
`;

export type BasicEmployee = {
  id: string;
  firstName: string;
  lastName: string;
  photo?: string | null;
  skills?: Technology[];
  alerts?: EmployeeAlertType[] | null;
  grade: Grade;
  currentEmploymentStatus?: EmploymentStatusType;
  isTreatedAsBillable?: boolean; // treated as billable e.g. despite not having assignments
};

export type Employee = BasicEmployee & {
  onboardingBuddy: BasicEmployee;
  office: Office;
  location?: string;
  phone?: string;
  role: Role;
  englishLevel?: EmployeeEnglishLevel;
  startDate: string;
  endDate: string;
  associatedEmails: string[];
  country?: string;
  companyEmail?: string;
  privateEmail?: string;
  costActiveContract?: number;
  managersLevel1?: BasicEmployee[];
  managersLevel2?: BasicEmployee[];
  supervisors?: BasicEmployee[];
  reasonForTermination?: EmploymentReasonForTerminationType | null;
  resume?: {
    experiences?: Experience[];
    education?: Education[];
    certificates?: Certificate[];
  };
  expirableDocuments?: ExpirableDocument[];
  resumeLink?: string;
  linkedinLink?: string;
  categoryId?: string;
  effigyApproval?: boolean;
  projects?: Project[];
  candidateSourceId?: string;
  kidsBelow8YearsOld?: boolean;
  accountingId?: string;
  github?: string;
};

export type ExpirableDocument = {
  createdAt: string;
  employee?: Employee;
  employeeId?: Employee["id"];
  expirationDate?: string | null;
  expiring?: boolean;
  id: string;
  type: ExpirableDocumentType;
  typeId: string;
  url: string;
  validFrom?: string;
};

export type Technology = {
  id: string;
  name: string;
  visible?: boolean;
  level: number;
  skillCategory?: SkillsCategory;
};

export type TenantTechnology = {
  id: string;
  name: string;
  level: number;
  skillsCategory?: SkillsCategory;
};

export enum EmployeeEnglishLevel {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export enum EmploymentStatusType {
  ACTIVE = "ACTIVE",
  MATERNITY_LEAVE = "MATERNITY_LEAVE",
  STARTING = "STARTING", // employee hired, but doesn't start working yet
  TERMINATION = "TERMINATION",
  LONG_HOLIDAYS = "LONG_HOLIDAYS",
  ARCHIVED = "ARCHIVED",
}

export enum EmploymentReasonForTerminationType {
  CLIENT_ISSUES = "CLIENT_ISSUES",
  MONEY = "MONEY",
  UNDER_PERFORMANCE = "UNDER_PERFORMANCE",
}

export type CreateEmployeeWithDependenciesData = {
  newEmployeeData: {
    createEmployee: Employee;
    createContract?: Contract;
    createProjectEmployeeAssignment?: ProjectEmployeeAssignment;
    createExpirableDocuments?: ExpirableDocument[];
  };
};

export type CreateEmployeeWithDependenciesVars = {
  createEmployeeWithAllDependenciesInput: {
    createEmployee: Omit<EmployeePersonalFormType, "associatedEmails" | "skills"> & {
      skills?: Omit<Technology, "name">[];
      associatedEmails: Employee["associatedEmails"];
    };
    createContract?: Omit<EmployeeContractFormType, "company" | "indefiniteDate"> & {
      company: string;
    };
    createProjectEmployeeAssignment?: Omit<EmployeeAssignmentFormType, "billableStatus" | "availability"> & {
      billableStatus: boolean;
      availability: number;
    };
    createExpirableDocuments?: EmployeeDocumentFormType["expirableDocuments"];
    transformCandidate?: boolean;
    candidateId?: string;
  };
};

export const CREATE_EMPLOYEE_WITH_DEPENDENCIES = gql`
  mutation createEmployeeWithAllDependencies(
    $createEmployeeWithAllDependenciesInput: CreateEmployeeWithAllDependenciesInput!
  ) {
    newEmployeeData: createEmployeeWithAllDependencies(
      createEmployeeWithAllDependenciesInput: $createEmployeeWithAllDependenciesInput
    ) {
      createEmployee {
        id
        lastName
        firstName
      }
    }
  }
`;

export const GET_MANAGERS = gql`
  query getManagers {
    getManagers {
      allManagersLevel1 {
        id
        firstName
        lastName
      }
      allManagersLevel2 {
        id
        firstName
        lastName
      }
      allSupervisors {
        id
        firstName
        lastName
      }
    }
  }
`;

export type ManagerType = {
  id: string;
  firstName: string;
  lastName: string;
};

export type Managers = {
  allManagersLevel1: ManagerType[];
  allManagersLevel2: ManagerType[];
  allSupervisors: ManagerType[];
};

export type GetManagersData = {
  getManagers: Managers;
};

export type GetEmployeesNamesVars = {
  page?: number;
  limit?: number;
  sort?: string;
  search?: string;
};

export type GetEmployeesNamesData = {
  employees: {
    data: Pick<Employee, "id" | "firstName" | "lastName" | "companyEmail">[];
  };
};

export const GET_EMPLOYEES_NAMES = gql`
  query employeesNames($limit: Int, $page: Int, $sort: String, $search: String) {
    employees(limit: $limit, page: $page, sort: $sort, search: $search) {
      data {
        id
        firstName
        lastName
        companyEmail
      }
    }
  }
`;
