import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { GroupDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";

import { useQueryParams } from "hooks";

import { removeEmptyProperties } from "./utils/utils";

import { TableGroupByOptions } from "../../types/kendo-table.types";

type TableQueryParams = {
  take?: number;
  skip?: number;
  sort?: string;
  dir?: "asc" | "desc";
  group?: string;
  search?: string;
};

type Props = {
  groupBy?: string;
  groupByOptions?: TableGroupByOptions;
  sortBy?: SortDescriptor[];
  withQueryParams?: boolean;
  allColumnFilter: string | null;
};

const useTableDataState = ({ groupBy, groupByOptions, sortBy, withQueryParams, allColumnFilter }: Props) => {
  const history = useHistory();
  const { queryParams } = useQueryParams();

  const [pickedGroupBy, setPickedGroupBy] = useState(Object.values(groupByOptions?.options || {})[0]?.value || groupBy);

  const initialDataState: State = useMemo(() => {
    let state = {};
    if (pickedGroupBy || sortBy)
      state = {
        ...state,
        sort: sortBy,
        group: pickedGroupBy ? [{ field: pickedGroupBy }] : undefined,
      };
    return state;
  }, [pickedGroupBy, sortBy]);

  useEffect(() => {
    setDataState((prev) => ({ ...prev, group: pickedGroupBy ? [{ field: pickedGroupBy }] : undefined }));
  }, [pickedGroupBy]);

  const group = queryParams.get("group")?.split("%");
  const sort = queryParams.get("sort");
  const dir = queryParams.get("dir");
  const skip = queryParams.get("skip");
  const take = queryParams.get("take");

  const dataStateFromParams: State = useMemo(
    () => ({
      skip: skip ? +skip : undefined,
      take: take ? +take : undefined,
      sort: sort
        ? ([
            {
              field: sort,
              dir: dir,
            },
          ] as SortDescriptor[])
        : undefined,
      group: group?.map((it) => ({
        field: it,
      })),
    }),
    [dir, group, skip, take, sort],
  );

  if (!group && groupBy) {
    const groupByState: GroupDescriptor[] = [{ field: groupBy }];
    dataStateFromParams.group = groupByState;
  }

  if (!sort && sortBy) {
    dataStateFromParams.sort = sortBy;
  }

  const [dataState, setDataState] = useState<State>(withQueryParams ? dataStateFromParams : initialDataState);

  useEffect(() => {
    if (withQueryParams) {
      const currentParams = new URLSearchParams(history.location.search);

      const initialParams: TableQueryParams = {
        ...dataState,
        group: dataState.group?.map((group) => group.field).join("%"),
        sort: dataState.sort?.map((i) => i.field)[0],
        dir: dataState.sort?.map((i) => i.dir)[0],
        search: allColumnFilter || undefined,
      };
      const params = removeEmptyProperties(initialParams);
      const page = queryParams.get("page");
      const rows = queryParams.get("rows");
      const newUrlSearchParams = new URLSearchParams(params);
      if (page) {
        newUrlSearchParams.append("page", page);
      }
      if (!currentParams.has("search") && allColumnFilter) {
        newUrlSearchParams.delete("page"); // go to page=1 if allColumnFilter is applied (page param is deleted -> empty page is treated as page=1)
      }
      if (rows) {
        newUrlSearchParams.append("rows", rows);
      }
      const newSearch = `?${newUrlSearchParams.toString()}`;
      if (newSearch.replace("?", "") !== `${queryParams}`.replace("?", "")) {
        history.push({
          search: `${newSearch}`,
        });
      }
    }
  }, [allColumnFilter, history, dataState, withQueryParams, queryParams]);

  return {
    initialDataState,
    dataState,
    setDataState,
    pickedGroupBy,
    setPickedGroupBy,
  };
};

export default useTableDataState;
