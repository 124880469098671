import { Divider, Typography } from "@mui/material";

import { Button } from "components";

import { SECTIONS } from "../types/account-settings.types";
import useStyles from "./account-settings-overview.style";
import AccountSettingsPhotoControl from "./photo-control/account-settings-photo-control";

type Props = {
  handleSectionChange: React.Dispatch<React.SetStateAction<SECTIONS>>;
};

const AccountSettingsOverview: React.FC<Props> = ({ handleSectionChange }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.section}>
          <Typography className={classes.header}>Photo</Typography>
          <AccountSettingsPhotoControl />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.section}>
          <Typography className={classes.header}>Password</Typography>
          <Typography component="div" className={classes.description}>
            You have never changed your password
          </Typography>
          <Button
            variant="text"
            size="medium"
            className={classes.button}
            onClick={() => handleSectionChange(SECTIONS.changePassword)}
            label="Change password"
          />
        </div>
      </div>
    </>
  );
};

export default AccountSettingsOverview;
