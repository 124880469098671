import { LogItem, LogItemType } from "modules/settings/graphql/audit-log.graphql";

import CreateLog from "./components/create-log/create-log";
import RemoveLog from "./components/remove-log/remove-log";
import UpdateLog from "./components/update-log/update-log";

type Props = {
  dataItem: LogItem;
  index?: number;
  isDetailsPage?: boolean;
};

export type LogType = LogItem & {
  isDetailsPage?: boolean;
};

const AuditLogItem: React.FC<Props> = ({ dataItem, isDetailsPage }) => {
  switch (dataItem.logType) {
    case LogItemType.CREATE:
      return (
        <CreateLog changes={dataItem.changes} user={dataItem.user} id={dataItem.id} isDetailsPage={isDetailsPage} />
      );
    case LogItemType.UPDATE:
      return (
        <UpdateLog changes={dataItem.changes} user={dataItem.user} id={dataItem.id} isDetailsPage={isDetailsPage} />
      );
    case LogItemType.REMOVE:
      return (
        <RemoveLog changes={dataItem.changes} user={dataItem.user} id={dataItem.id} isDetailsPage={isDetailsPage} />
      );
    default:
      return <>Not supported type!</>;
  }
};

export default AuditLogItem;
