import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  // WithParents
  parents: {
    color: palette.text.hint,
  },

  // WithCounter
  textWithCounter: {
    padding: spacing(1, 10),
    fontSize: "0.875rem",
    color: palette.text.hint,
    textAlign: "center",
  },
}));
