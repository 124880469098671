import { useState, useEffect } from "react";
import { FieldRenderProps } from "react-final-form";

import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, TextField } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";

import { useTimezoneContext } from "services/timezone/context/timezone.context";

import useStyles from "./date-time-picker-control.styles";

moment.updateLocale("en", {
  week: {
    dow: 1, // set monday as first day of the week
  },
});

type Variant = "date" | "dateTime" | "time";

const getVariant = (variant: Variant) => {
  const types = {
    date: {
      Component: DatePicker,
      dateFormat: "YYYY-MM-DD",
    },
    dateTime: {
      Component: DateTimePicker,
      dateFormat: "YYYY-MM-DD HH:mm",
    },
    time: {
      Component: TimePicker,
      dateFormat: "HH:mm",
    },
  };
  return types[variant];
};

type Props = FieldRenderProps<string, HTMLElement> & {
  label: string;
  dateFormat?: string;
  forcedError?: string;
  inputOnChange?: (value: string | null) => void;
  variant?: Variant;
  valueInitial?: Date | string;
};

const DateTimePickerControl: React.FC<Props> = ({
  input: { name, value, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  label,
  dateFormat,
  forcedError,
  inputOnChange,
  variant = "date",
  valueInitial,
  ...props
}) => {
  const { timezone } = useTimezoneContext();
  const classes = useStyles();

  const [inputVisible, setInputVisible] = useState(true);
  const [isDialogClosed, setIsDialogClosed] = useState(false);

  const error = forcedError || metaError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = (isDialogClosed || isDirty) && hasErrors;

  useEffect(() => {
    setInputVisible(false);
    const timezone = setTimeout(() => {
      setInputVisible(true);
    }, 0);

    return () => clearTimeout(timezone);
  }, [timezone]);

  const Variant = getVariant(variant);

  return (
    <>
      {inputVisible && (
        <Box className={classes.root}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Variant.Component
              mask="____-__-__" // bug: not working when 'views' prop is passed
              label={label}
              renderInput={({ inputProps, ...params }) => {
                return (
                  <TextField
                    {...params}
                    name={name}
                    helperText={showError ? error || submitError : undefined}
                    error={showError}
                    classes={{ root: classes.input }}
                    variant="outlined"
                    inputProps={{
                      ...inputProps,
                    }}
                    {...restInput}
                    InputLabelProps={{ shrink: true }}
                  />
                );
              }}
              value={value === "" ? null : value} // null is needed for 'clear' button to work
              onChange={(e) => {
                if (variant === "time" && valueInitial) {
                  // bug: TimePicker changes date to today
                  onChange(
                    moment(e).set({
                      year: moment(valueInitial).year(),
                      month: moment(valueInitial).month(),
                      date: moment(valueInitial).date(),
                      hours: moment(e).hours(),
                      minutes: moment(e).minutes(),
                    }),
                  );
                } else {
                  onChange(e);
                }
                inputOnChange?.(e);
              }}
              inputFormat={dateFormat ? dateFormat : Variant.dateFormat}
              onClose={() => setIsDialogClosed(true)}
              components={{
                OpenPickerIcon: variant === "time" ? ScheduleIcon : EventOutlinedIcon,
              }}
              OpenPickerButtonProps={{
                className: classes.icon,
              }}
              PaperProps={{
                classes: {
                  root: classes.paperRoot,
                },
              }}
              ampm={false}
              {...restInput}
              {...props}
            />
          </LocalizationProvider>
        </Box>
      )}
    </>
  );
};

export default DateTimePickerControl;
