import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette }) => ({
  customMarker: {
    borderRight: `1px dashed ${palette.background.pacificBlue} !important`,
    zIndex: 100,
    backgroundColor: "transparent !important",
    top: 3,
  },
  indicator: {
    display: "block",
    width: 8,
    height: 8,
    borderRadius: 8,
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    background: palette.background.pacificBlue,
  },
}));
