import { useRef, useEffect } from "react";

type WindowEventHandler<K extends keyof WindowEventMap> = (e: WindowEventMap[K]) => void;

// this needs more general types if we want to use it with other elements than window
// source: https://usehooks.com/useEventListener/
// typings: https://usehooks-ts.com/react-hook/use-event-listener
function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: WindowEventHandler<K>,
  element = window,
) {
  const savedHandler = useRef<WindowEventHandler<K>>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener: typeof handler = (event) => savedHandler.current?.(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default useEventListener;
