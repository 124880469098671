import { MutableRefObject, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";

import { Grid } from "@progress/kendo-react-grid";

import { List, ListItem, ListItemText } from "@mui/material";

import { TableSidebarOptions } from "components/kendo-table/types/kendo-table.types";

import useStyles from "./kendo-table-sidebar.style";

type Props = {
  tableRef: MutableRefObject<Grid | null>;
  options: TableSidebarOptions;
  loading: boolean;
};

const KendoTableSidebar: React.FC<Props> = ({ tableRef, options, loading }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const classes = useStyles({ headerHeight });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (tableRef.current?.props.className) {
      const table = document.getElementsByClassName(tableRef.current?.props.className);
      const header = table[0]?.getElementsByClassName("k-grid-header");

      setHeaderHeight(header[0]?.clientHeight || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <List component="nav" aria-label="Table sidebar" className={classes.root}>
      {Object.keys(options).map((key) => (
        <ListItem
          key={key}
          button
          selected={location.pathname === options[key].path}
          className={classes.button}
          onClick={() => {
            if (options[key].path) {
              history.push(options[key].path);
            }
          }}
        >
          <ListItemText primary={options[key].label} classes={{ primary: classes.itemLabel }} />
        </ListItem>
      ))}
    </List>
  );
};

export default KendoTableSidebar;
