import { State } from "@progress/kendo-data-query";
import { GridColumnMenuFilter, GridColumnMenuSort } from "@progress/kendo-react-grid";

export const isColumnFilterActive = (field: string, dataState: State) => {
  const isFilteringActive = GridColumnMenuFilter.active(field, dataState.filter);
  const isSortingActive = GridColumnMenuSort.active(field, dataState.sort);
  const isGroupingActive = (dataState.group || []).some((i) => i.field === field);

  return isFilteringActive || isSortingActive || isGroupingActive;
};
