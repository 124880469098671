import { useState, useCallback } from "react";

type SortDirection = "ascending" | "descending";
type SortField<D> = keyof D | "createdAt" | "updatedAt";
export type SortState<D> = { field: SortField<D>; direction: SortDirection };

export type Sort<D = any> = {
  sortState: SortState<D>;
  toggleField: (field: SortField<D>) => void;
  resetSortState: () => void;
  transformToString: () => string;
};

type UseSortOptions<D> = {
  secondSortingField?: SortState<D>;
  onSortStateChange?: () => void;
};

/**
 * `useSort` can be used for managing sorting state. Pass the type of the object whose fields you use for sorting as generic type `D`.
 * @param initialSortState - Initial specification of sorting.
 * @param options.secondSortingField - Can be used to always add second sorting parameter to the result of `transformToString` function.
 * The only exception is when first field is the same as the second field. In such case, `secondSortingField` is ignored.
 * @param options.onSortStateChange - Can be used for defining what additional operations should be performed when sort state is changed.
 */
function useSort<D extends Record<string, any>>(
  initialSortState: SortState<D>,
  options: UseSortOptions<D> = {}
): Sort<D> {
  const { secondSortingField, onSortStateChange } = options;

  const [sortState, setSortState] = useState(initialSortState);

  const sortStateToToString = useCallback(
    (sortState: SortState<D>) => `${sortState.direction === "descending" ? "-" : ""}${sortState.field}`,
    []
  );

  const resetSortState = useCallback(() => {
    setSortState(initialSortState);
    onSortStateChange?.();
  }, [initialSortState, onSortStateChange]);

  const toggleField = (field: SortField<D>) => {
    const newSortState: SortState<D> = {
      field,
      direction:
        field !== sortState.field ? "ascending" : sortState.direction === "ascending" ? "descending" : "ascending",
    };

    setSortState(newSortState);
    onSortStateChange?.();
  };

  const transformToString = () => {
    let finalParameter = sortStateToToString(sortState);
    if (secondSortingField && secondSortingField.field !== sortState.field) {
      finalParameter += `, ${sortStateToToString(secondSortingField)}`;
    }

    return finalParameter;
  };

  return { sortState, toggleField, resetSortState, transformToString };
}

export default useSort;
