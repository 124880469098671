import { FC, memo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import clsx from "clsx";
import type { XYCoord, Identifier } from "dnd-core";

import useStyles from "./drag-item.style";

type Props = {
  id: string;
  dropzoneId: string;
  index: number;
  draggable: boolean;
  onDragNDrop?: (dropzoneId: string, dragIndex: number, hoverIndex: number) => void;
  renderItem: (isOver: boolean) => React.ReactNode;
  className?: string;
};
interface DraggableItem {
  index: number;
  id: string;
  type: string;
}

// Most of the logic and comments come from the dnd example https://react-dnd.github.io/react-dnd/examples/sortable/simple
const DragItem: FC<Props> = memo(({ dropzoneId, id, index, draggable, onDragNDrop, renderItem, className }) => {
  const classes = useStyles();

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: dropzoneId,
    canDrag: draggable,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId, isOver }, drop] = useDrop<DraggableItem, void, { handlerId: Identifier | null; isOver: boolean }>(
    {
      accept: dropzoneId,
      collect: (monitor) => ({
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver(),
      }),
      hover: (item: DraggableItem, monitor) => {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        onDragNDrop?.(dropzoneId, dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
        // but it's good here for the sake of performance to avoid expensive index searches.
        item.index = hoverIndex;
      },
    },
  );

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={clsx(classes.dragItem, className, draggable && classes.draggable, isDragging && classes.isDragging)}
      data-handler-id={handlerId}
    >
      {renderItem(isOver)}
    </div>
  );
});

export default DragItem;
