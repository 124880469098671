import React, { createContext, useContext } from "react";

import { Tenants, tenantsId } from "config/tenants";

type TenantContextType = {
  data: string;
};

const TenantContext = createContext<TenantContextType>({} as TenantContextType);

const TenantProvider: React.FC = ({ children }) => {
  const getTenantId = () => {
    switch (window.location.host ?? "") {
      case Tenants.TEST:
        return tenantsId.test;
      case Tenants.QA:
        return tenantsId.qa;
      case Tenants.STAGE:
        return tenantsId.stage;
      case Tenants.PROD:
        return tenantsId.prod;
      case Tenants.PROD_TEST:
        return tenantsId.prod_test;
      default:
        return tenantsId.default;
    }
  };

  return <TenantContext.Provider value={{ data: getTenantId() }}>{children}</TenantContext.Provider>;
};

const useTenantContext = () => useContext(TenantContext);

export { TenantProvider, useTenantContext };

// In fact we could create separate hook for accessing just tenant data and tell TypeScript that it's not undefined (!)
// because we fetch data when rendering provider in index and then wait for no loading and no errors in App

// Also, please be aware that this context is currently problematic when it comes to refetching tenant data
// that's why refetch is not exposed - we can address this problem when needs arise
