import React, { useState } from "react";

import { KeyboardArrowDownRounded as ArrowDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";

import clsx from "clsx";

import useStyle from "./select-filter.styles";

type Props<T> = {
  options: Array<{ value: T; label: string }>;
  value?: T;
  onChange(value: T): void;
  label?: string;
  size?: "large" | "small";
};

const SelectFilter = <T extends any>({ options, value, onChange, label, size = "large" }: Props<T>) => {
  const classes = useStyle({ size });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (newValue: T) => {
    handleClose();
    onChange(newValue);
  };

  return (
    <div>
      <Button
        id="select-button"
        aria-controls={open ? "select-dropdown" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
        endIcon={<ArrowDownIcon />}
        classes={{
          root: clsx(classes.dropdownButton, classes[size]),
          endIcon: clsx(classes.dropdownButtonIcon, classes[size]),
        }}
      >
        {label} {options.find((item) => item.value === value)?.label}
      </Button>
      <Menu
        id="select-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "select-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          list: classes.dropdownList,
        }}
      >
        {options
          .filter((item) => item.value !== value)
          .map((item) => (
            <MenuItem
              key={item.label}
              className={clsx(classes.dropdownItem, classes[size])}
              onClick={() => handleSelect(item.value)}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default SelectFilter;
