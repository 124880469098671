import React, { Dispatch, SetStateAction, useState } from "react";

import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid } from "@mui/material";

import { Button, Modal } from "components";
import { ColumnsVisibilityConfig } from "components/kendo-table/types/kendo-table.types";

import { useColumnsFilterStorage } from "./hooks/useColumnsFilterStorage";

import useStyles from "../kendo-table-filters.style";

type Props = {
  columnsConfig: ColumnsVisibilityConfig[];
  setColumnsConfig: Dispatch<SetStateAction<ColumnsVisibilityConfig[]>>;
};

const KendoTableColumnsFilter: React.FC<Props> = ({ columnsConfig, setColumnsConfig }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const { saveConfig } = useColumnsFilterStorage({
    initConfig: columnsConfig,
    onLoad: (config) => {
      setColumnsConfig(config);
    },
  });
  const classes = useStyles({});

  const handleChange = (column: ColumnsVisibilityConfig, value: boolean) => {
    setColumnsConfig((prev) => {
      const columnsCopy = [...prev];
      const index = columnsCopy.findIndex((el) => el.title === column.title);

      if (index >= 0) {
        columnsCopy[index] = { ...columnsCopy[index], isVisible: !!value };
      }
      const visibleCount = columnsCopy.findIndex((el) => el.isVisible === true);
      const newConfig = visibleCount >= 0 ? columnsCopy : prev;

      saveConfig(newConfig);
      return newConfig;
    });
  };

  return (
    <>
      <Button
        onClick={openModal}
        className={classes.columnsButton}
        label="Columns"
        color="primary"
        variant="outlined"
      />

      <Modal maxWidth={400} open={isOpen} onClose={closeModal} title="Mark which columns should be visible">
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            {columnsConfig
              .filter(({ isFilterable }) => isFilterable)
              .map((column) => (
                <Grid item xs={12} md={12} key={column.title}>
                  <FormControlLabel
                    key={column.title}
                    label={column.title}
                    classes={{ root: classes.columnNameRow }}
                    control={
                      <Checkbox
                        checked={column.isVisible}
                        value={column.isVisible}
                        classes={{ root: classes.columnNameCheckbox }}
                        color="secondary"
                        onChange={(_e, value) => handleChange(column, value)}
                      />
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="outlined" color="hint" label={"Close"} disabled={false} />
        </DialogActions>
      </Modal>
    </>
  );
};

export default KendoTableColumnsFilter;
