import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ shape, spacing, palette, typography }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& p": {
      wordBreak: "break-all",
      maxWidth: "inherit",
    },
  },
  dropContainer: {
    borderRadius: shape.borderRadius,
    border: `1px dashed ${palette.text.n4}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: spacing(2),
    "&:focus": {
      outline: "none",
    },
  },
  uploadIcon: {
    color: palette.text.n4,
    fontSize: "1.5rem",
    marginBottom: spacing(1),
  },
  dropText: {
    color: palette.text.n4,
    fontSize: typography.pxToRem(14),
  },
  browseButton: {
    margin: spacing(0, 0.5),
  },
  dropTextContainer: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  fileName: {
    marginRight: spacing(1),
  },

  error: {
    fontSize: "0.75rem",
  },

  // image type
  avatarDropContainer: {
    "&:focus": {
      outline: "none",
    },
  },
  labelContainer: {
    marginBottom: spacing(0.5),
    marginLeft: spacing(4.5),
    "& span": {
      color: palette.text.secondary,
    },
  },
  tileContainer: {
    position: "relative",
    "&:hover": {
      "& div": {
        opacity: 1,
      },
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  actionButtons: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bottom: -12,
    width: "100%",
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    opacity: 1,
    margin: spacing(0, 1),
    borderRadius: "50%",
    backgroundColor: palette.background.default,
    transition: "opacity 0.2s ease-in-out",
    boxShadow: `0px 4px 10px ${palette.hint.main + "60"}`,

    "& button": {
      width: "100%",
      height: "100%",
      padding: 0,
    },
  },
  openButton: {
    "&$rounded": {
      top: 80,
      left: 64,
    },
  },
  deleteButton: {
    left: 32,
    "&$rounded": {
      top: 80,
      left: 10,
    },
  },
  actionIcon: {
    color: palette.text.default,
    fontSize: "1rem",
  },
  imagePreview: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  // rounded variant
  rounded: {},
  roundedAvatar: {
    backgroundColor: palette.hint.light,
    width: 100,
    height: 100,
    borderRadius: "50%",
    margin: "0 auto",

    "& svg": {
      width: "50%",
      height: "50%",
    },
  },
  roundedText: {
    position: "absolute",
    left: 18,
    top: 28,
  },

  // square variant
  square: {
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.02)",
    backgroundColor: palette.hint.light,
    borderRadius: 5,
    height: 130,
    width: 130,
  },
}));
