import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ typography }) => ({
  root: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  name: {
    fontSize: typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
}));
