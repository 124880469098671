import { makeStyles } from "@mui/styles";

type Params = {
  size: "large" | "small";
};

export default makeStyles<CustomTheme, Params>(({ palette, spacing, typography }) => ({
  dropdownButton: {
    color: palette.text.n3,
    border: `1px solid ${palette.text.n5}`,
    "&:hover": {
      textDecoration: "none",
    },
    "&$small": {
      padding: spacing(0.5, 1),
      fontSize: typography.pxToRem(12),
    },
    "&$large": {
      padding: spacing(1.25),
      fontSize: typography.pxToRem(14),
    },
  },
  dropdownButtonIcon: {
    color: palette.text.n2,

    "&$small": {
      marginLeft: spacing(0.5),
    },
    "&$large": {
      marginLeft: spacing(1),
    },
  },
  dropdownList: {
    padding: spacing(0.5, 0),
  },
  dropdownItem: {
    fontWeight: 700,
    color: palette.text.n3,
    "&$small": {
      padding: spacing(0.5, 1),
      fontSize: typography.pxToRem(12),
    },
    "&$large": {
      padding: spacing(1, 1.5),
      fontSize: typography.pxToRem(14),
    },
  },

  // sizes
  large: {},
  small: {},
}));
