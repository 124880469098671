import makeStyles from '@mui/styles/makeStyles';

export default makeStyles<CustomTheme>(({ breakpoints }) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "stretch",
    padding: 0,
    alignItems: "stretch",

    flexGrow: 1,
    borderRadius: 0,
    flexDirection: "column",

    [breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "stretch",
      padding: 0,
    },
  },
  wrapper: {
    display: "flex",
  },
}));
