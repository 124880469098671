import * as React from "react";
import { FieldRenderProps } from "react-final-form";

import {
  Switch,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Box,
} from "@mui/material";

import useStyles from "./switch-control.styles";

type Props = FieldRenderProps<any, HTMLElement> & {
  label?: string;
  forcedError?: string;
};

const SwitchControl: React.FC<Props> = ({
  input: { checked, value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  label,
  onLabel = "Yes",
  offLabel = "No",
  forcedError,
  ...restSwitch
}) => {
  const classes = useStyles();
  const error = metaError || forcedError;
  const showError = Boolean(touched && ((submitError && !dirtySinceLastSubmit) || error));

  return (
    <Box mt={3}>
      <InputLabel>{label}</InputLabel>
      <FormControl error={showError} classes={{ root: classes.formControl }}>
        <FormGroup classes={{ root: classes.formGroup }}>
          <FormControlLabel
            label={""}
            control={
              <Box ml={1}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>{offLabel}</Grid>
                  <Grid item>
                    <Switch {...restSwitch} name={name} inputProps={restInput} onChange={onChange} checked={value} />
                  </Grid>
                  <Grid item>{onLabel}</Grid>
                </Grid>
              </Box>
            }
          />
        </FormGroup>
        {showError && <FormHelperText error>{error || submitError}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default SwitchControl;
