import makeStyles from "@mui/styles/makeStyles";

type Params = {
  hasLabel: boolean;
};

export default makeStyles<CustomTheme, Params>(({ spacing, typography, palette, breakpoints: mq }) => ({
  fieldLabel: {
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.black,
    fontWeight: typography.fontWeightBold,
  },
  radioInput: {
    padding: spacing(0.5),
    color: palette.text.neutral,
    "& .MuiSvgIcon-root": {
      fontSize: typography.pxToRem(25),
      "& > circle": {
        color: palette.common.white,
        opacity: 1,
      },
    },
  },
  tableHead: {
    width: "23.3%",
    height: 44,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    color: palette.text.default,
    "&.MuiTableCell-root.MuiTableCell-body": {
      padding: spacing(1, 0.5, 1, 2),
    },
  },
  table: {
    boxShadow: "none",
    marginTop: spacing(4),
    "& .MuiTable-root": {
      minWidth: 500,
    },
    "& .MuiTableBody-root tr:nth-child(odd) >*": {
      background: palette.background.lightGray,
    },
    "& .MuiTableBody-root td:last-child": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
    "& .MuiTableRow-head th": {
      height: 40,
      color: palette.text.default,
    },
    "& .MuiTableCell-root": {
      height: 45,
      padding: spacing(0.5),
    },
    "& .MuiTableCell-head": {
      border: 0,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
    },
    "& .MuiTableCell-body": {
      border: 0,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
    },
  },
}));
