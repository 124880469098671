import { Field, useForm } from "react-final-form";

import { RadioButton } from "@progress/kendo-react-inputs";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { Technology } from "modules/employees/graphql/employees.graphql";
import { assessmentOptions } from "modules/evaluation/components/assessment/constants/assessment-survey-constants";

import useStyles from "./assessment-survey-matrix-table-group.style";

type Props = {
  items: Technology[];
  disabled: boolean;
  name: string;
};

const AssessmentSurveyMatrixTableGroup: React.FC<Props> = ({ items, disabled, name }) => {
  const classes = useStyles();
  const { submit } = useForm();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {assessmentOptions.map(({ label, value }) => (
              <TableCell align="center" key={value} className={classes.answerLabel}>
                <span>{label}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <TableRow key={`${row.name}${index}`}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {assessmentOptions.map(({ label, value }) => (
                <TableCell align="center" key={`${row.name}${label}`}>
                  <Field
                    name={`${name}.items[${index}]`}
                    component={({ input }) => (
                      <RadioButton
                        className={classes.radioButton}
                        disabled={disabled}
                        name={`${row.name}`}
                        value={value}
                        checked={input.value.level === value}
                        onChange={() => {
                          input.onChange({ ...input.value, level: value });
                          submit();
                        }}
                        onFocus={() => {
                          if (input.value.level === value) {
                            input.onChange({ ...input.value, level: undefined });
                            submit();
                          }
                        }}
                      />
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssessmentSurveyMatrixTableGroup;
