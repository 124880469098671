import { SensitiveInformationType } from "./sensitive-informations.context";

export const ACTIONS = {
  CHANGE_EMPLOYEE: "changeEmployee",
  CHANGE_CONTRACT: "changeContract",
  CHANGE_PROJECT: "changeProject",
  CHANGE_PROJECT_EMPLOYEE_ASSIGNMENT: "changeProjectEmployeeAssignment",
  SET_SHOW_SENSITIVE_DATA: "setShowSensitiveData",
};

export const reducer = (
  state: SensitiveInformationType,
  action: {
    type: string;
    payload: {
      field?: string;
      value: boolean;
    };
  }
): SensitiveInformationType => {
  let newState: SensitiveInformationType = {} as SensitiveInformationType;
  switch (action.type) {
    case "setShowSensitiveData":
      newState = {
        ...state,
        hideSensitiveData: action.payload.value,
      };
      localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(newState));
      return newState;
    case "changeEmployee":
      newState = {
        ...state,
        employees: {
          ...state.employees,
          [action.payload.field ?? ""]: action.payload.value,
        },
      };
      localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(newState));

      return newState;
    case "changeContract":
      newState = {
        ...state,
        contracts: {
          ...state.contracts,
          [action.payload.field ?? ""]: action.payload.value,
        },
      };
      localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(newState));

      return newState;
    case "changeProject":
      newState = {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.field ?? ""]: action.payload.value,
        },
      };
      localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(newState));
      return newState;
    case "changeProjectEmployeeAssignment":
      newState = {
        ...state,
        projectEmployeeAssignments: {
          ...state.projectEmployeeAssignments,
          [action.payload.field ?? ""]: action.payload.value,
        },
      };
      localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(newState));

      return newState;
    default:
      return state;
  }
};
