import { Rating, RatingChangeEvent } from "@progress/kendo-react-inputs";

import CustomSkillItem from "./custom-item/skills-rating-custom-item";
import useStyle from "./skills-rating.style";

type Props = {
  readOnly?: boolean;
  level?: number;
  icon?: string;
  handleValueChange?: (event: RatingChangeEvent) => void;
};

const SkillsRating: React.FC<Props> = ({ readOnly = true, level = 0, icon = "k-i-circle", handleValueChange }) => {
  const classes = useStyle();

  return (
    <Rating
      max={4}
      readonly={readOnly}
      value={level}
      item={CustomSkillItem}
      icon={icon}
      onChange={handleValueChange}
      className={classes.root}
    />
  );
};

export default SkillsRating;
