import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  tabButton: {
    minHeight: 48,
    textTransform: "capitalize",
    fontSize: "1rem",
    borderRadius: "2px 2px 0px 0px",
    color: palette.text.primary,
  },
  large: {
    minWidth: 180,
    padding: spacing(1, 2),
  },
  small: {
    minWidth: 115,
    padding: spacing(1, 1.5),
  },
  linkButton: {
    backgroundColor: "transparent",
    color: palette.text.grey,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: palette.text.lightGrey,
      textDecoration: "none",
    },
    "&$active": {
      color: palette.text.dark,
      borderBottom: "2px solid #3f51b5;",
    },
  },
  noLinkButton: {
    color: palette.text.grey,
    "&:hover": {
      backgroundColor: palette.text.lightGrey,
      textDecoration: "none",
    },
    "&$active": {
      color: palette.text.dark,
      borderBottom: "2px solid #3f51b5;",
    },
    "&$disabled": {
      color: palette.hint.main,
    },
  },
  disabled: {},
  active: {},
}));
