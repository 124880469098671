import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  inputContainer: {
    margin: spacing(1, 0),
    position: "relative",
    width: "100%",
  },
  indicatorError: {
    color: palette.error.main,
    marginRight: spacing(1),
  },
  indicatorSuccess: {
    color: palette.text.green,
    marginRight: spacing(1),
  },
  startComponent: {
    position: "absolute",
    left: 2,
    top: -7,
    zIndex: 1,
    width: spacing(11),

    "&:after": {
      content: "''",
      position: "absolute",
      top: "52%",
      right: 1,
      width: 1,
      height: "32%",
      background: palette.text.grey5,
      transform: "translateY(-50%)",
    },

    "& *, & *:before, & *:after": {
      border: "unset !important",
    },
  },

  hasStartComponent: {
    paddingLeft: spacing(11),
  },

  input: {
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.02)",
  },
  helperText: {
    marginTop: spacing(-0.5),
  },
}));
