import React, { useEffect } from "react";

import { ApolloError, useLazyQuery } from "@apollo/client";

import { useAuth } from "services/auth";
import usePermissionsAccess from "services/permissions-access/hooks/usePermissionsAccessContext/usePermissionsAccess";

import { CompanyConfiguration } from "modules/settings/graphql/configurations/company-config.graphql";
import {
  EmployeeStatusConfiguration,
  GetConfigData,
  GetConfigVars,
  GET_TENANT_CONFIG,
  ProjectsConfiguration,
  SkillsCategory,
} from "modules/settings/graphql/configurations/configurations.graphql";

import { ExpirableDocumentType } from "../graphql/configurations/expirable-document-type-config.graphql";

type TenantConfigContextType = {
  tenantConfigData?: EmployeeStatusConfiguration;
  projectConfigData?: ProjectsConfiguration;
  companyConfigData?: CompanyConfiguration[];
  skillsCategoryConfigData?: SkillsCategory[];
  expirableDocumentTypes?: ExpirableDocumentType[];
  error?: ApolloError;
  loading: boolean;
};

const TenantConfigContext = React.createContext<TenantConfigContextType>({} as TenantConfigContextType);

const TenantConfigProvider: React.FC<React.ReactNode> = ({ children }) => {
  const auth = useAuth();
  const canExpirableDocumentsTypeRead = usePermissionsAccess(["expirableDocumentsType.read"]);
  const canTenantsRead = usePermissionsAccess(["tenants.read"]);

  const [getTenantConfig, { data, loading, error }] = useLazyQuery<GetConfigData, GetConfigVars>(GET_TENANT_CONFIG, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (auth?.user?.tenantId) {
      getTenantConfig({
        variables: { id: auth.user.tenantId, canExpirableDocumentsTypeRead, canTenantsRead },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user]);

  return (
    <TenantConfigContext.Provider
      {...{
        children,
        value: {
          tenantConfigData: data?.getEmployeeStatusConfiguration,
          projectConfigData: data?.getProjectsConfiguration,
          companyConfigData: data?.getCompanyConfiguration,
          skillsCategoryConfigData: data?.getSkillCategories,
          expirableDocumentTypes: data?.expirableDocumentTypes,
          loading,
          error,
        },
      }}
    />
  );
};

const useTenantConfigContext = () => React.useContext(TenantConfigContext);

export { TenantConfigProvider, useTenantConfigContext };
