import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

import clsx from "clsx";

import { PROJECT_DETAILS_TABS } from "modules/projects/constants/projects.tabs";

import useStyles from "./project-link.style";

type Props = {
  project: {
    id: string;
    title: string;
  };
  className?: string;
};

const ProjectLink: React.FC<Props> = ({ project, className }) => {
  const classes = useStyles();

  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={PROJECT_DETAILS_TABS.overview.path.replace(":id", project.id)}
      className={classes.root}
    >
      <Typography className={clsx(classes.name, className)} aria-label={`project ${project.title}`}>
        {project.title}
      </Typography>
    </Link>
  );
};

export default ProjectLink;
