import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(() => ({
  remove: {
    width: 24,
    height: 24,
    position: "absolute",
    right: 27,
    top: "50%",
    transform: "translateY(-50%)",

    "& svg": {
      width: `${16}px !important`,
      height: `${16}px !important`,
    },
  },
}));
