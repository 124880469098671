import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  groupContainer: {
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
    border: "0 !important",
    opacity: 0.5,
    "&$isActive": { opacity: 1 },
    "&$isNested": {
      marginLeft: spacing(3),
    },
  },
  isActive: {},
  isNested: {},

  groupTitle: {
    gap: spacing(0.5),
    "& p": {
      fontSize: typography.pxToRem(16),
      lineHeight: typography.pxToRem(22),
      fontWeight: 500,
      color: palette.text.grey7,
    },
  },
  groupDetails: {
    display: "flex",
    alignItems: "center",
    gap: spacing(1),
  },
  groupPosition: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    color: palette.text.grey8,
  },
}));
