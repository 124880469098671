import * as React from "react";

import { Tooltip as MuiTooltip, Typography, Divider, Box } from "@mui/material";

import useStyles from "./tooltip-list.styles";

type Props = {
  title: string;
  list?: string[] | React.ReactNode[];
  visibleCount?: number;
};

const TooltipList: React.FC<Props> = ({ title, list, visibleCount = 1 }) => {
  const classes = useStyles();

  return (
    <>
      {list && list?.length !== 0 ? (
        <Box flexDirection="row" className={classes.wrapper}>
          <div className={classes.visibleList}>
            {list.length === 1 ? list[0] : [...Array(visibleCount)].map((_el, i) => list[i]).join(", ")}
          </div>

          {list.length > 1 && list.length - visibleCount !== 0 && (
            <MuiTooltip
              classes={{ tooltip: classes.tooltip }}
              placement="right-start"
              title={
                <>
                  <Typography className={classes.title}>{title}</Typography>
                  <Divider className={classes.divider} />
                  <Typography className={classes.tooltipContent}>
                    {typeof list[0] === "string" ? list.join(", ") : list}
                  </Typography>
                </>
              }
            >
              <div className={classes.moreContainer}>
                <span className={classes.moreCounter}>{`+${list.length - visibleCount}`}</span>
              </div>
            </MuiTooltip>
          )}
        </Box>
      ) : null}
    </>
  );
};

export default TooltipList;
