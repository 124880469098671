import React, { PropsWithChildren } from "react";

import { ErrorOutline as ErrorIcon, AttachMoney as MoneyIcon } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";

import clsx from "clsx";

import { EmployeeAlertType, EmployeeAlertTypeLabel } from "modules/employees/types/employees.types";

import useStyles from "./user-avatar-badge.styles";

export type UserAvatarBadgeType = { bottom?: EmployeeAlertType[]; top?: boolean };

type Props = {
  badge?: UserAvatarBadgeType;
};

const BadgeTop: React.FC<PropsWithChildren<Required<Props>>> = ({ children, badge }) => {
  const classes = useStyles();
  if (!badge.top) return <>{children}</>;

  return (
    <Badge
      badgeContent={
        <>
          <svg height="10" width="10" className={classes.badgeIconCrossedLine}>
            <line x1="1" y1="1" x2="9" y2="9" />
          </svg>
          <MoneyIcon className={clsx(classes.badgeIcon, classes.topIcon)} />
        </>
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      overlap="circular"
    >
      {children}
    </Badge>
  );
};

const BadgeBottom: React.FC<PropsWithChildren<Required<Props>>> = ({ children, badge }) => {
  const classes = useStyles();
  if (badge.bottom?.length === 0) return <>{children}</>;

  return (
    <Tooltip
      classes={{ popper: classes.badgePopper }}
      placement="right-start"
      title={
        <span>
          <ul className={classes.list}>
            {badge.bottom?.map((i) => (
              <li key={i}>
                <span className={classes.text}>{EmployeeAlertTypeLabel[i]}</span>
              </li>
            ))}
          </ul>
        </span>
      }
    >
      <Badge
        badgeContent={<ErrorIcon className={clsx(classes.badgeIcon, classes.bottomIcon)} />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        overlap="circular"
      >
        {children}
      </Badge>
    </Tooltip>
  );
};

const UserAvatarBadge: React.FC<PropsWithChildren<Props>> = ({ children, badge }) => {
  if (!badge) return <>{children}</>;

  return (
    <BadgeTop badge={badge}>
      <BadgeBottom badge={badge}>{children}</BadgeBottom>
    </BadgeTop>
  );
};

export default UserAvatarBadge;
