import { useEffect, useState } from "react";

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import clsx from "clsx";

import { ReactComponent as AcaisoftLogo } from "assets/images/svg/acaisoft-white-logo.svg";

import { Employee } from "modules/employees/graphql/employees.graphql";
import { Project } from "modules/projects/graphql/projects.graphql";

import { Evaluation } from "components/evaluations/graphql/evaluations-graphql";

import { assessmentLegend } from "../../constants/assessment-survey-constants";
import useStyles from "./survey-form-top-section.styles";

type Props = {
  version: "assessment" | Evaluation;
  employee?: Pick<Employee, "firstName" | "lastName">;
  project?: Pick<Project, "name">;
};

const SurveyFormTopSection = ({ version, employee, project }: Props) => {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const isSelfAssessment = version === "assessment";

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={clsx(classes.topSection, scrollPosition > 153 && isSelfAssessment && classes.marginTop)}>
        <div />
        <div className={classes.logoContainer}>
          <AcaisoftLogo />
          <span className={classes.logoTitle}>
            {isSelfAssessment
              ? "Self Assessment"
              : `Evaluation ${version.type === "PROJECT" ? version.templateName : ""}`}
          </span>
        </div>

        {!isSelfAssessment && (
          <Typography className={classes.evaluatedItemContainer} variant="inherit">
            Evaluated {employee ? "employee" : "project"}:
            <span className={classes.evaluatedItemName}>
              {employee ? `${employee.firstName} ${employee.lastName}` : project ? `${project.name}` : "-"}
            </span>
          </Typography>
        )}
      </div>

      {isSelfAssessment && (
        <div className={clsx(classes.tableContainer, scrollPosition > 153 && classes.fixedSection)}>
          <TableContainer component={Paper} className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {assessmentLegend.map(({ name }) => (
                    <TableCell align="center" key={name} className={classes.answerLabel}>
                      <span>{name}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" />
                  {assessmentLegend.map(({ tip, name }) => (
                    <TableCell align="center" key={name}>
                      {tip}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};
export default SurveyFormTopSection;
