import * as React from "react";
import { TableCell, TableSortLabel } from "@mui/material";
import clsx from "clsx";

type ColumnConfig = {
  key: string;
  title: React.ReactNode;
  sortable?: boolean;
  setWhiteSpaceNormal?: boolean;
};

type Props = {
  column: ColumnConfig;
  sortingState?: { isSortedBy: boolean; direction?: "asc" | "desc" };
  onSortChange?: () => void;
};

const ColumnHeader: React.FC<Props> = ({ column, sortingState, onSortChange }) => {
  const { key, title, sortable, setWhiteSpaceNormal } = column;

  if (!sortable) {
    return (
      <TableCell key={key} variant="head" className={clsx(setWhiteSpaceNormal && { whiteSpace: "normal" })}>
        {title}
      </TableCell>
    );
  }

  return (
    <TableCell key={key} variant="head" sortDirection={sortingState?.direction}>
      <TableSortLabel
        active={sortingState?.isSortedBy}
        direction={sortingState?.isSortedBy ? sortingState?.direction : "asc"}
        onClick={() => onSortChange?.()}
      >
        {title}
      </TableSortLabel>
    </TableCell>
  );
};

export default ColumnHeader;
