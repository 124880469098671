import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  rootContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: spacing(1),
  },

  rootName: {
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(25),
    fontWeight: 600,
    color: palette.text.grey7,
    paddingLeft: spacing(0.75),
  },

  expandIcon: {
    fontSize: "1.4rem",
    "&$isExpanded": { transform: "rotate(270deg)" },
  },
  isExpanded: {},
}));
