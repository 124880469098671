import * as React from "react";

import useStyles from "./responsive-table-wrapper.styles";

const ResponsiveTableWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ResponsiveTableWrapper;
