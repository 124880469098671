import { useMemo } from "react";

import { ProjectStatus, ProjectStatusLabel } from "modules/projects/types/projects.types";

import { StatusChip } from "components";

type Props = {
  type?: ProjectStatus;
};

const ProjectStatusChip: React.FC<Props> = ({ type }) => {
  const chipProps = useMemo(
    () =>
      (() => {
        const flatTypes = Object.entries(ProjectStatus).map((el) => el);
        const foundType = flatTypes.find((el) => el[0] === type);

        switch (foundType?.[1]) {
          case ProjectStatus.ACTIVE:
            return { label: ProjectStatusLabel.ACTIVE, variant: "success" };
          case ProjectStatus.CLOSED:
            return { label: ProjectStatusLabel.CLOSED, variant: "error" };
          case ProjectStatus.UPCOMING:
            return { label: ProjectStatusLabel.UPCOMING, variant: "info" };
          case ProjectStatus.ARCHIVED:
            return { label: ProjectStatusLabel.ARCHIVED, variant: "error" };
          default:
            return { label: "Unknown", variant: "error" };
        }
      })(),
    [type],
  );

  return <StatusChip {...chipProps} />;
};

export default ProjectStatusChip;
