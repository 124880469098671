import { ReactElement, useEffect, useState } from "react";

import { FilterDescriptor, process } from "@progress/kendo-data-query";

import { get as _get } from "lodash";

import { useQueryParams } from "hooks";

import { TableData } from "../../types/kendo-table.types";

// eg: "name1+name2" -> "name2+name1"
const reverseConcatedField = (field: string) => {
  const splitted = field.split("+");
  return `${splitted[1]}+${splitted[0]}`;
};

const prepareData = <T,>(
  allColumnFilter: string | null,
  withInternalSearch: boolean,
  children: ReactElement[],
  data?: T[],
) => {
  if (withInternalSearch) {
    const allConcatedFields: string[] = [];

    const textColumns: string[] = children
      ?.filter((col) => !col?.props?.omitInSearch)
      // eslint-disable-next-line array-callback-return
      ?.map((col) => {
        if (col?.props?.children) {
          return col.props.children.map((child: ReactElement) => {
            if (child.props.filter !== "boolean" && child.props.filter !== "date") {
              const field = child.props.searchField ?? child.props.field;
              return field.includes("+") ? [field, reverseConcatedField(field)] : field;
            }
            return child;
          });
        } else if (col.props.filter !== "boolean" && col.props.filter !== "date") {
          const field = col.props.searchField ?? col.props.field;
          if (field.includes("+")) {
            allConcatedFields.push(field, reverseConcatedField(field));
            return [field, reverseConcatedField(field)];
          }
          return field;
        }
      })
      .flat()
      .filter((field) => field);

    const allColumnsFilters: FilterDescriptor[] = textColumns.map((column) => {
      return {
        field: column,
        operator: "contains",
        value: allColumnFilter,
      };
    });

    const newData: T[] = [...(data || [])];

    allConcatedFields.forEach((concatedField) => {
      const fields: string[] = concatedField.split("+");

      Object.keys(newData)?.forEach((key: any) => {
        const value = fields
          ?.map((field) => {
            return _get(newData[key], field);
          })
          ?.join(" ");
        newData[key] = { ...newData[key], [concatedField]: value };
      });
    });

    return allColumnFilter
      ? process(newData || [], { filter: { logic: "or", filters: allColumnsFilters } }).data
      : newData || [];
  } else {
    return [...(data || [])];
  }
};

type Props<T> = {
  withInternalSearch: boolean;
  data?: T[];
  children: ReactElement[];
};

const useTableSearch = <T extends TableData>({ withInternalSearch, data, children }: Props<T>) => {
  const { queryParams } = useQueryParams();
  const initialSearch = queryParams.get("search");
  const [allColumnFilter, setAllColumnFilter] = useState(initialSearch);

  const [allColumnFilteredData, setAllColumnFilteredData] = useState<T[]>(() =>
    prepareData(allColumnFilter, withInternalSearch, children, data),
  );

  useEffect(() => {
    const result = prepareData(allColumnFilter, withInternalSearch, children, data);
    setAllColumnFilteredData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, allColumnFilter]);

  return { allColumnFilteredData, setAllColumnFilter, allColumnFilter };
};

export default useTableSearch;
