import { useState } from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";

import { SkillsGrouped } from "../assessment-survey-matrix-table";
import AssessmentSurveyMatrixTableGroup from "../group/assessment-survey-matrix-table-group";
import useStyles from "./assessment-survey-matrix-table-row.style";

type Props = {
  category: SkillsGrouped;
  disabled: boolean;
  name: string;
};

const AssessmentSurveyMatrixTableRow: React.FC<Props> = ({ category, disabled, name }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={classes.groupRowContainer}>
      <div className={classes.groupRow}>
        <span className={classes.groupTitle}>{category.name}</span>
        <IconButton
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
          className={classes.expandedButton}
          aria-label="expand skills category"
        >
          <ExpandMore className={!isExpanded ? classes.expandedIcon : undefined} />
        </IconButton>
      </div>
      {isExpanded && <AssessmentSurveyMatrixTableGroup items={category.items} disabled={disabled} name={name} />}
    </div>
  );
};

export default AssessmentSurveyMatrixTableRow;
