import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  groupContainer: {
    padding: spacing(1),
    border: "0 !important",
    display: "flex",
    alignItems: " flex-start",
    flexDirection: "column",
  },
  groupTitle: {
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(22),
    fontWeight: typography.fontWeightBold,
    color: palette.text.grey7,
  },
  groupPosition: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    color: palette.text.grey8,
  },
}));
