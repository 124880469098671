import moment from "moment";

export const dateToString = (date?: Date | string | null, format = "YYYY-MM-DD"): string => {
  const dateObject = date ? new Date(date) : undefined;
  return moment(dateObject).format(format);
};

export const removeTimeFromDate = (date: Date) => {
  return new Date(new Date(date).toDateString());
};
