import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  filterButton: {
    color: palette.text.n4,
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.text.n5}`,
    "&:hover": {
      textDecoration: "none",
      color: palette.text.n4,
      backgroundColor: palette.text.n6,
    },
  },

  popoverContent: {
    display: "flex",
  },

  daysButtonsConatiner: {
    display: "flex",
    flexDirection: "column",
    padding: spacing(0, 3, 0, 2),
    justifyContent: "center",
    margin: spacing(3, 0),
    borderRight: `1px solid ${palette.divider}`,
  },
  daysSelector: {
    display: "flex",
    padding: spacing(1, 0),
    alignItems: "flex-start",
  },
  daysButton: {
    color: palette.text.n4,
  },
  checkIconContainer: {
    width: 32,
  },
  calendarContent: {
    marginTop: spacing(2),
    "& > div > div:last-child": {
      minHeight: 240,
    },
  },
}));
