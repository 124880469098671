import React from "react";
import { IAuthClient, IAuthContext } from "../types";

const AuthContext = React.createContext<IAuthContext | null>(null);
const { Provider, Consumer } = AuthContext;

interface IProps {
  children: React.ReactNode;
  client: IAuthClient;
}

class StatefulProvider extends React.Component<IProps, IAuthContext> {
  methods = this.prepareMethods();

  constructor(props: IProps) {
    super(props);

    this.state = {
      isInitialized: false,
      isAuthenticated: false,
      token: "",
      user: null,
      ...this.methods,
    };
  }

  syncState = () => {
    const { client } = this.props;

    this.setState({
      isInitialized: client.getIsInitialized(),
      isAuthenticated: client.getIsAuthenticated(),
      token: client.getToken(),
      user: client.getUser(),
      ...this.methods,
    });
  };

  prepareMethods() {
    const { client } = this.props;

    return {
      loginWithEmailAndPassword: client.loginWithEmailAndPassword.bind(client),
      logout: client.logout.bind(client),
      init: client.init.bind(client),
      getToken: client.getToken.bind(client),
      getFreshToken: client.getFreshToken.bind(client),
      checkAccess: client.checkAccess.bind(client),
      sendVerificationEmail: client.sendVerificationEmail.bind(client),
      resetPassword: client.resetPassword.bind(client),
      updateUser: client.updateUser.bind(client),
    };
  }

  componentDidMount() {
    this.props.client.registerOnUpdateHandler(this.syncState);
  }

  render() {
    const { children } = this.props;

    return <Provider value={this.state}>{children}</Provider>;
  }
}

const useAuth = () => React.useContext(AuthContext);

export { AuthContext, Consumer as AuthConsumer, StatefulProvider as AuthProvider, useAuth };
