import React from "react";
import clsx from "clsx";

import { CircularProgress, IconButton, Input } from "@mui/material";

import useStyles from "./one-textfield-form.styles";

type Props = {
  value?: string;
  onSubmit(text: string): void;
  buttonLabel?: string | React.ReactNode;
  placeholder?: string;
  loading?: boolean;
  [key: string]: any;
};

const OneTextFieldForm: React.FC<Props> = ({
  onSubmit,
  value = "",
  placeholder = "",
  buttonLabel = "Save",
  loading,
  ...restInputProps
}) => {
  const classes = useStyles();
  const [text, setText] = React.useState(value);

  return (
    <div className={classes.wrapper}>
      <Input
        type="text"
        value={text}
        placeholder={placeholder}
        classes={{
          root: classes.root,
          input: classes.input,
        }}
        disableUnderline
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
        }}
        {...restInputProps}
      />
      {text !== value && (
        <IconButton
          className={clsx(classes.iconButton, loading && classes.buttonDisabled)}
          aria-label="search"
          onClick={() => onSubmit(text)}
          disabled={loading}
          size="large">
          {loading ? <CircularProgress color="secondary" size={28} /> : buttonLabel}
        </IconButton>
      )}
    </div>
  );
};

export default OneTextFieldForm;
