import Select, { ValueType } from "react-select";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DropdownArrowIcon } from "assets/images/icons";

import { OptionType } from "components/forms/select-control/select-control";
import { TableGroupByOptions } from "components/kendo-table/types/kendo-table.types";

import useGlobalStyles from "../kendo-table-filters.style";
import useStyles, { getStyles } from "./kendo-table-select-filter.style";

type Props = {
  options: TableGroupByOptions["options"];
  onChange(value: string | string[]): void;
  value?: string | string[];
  placeholder?: string;
  prefix?: string;
  isMulti?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onMenuOpen?(): void;
  isClearable?: boolean;
};

const KendoTableSelectMultiFilter: React.FC<Props> = ({
  options,
  value: selectValue,
  isMulti,
  isSearchable,
  isLoading,
  isDisabled,
  placeholder = "Pick value",
  prefix,
  onChange,
  onMenuOpen,
  isClearable = true,
}) => {
  const classes = useStyles();
  const theme = useTheme<CustomTheme>();
  const styles = getStyles({ theme });
  const globalClasses = useGlobalStyles({});

  return (
    <Select
      isMulti={isMulti}
      placeholder={prefix?.replace(":", "") || placeholder}
      isClearable={isClearable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      contentEditable={false}
      isSearchable={isSearchable}
      value={
        Array.isArray(selectValue)
          ? selectValue?.map((val) => options.find((el) => el.value === val)!).filter(Boolean) || []
          : options.find((el) => el.value === selectValue) || null
      }
      options={options}
      styles={styles}
      isOptionDisabled={(option) => !!option.disabled}
      components={{
        SingleValue:
          !isMulti && prefix
            ? () => (
                <>
                  {prefix}
                  <Typography component="span" className={classes.selectedOption}>
                    {options.find((el) => el.value === selectValue)?.label}
                  </Typography>
                </>
              )
            : () => (
                <>
                  {prefix}
                  <Typography variant="h5" component="span" className={classes.selectedOption}>
                    {options.find((el) => el.value === selectValue)?.label}
                  </Typography>
                </>
              ),
        DropdownIndicator: () => <DropdownArrowIcon className={globalClasses.arrow} />,
      }}
      onChange={(selected: ValueType<OptionType>) => {
        Array.isArray(selected)
          ? onChange(selected.map((s) => s.value) as string[])
          : onChange(selected ? ((selected as OptionType).value as string) : "");
      }}
      closeMenuOnSelect={!isMulti}
      classNamePrefix="react-select"
      onMenuOpen={onMenuOpen}
    />
  );
};

export default KendoTableSelectMultiFilter;
