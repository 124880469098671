import makeStyles from "@mui/styles/makeStyles";

type Params = {
  status: boolean | null;
};

export default makeStyles<CustomTheme, Params>(({ palette, spacing, typography }) => ({
  popover: {
    pointerEvents: "none",
    borderRadius: 5,
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  wrapper: {
    minWidth: 260,
  },
  header: {
    padding: spacing(1.2, 1.5, 1.2),
    border: `1px solid ${palette.background.lightGray}`,
  },
  headerDate: {
    color: palette.text.grey3,
    fontSize: typography.pxToRem(12),
    margin: 0,
  },
  body: {
    padding: spacing(1.2, 1.5, 1.25),
    fontSize: typography.pxToRem(12),
  },
  user: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing(1.25),
  },
  userDetails: {
    fontSize: typography.pxToRem(12),
  },
  userName: {
    margin: spacing(0, 0, 0.25, 0),
    fontWeight: 600,
    color: palette.text.grey7,
  },
  userRole: {
    margin: spacing(0),
    color: palette.text.grey3,
  },
  projectDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  projectTitle: {
    color: palette.text.grey7,
    margin: spacing(0, 0, 0.25, 0),
  },
  projectFTE: {
    margin: 0,
    color: palette.text.grey8,
  },
  badge: {
    lineHeight: 1,
    borderRadius: 5,
    padding: spacing(0.25, 0.6),
    height: 20,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: ({ status }) => (status ? palette.background.pink : palette.background.green3),
    backgroundColor: ({ status }) => (status ? `rgba(74, 213, 195, 0.08)` : `rgba(219, 0, 79, 0.06)`),
  },
}));
