import React, { useEffect, useRef, useCallback, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import Select from "react-select";
import { SelectComponents } from "react-select/src/components";
import { ValueType } from "react-select/src/types";

import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { FormControlProps } from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";

import _ from "lodash";

import SelectOptionsWithCounter from "./select-components/select-options-with-counter";

import useStyles, { getStyles } from "./select-control.styles";

export type OptionType = { value: string; label: string };
export type CounterDataType = { limit: number; count: number };

type Props = FieldRenderProps<string[] | string, HTMLElement> & {
  label?: string;
  formControlProps?: FormControlProps;
  allowMultiple?: boolean;
  options: Array<OptionType>;
  defaultOptions?: Array<OptionType>;
  isLoading?: boolean;
  isError?: boolean;
  noOptionsMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  components?: Partial<SelectComponents<OptionType>>;
  isClearable?: boolean;
  getOptions: (inputValue: string) => void;
  counterData?: CounterDataType;
  forcedError?: string;
  helperTextPosition?: "right" | "left" | "center";
  helperText?: string;
};

const AsyncSelectControl: React.FC<Props> = ({
  label = "",
  allowMultiple = false,
  options,
  defaultOptions = [],
  input: { value, name, onChange, onFocus, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  isLoading = false,
  isError = false,
  noOptionsMessage = "No options",
  placeholder = "Start typing to search for options",
  helperText,
  helperTextPosition = "left",
  components,
  disabled,
  isClearable = true,
  getOptions,
  counterData,
  forcedError,
  ...customProps
}) => {
  const theme = useTheme();

  const error = metaError || forcedError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;
  const debouncedGetOptions = useCallback(_.debounce(getOptions, 500), [getOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isActive, setIsActive] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const controlRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ showError, isMouseOver: isActive || isMouseOver });
  const styles = getStyles({ theme, showError });

  // Different from 'isDirty' below. Allows to mark select input as dirty if user searched for options, but didn't submit form.
  const [isInputTouched, setIsInputTouched] = React.useState(false);

  useEffect(() => {
    const ref = controlRef.current;
    const mouseEnter = () => setIsMouseOver(true);
    const mouseLeave = () => setIsMouseOver(false);
    ref?.addEventListener("mouseenter", mouseEnter);
    ref?.addEventListener("mouseleave", mouseLeave);
    return () => {
      ref?.removeEventListener("mouseenter", mouseEnter);
      ref?.removeEventListener("mouseleave", mouseLeave);
    };
  }, []);

  const [optionsList, setOptionsList] = useState<OptionType[]>([]);

  const selectedOptions = (allowMultiple ? (value as string[]) || [] : [value as string]).map((v) =>
    optionsList.find((o) => o.value === v),
  ) as Array<OptionType>;

  React.useEffect(() => {
    setOptionsList((prev) => {
      const filteredOptions = options.filter((option) => !prev.some((prevOption) => prevOption.value === option.value));
      return [...prev, ...filteredOptions];
    });
  }, [options]);

  return (
    <Box my={1}>
      <FormControl error={showError}>
        <InputLabel htmlFor={label} className={classes.label} variant="outlined">
          <span>{label}</span>
        </InputLabel>

        <div ref={controlRef}>
          <Select
            id={label}
            isMulti={allowMultiple}
            name={name}
            placeholder={!isLoading && placeholder}
            value={selectedOptions}
            isSearchable
            isClearable={isClearable}
            options={options}
            isDisabled={disabled}
            onInputChange={(inputValue) => {
              // Mark input as touched if user searched for some options
              if (inputValue) {
                setIsInputTouched(true);
                return debouncedGetOptions(inputValue);
              }
            }}
            onFocus={() => {
              setIsActive(true);
              // If input was touched -> fetch fresh data and clear input state.
              // It forces input to refetch data if previuos result was e.g. empty [].
              if (isInputTouched) {
                setIsInputTouched(false);
                return debouncedGetOptions("");
              }
            }}
            onChange={(selected: ValueType<OptionType>) => {
              Array.isArray(selected)
                ? onChange(selected.map((s) => s.value))
                : onChange(selected ? (selected as OptionType).value : undefined);
            }}
            closeMenuOnSelect={!allowMultiple}
            isLoading={isError ? false : isLoading}
            styles={styles}
            noOptionsMessage={() => (isError ? "There is something wrong with data" : noOptionsMessage)}
            components={{
              ...components,
              ...(counterData && {
                MenuList: (props) => (
                  <SelectOptionsWithCounter {...props} {...{ counterData }} selectedCount={selectedOptions.length} />
                ),
              }),
            }}
            classNamePrefix="react-select"
            {...restInput}
            {...customProps}
            onBlur={() => {
              restInput?.onBlur();

              setIsActive(false);
              setIsMouseOver(false);
            }}
          />
        </div>

        {showError && <FormHelperText>{error || submitError}</FormHelperText>}
      </FormControl>
      {helperText && (
        <FormHelperText className={classes.helperText} style={{ textAlign: helperTextPosition }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default AsyncSelectControl;
