import { lazy } from "react";

const EmployeesRouter = lazy(() => import("modules/employees/router"));
const ProjectsRouter = lazy(() => import("modules/projects/router"));
const BenchRouter = lazy(() => import("modules/bench-statistics/router"));
const EvaluationRouter = lazy(() => import("modules/evaluation/router"));
const SettingsRouter = lazy(() => import("modules/settings/router"));
const AssignmentsRouter = lazy(() => import("modules/assignments/router"));
const TimesheetsRouter = lazy(() => import("modules/timesheets/router"));

export type ProjectDetailsParams = {
  id: string;
  tab?: "overview" | "history" | "finance" | "notes" | "evaluations";
  assetId?: string;
};

export type EmployeeDetailsParams = {
  id: string;
  tab?:
    | "overview"
    | "history"
    | "experience-education"
    | "resume"
    | "evaluations"
    | "metrics"
    | "transaction-log"
    | "accounting-documents"
    | "notes";
};

export type CandidateDetailsParams = {
  id: string;
  tab?: "overview" | "experience-education" | "resume";
};

export type CandidateEditParams = {
  id: string;
};

export type EvaluationTemplateEditParams = {
  id: string;
};

export type EmployeeEditParams = {
  id: string;
};

export type EditResumeParams = {
  id: string;
  resumeId: string;
};

export type ProjectEditParams = {
  id: string;
};

export type EmployeeMetricsParams = {
  type: "jira" | "git" | "slack";
};

export type SettingsRolesParams = {
  type: "permissions" | "roles";
};

export type EvaluationSurveyParams = {
  id: string;
};

export const backendEndpoints = {
  login: "auth/login",
  refreshToken: "auth/refreshToken",
  resetPassword: "auth/resetPassword",
  changePassword: "auth/changePassword",
  sendVerificationEmail: "auth/sendVerificationEmail",
  uploadFile: "/files/upload",
  uploadEmployeesNotReportedHours: "/emails/upload-employees",
  evaluationsReport: "/evaluation/report",
  timesheetsReport: "/timesheets/report",
  getEvaluation: "evaluation/:id",
  evaluationUpdate: "evaluation/update",
  getAssessment: "self-assessment/:id",
  assessmentUpdate: "self-assessment/update",
};

const routes = {
  auth: {
    login: "/login",
    forgotPassword: "/forgot-password",
    setPassword: "/set-password",
    evaluationSurvey: "/survey/evaluation/:id",
    assessmentSurvey: "/survey/assessment/:id",
    meta: {
      basePath: "/login",
      routerComponent: null,
    },
  },
  timesheets: {
    report: "/timesheets",
    meta: {
      basePath: "/timesheets",
      routerComponent: TimesheetsRouter,
    },
  },
  surveys: {
    evaluation: "/survey/evaluation/:id",
    evaluationView: "/survey/evaluation/view/:id",
    assessment: "/survey/assessment/:id",
    assessmentView: "/survey/assessment/view/:id",

    templates: {
      list: "/survey/templates",
      add: "/survey/templates/add",
      edit: "/survey/templates/edit/:id",
    },

    meta: {
      basePath: "/survey",
      routerComponent: EvaluationRouter,
    },
  },
  benchStatistic: {
    list: "/bench-statistic",
    meta: {
      basePath: "/bench-statistic",
      routerComponent: BenchRouter,
    },
  },
  employees: {
    list: "/employees",
    archived: "/employees/archived",
    contracts: "/employees/contracts",
    metrics: "/employees/metrics/:type?",
    add: "/employees/add",
    edit: "/employees/edit/:id",

    candidates: {
      list: "/employees/candidates",
      details: "/employees/candidates/details/:id/:tab?",
      add: "/employees/candidates/add",
      edit: "/employees/candidates/edit/:id",
      transform: "/employees/candidates/transform/:id",
      addResume: "/employees/candidates/details/:id/resume/add",
      editResume: "/employees/candidates/details/:id/resume/edit/:resumeId",
    },

    details: "/employees/details/:id/:tab?/:assetId?",
    addResume: "/employees/details/:id/resume/add",
    editResume: "/employees/details/:id/resume/edit/:resumeId",

    meta: {
      basePath: "/employees",
      routerComponent: EmployeesRouter,
    },
  },
  projects: {
    list: "/projects",
    archived: "/projects/archived",
    add: "/projects/add",
    edit: "/projects/edit/:id",
    details: "/projects/details/:id/:tab?",
    chart: "/projects/chart",
    meta: {
      basePath: "/projects",
      routerComponent: ProjectsRouter,
    },
  },
  settings: {
    users: "/settings/users",
    auditLog: "/settings/audit-log",
    configurations: "/settings",
    serviceKeys: "/settings/service-keys",
    rolesAndPermissions: "/settings/roles/:type?",
    meta: {
      basePath: "/settings",
      routerComponent: SettingsRouter,
    },
  },
  assignments: {
    overview: "/assignments",
    list: "/assignments/list",
    meta: {
      basePath: "/assignments",
      routerComponent: AssignmentsRouter,
    },
  },
};

export const DEFAULT_ROUTE = routes.projects.list;

export default routes;
