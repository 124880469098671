import { FieldFunctionOptions, FieldPolicy, TypePolicies } from "@apollo/client";
import { KeyFieldsFunction } from "@apollo/client/cache/inmemory/policies";

const users: FieldPolicy = {
  read: (existing) => Object.assign({}, existing),
  merge: false,
};

const roles: FieldPolicy = {
  read: (existing) => Object.assign({}, existing),
  merge: false,
};

const contracts: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const employees: FieldPolicy = {
  read: (existing) => existing,
  merge: (existing, incoming, { args }) => {
    if (args?.page === 1) {
      return incoming;
    }
    const mergedData = getResult(Array.from(existing?.data ?? []), Array.from(incoming?.data ?? []), args);

    return { ...incoming, data: mergedData };
  },
};

const candidates: FieldPolicy = {
  read: (existing) => existing,
  merge: (existing, incoming, { args }) => {
    if (args?.page === 1) {
      return incoming;
    }
    const mergedData = getResult(Array.from(existing?.data ?? []), Array.from(incoming?.data ?? []), args);

    return { ...incoming, data: mergedData };
  },
};

const projects: FieldPolicy = {
  read: (existing) => existing,
  merge: (existing, incoming, { args }) => {
    if (args?.page === 1) {
      return incoming;
    }
    const mergedData = getResult(Array.from(existing?.data ?? []), Array.from(incoming?.data ?? []), args);

    return { ...incoming, data: mergedData };
  },
};

const getSkillCategories: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const employee: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const gitRepositoryStatistics: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const slackMetricsSummary: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const jiraProjectsStatistics: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const employeeSkills: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const tenantConfigurationTechnologies: FieldPolicy = {
  merge: (existing, incoming, { args }) => {
    const mergedData = getResult(Array.from(existing?.data ?? []), Array.from(incoming?.data ?? []), args);
    return { ...incoming, data: mergedData };
  },
};

const getEvaluations: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

const expirableDocumentTypes: FieldPolicy = {
  read: (existing) => existing,
  merge: false,
};

function getResult<T>(
  existing: Array<T>,
  incoming: Array<T>,
  args: FieldFunctionOptions["args"],
  argsKeys?: Array<string>,
) {
  // if any of the args with declared argsKeys exists and page === 1 => return incoming data
  if (args && argsKeys?.some((key) => args[key]) && args.page === 1) {
    return incoming;
  }

  // Loop function merges and overrides existing and incoming elements
  // Similar usage: https://www.apollographql.com/docs/react/pagination/cursor-based/
  // Example when page=1:
  // limit=10, search="abc" -> e.g. query returns 2 items
  // then search is cleared -> query returns first 10 items
  // wrong result: With spread [...merged,...incoming], cache will merge previous 2 items + 10 new items.
  // good result: With the loop, cache returns only 10 new items, becuase function overrides existing index by incoming index.
  // With page > 1, it is similar, but limit is needed to calcuate index.
  const result = Array.from(existing ?? []);
  for (let i = 0; i < incoming.length; ++i) {
    args?.page === 1 ? (result[i] = incoming[i]) : (result[(args?.page - 1) * args?.limit + i] = incoming[i]);
  }
  return result;
}

const dataIdFromObjectEmployee: KeyFieldsFunction = (object) => {
  // This function is substitute for ["id", "firstName", "lastName"] because sometimes our response has __typename = "Employee" but It doesn't contain field firstName, lastName or ID.
  if (!("id" in object)) {
    return ["firstName", "lastName"];
  } else if ("firstName" in object && "lastName" in object) {
    return ["id", "firstName", "lastName"];
  } else {
    return ["id"];
  }
};

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      users,
      roles,
      contracts,
      employees,
      candidates,
      projects,
      gitRepositoryStatistics,
      slackMetricsSummary,
      jiraProjectsStatistics,
      getSkillCategories,
      employee,
      getEvaluations,
      expirableDocumentTypes,
    },
  },
  Employee: {
    fields: {
      skills: employeeSkills,
    },
    keyFields: dataIdFromObjectEmployee,
    // This solution was provided to fix bug with merging Employee historical data into current Employee data. Apollo has seen two objects with same ID's and typename.
    // KeyFields customize how cache interacts with __typename = "Employee" objects. Thanks to this Apollo can determine Employee uniqueness with not only ID but also firstName and lastName.
  },
  EmployeeResumeLevelSkills: {
    keyFields: ["id", "name", "level", "visible"],
  },
  LevelSkills: {
    keyFields: ["id", "name", "level", "skillCategory", "visible"],
  },
  EvaluationQuestionDto: {
    keyFields: ["id", "question"],
  },
  MatrixQuestionsDto: {
    keyFields: ["id", "name"],
  },
  OptionsForEvaluationQuestionDto: {
    keyFields: ["id", "name"],
  },
  EmployeeStatusConfiguration: {
    fields: {
      technologies: tenantConfigurationTechnologies,
    },
  },
};
