import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing, typography, }) => ({
  topSection: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    maxWidth: 870,
    margin: "0 auto",
    marginTop: spacing(6.75),
    padding: spacing(3.75, 2.5, 3.25, 2.5),
    background: palette.text.acai,
    borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0px 0px`,
  },

  logoTitle: {
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(21),
    color: palette.text.contrast,
    marginTop: spacing(0.5),
    textAlign: "center",
  },

  evaluatedItemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "end",
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.n5,
  },
  evaluatedItemName: {
    marginTop: spacing(1),
    color: palette.text.contrast,
    fontWeight: typography.fontWeightBold,
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  table: {
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
    padding: spacing(1, 4, 0, 4),
    borderRadius: `0px 0px ${shape.borderRadius}px ${shape.borderRadius}px`,

    "& .MuiTableCell-root": {
      padding: spacing(2.25, 2),
    },
    "& .MuiTableCell-head": {
      border: 0,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
    },

    "& tr": {
      "&:last-child": {
        "& td, & th": {
          border: 0,
        },
      },
      "& td, & th": {
        borderBottom: "1px solid #F1F2F3",
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(16),
      },
      "& th": {
        width: 100,
        wordBreak: "break-all",
      },
    },
  },

  answerLabel: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    color: palette.text.acai,
    padding: `0 !important`,
  },

  tableContainer: {
    maxWidth: 870,
    margin: "0 auto",
    width: "100%",
    borderRadius: `0px 0px ${shape.borderRadius}px ${shape.borderRadius}px`,
  },

  fixedSection: {
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0)",
    background: palette.background.default,
    zIndex: 1,
    top: 0,
  },
  marginTop: {
    marginBottom: spacing(20.875),
  },
}));
