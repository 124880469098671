import React from "react";

import { Button } from "components";

import { substringWithDots } from "utils/strings";

import useStyles from "./text-expand.style";

export interface TextExpandProps {
  isExpanded: boolean;
  toggleExpand: () => void;
  text: string;
  limit?: number;
}

const TextExpand: React.FC<TextExpandProps> = ({ isExpanded, toggleExpand, text, limit = 100 }) => {
  const classes = useStyles();

  return (
    <>
      <p className={classes.text}>{isExpanded ? text : substringWithDots(text, limit)}</p>
      {text.length > limit ? (
        <Button
          className={classes.button}
          onClick={toggleExpand}
          label={isExpanded ? "Show less" : "Show more"}
          variant="text"
        />
      ) : null}
    </>
  );
};

export default TextExpand;
