import { makeStyles } from "@mui/styles";

type Params = {
  type: "employee" | "project";
};
export default makeStyles<CustomTheme, Params>(({ palette, spacing, typography }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: spacing(1.5, 0),
  },
  section: {
    display: "block",
    width: "100%",
  },
  name: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.pxToRem(16),
    marginRight: spacing(1),
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  detailsContainer: {
    display: "flex",
    alignItems: "center",

    "& p": {
      position: "relative",
      fontSize: typography.pxToRem(12),
      paddingRight: `calc(${spacing(1)} + 2px)`,
      marginRight: ({ type }) => (type === "employee" ? spacing(1) : spacing(1.5)),

      "&:after": {
        content: "''",
        display: "block",
        position: "absolute",
        right: 0,
        top: "50%",
        width: 3,
        height: 3,
        background: palette.text.grey4,
        transform: "translatey(-50%)",
      },
      "&:first-of-type": {
        "&:after": {
          display: ({ type }) => type === "project" && "none",
        },
      },
      "&:last-of-type": {
        "&:after": {
          display: ({ type }) => type === "employee" && "none",
        },
      },
    },
  },
  role: {
    order: ({ type }) => (type === "employee" ? 0 : 1),
  },
}));
