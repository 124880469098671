import * as React from "react";
import clsx from "clsx";
import { SnackbarProvider, OptionsObject } from "notistack";
import { IconButton } from "@mui/material";
import { CheckCircleOutlineRounded, ErrorOutlineRounded, Close } from "@mui/icons-material";

import useStyles from "./toast-provider.styles";

const ToastProvider: React.FC = ({ children }) => {
  const classes = useStyles();

  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: OptionsObject["key"]) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)} size="small" className={classes.closeButton}>
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
      maxSnack={3}
      dense
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={{
        success: <CheckCircleOutlineRounded />,
        error: <ErrorOutlineRounded />,
      }}
      classes={{
        containerAnchorOriginTopRight: classes.container,
        variantSuccess: clsx(classes.toast, classes.success),
        variantError: clsx(classes.toast, classes.error),
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
