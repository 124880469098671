import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";

import useStyles from "./image-preview.styles";

export type Props = {
  img: { url: string; alt: string };
  isOpen: boolean;
  onClose: () => void;
};

const ImagePreview: React.FC<Props> = ({ img, isOpen, onClose }) => {
  const classes = useStyles();

  const handleModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      scroll="body"
      open={isOpen}
      onClose={handleModalClose}
      PaperProps={{ classes: { root: classes.paperRoot } }}
    >
      <IconButton onClick={handleModalClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <img src={img.url ?? undefined} alt={img.alt} className={classes.imagePreview} />
    </Dialog>
  );
};

export default ImagePreview;
