import { FC } from "react";

import { CloseRounded as CloseIcon } from "@mui/icons-material";
import { Box, Dialog, DialogProps, Fade, IconButton, DialogTitle } from "@mui/material";

import useStyles from "./modal.styles";

type Props = Omit<DialogProps, "maxWidth" | "onClose"> & {
  open: DialogProps["open"];
  maxWidth?: number;
  maxWidthContent?: number;
  title?: string;
  onClose: (e: {}, reason: "escapeKeyDown" | "backdropClick" | "closeButton") => void;
};

const Modal: FC<Props> = ({ open, maxWidth, maxWidthContent, title, onClose, children, ...props }) => {
  const classes = useStyles({ maxWidth, maxWidthContent });

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="modal-title"
      {...props}
    >
      <Fade in={open}>
        <Box width="100%">
          <IconButton onClick={(e) => onClose(e, "closeButton")} className={classes.closeButton} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>

          {title ? (
            <DialogTitle id="modal-title" className={classes.dialogTitle}>
              {title}
            </DialogTitle>
          ) : null}
          <div className={classes.dialogContent}>{children}</div>
        </Box>
      </Fade>
    </Dialog>
  );
};

export default Modal;
