import React from "react";

import { PermissionsProp, useAuth } from "services/auth/auth-provider";

type Props = {
  children: React.ReactNode;
  permissions: PermissionsProp;
};

const HasAccess: React.FC<Props> = ({ children, permissions }) => {
  const auth = useAuth();
  const hasAccess = auth?.checkAccess({ permissions });

  return hasAccess ? <>{children}</> : null;
};

export default HasAccess;
