import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  section: {
    display: "flex",
    alignItems: "center",
    margin: spacing(1.5, 0),
  },
  label: {
    color: palette.text.grey,
    width: "30%",
    textAlign: "right",
    paddingRight: spacing(1.5),
    fontSize: typography.pxToRem(12),
    wordBreak: "break-word",
  },
  value: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    paddingLeft: spacing(1.5),
    fontSize: typography.pxToRem(14),
    wordBreak: "break-word",
  },
}));
