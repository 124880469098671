import { useEffect, useState } from "react";

import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Fade } from "@mui/material";

import { AutoGrid, Button, LoadingPlaceholder } from "components";

import useStyles from "./confirmation-modal.styles";

export type Props = {
  isOpen: boolean;
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  onConfirm?: () => any;
  onAbort?: () => void;
  confirmText?: string;
  abortText?: string;
  confirmColor?: "error" | "secondary" | "primary";
};

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  title,
  text,
  onConfirm,
  onAbort,
  confirmText,
  abortText,
  confirmColor = "error",
}) => {
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitting(false);
  }, [isOpen]);

  const handleConfirm = async () => {
    setSubmitting(true);
    onConfirm && (await onConfirm());
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={onAbort}
      disableEscapeKeyDown={isSubmitting}
    >
      <Fade in={isOpen}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {title && <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>}
          {text && (
            <DialogContent
              classes={{
                root: classes.content,
              }}
            >
              <DialogContentText align="center" className={classes.contentText}>
                {text}
              </DialogContentText>
            </DialogContent>
          )}
          {isSubmitting && <LoadingPlaceholder inline />}
          <DialogActions className={classes.dialogActions}>
            <AutoGrid spacing={2} alignItems="center" wrap="nowrap">
              {abortText && (
                <Button onClick={onAbort} variant="outlined" color="hint" label={abortText} disabled={isSubmitting} />
              )}
              {confirmText && (
                <Button onClick={handleConfirm} disabled={isSubmitting} color={confirmColor} label={confirmText} />
              )}
            </AutoGrid>
          </DialogActions>
        </Box>
      </Fade>
    </Dialog>
  );
};

export default ConfirmationModal;
