import * as React from "react";
import { Box, Typography, CircularProgress, CircularProgressProps } from "@mui/material";

import useStyle from "./upload-progress-with-value.style";

type Props = CircularProgressProps & { progressEvent: any };

const UploadProgressWithValue: React.FC<Props> = ({ progressEvent, ...restProps }) => {
  const classes = useStyle();

  var percentCompleted = progressEvent ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;

  return (
    <Box className={classes.root}>
      <CircularProgress variant="determinate" size={45} value={percentCompleted} {...restProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {progressEvent && (
          <Typography variant="caption" component="div" color="primary" className={classes.label}>{`${Math.round(
            percentCompleted
          )}%`}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default UploadProgressWithValue;
