import { useState, Dispatch, SetStateAction } from "react";

export type Search = {
  setSearch: Dispatch<SetStateAction<string | undefined>>;
  value?: string;
};

const useSearch = (initialValue?: string) => {
  const [search, setSearch] = useState(initialValue);

  return {
    value: search,
    setSearch,
  };
};

export default useSearch;
