import { StylesConfig } from "react-select/src/styles";

import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing, palette }) => ({
  selectedOption: {
    color: palette.text.turquoise,
    marginLeft: spacing(1),
  },
}));

export function getStyles({ theme }: { theme: CustomTheme }): StylesConfig {
  const { palette, spacing, shape } = theme;
  return {
    control: (styles) => ({
      ...styles,
      minWidth: 150,
      paddingRight: spacing(3),
      border: `1px solid ${palette.text.n5}`,
      borderRadius: shape.borderRadius,
      boxShadow: "unset !important",
      minHeight: 42,
      ":hover": {
        borderColor: palette.text.n5,
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#828282",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      whiteSpace: "normal",
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1000,
      minWidth: 200,
    }),
    menuList: (styles) => ({
      ...styles,
      color: palette.text.n2,
    }),
    placeholder: (styles) => ({
      ...styles,
      transform: "unset",
      position: "unset",
      color: palette.text.grey1,
    }),
  };
}
