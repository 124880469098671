import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ spacing }) => ({
  none: {
    marginBottom: spacing(0),
  },
  dense: {
    marginBottom: spacing(4),
  },
  normal: {
    marginBottom: spacing(6),
  },
  big: {
    marginBottom: spacing(12),
  },
}));
