import { backendEndpoints } from "config/routes";

import { API } from "services/api";
import { useAuth } from "services/auth";
import { useTenantContext } from "services/tenant/context/tenant.context";

const useChangePassword = (token?: string) => {
  const auth = useAuth();
  const { data: tenantId } = useTenantContext();

  const changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    if (auth?.token) {
      await API({
        method: "post",
        url: backendEndpoints.changePassword,
        data: { tenantId, oldPassword, newPassword, confirmNewPassword },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
    }
  };
  return { changePassword };
};

export default useChangePassword;
