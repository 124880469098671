import { gql } from "@apollo/client";

import { ProjectEmployeeAssignment } from "modules/assignments/graphql/project-employee-assignment.graphql";
import { Contract } from "modules/employees/types/employee-contract.types";

import { Employee } from "../employees.graphql";

export const employeeFragment = gql`
  fragment EmployeeData on Employee {
    currentEmploymentStatus
    firstName
    id
    lastName
    location
    associatedEmails
    privateEmail
    companyEmail
    photo
    startDate
    endDate
    country
    effigyApproval
    reasonForTermination
    accountingId
    office {
      id
      name
    }
    onboardingBuddy {
      firstName
      id
      lastName
    }
    phone
    skills {
      id
      name
      level
      visible
      skillCategory {
        id
        name
      }
    }
    role {
      id
      name
    }
    grade {
      id
      name
    }
    categoryId
    resumeLink
    linkedinLink
    github
    candidateSourceId
    isTreatedAsBillable
    englishLevel
    kidsBelow8YearsOld
  }
`;

export const employeeResumeFragment = gql`
  fragment EmployeeResumeData on Employee {
    resume {
      experiences {
        company
        position
        grade
        project
        description
        technologies
        start
        end
        isCurrent
        visible
      }
      certificates {
        name
        start
        visible
      }
      education {
        end
        fieldOfStudy
        isCurrent
        name
        start
        visible
      }
    }
  }
`;

export const contractFragment = gql`
  fragment NoCostContractData on Contract {
    id
    noticePeriod
    startDate
    endDate
    type
    workTime
    company {
      id
      name
    }
    holidaysB2B
    employee {
      id
    }
    note
  }
`;

export const projectAssignmentFragment = gql`
  fragment AssignmentData on ProjectEmployeeAssignment {
    id
    status
    startDate
    endDate
    role {
      id
      name
    }
    statusForNonBillable {
      id
      name
    }
    reasonForNonBillable
    employee {
      id
      firstName
      lastName
      photo
    }
    project {
      id
      name
      startDate
      endDate
      managementHistory {
        startDate
        endDate
        manager {
          id
          firstName
          lastName
        }
        position
      }
      management {
        managerLevel1 {
          firstName
          lastName
          id
        }
        managerLevel2 {
          firstName
          lastName
          id
        }
        supervisor {
          firstName
          id
          lastName
        }
      }
    }
  }
`;

export type GetEmployeeDetailsData = {
  employee: Employee;
  contracts: {
    data: Contract[];
  };
  projectsEmployeeAssignment: {
    data: ProjectEmployeeAssignment[];
  };
};

export type GetEmployeeDetailsVars = {
  id: string;
  pastAssignments: boolean;
  sort?: string;
  canContractsRead: boolean;
  canProjectsEmployeeAssignmentRead: boolean;
};

export type GetEmployeeDetailsArgs = {
  canContractCostRead: boolean;
  canAssignmentsAvailabilityRead: boolean;
  canAssignmentsBillableStatusRead: boolean;
};

export const GET_EMPLOYEE_DETAILS = (args: GetEmployeeDetailsArgs) => gql`
  query employeeDetails($id: String!, $pastAssignments: Boolean!, $sort: String, $canContractsRead: Boolean!, $canProjectsEmployeeAssignmentRead: Boolean!) {
    employee(id: $id)  {
     ...EmployeeData
     alerts
     ...EmployeeResumeData
    }
    contracts(employeeID: $id, sort: $sort) @include(if: $canContractsRead) {
      data {
        ...NoCostContractData
        ${args.canContractCostRead ? "cost" : ""}
      }
    }
    projectsEmployeeAssignment(employeeID: $id, futureAssignments: true, pastAssignments:$pastAssignments) @include(if: $canProjectsEmployeeAssignmentRead) {
      data {
        ...AssignmentData
        ${args.canAssignmentsBillableStatusRead ? "billableStatus" : ""}
        ${args.canAssignmentsAvailabilityRead ? "availability" : ""}
      }
    }
  }
   ${employeeFragment}, ${employeeResumeFragment}, ${contractFragment}, ${projectAssignmentFragment}
`;

export type GetEmployeeData = {
  employee: Employee;
};

export type GetEmployeeVars = {
  id: string;
};

export const GET_EMPLOYEE = gql`
  query employee($id: String!) {
    employee(id: $id) {
      ...EmployeeData
    }
  }
  ${employeeFragment}
`;
