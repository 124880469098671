import { FC, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@mui/material";

import clsx from "clsx";

import useStyles from "./dropdown.styles";

export type DropdownItem = {
  label: string | React.ReactElement;
  onClick: () => void;
  component?: "li" | "a";
  href?: string;
  download?: string;
};

type Props = {
  children?: React.ReactNode;
  items: Array<DropdownItem>;
  variant?: "default" | "outlined";
  visibleOnHover?: boolean;
  classes?: any;
};

const Dropdown: FC<Props> = ({
  items,
  children,
  variant = "default",
  visibleOnHover = true,
  classes: parentClasses,
}) => {
  const classes = useStyles({ classes: parentClasses });

  const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(false);
  const showMoreButton = () => setIsMoreButtonVisible(true);
  const hideMoreButton = () => setIsMoreButtonVisible(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const closeDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={visibleOnHover ? showMoreButton : undefined}
      onMouseLeave={visibleOnHover ? hideMoreButton : undefined}
    >
      {children}

      {items.length > 0 ? (
        <>
          <IconButton
            aria-controls="dropdown"
            aria-haspopup="true"
            className={clsx(
              classes.moreButton,
              variant === "outlined" && classes.outlinedButton,
              visibleOnHover && classes.moreButtonHidden,
              (isMoreButtonVisible || Boolean(anchorEl)) && classes.isVisible,
            )}
            onClick={openDropdown}
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
          <Menu
            id="dropdown"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={closeDropdown}
            className={classes.dropdown}
          >
            {items.map(({ label, onClick, component, href, download }, index) => (
              <MenuItem
                component={component ? component : "li"}
                href={href}
                download={download}
                key={index}
                onClick={(e: any) => {
                  closeDropdown(e);
                  onClick();
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
    </div>
  );
};
export default Dropdown;
