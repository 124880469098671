import * as React from "react";

import { TablePagination } from "@mui/material";

import { AutoGrid } from "components";

import { PaginationState } from "hooks/usePagination/index";

import useStyles from "./pagination.styles";

export type Props = {
  page: number;
  totalCount?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onChange?: (nextPagination: PaginationState) => void;
};

const Pagination: React.FC<Props> = ({
  page,
  totalCount = 0,
  rowsPerPage = 10,
  onChange = () => {},
  rowsPerPageOptions = [10, 25, 50],
}) => {
  const classes = useStyles();

  return (
    <>
      {Boolean(totalCount) && (
        <div className={classes.paginationContainer}>
          <AutoGrid justify="flex-end" showEmpty spacing={0}>
            <TablePagination
              component="div"
              page={page - 1}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={(_, newPage) => {
                onChange({
                  page: newPage > page - 1 ? page + 1 : page - 1,
                  rowsPerPage,
                });
              }}
              onRowsPerPageChange={(e) => {
                onChange({
                  page: 1,
                  rowsPerPage: +e.target.value,
                });
              }}
            />
          </AutoGrid>
        </div>
      )}
    </>
  );
};

export default Pagination;
