import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { transformError } from "services/graphql/errorTransformers";

import {
  GetProjectsNamesData,
  GetProjectsNamesVars,
  GET_PROJECTS_NAMES,
} from "modules/projects/graphql/projects.graphql";

import { KendoTableSelectFilter } from "components";
import { TableGroupByOptions } from "components/kendo-table/types/kendo-table.types";

type Props = {
  value: string[] | string | undefined;
  onChange(val: string[] | string): void;
  isMulti?: boolean;
};

const ProjectFilter: React.FC<Props> = ({ value, onChange, isMulti = true }) => {
  const { data, loading, error } = useQuery<GetProjectsNamesData, GetProjectsNamesVars>(GET_PROJECTS_NAMES, {
    variables: {
      limit: 0,
      page: 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const options = useMemo(() => {
    if (error) {
      return [{ label: transformError(error).error as string, value: "", disabled: true }];
    } else {
      return (
        data?.projects?.data?.reduce((obj, project) => {
          if (project) {
            obj.push({ value: project.id, label: project.name });
          }

          return obj;
        }, [] as TableGroupByOptions["options"]) || []
      );
    }
  }, [data, error]);

  return (
    <KendoTableSelectFilter
      onChange={onChange}
      isMulti={isMulti}
      placeholder="Projects"
      value={value}
      isSearchable
      isLoading={loading}
      options={options}
    />
  );
};

export default ProjectFilter;
