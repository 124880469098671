import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

export type PaginationState = { rowsPerPage: number; page: number };
export type Pagination = {
  setPagination: (paginationState: Partial<PaginationState>) => void;
} & PaginationState;

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

//FLOW FOR PAGINATION QUERYFIRST
//CHANGING QUERY -> CHANGE PAGINATION STATE
const usePagination = (initialValues?: PaginationState) => {
  const history = useHistory();
  const paginationState = initialValues ?? { rowsPerPage: DEFAULT_ROWS, page: DEFAULT_PAGE };
  const [rowsPerPage, setRowsPerPage] = useState(paginationState.rowsPerPage);
  const [page, setPage] = useState(paginationState.page);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const queryPage = queryParams.get("page") || DEFAULT_PAGE;
    const queryRows = queryParams.get("rows") || DEFAULT_ROWS;
    setPage(+queryPage);
    setRowsPerPage(+queryRows);
  }, [history.location.search]);

  const handleSetPage = useCallback(
    (page: number) => {
      const queryParams = new URLSearchParams(history.location.search);
      const { page: oldPage, rows, ...queryParamsObj } = Object.fromEntries(queryParams);
      const newSearchParams = new URLSearchParams(queryParamsObj);
      if (page !== DEFAULT_PAGE) {
        newSearchParams.append("page", page.toString());
      }
      if (rows) {
        newSearchParams.append("rows", rows);
      }
      history.push({
        search: `?${newSearchParams}`,
      });
    },
    [history],
  );

  const handleSetRowsPerPage = useCallback(
    (rowsPerPage: number) => {
      const queryParams = new URLSearchParams(history.location.search);
      const { rows: oldRows, page, ...queryParamsObj } = Object.fromEntries(queryParams);
      const newSearchParams = new URLSearchParams(queryParamsObj);
      if (page) {
        newSearchParams.append("page", page);
      }
      if (rowsPerPage !== DEFAULT_ROWS) {
        newSearchParams.append("rows", rowsPerPage.toString());
      }
      history.push({
        search: `?${newSearchParams}`,
      });
    },
    [history],
  );

  useEffect(() => {
    if (initialValues?.rowsPerPage) {
      handleSetRowsPerPage(initialValues?.rowsPerPage);
    }
    if (initialValues?.page) {
      handleSetPage(initialValues?.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagination: Pagination = {
    rowsPerPage,
    page,
    setPagination: ({ rowsPerPage, page }) => {
      typeof rowsPerPage !== "undefined" && handleSetRowsPerPage(rowsPerPage);
      typeof page !== "undefined" && handleSetPage(page);
    },
  };

  return pagination;
};

export default usePagination;
