import { SvgIconProps, SvgIcon } from "@mui/material";

const Logout = (props: SvgIconProps) => (
  <SvgIcon
    style={{ width: "16", height: "16" }}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M12.1667 4.66667L10.9917 5.84167L12.3083 7.16667H5.5V8.83333H12.3083L10.9917 10.15L12.1667 11.3333L15.5 8L12.1667 4.66667ZM2.16667 2.16667H8V0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H8V13.8333H2.16667V2.16667Z"
      fill={props.fill || "#5F5F5F"}
    />
  </SvgIcon>
);

export default Logout;
