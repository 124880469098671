import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ breakpoints, palette, spacing, typography }) => ({
  submitButton: {
    width: "100%",
    margin: spacing(3, 0),
    [breakpoints.up("sm")]: {
      width: 200,
    },
  },

  forgotPassword: {
    textAlign: "right",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
