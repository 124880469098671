import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.hint.light,
    padding: spacing(1),
    overflow: "hidden",
  },

  row: {
    border: "unset",
    background: palette.primary.light,
    borderTop: `2px solid ${palette.hint.light}`,
    borderBottom: `2px solid ${palette.hint.light}`,
  },

  cell: {
    padding: spacing(1),
    border: "unset",
  },
}));
