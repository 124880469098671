import { dateToString } from "utils/dateFormat";

export const transformObj = (item: any, key: string) => {
  switch (key) {
    case "employee":
      return `${item.firstName} ${item.lastName}`;
    case "management":
      return [
        item?.supervisor && `Supervisor: ${item?.supervisor}`,
        item?.managerLevel2 && `Senior Delivery Manager: ${item?.managerLevel2}`,
        item?.managerLevel1 && `Delivery Manager: ${item?.managerLevel1}`,
      ]
        .filter(Boolean)
        .join("\n");
    case "endDate":
      return dateToString(item);
    case "startDate":
      return dateToString(item);
    case "createdAt":
      return dateToString(item);
    case "createdBy":
      return item && `${item.firstName} ${item.lastName}`;
    case "updatedAt":
      return dateToString(item);
    case "updatedBy":
      return item && `${item.firstName} ${item.lastName}`;
    default:
      return item;
  }
};
