import React, { useEffect, useState, createContext, useContext } from "react";
import moment from "moment-timezone";

const STORED_TIMEZONE = localStorage.getItem("timezone");
const DEFAULT_TIMEZONE: string = moment.tz.guess();

type TimezoneContextType = {
  timezone: string;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
};

const TimezoneContext = createContext<TimezoneContextType>({} as TimezoneContextType);

const TimezoneProvider: React.FC = ({ children }) => {
  const [timezone, setTimezone] = useState<string>(STORED_TIMEZONE || DEFAULT_TIMEZONE);

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, [timezone]);

  return <TimezoneContext.Provider value={{ timezone, setTimezone }}>{children}</TimezoneContext.Provider>;
};

const useTimezoneContext = () => useContext(TimezoneContext);

export { TimezoneProvider, useTimezoneContext };

// In fact we could create separate hook for accessing just Timezone data and tell TypeScript that it's not undefined (!)
// because we fetch data when rendering provider in index and then wait for no loading and no errors in App

// Also, please be aware that this context is currently problematic when it comes to refetching Timezone data
// that's why refetch is not exposed - we can address this problem when needs arise
