import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import "@progress/kendo-theme-material/dist/all.css";

import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { createBrowserHistory } from "history";

import { config, theme } from "config";

import gitInfo from "./assets/_git_commit";

import { authClient, AuthProvider } from "services/auth";
import { apolloClient } from "services/graphql";
import { SensitiveInformationsProvider } from "services/sensitive-informations/context/sensitive-informations.context";
import { TenantProvider } from "services/tenant/context/tenant.context";
import { TimezoneProvider } from "services/timezone/context/timezone.context";

import { TenantConfigProvider } from "modules/settings/context/configurations.context";

import { ToastProvider } from "components";

import { isLocalhost } from "utils/connection";
import "utils/polyfills";

import { version as packageVersion } from "../package.json";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./wydr";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const history = createBrowserHistory();

if (!isLocalhost) {
  Sentry.init({
    dsn: config.sentrDSN,
    release: `acai-erp-frontend@v${gitInfo.commitHash || "default"}`, // To be changed
    dist: `${packageVersion}.0`,
    environment: process.env.REACT_APP_ENV === "production" ? "production" : "development",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeSend(event) {
      const safeUrl = event.request?.url?.replace(/token=(\w+)/, config.tokenReplace || "");
      return {
        ...event,
        request: {
          ...event.request,
          url: safeUrl,
        },
      };
    },
  });
}

ReactDOM.render(
  <Router history={history}>
    <AuthProvider client={authClient}>
      <ApolloProvider client={apolloClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <TimezoneProvider>
              <TenantProvider>
                <TenantConfigProvider>
                  <SensitiveInformationsProvider>
                    <ToastProvider>
                      <CssBaseline />
                      <App />
                    </ToastProvider>
                  </SensitiveInformationsProvider>
                </TenantConfigProvider>
              </TenantProvider>
            </TimezoneProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </AuthProvider>
  </Router>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
