import React from "react";
import { Container } from "@mui/material";
import useStyles from "./guest-layout.styles";

type Props = {
  children: any;
};

const GuestLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container} maxWidth={false}>
        {children}
      </Container>
    </div>
  );
};

export default GuestLayout;
