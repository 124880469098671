import { ReactElement, useEffect, useRef } from "react";

import { Grid, GridDataStateChangeEvent, GridProps } from "@progress/kendo-react-grid";

import { Box } from "@mui/material";

import clsx from "clsx";
import _ from "lodash";

import {
  AutoGrid,
  Button,
  KendoTableColumnsFilter,
  KendoTableSelectFilter,
  KendoTableSidebar,
  LoadingPlaceholder,
  Search,
} from "components";

import { useFiltersLSConfig, useQueryParams } from "hooks";

import {
  useTableCollapse,
  useTableColumns,
  useTableCounters,
  useTableDataState,
  useTableRowActions,
  useTableSearch,
} from "./hooks";
import useStyles from "./kendo-table.styles";
import { TableData, TableGroupByOptions, TableSidebarOptions } from "./types/kendo-table.types";

export type TableProps<T, F> = {
  data?: T[];
  groupBy?: string;
  groupByOptions?: TableGroupByOptions;
  children: ReactElement[];
  search?: boolean;
  withGrouping?: boolean;
  withInternalSearch?: boolean;
  height?: string;
  width?: string;
  withQueryParams?: boolean;
  customFilter?: ReactElement | ReactElement[];
  loading?: boolean;
  isColumnsFilter?: boolean;
  withClearAllFilters?: boolean;
  sidebarOptions?: TableSidebarOptions;
  hasPagination?: boolean;
  additionalButtons?: ReactElement | ReactElement[];
  tabName?: string;
  classes?: { root?: string };
  defaultStateExternalFilters?: F;
  externalFilters?: F;
  setExternalFilters?: (filters: F) => void;
};

type Props<T, F> = Omit<GridProps, "data"> & TableProps<T, F>;

function KendoTable<T extends TableData, F extends Record<string, any>>({
  data,
  groupBy,
  sort,
  children,
  search = true,
  sortable = true,
  resizable,
  withGrouping = true,
  withInternalSearch = false, // if false - disable kendo search to use backend search
  height = "700px",
  width = "100%",
  customFilter,
  withQueryParams = false,
  loading = false,
  groupByOptions,
  onRowClick,
  onRowDoubleClick,
  isColumnsFilter = true,
  withClearAllFilters = true,
  sidebarOptions,
  hasPagination,
  additionalButtons,
  tabName,
  classes: parentClasses,
  defaultStateExternalFilters,
  externalFilters,
  setExternalFilters,
  ...others
}: Props<T, F>): ReactElement {
  const classes = useStyles({ height, width, resizable, classes: parentClasses });
  const { queryParams } = useQueryParams();

  const tableRef = useRef<Grid | null>(null);
  const initialSearch = queryParams.get("search");

  const { handleRowClick, handleRowMouseDown, handleRowMouseUp } = useTableRowActions<T>({ onRowClick });

  const { allColumnFilteredData, setAllColumnFilter, allColumnFilter } = useTableSearch({
    withInternalSearch,
    children,
    data,
  });

  const { initialDataState, dataState, setDataState, pickedGroupBy, setPickedGroupBy } = useTableDataState({
    groupByOptions,
    groupBy,
    sortBy: sort,
    withQueryParams,
    allColumnFilter,
  });

  const { columnsConfig, setColumnsConfig, visibleColumns } = useTableColumns({ pickedGroupBy, dataState, children });

  const { isAnyFilterAppliedByUser, saveKendoColumnsFiltersConfig, saveExternalFiltersConfig, setClearAllFilters } =
    useFiltersLSConfig({
      data,
      setKendoColumnsFilters: setDataState,
      setExternalFilters,
    });
  const clearAllFilters = () => {
    setClearAllFilters({
      defaultStateKendoColumnsFilters: initialDataState,
      defaultStateExternalFilters,
    });
  };

  useEffect(() => {
    if (!_.isEqual(defaultStateExternalFilters, externalFilters)) {
      saveExternalFiltersConfig(externalFilters);
    }
  }, [defaultStateExternalFilters, externalFilters, saveExternalFiltersConfig]);

  const {
    tableData: filteredData,
    onExpandChange,
    total,
  } = useTableCollapse({ data: allColumnFilteredData, dataState });

  useTableCounters({ loading, tabName, total });

  const handleDataStateChange = (event: GridDataStateChangeEvent) => {
    saveKendoColumnsFiltersConfig(event.dataState);
    setDataState(event.dataState);
  };

  const withAnyFilter = Boolean(groupByOptions || search || customFilter || isColumnsFilter);

  return (
    <>
      {withAnyFilter ? (
        <div className={classes.filtersContainer}>
          <AutoGrid justify="space-between" spacing={0}>
            <div className={classes.leftContainer}>
              {search && (
                <Search
                  className={classes.search}
                  variant="outlined"
                  value={initialSearch || ""}
                  onSearch={(value) => {
                    setAllColumnFilter(value || "");
                  }}
                />
              )}
              {customFilter}
            </div>

            <div className={classes.rightContainer}>
              {groupByOptions && (
                <KendoTableSelectFilter
                  {...groupByOptions}
                  value={pickedGroupBy}
                  onChange={(val) => setPickedGroupBy(val as string)}
                />
              )}
              <div className={classes.filtersRight}>
                {additionalButtons}
                {withClearAllFilters && (
                  <Button
                    disabled={!isAnyFilterAppliedByUser}
                    onClick={clearAllFilters}
                    label="Clear all filters"
                    color="primary"
                    variant="outlined"
                  />
                )}
                {isColumnsFilter && (
                  <KendoTableColumnsFilter columnsConfig={columnsConfig} setColumnsConfig={setColumnsConfig} />
                )}
              </div>
            </div>
          </AutoGrid>
        </div>
      ) : null}

      <Box className={classes.tableContainer}>
        {sidebarOptions && <KendoTableSidebar loading={loading} tableRef={tableRef} options={sidebarOptions} />}
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <Grid
            className={clsx(
              classes.root,
              sidebarOptions && "has-sidebar",
              (onRowClick || onRowDoubleClick) && "row-clickable",
            )}
            ref={tableRef}
            data={!withGrouping || hasPagination ? data : filteredData}
            onDataStateChange={handleDataStateChange}
            sortable={sortable}
            resizable={resizable}
            expandField="expanded"
            columnMenu={null}
            onExpandChange={onExpandChange}
            total={total}
            sort={sort}
            rowRender={(row, props) => {
              const newRow = { ...row };
              newRow.props = {
                ...newRow.props,
                // We must use ts-ignore because props object is type HTMLTableRowElement, which contains pure onclick instead of onClick prop.
                // @ts-ignore
                onClick: (e) => handleRowClick(e, props.dataItem),
                onMouseDown: handleRowMouseDown,
                onMouseUp: handleRowMouseUp,
              };
              return newRow;
            }}
            {...others}
            {...dataState}
            onRowClick={undefined}
          >
            {visibleColumns}
          </Grid>
        )}
      </Box>
    </>
  );
}

export default KendoTable;
