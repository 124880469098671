import { useCallback, useEffect, useRef, useState } from "react";

import * as Sentry from "@sentry/react";

import { AxiosError } from "axios";

import { backendEndpoints } from "config/routes";

import { API } from "services/api";

import { Evaluation } from "components/evaluations/graphql/evaluations-graphql";

type Props = {
  id: string;
  headers: { [key: string]: string | null };
};

export const useEvaluation = ({ headers, id }: Props) => {
  const [data, setData] = useState<Evaluation>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const veryFirstLoad = useRef(true);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data: response } = await API.get<Evaluation>(backendEndpoints.getEvaluation.replace(":id", id || ""), {
        headers,
      });
      setData(response);
    } catch (error) {
      const err = error as AxiosError;
      Sentry.captureException(error);
      setError(err.response?.data.message);
    } finally {
      setLoading(false);
    }
  }, [headers, id]);

  useEffect(() => {
    fetchData();
    veryFirstLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
    veryFirstLoad,
  };
};
