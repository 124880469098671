import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Box, Typography } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox, DragIndicator } from "@mui/icons-material";
import { TableCellProps } from "@mui/material/TableCell";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";

import { ListSelectionState } from "hooks/useListSelection/index";
import { Sort } from "hooks/useSort/index";
import { LoadingPlaceholder, Button } from "components";

import ResponsiveTableWrapper from "./responsive-table-wrapper/responsive-table-wrapper";
import EmptyPlaceholder from "./empty-placeholder/empty-placeholder";
import ColumnHeader from "./column-header";
import useStyles from "./data-table.styles";

type Data = Record<string, any>;

export type Direction = "asc" | "desc";

export type OrderBy = {
  columnName?: string;
  direction?: Direction;
};

export type ReadMoreButtonConfig = {
  field: string;
  characters: number;
};

export type ColumnConfig<D> = {
  key: string;
  title: string | React.ReactNode;
  component?: TableCellProps["component"];
  width?: string | number;
  className?: string | ((item: D) => string);
  render: (item: D) => React.ReactNode;
  skip?: boolean;
  trim?: boolean;
  maxWidth?: number;
  sortable?: boolean;
  setWhiteSpaceNormal?: boolean;
  hide?: boolean;
  readMoreButton?: ReadMoreButtonConfig;
};

type Props<D extends Data> = {
  loading?: boolean;
  data: D[];
  columns: ColumnConfig<D>[];
  listSelection?: ListSelectionState<D>;
  rowIdKey?: keyof D;
  emptyText: string;
  sort?: Sort<D>;
  draggable?: boolean;
  showDisabledDrag?: boolean;
  dragDisabled?: boolean;
  dragColumn?: Omit<ColumnConfig<D>, "render">;
  onDragEnd?: (x: DropResult, y?: ResponderProvided) => void;
};

const DataTable = <T extends Data>({
  data: initData,
  columns,
  listSelection,
  loading,
  rowIdKey = "id",
  emptyText,
  sort,
  draggable = false,
  dragDisabled,
  dragColumn,
  showDisabledDrag,
  onDragEnd,
}: Props<T>) => {
  const classes = useStyles();

  const [data, setData] = useState<T[]>(initData);
  const pointDisabledDrag = (!!sort && sort.sortState.field !== "order") || showDisabledDrag;

  useEffect(() => {
    setData(initData);
  }, [initData]);

  if (loading) {
    return (
      <Box className={classes.loaderContainer}>
        <LoadingPlaceholder />
      </Box>
    );
  }

  const getColumnContent = (column: ColumnConfig<T>, d: T) => {
    if (column.readMoreButton) {
      const { field, characters } = column.readMoreButton;
      const fieldToUpdate = d[field];

      return {
        ...d,
        [field]:
          d.expanded || characters >= fieldToUpdate.length
            ? fieldToUpdate
            : fieldToUpdate.substr(0, characters) + "...",
      };
    } else {
      return d;
    }
  };

  const getReadMorebutton = (column: ColumnConfig<T>, d: T) => {
    if (column.readMoreButton && column.key === column.readMoreButton.field) {
      const { field, characters } = column.readMoreButton;
      const fieldToUpdateLength = d[field].length;

      if (fieldToUpdateLength > characters) {
        return (
          <Button
            classes={{ root: classes.readMoreButton }}
            onClick={() => {
              const newData = [...data];
              const index = data.findIndex((el) => el === d);

              newData[index] = {
                ...d,
                expanded: !Boolean(d.expanded),
              };
              setData(newData);
            }}
            label={Boolean(d.expanded) ? "Collapse" : "Read More"}
          />
        );
      }
    }
    return null;
  };

  const renderedColumns = columns.filter((c) => !c.skip);

  return (
    <div className={classes.table}>
      <ResponsiveTableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {listSelection && (
                <TableCell variant="head" padding="checkbox">
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    checked={listSelection.actions.isAllSelected()}
                    onChange={
                      listSelection.actions.isAllSelected()
                        ? listSelection.actions.unselectAll
                        : listSelection.actions.selectAll
                    }
                  />
                </TableCell>
              )}

              {draggable ? (
                dragColumn ? (
                  <ColumnHeader
                    key={dragColumn.key}
                    column={dragColumn}
                    sortingState={{
                      isSortedBy: sort?.sortState.field === dragColumn.key,
                      direction: sort?.sortState.direction === "ascending" ? "asc" : "desc",
                    }}
                    onSortChange={() => sort?.toggleField(dragColumn.key)}
                  />
                ) : (
                  <TableCell width="1%"></TableCell>
                )
              ) : null}
              {renderedColumns.map((column) =>
                column.hide ? null : (
                  <ColumnHeader
                    key={column.key}
                    column={column}
                    sortingState={{
                      isSortedBy: sort?.sortState.field === column.key,
                      direction: sort?.sortState.direction === "ascending" ? "asc" : "desc",
                    }}
                    onSortChange={() => sort?.toggleField(column.key)}
                  />
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody {...{ component: draggable ? DroppableComponent(onDragEnd) : undefined }}>
            {data.map((d, index) => (
              <TableRow
                hover
                key={d[rowIdKey]}
                selected={listSelection ? listSelection.selected.has(d.id) : undefined}
                {...{
                  component: draggable ? DraggableComponent(d.id, index, dragDisabled || pointDisabledDrag) : undefined,
                }}
              >
                {listSelection && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      checked={listSelection.selected.has(d.id)}
                      onChange={() => listSelection.actions.toggleOne(d)}
                    />
                  </TableCell>
                )}

                {draggable ? (
                  <TableCell
                    width="1%"
                    className={clsx(pointDisabledDrag && classes.dragBlocked)}
                    title={pointDisabledDrag ? "Reorder disabled" : ""}
                  >
                    <DragIndicator />
                  </TableCell>
                ) : null}

                {renderedColumns.map((column) => {
                  return column.hide ? null : (
                    <TableCell
                      key={column.key}
                      variant="body"
                      className={typeof column.className === "function" ? column.className(d) : column.className}
                      component={column.component}
                      style={{ width: column.width }}
                    >
                      {column.trim && !column.readMoreButton ? (
                        <>
                          <Typography
                            className={clsx(classes.ellipsis, classes.customText)}
                            component="span"
                            style={{ maxWidth: column.maxWidth }}
                          >
                            {column.render(d)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className={classes.defaultText} component="span">
                            {column.render(getColumnContent(column, d))}
                          </Typography>
                          {getReadMorebutton(column, d)}
                        </>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {data.length === 0 && <EmptyPlaceholder text={emptyText} />}
      </ResponsiveTableWrapper>
    </div>
  );
};

export default DataTable;

const DraggableComponent = (id: string, index: number, dragDisabled: boolean = false) => (props: any) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={dragDisabled}>
      {(provided) => (
        <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} {...props}>
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (onDragEnd: Props<any>["onDragEnd"]) => (props: any) => {
  return (
    onDragEnd && (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="DroppableID" direction="vertical">
          {(provided, snapshot) => {
            return (
              <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    )
  );
};
