import * as React from "react";
import { useParams } from "react-router";

import { EvaluationSurveyParams } from "config/routes";

import { useTenantContext } from "services/tenant/context/tenant.context";

import { Assessment } from "modules/employees/graphql/details/employee-details-assessments-graphql";

import { useAssessment } from "../hooks/useAssessment";
import { useQueryParams } from "hooks";

type AssessmentSurveyContextType = {
  data?: Assessment;
  error?: string;
  loading?: boolean;
  refetch: () => Promise<void>;
  headers: { TOKEN: string | null; tenantId: string };
  veryFirstLoad: React.MutableRefObject<boolean>;
};

const AssessmentSurveyContext = React.createContext<AssessmentSurveyContextType>({} as AssessmentSurveyContextType);

const AssessmentSurveyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { id } = useParams<EvaluationSurveyParams>();
  const { queryParams } = useQueryParams();
  const token = queryParams.get("token");
  const tenant = useTenantContext();

  const headers = {
    TOKEN: token,
    tenantId: tenant.data,
  };

  const { data, loading, error, refetch, veryFirstLoad } = useAssessment({ headers, id });

  return (
    <>
      <AssessmentSurveyContext.Provider
        {...{
          children,
          value: {
            data,
            loading,
            error,
            refetch,
            headers,
            veryFirstLoad,
          },
        }}
      />
    </>
  );
};

const useAssessmentSurveyContext = () => React.useContext(AssessmentSurveyContext);

export { AssessmentSurveyProvider, useAssessmentSurveyContext };
