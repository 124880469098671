import * as React from "react";

import Grid, { GridProps } from "@mui/material/Grid";

import useStyles from "./auto-grid.styles";

const getFlatChildren = (children: React.ReactNode, skipEmpty: boolean): React.ReactNode => {
  if (React.Children.count(children) === 0) {
    return [];
  }

  const childrenArray = Array.isArray(children) ? [...children] : [children];

  const flatChildren = childrenArray.reduce((acc: Array<any>, child) => {
    if (acc && acc.length === 0) {
      return [child];
    }

    if (React.isValidElement(child) && child.type === React.Fragment) {
      return [...acc, ...React.Children.toArray((child as any).props.children as React.ReactNode)];
    }

    if (!child && skipEmpty) {
      return acc;
    }

    return [...acc, child];
  }, []);

  return flatChildren;
};

type Props = {
  className?: string;
  spacing?: GridProps["spacing"];
  justify?: GridProps["justifyContent"];
  alignItems?: GridProps["alignItems"];
  wrap?: GridProps["wrap"];
  zeroMinWidth?: GridProps["zeroMinWidth"];
  showEmpty?: boolean;
};

const AutoGrid: React.FC<Props> = ({
  children,
  className,
  spacing = 1,
  justify = "flex-start",
  alignItems = "center",
  wrap,
  zeroMinWidth,
  showEmpty = false,
}) => {
  const classes = useStyles();
  const childrenFlat = getFlatChildren(children, !showEmpty);

  return (
    <Grid
      container
      spacing={spacing}
      justifyContent={justify}
      alignItems={alignItems}
      wrap={wrap}
      className={className}
    >
      {React.Children.map(childrenFlat, (child) => {
        const style = React.isValidElement(child) ? child?.props?.style : {};
        return (
          <Grid item xs="auto" zeroMinWidth={zeroMinWidth} className={classes.gridItem} style={style}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AutoGrid;
