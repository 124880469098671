import makeStyles from "@mui/styles/makeStyles";

interface Props {
  optionsCount: number;
}

export default makeStyles<CustomTheme, Props>(({ breakpoints, spacing, typography, palette }) => ({
  // type TEXT
  label: {
    display: "block",
    marginBottom: spacing(2),
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.black,
    fontWeight: typography.fontWeightBold,
  },
  // type RADIO
  controlLabelRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: spacing(0, 0, 1),
    "&:last-child": {
      marginBottom: 0,
    },
    [breakpoints.up(700)]: {
      marginBottom: 0,
      width: ({ optionsCount }) => (optionsCount <= 2 ? "100%" : optionsCount === 3 ? "25%" : `${100 / optionsCount}%`),
      flexDirection: ({ optionsCount }) => (optionsCount <= 2 ? "row" : "column-reverse"),
      justifyContent: ({ optionsCount }) => (optionsCount <= 2 ? "flex-start" : "space-between"),
    },
  },
  evaluationLabel: {
    textAlign: "left",
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    padding: spacing(0, 1),
    margin: 0,
    [breakpoints.up(700)]: {
      textAlign: "center",
    },
  },
  evaluationFieldLabel: {
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(18),
    color: palette.text.black,
    fontWeight: typography.fontWeightBold,
  },
  evaluationRadioGroup: {
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "stretch",
    marginTop: spacing(3),
    [breakpoints.up(700)]: {
      flexDirection: ({ optionsCount }) => (optionsCount <= 2 ? "column" : "row"),
    },
  },
  radioInput: {
    marginLeft: ({ optionsCount }) => (optionsCount <= 2 ? spacing(1) : 0),
    marginRight: ({ optionsCount }) => (optionsCount <= 2 ? "unset" : 0),
    color: palette.text.neutral,
    [breakpoints.up(700)]: {
      marginTop: ({ optionsCount }) => (optionsCount <= 2 ? 0 : spacing(1)),
    },
  },

  // type empty
  headerText: {
    fontWeight: 700,
  },
}));
