import * as React from "react";

import useStyles from "./section.styles";

type Props = {
  margin?: "none" | "normal" | "dense" | "big";
};

const Section: React.FC<Props> = ({ children, margin = "none" }) => {
  const classes = useStyles();

  return <div className={classes[margin]}>{children}</div>;
};

export default Section;
