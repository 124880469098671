import { CustomHeader, Interval, Unit } from "react-calendar-timeline";

import useStyle from "./chart-date-header.style";

type Props = {
  unit: Unit;
  format: string;
};

const ChartDateHeader: React.FC<Props> = ({ unit, format }) => {
  const classes = useStyle();
  return (
    <CustomHeader height={28} unit={unit}>
      {(props) => {
        return (
          <div {...props?.getRootProps()}>
            {props?.headerContext.intervals.map((interval: Interval) => {
              return (
                <div
                  {...props?.getIntervalProps({ interval })}
                  onClick={() => {
                    props?.showPeriod(interval.startTime, interval.endTime);
                  }}
                  className={classes.customTimeHeader}
                >
                  <div>{interval.startTime.format(format)}</div>
                </div>
              );
            })}
          </div>
        );
      }}
    </CustomHeader>
  );
};

export default ChartDateHeader;
