import makeStyles from "@mui/styles/makeStyles";

type Params = {
  width: string;
};

export default makeStyles<CustomTheme, Params>(({ palette, typography, spacing }) => {
  return {
    root: {
      width: ({ width }) => width,
      margin: "0 auto",
    },
    section: {
      borderRadius: 5,
      marginTop: spacing(1.5),
      background: palette.background.paper,
      padding: spacing(4, 7.5),
      boxShadow: "0px 4px 15px rgba(33, 33, 33, 0.08)",
    },
    title: {
      color: palette.text.blackPearl,
      fontSize: typography.pxToRem(14),
      fontWeight: typography.fontWeightBold,
      marginBottom: spacing(2),
    },
    subtitle: {
      color: palette.text.grey4,
      fontSize: typography.pxToRem(12),
      margin: spacing(1, 0, 2),
    },
    buttons: {
      marginTop: spacing(4),
    },
  };
});
