import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing }) => ({
  indicatorError: {
    color: palette.error.main,
    marginRight: spacing(1),
  },
  indicatorSuccess: {
    color: palette.secondary.main,
    marginRight: spacing(1),
  },
  root: {
    margin: spacing(1, 0),
  },
  inputContainer: {
    position: "relative",

    "& .DraftEditor-root": {
      height: "unset",

      "& .public-DraftStyleDefault-block": {
        margin: 0,
      },
    },

    "& .rdw-link-modal": {
      height: 175,
    },

    "& .rdw-link-modal-target-option": {
      display: "none",
    },
  },

  // label color
  primary: {
    color: palette.text.primary,
    fontWeight: 500,
  },
  default: {},

  label: {
    marginBottom: spacing(1),
  },

  indicator: {
    position: "absolute",
    marginTop: 25,
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
  },

  input: {
    boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.02)",
    padding: spacing(1.5),
    outline: "none",
    minHeight: 75,
    borderRadius: shape.borderRadius,

    "& a": {
      textDecoration: "underline",
    },
  },
  adormentPadding: {
    paddingRight: 50,
  },
}));
