import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing, typography, }) => ({
  table: {
    boxShadow: "none",
    padding: spacing(2, 4, 3, 4),
    borderRadius: `0px 0px ${shape.borderRadius}px ${shape.borderRadius}px`,

    "& .MuiTableCell-root": {
      padding: spacing(2.25, 2),
    },
    "& .MuiTableCell-head": {
      border: 0,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
    },

    "& tr": {
      "&:last-child": {
        "& td, & th": {
          border: 0,
        },
      },
      "& td, & th": {
        borderBottom: "1px solid #F1F2F3",
        fontSize: typography.pxToRem(12),
        lineHeight: typography.pxToRem(16),
      },
      "& th": {
        width: 100,
        wordBreak: "break-word",
      },
    },
  },

  answerLabel: {
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16),
    color: palette.text.acai,
    padding: `0 !important`,
  },

  radioButton: {
    width: 20,
    height: 20,
    border: `2px solid ${palette.text.n5}`,
    "&::before": {
      width: 10,
      height: 10,
    },
  },
}));
