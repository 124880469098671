import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";

import { Slider, Typography, CircularProgress, Box, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import * as Sentry from "@sentry/react";

import { backendEndpoints } from "config/routes";

import { AutoGrid, Button, FormError, Modal } from "components";

import getCroppedImg from "utils/cropper";
import { uploadFile } from "utils/file";

import useStyle from "./crop-modal.style";

type Props = {
  open: boolean;
  title: string;
  ratio: number;
  rawImage?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCrop: (url: string) => void;
};

type Coordinates = {
  x: number;
  y: number;
};

const CropModal: React.FC<Props> = ({ rawImage, title, ratio, open, setOpen, onCrop }) => {
  const classes = useStyle();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [crop, setCrop] = useState<Coordinates>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onCropComplete = useCallback(async (_, cropArea) => {
    setCroppedAreaPixels(cropArea);
  }, []);

  const handleSubmit = async () => {
    if (rawImage) {
      try {
        const { blob } = await getCroppedImg(rawImage, croppedAreaPixels);
        setLoading(true);
        const file = await uploadFile(backendEndpoints.uploadFile, blob);

        if (file) {
          onCrop(file.url);
          handleClose();
        } else {
          setError("Sorry, something went wrong");
        }
      } catch (ex) {
        setError(ex as any);
        Sentry.captureException(ex);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal maxWidth={900} onClose={handleClose} open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent className={classes.croppContainer}>
        {rawImage ? (
          <Cropper
            image={rawImage}
            crop={crop}
            zoom={zoom}
            aspect={ratio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
          />
        ) : (
          <Box className={classes.loader}>
            <CircularProgress color="secondary" />
          </Box>
        )}
      </DialogContent>
      {rawImage && (
        <DialogContent className={classes.zoomContainer}>
          <Typography color="primary" gutterBottom>
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(_, zoom) => setZoom(zoom as number)}
            classes={{
              root: classes.slider,
              thumb: classes.thumb,
              track: classes.track,
              rail: classes.rail,
            }}
          />
        </DialogContent>
      )}
      {error && (
        <div className={classes.formError}>
          <FormError>{error}</FormError>
        </div>
      )}
      <DialogActions className={classes.dialogActions}>
        <AutoGrid spacing={2} justify="center">
          <Button variant="outlined" onClick={handleClose} label="Cancel" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!rawImage || loading}
            label="Crop"
          />
        </AutoGrid>
      </DialogActions>
    </Modal>
  );
};

export default React.memo(CropModal);
