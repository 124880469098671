import makeStyles from '@mui/styles/makeStyles';

export default makeStyles<CustomTheme>(({ typography, palette, spacing }) => ({
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "stretch",
    borderRadius: 5,
  },

  root: {
    width: "100%",
    padding: 0,
    backgroundColor: palette.background.default,
    textDecoration: "none",
  },

  input: {
    padding: 13,
    fontSize: typography.pxToRem(14),
    border: `1px solid ${palette.hint.main}`,
    paddingRight: 75,

    "&::-ms-clear": {
      display: "none",
    },
  },

  normal: {
    padding: spacing(2, 1),
  },

  outlined: {
    border: `1px solid ${palette.hint.main}`,
    padding: 0,
  },

  inputAdornment: {
    color: palette.hint.main,
  },

  inputAdornmentSearch: {
    marginLeft: spacing(3),
  },

  inputAdornmentClear: {
    marginRight: spacing(2),
  },

  icon: {
    fontSize: "1rem",
  },

  iconButton: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: 75,
    backgroundColor: `${palette.primary.main} !important`,
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    color: palette.primary.contrastText,
    fontSize: typography.pxToRem(14),

    "&:hover": {
      backgroundColor: palette.primary.dark,
    },
  },

  buttonDisabled: {
    opacity: 0.6,
  },

  hidden: {
    opacity: 0,
    visibility: "hidden",
  },
}));
