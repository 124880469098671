import React from "react";

import { reducer } from "./sensitiveInformationsReducer";

export type SensitiveInformationType = {
  hideSensitiveData: boolean;
  employees: {
    firstName: boolean;
    middleName: boolean;
    lastName: boolean;
    currentEmploymentStatus: boolean;
    phone: boolean;
    location: boolean;
    office: boolean;
    onboardingBuddy: boolean;
    grade: boolean;
    specs: boolean;
    skills: boolean;
  };
  contracts: {
    comments: boolean;
    company: boolean;
    cost: boolean;
    employee: boolean;
    endDate: boolean;
    holidaysB2B: boolean;
    noticePeriod: boolean;
    startDate: boolean;
    type: boolean;
  };
  projects: {
    endDate: boolean;
    projectManagement: boolean;
    name: boolean;
    startDate: boolean;
  };
  projectEmployeeAssignments: {
    availability: boolean;
    billableStatus: boolean;
    employee: boolean;
    project: boolean;
    status: boolean;
  };
};

type SensitiveInformationsContextType = {
  sensitiveInformations: {
    state: SensitiveInformationType;
    dispatch: React.Dispatch<{
      type: string;
      payload: {
        field: string;
        value: boolean;
      };
    }>;
  };
};

const localStorageData = localStorage.getItem("sensitiveInformationsConfig");
let initialState: SensitiveInformationType;

if (!localStorageData) {
  initialState = {
    hideSensitiveData: false,
    employees: {
      firstName: false,
      middleName: false,
      lastName: false,
      currentEmploymentStatus: false,
      phone: false,
      location: false,
      office: false,
      onboardingBuddy: false,
      grade: false,
      specs: false,
      skills: false,
    },
    contracts: {
      comments: false,
      company: false,
      cost: false,
      employee: false,
      endDate: false,
      holidaysB2B: false,
      noticePeriod: false,
      startDate: false,
      type: false,
    },
    projects: {
      endDate: false,
      projectManagement: false,
      name: false,
      startDate: false,
    },
    projectEmployeeAssignments: {
      availability: false,
      billableStatus: false,
      employee: false,
      project: false,
      status: false,
    },
  };
  localStorage.setItem("sensitiveInformationsConfig", JSON.stringify(initialState));
} else {
  initialState = JSON.parse(localStorageData);
}

const SensitiveInformationsContext = React.createContext<SensitiveInformationsContextType>(
  {} as SensitiveInformationsContextType,
);

const SensitiveInformationsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SensitiveInformationsContext.Provider {...{ children, value: { sensitiveInformations: { state, dispatch } } }} />
  );
};

const useSensitiveInformationsContext = () => React.useContext(SensitiveInformationsContext);

export { SensitiveInformationsProvider, useSensitiveInformationsContext };
