import { ChangeEvent, FC, useState } from "react";

import { GridColumnMenuFilterUIProps } from "@progress/kendo-react-grid";

import { isDate } from "../../../utils/kendo-table.utils";
import { dateToString } from "utils/dateFormat";

import { TableData } from "../../../types/kendo-table.types";
import {
  CHECK_ALL_VALUE,
  handleSetOperatorFn,
  isAllVisibleAreSelected,
  prepareItemsList,
} from "./custom-checkbox-filter-utils";

type Props = {
  firstFilterProps: GridColumnMenuFilterUIProps["firstFilterProps"];
  data: TableData[];
};

const CustomColumnMenuCheckboxFilter: FC<Props> = ({ data, firstFilterProps }) => {
  const allItems = prepareItemsList(data, firstFilterProps.field ?? "");

  const [itemsVisible, setItemsVisible] = useState<string[]>(allItems);
  const [itemsSelected, setItemsSelected] = useState<string[]>(
    !firstFilterProps.value
      ? []
      : Array.isArray(firstFilterProps.value)
      ? firstFilterProps.value
      : [firstFilterProps.value],
  );

  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(allItems.length === itemsSelected.length);
  const [searchValue, setSearchValue] = useState("");

  const onChange = (event: any) => {
    const newValue = event.target.value;
    const isChecked = event.target.checked;

    const newItemsSelected = isChecked ? [...itemsSelected, newValue] : itemsSelected.filter((v) => v !== newValue);
    setItemsSelected(newItemsSelected);

    firstFilterProps.onChange({
      value: isChecked ? [...itemsSelected, newValue] : itemsSelected.filter((v) => v !== newValue),
      syntheticEvent: event.syntheticEvent,
      operator: (fieldValue: string | number | undefined | null, checkedValues: string[]) =>
        handleSetOperatorFn(fieldValue, checkedValues, itemsVisible),
    });

    const isAllChecked = isAllVisibleAreSelected(itemsVisible, newItemsSelected);
    setIsCheckedAll(isAllChecked);
  };

  const checkAll = (event: any) => {
    const isChecked = event.target.checked;
    if (itemsVisible.length === 0 && isChecked) {
      return; // don't check the checkbox if there are no visible items
    } else {
      setIsCheckedAll(isChecked);
      setItemsSelected(isChecked ? itemsVisible : []);

      firstFilterProps.onChange({
        value: isChecked ? itemsVisible : [],
        syntheticEvent: event.syntheticEvent,
        operator: (fieldValue: string | number | undefined | null, checkedValues: string[]) =>
          handleSetOperatorFn(fieldValue, checkedValues, itemsVisible),
      });
    }
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearchValue(newSearchValue);

    const newItemsVisible = allItems.filter((i) => i.toLowerCase().includes(newSearchValue.toLowerCase()));
    setItemsVisible(newItemsVisible);

    const newIsCheckedAll = isAllVisibleAreSelected(newItemsVisible, itemsSelected);
    setIsCheckedAll(newIsCheckedAll);
  };

  const onSearchClear = () => {
    setSearchValue("");
    setItemsVisible(allItems);

    const newIsCheckedAll = isAllVisibleAreSelected(allItems, itemsSelected);
    setIsCheckedAll(newIsCheckedAll);
  };

  return (
    <>
      {/* html structure and classes copied from the original Kendo component GridColumnMenuCheckboxFilter */}
      <div className="k-searchbox">
        <span className="k-input-prefix">
          <span className="k-icon k-i-search" />
        </span>
        <input type="text" placeholder="Search" className="k-input" value={searchValue} onChange={onSearchChange} />
        <span className="k-input-suffix">
          <button
            type="button"
            aria-label="clear input"
            className="k-button k-icon-button k-button-clear"
            onClick={onSearchClear}
          >
            <span className="k-icon k-i-close" />
          </button>
        </span>
      </div>

      <ul className="k-reset k-multicheck-wrap">
        <li className="k-item k-check-all-wrap">
          <span>
            <input
              id={CHECK_ALL_VALUE}
              name={CHECK_ALL_VALUE}
              className="k-checkbox k-checkbox-md k-rounded-md"
              type="checkbox"
              value={CHECK_ALL_VALUE}
              checked={isCheckedAll}
              onChange={checkAll}
            />
            <label className="k-checkbox-label" htmlFor={CHECK_ALL_VALUE}>
              &nbsp;Check all
            </label>
          </span>
        </li>

        {itemsVisible.map((item) => {
          const isChecked = itemsSelected.includes(item);
          return (
            <li className="k-item" key={item}>
              <span>
                <input
                  id={item}
                  name={item}
                  className="k-checkbox k-checkbox-md k-rounded-md"
                  type="checkbox"
                  value={item}
                  checked={isChecked}
                  onChange={onChange}
                />
                <label className="k-checkbox-label" htmlFor={item}>
                  &nbsp;{isDate(item) ? dateToString(item) : item}
                </label>
              </span>
            </li>
          );
        })}
      </ul>
      <div className="k-filter-selected-items">{itemsSelected.length} selected items</div>
    </>
  );
};

export default CustomColumnMenuCheckboxFilter;
