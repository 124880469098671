import * as Sentry from "@sentry/react";

import { backendEndpoints } from "config/routes";

import { API } from "services/api";
import {
  ApiClient,
  CheckAccessOptions,
  LoginWithEmailAndPasswordParams,
  PermissionsAdvArg,
  PermissionsArg,
} from "services/auth/auth-provider";
import { IRestAuthEndpoints } from "services/auth/auth-provider/clients/ApiClient";

export const endpointsMapping: IRestAuthEndpoints = {
  loginWithEmailAndPassword: async ({ email, password, tenantId }) => {
    try {
      const response = await API.post(backendEndpoints.login, {
        email,
        password,
        tenantId,
      });
      const { accessToken, refreshToken } = response.data;

      return {
        data: { accessToken, refreshToken },
      };
    } catch (ex) {
      throw { data: null, error: ex };
    }
  },
  identity: async (token) => {
    try {
      const response = await API.get(`/auth/identity`, {
        headers: {
          Authorization: `Bearer ${token}`,
          // (BK) => this part is necessary for IE11 compatibility - otherwise response is taken from cache
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      });

      API.defaults.headers.Authorization = `Bearer ${token}`;

      return { data: response.data };
    } catch (ex) {
      Sentry.captureException(ex);
      throw { data: null, error: ex };
    }
  },
  refreshToken: async (refreshToken, tenantId) => {
    try {
      delete API.defaults.headers.Authorization;

      const response = await API.post(backendEndpoints.refreshToken, {
        refreshToken,
        tenantId,
      });
      API.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;

      return { data: response.data };
    } catch (ex) {
      Sentry.captureException(ex);
      return { data: null };
    }
  },
  sendVerificationEmail: async ({ email, tenantId, forgotPassword }) => {
    try {
      const response = await API.post(backendEndpoints.sendVerificationEmail, {
        email,
        tenantId,
        forgotPassword,
      });
      return { data: { status: response.status === 201 } };
    } catch (ex) {
      Sentry.captureException(ex);
      throw { data: { status: false }, error: ex };
    }
  },

  resetPassword: async ({ token, tenantId, newPassword }) => {
    try {
      const response = await API.post(backendEndpoints.resetPassword, {
        token,
        tenantId,
        newPassword,
      });
      return { data: { status: response.status === 201 } };
    } catch (ex) {
      Sentry.captureException(ex);
      throw { data: { status: false }, error: ex };
    }
  },
};

export class CustomApiClient extends ApiClient {
  instance: number;
  shouldAutoRefresh: boolean;

  constructor(mappings: IRestAuthEndpoints, storage: Storage) {
    super(mappings, storage);
    const couldAutoRefresh = !!storage.getItem("accessToken");
    this.instance = couldAutoRefresh ? Date.now() : 0;
    this.shouldAutoRefresh = couldAutoRefresh;
    storage.setItem("instance", this.instance.toString());

    window.addEventListener("beforeunload", () => {
      this.shouldAutoRefresh = false;
      storage.setItem("instance", "0");
    });

    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === "instance") {
        // (BK) => this is a safety switch since this type of event is wrongly supported by IE11
        if (+newValue! >= this.instance) {
          this.shouldAutoRefresh = false;
        } else {
          this.shouldAutoRefresh = true;
          storage.setItem("instance", this.instance.toString());
        }
      }
      if (key === "accessToken") {
        if (newValue) {
          API.defaults.headers.Authorization = `Bearer ${newValue}`;
        } else {
          this.logout();
        }
      }
    });
  }

  checkAccess({ permissions }: CheckAccessOptions) {
    if (!this.isAuthenticated || !this.user) {
      return false;
    }
    if (this.user.superUser) {
      return true;
    }
    const userPermissions = this.user.permissions;
    if (Array.isArray(permissions[0])) {
      return (permissions as PermissionsAdvArg).some((permissionsArgs) =>
        permissionsArgs.every((permission) => userPermissions?.includes(permission)),
      );
    } else {
      return (permissions as PermissionsArg).every((permission) => userPermissions?.includes(permission));
    }
  }

  shouldRefreshTokens() {
    return this.shouldAutoRefresh && super.shouldRefreshTokens();
  }

  async logout() {
    this.shouldAutoRefresh = false;
    this.instance = 0;
    return super.logout();
  }

  async loginWithEmailAndPassword(params: LoginWithEmailAndPasswordParams) {
    await super.loginWithEmailAndPassword(params);
    this.instance = Date.now();
    this.shouldAutoRefresh = true;
    this.storage.setItem("instance", this.instance.toString());
  }
}

const authClient = new CustomApiClient(endpointsMapping, window.localStorage);

export default authClient;
