import React, { useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { FieldRenderProps } from "react-final-form";

import { CheckCircle, Error } from "@mui/icons-material";
import { InputAdornment, InputLabel, FormHelperText } from "@mui/material";

import clsx from "clsx";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";

import "modules/../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TOOLBAR_OPTIONS } from "./constants";
import useStyles from "./text-html-control.styles";

type Props = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  validationIndicator?: boolean;
  labelColor?: "primary" | "default";
  placeholder?: string;
  rows?: number;
  showToolbar?: boolean;
  customToolbarOptions?: Record<string, any>;
  forcedError?: string;
};

const TextHtmlControl: React.FC<Props> = ({
  label,
  input: { value, onChange },
  meta: { submitError, dirtySinceLastSubmit, error: metaError },
  validationIndicator = false,
  labelColor = "default",
  placeholder = "",
  showToolbar = true,
  rows = 1,
  customToolbarOptions,
  forcedError,
}) => {
  const classes = useStyles();

  const error = metaError || forcedError;
  const touched = useRef<boolean>(false);
  const isDirty = Boolean(touched.current);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === "a") {
            return createEntity("LINK", "MUTABLE", { url: node.href });
          }
        },
      })(value),
    ),
  );

  let endAdornment;
  if (validationIndicator && isDirty) {
    endAdornment = (
      <InputAdornment position="end" className={classes.indicator}>
        {hasErrors ? (
          <Error className={classes.indicatorError} />
        ) : (
          <CheckCircle className={classes.indicatorSuccess} />
        )}
      </InputAdornment>
    );
  }

  const handleChange = (state?: any) => {
    touched.current = true;
    setEditorState(state);

    onChange(
      convertToHTML({
        entityToHTML: (entity, originalText) => {
          if (entity.type === "LINK") {
            return <a href={entity.data.url}>{originalText}</a>;
          }
          return originalText;
        },
      })(state.getCurrentContent()).replace(/<br\s*\/?>/gi, ""),
    );
  };

  return (
    <div className={classes.root}>
      <InputLabel className={clsx(classes.label, classes[labelColor])}>{label}</InputLabel>

      <div className={classes.inputContainer}>
        <Editor
          stripPastedStyles
          toolbarHidden={!showToolbar}
          placeholder={placeholder}
          editorState={editorState}
          editorClassName={clsx(classes.input, endAdornment && classes.adormentPadding)}
          editorStyle={{ height: 75 + rows * 30 }}
          toolbar={{ ...TOOLBAR_OPTIONS, ...customToolbarOptions }}
          onEditorStateChange={handleChange}
        />
        {endAdornment}
      </div>

      {showError && <FormHelperText error>{error || submitError}</FormHelperText>}
    </div>
  );
};

export default TextHtmlControl;
