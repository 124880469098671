import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette }) => {
  function createColorStyle(colorName: string) {
    return {
      "&$contained": {
        backgroundColor: (palette as any)[colorName].main,
        color: (palette as any)[colorName].contrastText,

        "&:hover": {
          backgroundColor: (palette as any)[colorName].dark,
        },
        "&:disabled": {
          backgroundColor: palette.action.disabledBackground,
          color: palette.text.contrast,
        },
      },
      "&$outlined": {
        backgroundColor: "transparent",
        color: (palette as any)[colorName].contrastText,
        borderColor: (palette as any)[colorName].main,
        "&$disabled": {
          color: palette.action.disabled,
          borderColor: palette.action.disabled,
        },

        "&:hover": {
          backgroundColor: (palette as any)[colorName].main,
          color: (palette as any)[colorName].light,
        },
      },
      "&$text": {
        backgroundColor: "transparent",
        color: (palette as any)[colorName].main,
        "&$disabled": {
          color: palette.action.disabled,
        },
      },
    };
  }

  return {
    root: {
      display: "inline-flex",
      alignItems: "center",
    },
    label: {
      color: "inherit",
      fontSize: "inherit",
      display: "inline-flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    disabled: {},

    // button variants
    contained: {},
    outlined: {},
    text: {},

    // button colors
    error: createColorStyle("error"),
    hint: createColorStyle("hint"),
    default: {},
    endIcon: {},
  };
});
