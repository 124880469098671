import { Typography } from "@mui/material";

import sendDirect from "assets/images/svg/send-direct.svg";

import useStyles from "./evaluation-survey-completed-screen.styles";

type Props = {
  type?: "evaluation" | "assessment";
};

const EvaluationSurveyCompletedScreen: React.FC<Props> = ({ type = "evaluation" }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={sendDirect} alt="survey sent" className={classes.icon} />
      <Typography className={classes.textUpper}>Thank you!</Typography>
      <Typography className={classes.textBottom}>Your {type} has been sent</Typography>
    </div>
  );
};

export default EvaluationSurveyCompletedScreen;
