import { useMemo } from "react";

import { EmploymentStatusType } from "modules/employees/graphql/employees.graphql";
import { EmploymentStatusTypeLabel } from "modules/employees/types/employees.types";

import { StatusChip } from "components";
import { ChipSize } from "components/chip/status-chip/status-chip";

type Props = {
  status?: EmploymentStatusType;
  size?: ChipSize;
};

const EmploymentStatusChip: React.FC<Props> = ({ status, size }) => {
  const chipProps = useMemo(
    () =>
      (() => {
        const flatTypes = Object.entries(EmploymentStatusType).map((el) => el);
        const foundType = flatTypes.find((el) => el[0] === status);

        switch (foundType?.[1]) {
          case EmploymentStatusType.ACTIVE:
            return { label: EmploymentStatusTypeLabel.ACTIVE, variant: "success" };
          case EmploymentStatusType.LONG_HOLIDAYS:
            return { label: EmploymentStatusTypeLabel.LONG_HOLIDAYS, variant: "warning" };
          case EmploymentStatusType.MATERNITY_LEAVE:
            return { label: EmploymentStatusTypeLabel.MATERNITY_LEAVE, variant: "warning" };
          case EmploymentStatusType.TERMINATION:
            return { label: EmploymentStatusTypeLabel.TERMINATION, variant: "error" };
          case EmploymentStatusType.ARCHIVED:
            return { label: EmploymentStatusTypeLabel.ARCHIVED, variant: "error" };
          case EmploymentStatusType.STARTING:
            return { label: EmploymentStatusTypeLabel.STARTING, variant: "info" };
          default:
            return { label: "Unknown", variant: "error" };
        }
      })(),
    [status],
  );

  return <StatusChip {...chipProps} size={size} />;
};

export default EmploymentStatusChip;
