import { FC, PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";

import { Typography } from "@mui/material";

import { useEvaluationSurveyContext } from "modules/evaluation/context/evaluation-survey-context";
import {
  EvaluationSurveyFormType,
  EvaluationSurveyQuestionAndAnswerType,
} from "modules/evaluation/types/evaluation-survey-types";

import { Button } from "components";

import { dateToString } from "utils/dateFormat";

import useStyles from "../evaluation-survey-form.style";
import FormElement from "../form-element/evaluation-survey-form-element";

const EvaluationSurveyFormRenderer: FC<
  PropsWithChildren<FormRenderProps<EvaluationSurveyFormType, EvaluationSurveyFormType>>
> = ({ form, handleSubmit: handleSubmitInner, submitting, values }) => {
  const classes = useStyles();
  const { data: evaluation, permission } = useEvaluationSurveyContext();

  return (
    <form onSubmit={handleSubmitInner}>
      <div>
        {values.answers.map((question: EvaluationSurveyQuestionAndAnswerType, index: number) => (
          <div key={`${index}_${question.question}`} className={classes.formGroup}>
            <FormElement
              question={question}
              index={index}
              handleSubmit={handleSubmitInner}
              permission={permission.current}
              isCompleted={values.isCompleted}
            />
          </div>
        ))}
      </div>
      <div className={classes.infoBox}>
        <Typography className={classes.autosaveDate}>
          Form autosaved: {dateToString(evaluation?.updatedAt, "DD.MM.YY HH:mm")}
        </Typography>
        {evaluation?.type && (
          <Button
            type="submit"
            className={classes.submitButton}
            label="Submit"
            onClick={() => {
              form.mutators.setValue("isCompleted", true);
            }}
            disabled={submitting || evaluation?.status === "COMPLETE" || permission.current !== "READ_WRITE"}
          />
        )}
      </div>
    </form>
  );
};

export default EvaluationSurveyFormRenderer;
