import React, { useMemo } from "react";
import { Form, Field } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";

import { AxiosError } from "axios";
import { FORM_ERROR } from "final-form";

import { routes } from "config";

import { useAuth } from "services/auth";
import {
  PASSWORD_VALIDATION_EXPRESSION,
  PASSWORD_VALIDATION_MESSAGE,
} from "services/auth/auth-provider/constants/password.constants";
import { useTenantContext } from "services/tenant/context/tenant.context";

import Content from "../components/content/content";
import GuestLayout from "../components/guest-layout/guest-layout";
import { Button, TextControl, FormError } from "components";

import { useToast } from "hooks";

import { createValidator } from "utils/forms";

import useStyles from "../guest.styles";

type Values = {
  password: string;
  confirmPassword: string;
};

const validator = createValidator({
  password: {
    presence: { allowEmpty: false },
    format: {
      pattern: PASSWORD_VALIDATION_EXPRESSION,
      message: PASSWORD_VALIDATION_MESSAGE,
    },
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
    },
    equality: "password",
  },
});

const SetPasswordView: React.FC = () => {
  const { showToast } = useToast();
  const classes = useStyles();
  const auth = useAuth();
  const tenant = useTenantContext();
  const history = useHistory();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const onSubmit = async (values: Values) => {
    const token = queryParams.get("token");

    if (token) {
      try {
        await auth?.resetPassword({
          newPassword: values.password,
          tenantId: tenant.data,
          token: token,
        });
        showToast(`Your password has been changed`, "success");
        history.push(routes.auth.login);
      } catch (ex) {
        Sentry.captureException(ex);
        const error: AxiosError = (ex as any).error;
        return {
          [FORM_ERROR]: error?.response?.data?.message ?? "Sorry, something went wrong.",
        };
      }
    } else {
      return {
        [FORM_ERROR]: "Token is missing",
      };
    }
  };

  return (
    <GuestLayout>
      <Content title="Set new password">
        <Form onSubmit={onSubmit} validate={validator}>
          {({ handleSubmit, submitting, hasValidationErrors, pristine, submitError }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}

              <Box mb={3}>
                <Field
                  type="password"
                  name="password"
                  label="Password"
                  margin="dense"
                  component={TextControl}
                  passwordVisibilityIndicator
                />
              </Box>
              <Field
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                margin="dense"
                component={TextControl}
                passwordVisibilityIndicator
              />
              <Box textAlign="center">
                <Button
                  type="submit"
                  color="primary"
                  className={classes.submitButton}
                  disabled={submitting || pristine || hasValidationErrors}
                  label="Send"
                />
              </Box>
            </form>
          )}
        </Form>
      </Content>
    </GuestLayout>
  );
};

export default SetPasswordView;
