import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, typography }) => ({
  text: {
    margin: 0,
  },
  button: {
    color: palette.background.green2,
    fontSize: typography.pxToRem(14),
  },
}));
