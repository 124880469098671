import { useMemo } from "react";

import { BillableStatus } from "modules/employees/types/employees.types";

import { StatusChip } from "components";

type Props = {
  isBillable?: boolean | null;
};

const BillableStatusChip: React.FC<Props> = ({ isBillable }) => {
  const chipProps = useMemo(
    () =>
      (() => {
        const statusText = `${isBillable}`;
        switch (statusText) {
          case "true":
            return { label: BillableStatus.BILLABLE, variant: "success" };
          case "false":
            return { label: BillableStatus.NON_BILLABLE, variant: "error" };
          default:
            return { label: "Unknown", variant: "error" };
        }
      })(),
    [isBillable],
  );

  return <StatusChip {...chipProps} />;
};

export default BillableStatusChip;
