import { ExpirableDocumentStatus } from "modules/employees/types/employees.types";

import { StatusChip } from "components";

type Props = {
  status: ExpirableDocumentStatus;
};

const chipProps = (status: ExpirableDocumentStatus) => {
  switch (status) {
    case "Active":
      return { label: "Active", variant: "success" };
    case "Overdue":
      return { label: "Overdue", variant: "error" };
    default:
      return { label: "Unknown", variant: "error" };
  }
};

const ExpirableDocumentStatusChip: React.FC<Props> = ({ status }) => {
  return <StatusChip {...chipProps(status)} />;
};

export default ExpirableDocumentStatusChip;
