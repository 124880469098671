import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing }) => ({
  root: {
    display: "block",
    padding: 0,
    marginBottom: spacing(2),
    minHeight: spacing(11),
    backgroundColor: palette.background.paper,
    borderRadius: shape.borderRadius,
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    padding: spacing(2),
  },
  optionsContainer: {
    minHeight: 42,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  options: {
    display: "flex",
    flexDirection: "row",
  },
  tabs: {},
}));
