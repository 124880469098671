import React, { useRef } from "react";

import { Popover } from "@mui/material";

import { BillableStatus } from "modules/employees/types/employees.types";

import { UserAvatar } from "components";

import { dateToString } from "utils/dateFormat";

import { ProjectAssignmentTimelineItem } from "../types/types";
import useStyle from "./chart-item-popup.style";

type Props = {
  x: number;
  y: number;
  item: ProjectAssignmentTimelineItem;
  isOpen: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const ChartItemPopup: React.FC<Props> = ({ x, y, item, isOpen, onMouseEnter, onMouseLeave }) => {
  const anchorEl = useRef<HTMLElement | null>(null);
  const classes = useStyle({ status: item.billableStatus });
  const fullName = `${item.employee.firstName} ${item.employee.lastName}`;

  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{ top: y, left: x }}
      id={item.id}
      open={isOpen}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
      className={classes.popover}
      classes={{
        paper: classes.popoverContent,
      }}
      PaperProps={{ onMouseEnter, onMouseLeave }}
    >
      <div
        className={classes.wrapper}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={classes.header}>
          <p className={classes.headerDate}>
            {dateToString(item.startDate)} - {dateToString(item.endDate)}
          </p>
        </div>
        <div className={classes.body}>
          <div className={classes.user}>
            <UserAvatar imgUrl={item.employee.photo} fullName={fullName} />
            <div className={classes.userDetails}>
              <p className={classes.userName}>{fullName}</p>
              <p className={classes.userRole}>{item.role?.name}</p>
            </div>
          </div>
          <div className={classes.projectDetails}>
            <div className={classes.colLeft}>
              <p className={classes.projectTitle}>{item.project?.name}</p>
              {item.availability === undefined ? null : <p className={classes.projectFTE}>{item.availability}% FTE</p>}
            </div>
            <div className={classes.colRight}>
              {item.billableStatus === undefined ? null : (
                <div className={classes.badge}>
                  {item.billableStatus ? BillableStatus.BILLABLE : BillableStatus.NON_BILLABLE}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default ChartItemPopup;
