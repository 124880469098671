import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing, typography }) => ({
  container: {
    maxWidth: 870,
    margin: "0 auto",
    width: "100%",
    marginBottom: spacing(7),
  },

  expandedIcon: {
    transform: "rotate(270deg)",
  },

  inputSection: {
    padding: spacing(3, 4),
    borderRadius: `${shape.borderRadius}px ${shape.borderRadius}px 0px 0px`,
    background: palette.background.default,
    boxShadow: "0px 2px 1px rgba(84, 84, 84, 0.05)",
    marginTop: spacing(3),
    "&$hasInputError": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: palette.error.main,
      },
    },
  },
  hasInputError: {},
  inputLabel: {
    color: palette.text.primary,
    fontSize: typography.pxToRem(14),
  },

  bottomSection: {
    marginTop: spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  saveDateText: {
    fontSize: typography.pxToRem(13),
    lineHeight: typography.pxToRem(21),
    color: palette.text.black,
  },
  submitButton: {
    background: palette.background.brickPinkE1,
    color: palette.text.contrast,
    padding: spacing(1.25, 3.25),
  },
}));
