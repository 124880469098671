import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";

import useStyles from "./kendo-table-date-filter.style";
import TogglerWrapper from "./toggler-wrapper/kendo-table-date-filter.toggler-wrapper";
import Toggler from "./toggler-wrapper/toggler/kendo-table-date-filter.toggler";

type Props = {
  placeholder?: string;
  format?: string;
  value?: string | Date | null;
  onChange: (value?: Date | null) => void;
};

const DateRangeSelect: React.FC<Props> = ({ onChange, format = "yyyy.MM.DD", placeholder, value }) => {
  const classes = useStyles();
  const handleChange = (e: DatePickerChangeEvent) => {
    onChange(e.value);
  };

  return (
    <>
      <DatePicker
        className={classes.root}
        dateInput={() => null}
        toggleButton={(props) => (
          <Toggler value={value} format={format} placeholder={placeholder} onChange={onChange} {...props} />
        )}
        pickerWrap={TogglerWrapper}
        format={format}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  );
};

export default DateRangeSelect;
