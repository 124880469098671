import React from "react";
import { components, OptionProps } from "react-select";

import { substringWithDots } from "utils/strings";

import { OptionType } from "../select-control";

import useStyles from "./select-components.styles";

export const formatData = (
  originalName: string,
  parents: Array<{
    id: string;
    name: string;
  }> = []
) => {
  const name = substringWithDots(originalName, parents.length === 0 ? 105 : parents.length === 1 ? 70 : 35);

  const trimmedParentNames = parents.map(({ name }) => substringWithDots(name, parents.length === 1 ? 70 : 35));
  const parentsToDisplay = [
    parents.length > 1 ? trimmedParentNames[parents.length - 1] : undefined,
    trimmedParentNames[0],
  ].filter(Boolean);

  if (trimmedParentNames.length > 2) {
    parentsToDisplay.splice(1, 0, "...");
  }
  const parentsToReturn = parentsToDisplay.length > 0 ? `${parentsToDisplay.join(" > ")} > ` : undefined;

  return {
    name,
    parents: parentsToReturn,
  };
};

type Props = OptionProps<OptionType>;

const SelectOptionWithParents: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { name, parents } = formatData(props.label, props.data.parents || []);

  return (
    <components.Option {...props}>
      {parents && <span className={classes.parents}>{parents}</span>}
      {name}
    </components.Option>
  );
};

export default SelectOptionWithParents;
