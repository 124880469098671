import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing, typography, palette }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(5),
    width: "100%",
    height: "100%",

    "&$inline": {
      padding: spacing(0, 2),
      width: "auto",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    whiteSpace: "nowrap",

    "&$inline": {
      flexDirection: "row",
    },
  },
  image: {
    lineHeight: 1,
  },
  message: {
    marginTop: spacing(1),
    "& > *": {
      ...typography.h1,
    },

    "&$inline": {
      marginTop: spacing(0),
      marginLeft: spacing(1),
      "& > *": { ...typography.h5 },
    },
    "&$centered": {
      textAlign: "center",
    },
  },

  default: {},
  contrast: {
    color: palette.text.contrast,
  },
  primary: {
    color: palette.primary.main,
  },
  hint: {
    color: palette.text.hint,
  },
  inline: {},
  centered: {},
  fullscreen: {
    height: "100vh",
    width: "100%",
    display: "flex",
  },
}));
