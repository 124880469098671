import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { ProjectEmployeeAssignment } from "modules/assignments/graphql/project-employee-assignment.graphql";

import { Document } from "../types/documents.types";
import { Project } from "./projects.graphql";

export const projectFragment = gql`
  fragment ProjectData on Project {
    endDate
    serviceType
    billingType
    headcount
    billingType
    headcount
    type
    management {
      managerLevel1 {
        firstName
        id
        lastName
      }
      managerLevel2 {
        firstName
        id
        lastName
      }
      supervisor {
        firstName
        id
        lastName
      }
    }
    managementHistory {
      startDate
      endDate
      manager {
        firstName
        lastName
        id
      }
      position
    }
    name
    startDate
    id
    categoryId
  }
`;

export type GetProjectDetailsData = {
  project: Project;
  projectsEmployeeAssignment: {
    data: ProjectEmployeeAssignment[];
    metadata: Metadata;
  };
  documents: {
    data: Document[];
    metadata: Metadata;
  };
};

export type GetProjectDetailsVars = {
  id: string;
  assignmentPage?: number;
  assignmentLimit?: number;
  documentsPage?: number;
  documentsLimit?: number;
  canDocumentsRead?: boolean;
  canProjectAssignmentsRead?: boolean;
};

export type GetProjectDetailsArgs = {
  canAssignmentsAvailabilityRead?: boolean;
  canAssignmentsBillableStatusRead?: boolean;
};

export const GET_PROJECT_DETAILS = (args: GetProjectDetailsArgs) => gql`
  query project(
    $id: String!
    $documentsPage: Int
    $documentsLimit: Int
    $assignmentPage: Int
    $assignmentLimit: Int
    $canDocumentsRead: Boolean!
    $canProjectAssignmentsRead: Boolean!
  ) {
    project(id: $id) {
      ...ProjectData
    }
    documents(relatedEntityCollection: PROJECTS, relatedEntityId: $id, page: $documentsPage, limit: $documentsLimit, sort: "title")
      @include(if: $canDocumentsRead) {
      data {
        id
        title
        url
      }
    }
    projectsEmployeeAssignment(
      projectID: [$id]
      pastAssignments: true
      futureAssignments: true
      page: $assignmentPage
      limit: $assignmentLimit
    ) @include(if: $canProjectAssignmentsRead) {
      data {
        id
        ${args.canAssignmentsBillableStatusRead ? "billableStatus" : ""}
        ${args.canAssignmentsAvailabilityRead ? "availability" : ""}
        role {
          id
          name
        }
        startDate
        endDate
        project {
          id
          name
        }
        statusForNonBillable {
          id
          name
        }
        reasonForNonBillable
        employee {
          id
          firstName
          lastName
          photo
          alerts
          currentEmploymentStatus
          isTreatedAsBillable
        }
      }
      metadata {
        count
      }
    }
  }

  ${projectFragment}
`;
