import React from "react";

import { Box, Typography } from "@mui/material";

import useStyles from "./details-layout-list.style";

export type DetailsLayoutProps = {
  data: Record<string, string | React.ReactNode>;
};

const DetailsLayoutList: React.FC<DetailsLayoutProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Box>
      {Object.keys(data).map((key) => (
        <Box className={classes.section} key={key}>
          <Typography className={classes.label}>{key}</Typography>
          <Typography className={classes.value} component="div">
            {data[key]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default DetailsLayoutList;
