import * as React from "react";
import { Field } from "react-final-form";

import { CircleTwoTone as CircleIcon } from "@mui/icons-material";
import {
  Radio as MuiRadio,
  FormControl,
  FormControlLabelProps,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import useStyles from "./matrix-radio-control.styles";

type Props = {
  label?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  rowNames: Array<{
    id: string;
    name: string;
  }>;
  name: string;
  onChange?: (e?: any) => void;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  disabled?: boolean;
};

const MatrixRadioControl: React.FC<Props> = ({
  name,
  onChange,
  options,
  label,
  rowNames,
  labelPlacement = "top",
  disabled = false,
  ...props
}) => {
  const classes = useStyles({ hasLabel: !!label });

  return (
    <FormControl>
      {label && (
        <InputLabel htmlFor={label} className={classes.fieldLabel}>
          {label}
        </InputLabel>
      )}
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {options.map(({ label, value }) => (
                <TableCell align="center" scope="col" key={value}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowNames.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" className={classes.tableHead}>
                  {row.name}
                </TableCell>
                {options.map(({ label, value }) => (
                  <TableCell align="center" key={value}>
                    <Field
                      label={label}
                      type="radio"
                      name={`${name}[${index}].selectedOptionId`}
                      value={value}
                      component={({ input: { value, checked, onChange: inputOnChange }, ...props }) => (
                        <MuiRadio
                          {...{ value, checked }}
                          onChange={(e) => {
                            inputOnChange(e);
                            onChange?.();
                          }}
                          onClick={(e) => {
                            // ability to uncheck answer
                            if (checked) {
                              inputOnChange(undefined);
                              if (onChange) {
                                onChange(e);
                              }
                            }
                          }}
                          className={classes.radioInput}
                          color="primary"
                          {...props}
                          icon={<CircleIcon />}
                        />
                      )}
                      disabled={disabled}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormControl>
  );
};

export default MatrixRadioControl;
