import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import { createTheme, Theme, PaletteOptions } from "@mui/material/styles";

import _ from "lodash";

// TODO: adjust palette, typography and rest
const basePalette: PaletteOptions = {
  tonalOffset: 0.2,
  contrastThreshold: 3,
  primary: {
    main: "#3A0184",
    contrastText: "#FFFFFF",
    dark: "#260157",
    light: "#EFEBF5", // rgb(57, 1, 131, 0.09)
  },
  secondary: {
    main: "#FF036D",
    contrastText: "#FFFFFF",
    dark: "#e3005e",
    light: "#FFF2FC", // rgba(255, 242, 252, 1)
  },
  error: {
    main: "#F3495D",
    light: "#FEEDEF",
  },
  success: {
    main: "#3C9E06",
  },
  background: {
    default: "#FFFFFF",
  },
  text: {
    primary: "#161E47",
    secondary: "#707B8A",
  },
  action: {
    selected: "#F7F8FF",
    disabled: "#D3D8DE",
  },
  warning: {
    main: "#FF9800",
    light: "#FFF0DE", // rgba(255, 152, 0, 0.15)
  },
  divider: "#D7D9DB",
};

const customPalette = {
  text: {
    default: "#444444",
    contrast: "#FFFFFF",
    light: "#d5d5d5",
    lightGrey: "#F2F2F2",
    grey: "#888888",
    grey1: "#A8A8A8",
    grey2: "#b8b7b9",
    grey3: "#717171",
    grey4: "#8B8B8B",
    grey5: "#C2C2C2",
    grey6: "#767676",
    grey7: "#333333",
    grey8: "#828282",
    grey9: "#797979",
    dark: "#000000",
    blackPearl: "#0A191E",
    acai: "#3A0184",
    mercury: "#E5E5E5",
    turquoise: "#009fdb",
    blue: "#0042D3",
    green: "#00e676",
    black: "#404040",
    coal: "#121314",
    neutral: "#C9CCCF",
    n2: "#303336",
    n3: "#595E64",
    n4: "#6E757C",
    n5: "#D7D9DB",
    n6: "#F7F7F8",
    oceanGreen: "#13875F",
    error: "#DB0000",
    hint: "#AEB1C2",
  },
  background: {
    main: "#F8F8F8",
    pacificBlue: "#009FDB",
    jade: "#00B15C",
    gray: "#ADADAD",
    lightGray: "#F1F2F3",
    lightGray1: "#bbbbbb",
    lightGray2: "#D0D0D0",
    green2: "#13875F",
    green3: "#FF687C",
    pink: "#4AD5C3",
    brickPinkE1: "#F44196",
  },

  hint: {
    dark: "#6D6D6D",
    main: "#AEB1C2",
    contrastText: "#444444",
    light: "#faf9fc",
  },
  secondary: {
    medium: "#59C3C3",
  },
  danger: {
    light: "#f34444",
    dark: "#bb0505",
    contrastText: "#ffffff",
  },
};
const palette = _.merge(customPalette, basePalette);

let theme = createTheme({
  palette: palette,
});

theme = createTheme(theme, {
  typography: {
    fontFamily: ["'Lato', sans-serif", "Segoe UI Emoji"].join(","),
    fontSize: 14,
    h1: {
      fontFamily: "Lato",
      fontSize: "1.625rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Lato",
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h3: {
      fontFamily: "Lato",
      fontSize: "1.125rem",
      fontWeight: 400,
    },
    h4: {
      fontFamily: "Lato",
      fontSize: "1rem",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Lato",
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Lato",
      fontSize: "0.825rem",
      fontWeight: 400,
    },
    body1: {
      fontFamily: "Lato",
      fontSize: "1rem",
      color: theme.palette.text.default,
    },
    body2: {
      fontFamily: "Lato",
      fontSize: "0.875rem",
      color: theme.palette.hint.main,
    },
    button: {
      fontFamily: "Lato",
      fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "none",
    },
  },
});

export default createTheme(theme, {
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: theme.palette.background.main,

          "& a": {
            textDecoration: "unset",
            color: "inherit",

            "& .external-indicator": {
              fontSize: "0.75rem",
              marginLeft: 8,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&$selected": {
            borderColor: theme.palette.primary.main,
          },
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "inherit",
          padding: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "none",
          lineHeight: "1rem",
          minWidth: "auto",
          fontSize: theme.typography.pxToRem(14),
          whiteSpace: "nowrap",
        },
        sizeLarge: {
          height: 42,
          fontSize: theme.typography.pxToRem(15),
          padding: "8px 16px",
          lineHeight: 1.6,
        },
        sizeMedium: {
          height: 30,
          fontSize: theme.typography.pxToRem(14),
          padding: "5px 16px",
        },
        sizeSmall: {
          height: 24,
          fontSize: theme.typography.pxToRem(10),
          padding: "3px 16px",
        },

        containedSizeSmall: {
          textTransform: "uppercase",
          letterSpacing: "0.05em",
        },
        outlinedSizeSmall: {
          textTransform: "uppercase",
          letterSpacing: "0.05em",
        },
        textSizeSmall: {
          fontSize: theme.typography.pxToRem(12),
          padding: 0,
          height: "auto",
        },
        textSizeMedium: {
          padding: 0,
          height: "auto",
        },
        textSizeLarge: {
          padding: 0,
          height: "auto",
        },

        text: {
          fontWeight: 700,
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
        contained: {
          fontWeight: 700,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: theme.palette.action.disabled,
            color: theme.palette.text.contrast,
          },
        },
        outlined: {
          fontWeight: 700,
        },

        outlinedPrimary: {
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        },
        outlinedSecondary: {
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.main,
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,

          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },
        textSecondary: {
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        },

        startIcon: {
          marginRight: 8,
        },
        endIcon: {
          marginLeft: 8,
        },
        iconSizeSmall: {
          " > *:nth-of-type(1)": {
            fontSize: 16,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.hint.main,

          "&$checked": {
            color: theme.palette.primary.main,
          },
        },
        colorSecondary: {
          color: theme.palette.hint.main,

          "&$checked": {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.success.main,
        },
        colorSecondary: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.error.main,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": { content: '""' },
          "&:after": { content: '""' },
        },
        formControl: {
          marginTop: "0px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$error": {
            // opacity: 0.4,
          },
        },
        notchedOutline: {
          "& legend": {
            fontSize: theme.typography.pxToRem(14),
          },
        },
        input: {
          padding: "13px 12px",
        },
        multiline: {
          padding: "6px 0px", // to not overlap label and scrollable text in input
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          maxWidth: "unset",
          overflow: "visible",
          color: theme.palette.text.grey3,
          fontSize: theme.typography.pxToRem(14),
          transform: "translate(12px, -9px) scale(1) !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderWidth: "1px !important",
          minHeight: 50,
          backgroundColor: theme.palette.hint.light,
        },
        input: {
          width: "100%",
          padding: "13px 12px",
          fontSize: "1rem",
          "&$error": {
            opacity: 0.4,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          margin: "8px 0px",
        },
        marginNormal: {
          marginTop: 12,
          marginBottom: 12,
        },
        marginDense: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.default,
          "&$.Mui-error": {
            color: theme.palette.text.default,
          },
          "&.Mui-focused": {
            color: theme.palette.text.default,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
        labelPlacementStart: {
          marginLeft: 24,
          "&:first-of-type": {
            marginLeft: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "'Lato', sans-serif",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(16),
          color: palette.text.default,
          fontWeight: 900,
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.pxToRem(14),
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px 0px 0px",
          justifyContent: "center",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "72px !important",
          padding: "12px 25px",
          fontSize: "1rem",
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.primary,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: "inherit",
        },
        displayedRows: {
          color: "inherit",
        },
      },
    },
  },
});

type CustomText = typeof customPalette.text;
type CustomBackground = typeof customPalette.background;
declare module "@mui/material/styles" {
  interface Palette {
    hint: Palette["primary"];
    danger: Palette["primary"];
  }

  interface TypeText extends CustomText {}
  interface TypeBackground extends CustomBackground {}
  interface PaletteColor {
    medium?: string;
  }
  interface SimplePaletteColorOptions {
    medium?: string;
  }
}

declare global {
  interface CustomTheme extends Theme {}
}
