import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";

import { useAuth } from "services/auth";
import { transformError } from "services/graphql/errorTransformers";

import { UpdateUserData, UpdateUserVars, UPDATE_USER } from "modules/settings/graphql/users.graphql";

import { Button, ConfirmationModal, CropModal, UserAvatar } from "components";

import { useToast } from "hooks";

import useGlobalStyles from "../../account-settings.style";
import useStyles from "./account-settings-photo-control.style";

const AccountSettingsPhotoControl = () => {
  const { showToast } = useToast();
  const auth = useAuth();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [rawPhotoPath, setRawPhotoPath] = useState<string>("");
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const [updateUser] = useMutation<UpdateUserData, UpdateUserVars>(UPDATE_USER);

  const handlePhotoChange = (file: File) => {
    if (!file) {
      return;
    }

    setIsCropModalOpen(true);
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setRawPhotoPath(reader.result as string);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemove = () => {
    handleNewPhotoSubmit("");
  };

  const handleNewPhotoSubmit = async (photoPath: string) => {
    if (auth?.user) {
      try {
        await updateUser({
          variables: {
            updateUserInput: {
              id: auth?.user?.id,
              photo: photoPath,
            },
          },
        });
        auth.updateUser({ photo: photoPath });
        showToast("The photo has been successfully updated", "success");
      } catch (ex) {
        const { error } = transformError(ex as any);

        Sentry.captureException(ex);
        showToast(error as string, "error");
      } finally {
        setIsRemoveModalOpen(false);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <UserAvatar
        fullName={`${auth?.user?.firstName}${auth?.user?.lastName}`}
        imgUrl={auth?.user?.photo}
        size="lg"
        containerClass={classes.imagePreview}
      />
      <input
        accept="image/*"
        className={classes.input}
        style={{ display: "none" }}
        id="photo-file"
        type="file"
        onChange={(e) => {
          if (e.target.files?.length) {
            handlePhotoChange(e.target.files[0]);
          }
        }}
      />
      <Box>
        <label htmlFor="photo-file">
          <Button component="span" variant="text" size="medium" className={globalClasses.button} label="Upload new" />
        </label>
        {auth?.user?.photo && (
          <Button
            size="medium"
            variant="text"
            className={globalClasses.button}
            onClick={() => setIsRemoveModalOpen(true)}
            label="Remove"
          />
        )}
        <Typography component="div" className={globalClasses.description}>
          Short description what is photo for
        </Typography>
      </Box>

      <CropModal
        open={isCropModalOpen}
        title={"Crop your new photo"}
        setOpen={setIsCropModalOpen}
        rawImage={rawPhotoPath}
        ratio={1 / 1}
        onCrop={handleNewPhotoSubmit}
      />
      <ConfirmationModal
        isOpen={isRemoveModalOpen}
        title="Are you sure you want to delete your photo?"
        onConfirm={handleRemove}
        onAbort={() => setIsRemoveModalOpen(false)}
        confirmText="Delete"
        abortText="Cancel"
      />
    </Box>
  );
};

export default AccountSettingsPhotoControl;
