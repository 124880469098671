import React, { PropsWithChildren, useState } from "react";

import { IconButton } from "@mui/material";

import { ImagePreview } from "components";

import useStyles from "./user-avatar-preview.styles";

type Props = {
  imgUrl: string;
};

const UserAvatarPreview: React.FC<PropsWithChildren<Required<Props>>> = ({ children, imgUrl }) => {
  const classes = useStyles();

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const openPreview = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsPreviewOpen(true);
  };

  return (
    <>
      <IconButton
        onClick={imgUrl ? (e) => openPreview(e) : undefined}
        role={imgUrl ? "button" : undefined}
        aria-label={imgUrl ? "Show avatar's preview" : undefined}
        className={classes.button}
      >
        {children}
      </IconButton>

      {imgUrl && isPreviewOpen ? (
        <ImagePreview
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          img={{ url: imgUrl, alt: "User avatar" }}
        />
      ) : null}
    </>
  );
};

export default UserAvatarPreview;
