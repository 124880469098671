import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, typography, spacing }) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      background: palette.background.paper,
      padding: spacing(2),
      margin: spacing(-3),
      marginBottom: spacing(7.5),
    },
    title: {
      marginLeft: spacing(3),
      fontSize: typography.pxToRem(24),
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
  };
});
