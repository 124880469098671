import { CompositeFilterDescriptor, FilterDescriptor, State } from "@progress/kendo-data-query";

import { isEmpty } from "lodash";

import { handleSetOperatorFn } from "components/kendo-table/components/column-menu/filters/custom-checkbox-filter-utils";
import { TableData } from "components/kendo-table/types/kendo-table.types";

import { isJSON } from "utils/strings";

export const FILTERS_STORAGE_KEY = "tableFiltersConfig";
export const CUSTOM_OPERATOR_KEY = "custom-operator";

export const getFiltersConfigFromLS = (pathname?: string) => {
  const currentConfig = localStorage.getItem(FILTERS_STORAGE_KEY) || "";
  const parsedCurrentConfig = isJSON(currentConfig) ? JSON.parse(currentConfig) : {};

  return pathname ? parsedCurrentConfig[pathname] ?? {} : parsedCurrentConfig;
};

export const saveConfigFiltersToLS = (pathname: string, newConfig: any) => {
  const parsedCurrentConfig = getFiltersConfigFromLS();

  localStorage.setItem(
    FILTERS_STORAGE_KEY,
    JSON.stringify({
      ...parsedCurrentConfig,
      [pathname]: {
        ...parsedCurrentConfig?.[pathname],
        ...newConfig,
      },
    }),
  );
};

export const removeConfigFiltersFromLS = (pathname: string) => {
  const parsedCurrentConfig = getFiltersConfigFromLS();

  if (parsedCurrentConfig?.[pathname]) {
    // remove config from ls for current location only, leave for the others
    const { [pathname]: _, ...restConfig } = parsedCurrentConfig;
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(restConfig));
  }
};

// transform the KendoTable columns filters which operator field contains function (from CustomColumnMenuCheckboxFilter) before saving to ls
export const transformStateForLS = (newState: State) => {
  let preparedState = newState;

  if (newState.filter) {
    preparedState = {
      ...newState,
      filter: {
        logic: newState.filter.logic,
        filters: (newState.filter.filters ?? []).map((f) => {
          let newFilters: FilterDescriptor[] = [];

          if (f.hasOwnProperty("logic")) {
            const firstItem = (f as CompositeFilterDescriptor).filters?.[0] as FilterDescriptor;

            if (Array.isArray(firstItem.value)) {
              newFilters = [{ ...firstItem, operator: CUSTOM_OPERATOR_KEY }];
              return { logic: "and", filters: newFilters };
            }
            return f;
          }
          return f;
        }),
      },
    };
  }
  return preparedState;
};

// transform the KendoTable columns filters with custom operator field back into function
export const transformStateForTable = <T extends TableData>(newState: State, data: T[]) => {
  let preparedState = newState;

  if (newState.filter) {
    preparedState = {
      ...newState,
      filter: {
        logic: newState.filter.logic,
        filters: (newState.filter.filters ?? []).map((f) => {
          let newFilters: FilterDescriptor[] = [];

          if (f.hasOwnProperty("logic")) {
            const firstItem = (f as CompositeFilterDescriptor).filters?.[0] as FilterDescriptor;

            if (firstItem.operator === CUSTOM_OPERATOR_KEY) {
              newFilters = [
                {
                  ...firstItem,
                  operator: (fieldValue: string | number | undefined | null, checkedValues: string[]) =>
                    handleSetOperatorFn(fieldValue, checkedValues, data),
                },
              ];
              return { logic: "and", filters: newFilters };
            }
            return f;
          }
          return f;
        }),
      },
    };
  }
  return preparedState;
};

export const checkFiltersEmpty = (filtersData: any) => {
  if (isEmpty(filtersData)) {
    return true;
  }
  if (Object.values(filtersData).every((val) => (Array.isArray(val) ? val.length === 0 : !val))) {
    return true;
  }
  return false;
};
