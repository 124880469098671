import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Drawer, IconButton, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import clsx from "clsx";

import { DEFAULT_ROUTE } from "config/routes";

import { ReactComponent as AcaisoftLogo } from "assets/images/svg/acaisoft.svg";

import { useStyles } from "./app-layout.style";
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";
import VersionInfo from "./version-info/version-info";

const AppLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();

  const openInitial = JSON.parse(localStorage.getItem("showMenu") || "true");

  const [open, setOpen] = React.useState(openInitial);

  useEffect(() => {
    localStorage.setItem("showMenu", JSON.stringify(open));
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <VersionInfo />
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            component={Link}
            aria-label="go to main route"
            to={DEFAULT_ROUTE}
            className={classes.logoButton}
            disableRipple
          >
            <AcaisoftLogo />
          </IconButton>
          <IconButton onClick={handleDrawerClose} size="large" aria-label="close menu">
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Sidebar></Sidebar>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div>{children}</div>
      </main>
    </div>
  );
};

export default AppLayout;
