import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import {
  BarChart as BarChartIcon,
  FolderOpen as FolderOpenIcon,
  PeopleAltOutlined as PeopleIcon,
  Timeline as TimelineIcon,
  FilterNoneOutlined as FilterIcon, // DateRangeOutlined as DateRangeIcon,
} from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { routes } from "config";

import usePermissionsAccess from "services/permissions-access/hooks/usePermissionsAccessContext/usePermissionsAccess";

import useStyles from "./sidebar.style";

type NavItem = {
  href: string;
  exact: boolean;
  label: string;
  icon: React.ComponentType;
};

const Sidebar: React.FC = () => {
  const classes = useStyles();

  const canProjectRead = usePermissionsAccess(["project.read"]);
  const canEmployeesRead = usePermissionsAccess(["employees.read"]);
  const canProjectsEmployeeAssignmentRead = usePermissionsAccess(["projectsEmployeeAssignment.read"]);
  // const canTimesheetsRead = usePermissionsAccess([["timesheets.read"], ["timesheets.readOwn"]]);
  const canEvaluationTemplatesRead = usePermissionsAccess(["evaluationTemplates.read"]);

  const navItems: NavItem[] = [
    ...(canEmployeesRead
      ? [
          {
            href: routes.employees.list,
            exact: false,
            label: "Employees",
            icon: PeopleIcon,
          },
        ]
      : []),
    ...(canProjectRead
      ? [
          {
            href: routes.projects.list,
            exact: false,
            label: "Projects",
            icon: FolderOpenIcon,
          },
        ]
      : []),
    ...(canProjectsEmployeeAssignmentRead
      ? [
          {
            href: routes.assignments.overview,
            exact: false,
            label: "Assignments",
            icon: TimelineIcon,
          },
        ]
      : []),
    ...(canProjectsEmployeeAssignmentRead
      ? [
          {
            href: routes.benchStatistic.list,
            exact: false,
            label: "Bench",
            icon: BarChartIcon,
          },
        ]
      : []),
    ...(canEvaluationTemplatesRead
      ? [
          {
            href: routes.surveys.templates.list,
            exact: false,
            label: "Templates",
            icon: FilterIcon,
          },
        ]
      : []),
    // ...(canTimesheetsRead
    //   ? [
    //       {
    //         href: routes.timesheets.report,
    //         exact: false,
    //         label: "Timesheets",
    //         icon: DateRangeIcon,
    //       },
    //     ]
    //   : []),
  ];

  return (
    <>
      <List className={classes.list}>
        {navItems.map((item) => (
          <ListItemLink
            key={item.label}
            exact={item.exact}
            href={item.href}
            label={item.label}
            className={classes.listItemLink}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <item.icon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.listItemLabel,
              }}
            >
              {item.label}
            </ListItemText>
          </ListItemLink>
        ))}
      </List>
    </>
  );
};

export default Sidebar;

type ListItemLinkProps = {
  exact: boolean;
  href: string;
  label: string;
  className?: string;
};
const ListItemLink: React.FC<ListItemLinkProps> = ({ href, exact, className, label, ...props }) => {
  const classes = useStyles();

  return (
    <ListItem
      to={href}
      className={className}
      component={React.forwardRef((props: NavLinkProps, ref: React.Ref<HTMLAnchorElement> | undefined) => (
        <NavLink {...props} exact={exact} to={href} innerRef={ref} activeClassName={classes.listItemActive} />
      ))}
      {...props}
    />
  );
};
