import { formatFileSize } from "utils/file";

export const errorMessages = {
  ACCEPTED_SINGLE_FILE: "Only single file allowed",
  ACCEPTED_TYPES: "Accepted types: ",
  ACCEPTED_SIZE: "Maximum allowed size: ",
  GENERAL: "Invalid file",
};

export const checkIsTypeAccepted = (type: string, acceptTypes: string[]) => {
  // if acceptTypes are e.g. 'video/*'
  const isAccepted = acceptTypes.some(
    (acceptType) => acceptType === "*" || (acceptType.includes("*") && type.startsWith(acceptType.split("/")[0]))
  );
  return isAccepted || acceptTypes.includes(type);
};

type FileErrorMessageOptions = {
  acceptTypes?: string[];
  acceptTypesText?: string;
  maxSize?: number;
  isMultiple?: boolean;
};

export const getFileErrorMessage = (rejectedFiles: File[], options: FileErrorMessageOptions) => {
  const { acceptTypes, acceptTypesText, maxSize, isMultiple = false } = options;
  if (rejectedFiles.length > 1 && !isMultiple) {
    return errorMessages.ACCEPTED_SINGLE_FILE;
  }

  if (acceptTypes && acceptTypesText && rejectedFiles.some((file) => !checkIsTypeAccepted(file.type, acceptTypes))) {
    return errorMessages.ACCEPTED_TYPES + acceptTypesText;
  }

  if (maxSize && rejectedFiles.some((file) => file.size > maxSize)) {
    return errorMessages.ACCEPTED_SIZE + formatFileSize(maxSize);
  }

  return errorMessages.GENERAL;
};
