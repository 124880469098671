import * as React from "react";
import { FieldRenderProps } from "react-final-form";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormHelperText,
} from "@mui/material";

import useStyles from "./checkbox-control.styles";

type Props = FieldRenderProps<any, HTMLElement> & {
  label?: string;
  forcedError?: string;
  FormLabelProps?: FormControlLabelProps;
  FormControlProps?: FormControlProps;
  inputOnChange?: () => void;
  helperText?: string;
};

const CheckboxControl: React.FC<Props> = ({
  input: { checked, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  label = "",
  FormLabelProps,
  FormControlProps,
  forcedError,
  inputOnChange,
  helperText,
  ...restCheckbox
}) => {
  const classes = useStyles();

  const error = metaError || forcedError;
  const showError = Boolean(touched && ((submitError && !dirtySinceLastSubmit) || error));

  return (
    <>
      <FormControl error={showError} classes={{ root: classes.formControl }} {...FormControlProps}>
        <FormGroup classes={{ root: classes.formGroup }}>
          <FormControlLabel
            label={label}
            classes={{
              label: classes.label,
            }}
            {...FormLabelProps}
            control={
              <Checkbox
                color="secondary"
                name={name}
                inputProps={restInput}
                onChange={(e) => {
                  onChange(e);
                  inputOnChange?.();
                }}
                checked={checked}
                {...restCheckbox}
              />
            }
          />
        </FormGroup>
        {showError && <FormHelperText error>{error || submitError}</FormHelperText>}
      </FormControl>
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </>
  );
};

export default CheckboxControl;
