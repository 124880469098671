import AssessmentSurveyContainer from "modules/evaluation/components/assessment/assassement-survey";
import { AssessmentSurveyProvider } from "modules/evaluation/context/assessment-survey-context";
import GuestLayout from "modules/guest/components/guest-layout/guest-layout";

const AssessmentSurveyView: React.FC = () => {
  return (
    <AssessmentSurveyProvider>
      <GuestLayout>
        <AssessmentSurveyContainer permission="READ_WRITE" />
      </GuestLayout>
    </AssessmentSurveyProvider>
  );
};

export default AssessmentSurveyView;
