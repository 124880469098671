import React, { useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Field, FieldRenderProps } from "react-final-form";

import { InputLabel, FormControl, FormHelperText, Box, Input } from "@mui/material";

import Table from "./table/table";

type Props = FieldRenderProps<any, HTMLElement> & {
  name: string;
  modalTitle?: string;
  label?: string;
  forcedError?: string;
};

const ReorderControl: React.FC<Props> = ({
  input: { value, onChange, name, ...restInput },
  meta: { error: metaError, touched, submitError, dirtySinceLastSubmit },
  label,
  forcedError,
}) => {
  const error = metaError || forcedError;
  const showError = Boolean(touched && ((submitError && !dirtySinceLastSubmit) || error));

  const reorder = useCallback(
    (startIndex: number, endIndex: number) => {
      if (value) {
        const result = [...value] as string[];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      }
    },
    [value],
  );

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      onChange(reorder(result.source.index, result.destination.index));
    },
    [onChange, reorder],
  );
  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <FormControl>
        <Table data={value} handleDragEnd={handleDragEnd} name={name} />
        <Field name={name}>{({ input }) => <Input type="hidden" value={input.value} {...restInput} />}</Field>
        {showError && <FormHelperText error>{metaError || submitError}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default ReorderControl;
