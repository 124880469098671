import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing, typography }) =>
  createStyles({
    formControl: {
      margin: spacing(0, 0, 1),
    },
    formGroup: {
      width: "max-content",
    },
    label: {
      marginLeft: spacing(-0.5),
      fontSize: typography.pxToRem(16),
    },
    helperText: {
      marginBottom: spacing(1),
      marginTop: spacing(-0.5),
    },
  }),
);
