import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  content: {
    paddingBottom: 0,
    paddingTop: `${spacing(3)} !important`,
  },
  dialogTitle: {
    textAlign: "center",
  },
  contentText: {
    color: palette.text.default,
    fontWeight: "bold",
  },
  dialogActions: {},
  dialogPaper: {
    padding: spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
