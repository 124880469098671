import { ProjectCategory } from "modules/settings/graphql/configurations/configurations.graphql";

import { ProjectManagementFullNames } from "../../../utils/managementFullNames";

import { Project } from "../graphql/projects.graphql";
import { Document } from "./documents.types";

export type ProjectTableData = Project & {
  projectManagement?: ProjectManagementFullNames;
  totalBillableEmployees: number;
  category: ProjectCategory | null;
  serviceTypeLabel: string | null;
  billingTypeLabel: string | null;
  typeLabel?: string | null;
};

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED", // means same as "Archived", to be depracated
  UPCOMING = "UPCOMING",
  ARCHIVED = "ARCHIVED",
}
export enum ProjectStatusLabel {
  ACTIVE = "Active",
  CLOSED = "Closed",
  UPCOMING = "Upcoming",
  ARCHIVED = "Archived",
}

export enum ProjectServiceType {
  MANAGED_SERVICES = "Managed services",
  TEAM_AUGMENTATION = "Team augmentation",
}

export enum ProjectBillingType {
  FIXED_PRICE = "Fixed price",
  TIME_AND_MATERIAL = "Time & Material",
}

type DocumentFormType = {
  title: Document["title"];
  url: Document["url"];
};

export type ProjectFormType = Omit<Project, "id" | "management" | "managementHistory"> & {
  managerLevel1: string;
  managerLevel2: string;
  supervisor: string;
  indefiniteDate: boolean;
  documents?: DocumentFormType[];
};
