import { routes } from "config";

import { ToolbarTabs } from "components/toolbar/types/toolbar.types";

export enum ProjectsTabsNames {
  LIST = "list",
  ARCHIVED = "archived",
}
/*
 * ORDER is crucial here. Place hidden tabs at the end
 */
export const PROJECTS_TABS: ToolbarTabs<ProjectsTabsNames> = {
  list: {
    path: routes.projects.list,
    label: "List",
  },
  archived: {
    path: routes.projects.archived,
    label: "Archived",
  },
};

export enum ProjectDetailsTabsNames {
  OVERVIEW = "overview",
  HISTORY = "history",
  // FINANCE = "finance",
  NOTES = "notes",
  EVALUATIONS = "evaluations",
}
export const PROJECT_DETAILS_TABS: ToolbarTabs<ProjectDetailsTabsNames> = {
  overview: {
    path: routes.projects.details.replace("/:tab?", ""),
    label: "Overview",
  },
  history: {
    path: routes.projects.details.replace(":tab?", "history"),
    label: "History",
  },
  // finance: {
  //   path: routes.projects.details.replace(":tab?", "finance"),
  //   label: "Finance",
  // },
  notes: {
    path: routes.projects.details.replace(":tab?", "notes"),
    label: "Notes",
  },
  evaluations: {
    path: routes.projects.details.replace(":tab?", "evaluations"),
    label: "Evaluations",
  },
};
