import { useState } from "react";

import { KeyboardArrowDownRounded as ArrowDownIcon, CheckRounded as CheckRoundedIcon } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment, { Moment } from "moment";

import { Button } from "components";

import { dateToString } from "utils/dateFormat";

import useStyle from "./date-picker-filter.style";

const definedDaysSelector = ["30", "60", "90"];

const isDateEqual = (selectedDate: Moment, month: number) =>
  moment(new Date()).startOf("day").month(month).isSame(selectedDate);

type DatePickerFilterLabels = {
  today: string;
  anyDate: string;
  defined?: {
    "30": string;
    "60": string;
    "90": string;
  };
};

const getFilterLabel = (labels: DatePickerFilterLabels, date: Date | undefined, currentMonth: number) => {
  const selectedDate = date ? moment(date) : undefined;

  if (selectedDate && labels.defined) {
    if (isDateEqual(selectedDate, currentMonth + 1)) {
      return labels.defined["30"];
    }
    if (isDateEqual(selectedDate, currentMonth + 2)) {
      return labels.defined["60"];
    }
    if (isDateEqual(selectedDate, currentMonth + 3)) {
      return labels.defined["90"];
    }
  }

  if (selectedDate && moment(new Date()).startOf("day").isSame(selectedDate, "day")) {
    return labels.today;
  }

  return labels.anyDate + (selectedDate ? `${dateToString(selectedDate.toDate())}` : "");
};

type Props = {
  value: Date | undefined;
  onChange: (value: Date) => void;
  labels: DatePickerFilterLabels;
};

const DatePickerFilter: React.FC<Props> = ({ value, onChange, labels }) => {
  const classes = useStyle();
  const currentMonth = moment().month();
  const withDefinedDaysSelector = Boolean(labels.defined);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleChange = (newDate: Moment | null) => {
    if (newDate) {
      onChange(newDate.startOf("day").toDate());
      handleClose();
    }
  };

  return (
    <>
      <Button
        aria-controls={open ? "date-filter-popover" : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
        endIcon={<ArrowDownIcon />}
        className={classes.filterButton}
        label={getFilterLabel(labels, value, currentMonth)}
      />
      <Popover
        id="date-filter-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popoverContent }}
      >
        {withDefinedDaysSelector ? (
          <div className={classes.daysButtonsConatiner}>
            {definedDaysSelector.map((key) => (
              <span key={key} className={classes.daysSelector}>
                <span className={classes.checkIconContainer}>
                  {isDateEqual(moment(value), currentMonth + Number(key) / 30) ? (
                    <CheckRoundedIcon fontSize="small" />
                  ) : null}
                </span>
                <Button
                  variant="text"
                  label={`Next ${key} days`}
                  className={classes.daysButton}
                  onClick={() => {
                    onChange(
                      moment(new Date())
                        .startOf("day")
                        .month(currentMonth + Number(key) / 30)
                        .toDate(),
                    );
                    handleClose();
                  }}
                />
              </span>
            ))}
          </div>
        ) : null}

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CalendarPicker
            classes={{ viewTransitionContainer: classes.calendarContent }}
            date={value ? moment(value) : null}
            onChange={handleChange}
            disablePast
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default DatePickerFilter;
