import * as React from "react";
import { useParams } from "react-router";

import { EvaluationSurveyParams } from "config/routes";

import { useTenantContext } from "services/tenant/context/tenant.context";

import { Evaluation } from "components/evaluations/graphql/evaluations-graphql";

import { useEvaluation } from "../hooks/useEvaluation";
import { useQueryParams } from "hooks";

type EvaluationSurveyContextType = {
  data?: Evaluation;
  error?: string;
  loading: boolean;
  refetch: () => Promise<void>;
  headers: { TOKEN: string | null; tenantId: string };
  veryFirstLoad: React.MutableRefObject<boolean>;
  permission: React.MutableRefObject<"READ" | "READ_WRITE" | undefined>;
};

const EvaluationSurveyContext = React.createContext<EvaluationSurveyContextType>({} as EvaluationSurveyContextType);

const EvaluationSurveyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { id } = useParams<EvaluationSurveyParams>();
  const { queryParams } = useQueryParams();
  const token = queryParams.get("token");
  const tenant = useTenantContext();
  const permission = React.useRef<"READ" | "READ_WRITE">();

  const headers = {
    TOKEN: token,
    tenantId: tenant.data,
  };

  const { data, loading, error, refetch, veryFirstLoad } = useEvaluation({ headers, id });

  return (
    <>
      <EvaluationSurveyContext.Provider
        {...{
          children,
          value: {
            data,
            loading,
            error,
            refetch,
            headers,
            veryFirstLoad,
            permission,
          },
        }}
      />
    </>
  );
};

const useEvaluationSurveyContext = () => React.useContext(EvaluationSurveyContext);

export { EvaluationSurveyProvider, useEvaluationSurveyContext };
