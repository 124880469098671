import { SvgIconProps, SvgIcon } from "@mui/material";

const RotatingArrow = (props: SvgIconProps) => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ fill: "none" }}
  >
    <path
      d="M12.3213 7.71968C12.1527 9.00586 11.5211 10.1865 10.5448 11.0406C9.56853 11.8947 8.31441 12.3637 7.01724 12.3598C5.72008 12.356 4.46878 11.8795 3.4976 11.0195C2.52643 10.1596 1.90194 8.97521 1.74104 7.68805C1.58015 6.40089 1.89388 5.09921 2.6235 4.02669C3.35312 2.95416 4.44862 2.18431 5.70492 1.86125C6.96122 1.53819 8.2922 1.68406 9.44871 2.27156C10.6052 2.85906 11.508 3.84792 11.988 5.05302M12.3213 1.71968L12.3213 5.05302L8.98796 5.05302"
      stroke="#13875F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default RotatingArrow;
