import { useEffect } from "react";

import { useTabsContext } from "contexts/tabs/tabs.context";

import { TableProps } from "../../kendo-table";
import { TableData } from "../../types/kendo-table.types";

type Params<T, F> = Pick<TableProps<T, F>, "loading" | "tabName"> & {
  total: number;
};

const useTableCounters = <T extends TableData, F extends Record<string, any>>({
  loading,
  tabName,
  total,
}: Params<T, F>) => {
  const { tabs, setCountersToTabs, withCounters } = useTabsContext();

  useEffect(() => {
    if (
      !loading &&
      withCounters &&
      tabName &&
      tabs[tabName].hasOwnProperty("currentCount") &&
      tabs[tabName].currentCount !== total
    ) {
      setCountersToTabs([{ tabName, currentCount: total }]);
    }
  }, [total, tabName, tabs, loading, withCounters, setCountersToTabs]);
};

export default useTableCounters;
