import { Reducer, useEffect, useCallback, useReducer } from "react";

import * as Sentry from "@sentry/react";

import { API } from "services/api";

export type RefetchOptions = {
  withoutLoading?: boolean;
};

type State<D> = { data?: D; loading: boolean; error?: any; shouldFetch: boolean };
type Actions<D> =
  | { type: "makeRequest"; payload: RefetchOptions }
  | { type: "finalizeRequest"; payload: { error?: any; data?: D } };

function reducer<D extends Record<string, any>>(state: State<D>, action: Actions<D>): State<D> {
  switch (action.type) {
    case "makeRequest":
      if (action.payload.withoutLoading) {
        return { data: state.data, loading: false, error: undefined, shouldFetch: true };
      } else {
        return { data: undefined, loading: true, error: undefined, shouldFetch: true };
      }
    case "finalizeRequest":
      const { data, error } = action.payload;
      return { data, loading: false, error, shouldFetch: false };
  }
}

const useFetch = function <D extends Record<string, any>>(url: string, lazy: boolean = false) {
  const [{ data, loading, error, shouldFetch }, dispatch] = useReducer<Reducer<State<D>, Actions<D>>>(reducer, {
    loading: !lazy,
    shouldFetch: !lazy,
  });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await API.get<D>(url);
      dispatch({ type: "finalizeRequest", payload: { data } });
    } catch (ex) {
      dispatch({ type: "finalizeRequest", payload: { error: ex } });
      Sentry.captureException(ex);
    }
  }, [url]);

  useEffect(() => {
    if (shouldFetch) {
      (async () => {
        await fetchData();
      })();
    }
  }, [fetchData, shouldFetch]);

  return {
    data,
    loading,
    error,
    refetch: (options: RefetchOptions = {}) => dispatch({ type: "makeRequest", payload: options }),
  };
};

export default useFetch;
