import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "react-final-form";

import { CheckCircle, Error, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { TextField, InputAdornment, InputBaseComponentProps, FormHelperText, Box, IconButton } from "@mui/material";

import clsx from "clsx";

import DurationMask from "./duration-mask";
import useStyles from "./text-control.styles";

type Props = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  validationIndicator?: boolean;
  placeholder?: string;
  multiline?: boolean;
  durationMask?: boolean;
  helperText?: string;
  helperTextPosition?: "right" | "left" | "center";
  min: number;
  step: number;
  startComponent?: React.ReactNode;
  passwordVisibilityIndicator?: boolean;
  forcedError?: string;
  onInactivity?: () => void;
};

const TextControl: React.FC<Props> = ({
  label,
  input: { value, name, type, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  validationIndicator = false,
  placeholder = "",
  multiline = false,
  durationMask = false,
  helperText,
  helperTextPosition = "left",
  startComponent,
  min = 0,
  step,
  passwordVisibilityIndicator,
  forcedError,
  onInactivity,
  ...restTextField
}) => {
  const classes = useStyles();

  const error = metaError || forcedError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;
  const labelId = `label-${name}`;

  const [forcedType, setForcedType] = useState(type);

  let endAdornment;
  if (passwordVisibilityIndicator || (validationIndicator && isDirty)) {
    endAdornment = (
      <InputAdornment position="end">
        {validationIndicator &&
          isDirty &&
          (hasErrors ? (
            <Error className={classes.indicatorError} />
          ) : (
            <CheckCircle className={classes.indicatorSuccess} />
          ))}
        {passwordVisibilityIndicator && type === "password" && (
          <IconButton
            onClick={() => {
              setForcedType(forcedType === "password" ? "text" : "password");
            }}
            size="large"
          >
            {forcedType === "password" ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </IconButton>
        )}
      </InputAdornment>
    );
  }

  const [isTimerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    if (onInactivity && isTimerRunning) {
      const timer = setTimeout(() => {
        onInactivity?.();
      }, 15000);

      return () => {
        if (timer) {
          clearTimeout(timer);
          setTimerRunning(false);
        }
      };
    }
    return () => null;
  }, [isTimerRunning, onInactivity]);

  return (
    <div className={classes.inputContainer}>
      {startComponent && <Box className={classes.startComponent}>{startComponent}</Box>}
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        {...restTextField}
        fullWidth
        name={name}
        type={forcedType}
        helperText={showError ? error || submitError : undefined}
        error={showError}
        placeholder={placeholder}
        multiline={multiline}
        label={label}
        variant="outlined"
        InputProps={{
          inputComponent: durationMask ? (DurationMask as React.ElementType<InputBaseComponentProps>) : undefined,
          inputProps: {
            spellCheck: false,
            autoCorrect: "off",
            autoCapitalize: "none",
            "aria-labelledby": labelId,
            min: type === "number" ? min : undefined,
            step: type === "number" ? step : undefined,
            ...restInput,
          },
          endAdornment,
          classes: { root: clsx(classes.input, startComponent && classes.hasStartComponent) },
        }}
        onChange={(e) => {
          onChange(e);
          if (onInactivity) {
            setTimerRunning(true);
          }
        }}
        value={value}
      />
      {helperText && (
        <FormHelperText className={classes.helperText} style={{ textAlign: helperTextPosition }}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default TextControl;
