import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, shape, spacing }) => ({
  root: {
    borderRadius: shape.borderRadius,
    background: palette.background.paper,

    "& .react-calendar-timeline": {
      maxHeight: "75vh",
      overflowY: "scroll",
    },

    "& .react-calendar-timeline .rct-sidebar .rct-sidebar-row": {
      border: "none",
      padding: 0,
      display: "flex",
    },
    "& .rct-sidebar-row-odd": {
      backgroundColor: `${palette.text.n6} !important`,
    },

    "& .react-calendar-timeline .rct-sidebar": {
      borderRight: `1px solid ${palette.text.n5}`,
      paddingRight: spacing(3.75),
    },
    "& .rct-items >div[title='']": {
      opacity: 1,
    },
    "& .rct-hl-odd": {
      backgroundColor: `${palette.text.n6} !important`,
    },
  },
  line: {
    borderLeft: `1px solid ${palette.text.n5} !important`,
    borderBottom: "none !important",
    display: "flex",

    // saturday & sunday
    "&.rct-day-6, &.rct-day-0": {
      backgroundColor: `${palette.text.n6} !important`,
      borderBottom: "none !important",
    },
  },

  topHeader: {
    background: `${palette.background.default} !important`,
    border: "0 !important",
    position: "sticky",
    top: 0,
    zIndex: 200,
    alignItems: "flex-end",
  },

  rightTopHeaderContent: {
    background: "transparent !important",
    border: "0 !important",
    paddingTop: spacing(1),
  },
}));
