import { Grid, GridSize, Breakpoint } from "@mui/material";

import useStyles from "./details-layout.style";

type DetailsLayoutProps = {
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  leftSectionConfig?: Partial<Record<Breakpoint, GridSize>>;
  rightSectionConfig?: Partial<Record<Breakpoint, GridSize>>;
};

const DetailsLayout = ({ leftSection, rightSection, leftSectionConfig, rightSectionConfig }: DetailsLayoutProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4} {...leftSectionConfig} className={classes.section}>
        {leftSection}
      </Grid>

      <Grid item xs={12} lg={8} {...rightSectionConfig} className={classes.section}>
        {rightSection}
      </Grid>
    </Grid>
  );
};

export default DetailsLayout;
