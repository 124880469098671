import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, typography, spacing }) => ({
  employeeName: {
    fontSize: typography.pxToRem(14),
    fontWeight: typography.fontWeightBold,
  },
  subtitle: {
    fontSize: typography.pxToRem(12),
    color: palette.text.grey8,
  },
}));
