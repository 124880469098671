import React, { useCallback, useMemo } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { Box, IconButton, Input, InputAdornment } from "@mui/material";

import clsx from "clsx";
import { debounce } from "lodash";

import useStyles from "./search.styles";

type Props = {
  placeholder?: string;
  value?: string;
  onSearch: (value: string | undefined) => void;
  variant?: "normal" | "outlined";
  searchIconPosition?: "start" | "end";
  className?: string;
};

const Search: React.FC<Props> = ({
  placeholder = "Search",
  value = "",
  onSearch,
  variant = "normal",
  searchIconPosition = "end",
  className,
}) => {
  const classes = useStyles();

  const [text, setText] = React.useState(value);

  const changeHandler = useCallback(
    (value: string) => {
      const newTrimmedValue = value?.trim();
      setText(value);
      onSearch(newTrimmedValue);
    },
    [onSearch],
  );
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 500), [changeHandler]);

  const handleClear = () => {
    onSearch(undefined);
    setText("");
  };

  const searchIcon = (
    <span>
      <SearchIcon classes={{ root: clsx(classes.searchIcon, classes[searchIconPosition]) }} />
    </span>
  );

  return (
    <div className={clsx(classes.wrapper, classes[variant], className)}>
      {searchIconPosition === "start" ? <>{searchIcon}</> : null}
      <Input
        type="text"
        value={text}
        placeholder={placeholder}
        classes={{
          root: classes.root,
          input: classes.input,
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setText(event.target.value);
          debouncedChangeHandler(event.target.value);
        }}
        disableUnderline
        endAdornment={
          <Box className={clsx(!text.length && classes.hidden)}>
            <InputAdornment
              position="end"
              classes={{
                root: clsx(classes.inputAdornment),
              }}
            >
              <IconButton className={classes.resetIconButton} onClick={handleClear} aria-label="reset">
                <CloseIcon classes={{ root: classes.resetIcon }} />
              </IconButton>
            </InputAdornment>
          </Box>
        }
      />
      {searchIconPosition === "end" ? <>{searchIcon}</> : null}
    </div>
  );
};

export default Search;
