import { TimelineGroupBase, TimelineItemBase } from "react-calendar-timeline";

import { Moment } from "moment";

import { BasicProjectEmployeeAssignment } from "modules/assignments/graphql/project-employee-assignment.graphql";
import { EmploymentStatusType } from "modules/employees/graphql/employees.graphql";
import { EmployeeAlertType } from "modules/employees/types/employees.types";

export const defaultKeys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start_time",
  itemTimeEndKey: "end_time",
  groupLabelKey: "title",
};

export interface PopupPosition {
  x: number;
  y: number;
}

export type ProjectAssignmentTimelineItem = Omit<TimelineItemBase<Moment>, "start_time" | "end_time"> & {
  start_time: Moment | null;
  end_time: Moment | null;
} & BasicProjectEmployeeAssignment;

export interface EmployeeTimelineGroup extends TimelineGroupBase {
  id: string;
  title: string;
  position?: string;
  root?: boolean;
  parent?: string | null;
  startDate?: string;
  endDate?: string;
  employeeId?: string;
  headcount?: number;
  photo?: string;
  alerts?: EmployeeAlertType[];
  isTreatedAsBillable?: boolean;
  currentEmploymentStatus?: EmploymentStatusType;
  isActive?: boolean;
  isExpanded?: boolean;
}

export interface ProjectTimelineGroup extends TimelineGroupBase {
  id: string;
  title: string;
  position?: string;
}

export type RootGroupType = {
  id: string;
  title: string;
  root: boolean;
  headcount?: number;
  parent: null;
};

export type ChartOpenGroups = { [key: string]: boolean };
