import { useEffect, useState } from "react";

type Props = {
  totalCount: number;
  limit: number;
};

const useSimplePagination = ({ totalCount, limit }: Props) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(totalCount / limit));
  const [page, setPage] = useState(1);

  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / limit));
  }, [totalCount, limit]);

  const handlePage = (requestedPage: number) => {
    if (totalPages >= requestedPage && requestedPage > 0) {
      //check if breaks
      setPage(requestedPage);
    }
  };

  return { page, setPage: handlePage, totalPages };
};

export default useSimplePagination;
