import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, breakpoints }) => ({
  backdrop: {
    backgroundColor: "transparent",
  },
  paper: {
    padding: spacing(3, 2),
    boxShadow: `1px 10px 40px ${palette.hint.main + "70"}`,

    [breakpoints.up("sm")]: {
      padding: spacing(7, 6),
    },
  },

  xs: {
    width: 400,
  },
  sm: {
    width: 524,
  },
  md: {
    width: 750,
  },
  lg: {
    width: 1000,
  },
  xl: {
    width: 1350,
  },
}));
