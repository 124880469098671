import clsx from "clsx";

import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Button, Grid } from "@mui/material";

import useStyles from "./tabs.styles";

export type Tab = {
  label: string;
  path?: string;
  permissions?: Array<string>;
};

export type TabList = Record<string, Tab>;

type Props = {
  items: TabList;
  disabledTabs?: { [key: string]: boolean };
  size?: "small" | "large";
};

const Tabs: React.FC<Props> = ({ items, disabledTabs, size = "large" }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      {items ? (
        <Grid container>
          {Object.keys(items).map((key: any) =>
            items[key].path ? (
              <Button
                key={items[key].label}
                component={RouterLink}
                to={items[key].path as string}
                className={clsx(
                  classes.tabButton,
                  classes.linkButton,
                  location.pathname === items[key].path && classes.active,
                  classes[size],
                )}
              >
                {items[key].label}
              </Button>
            ) : (
              <Button
                key={items[key].label}
                className={clsx(
                  classes.tabButton,
                  classes.noLinkButton,
                  location.pathname === items[key].path && classes.active,
                  disabledTabs?.[key] && classes.disabled,
                  classes[size],
                )}
                disabled={disabledTabs?.[key]}
              >
                {items[key].label}
              </Button>
            ),
          )}
        </Grid>
      ) : null}
    </>
  );
};

export default Tabs;
