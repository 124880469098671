import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ spacing, breakpoints, palette }) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: spacing(8, 4),
    margin: 0,
    width: "100%",
  },

  title: {
    marginBottom: spacing(5),
    textAlign: "center",
  },

  container: {
    borderRadius: 8,
    padding: spacing(3, 2),
    background: palette.background.default,
    width: "100%",

    [breakpoints.up("lg")]: {
      maxWidth: 550,
    },
  },
}));
