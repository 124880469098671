import { useRef, useEffect } from "react";

import { Typography } from "@mui/material";

import { ReactComponent as AcaisoftLogo } from "assets/images/svg/acaisoft.svg";

import { useEvaluationSurveyContext } from "modules/evaluation/context/evaluation-survey-context";

import { Button } from "components";

import useStyles from "./evaluation-survey-initial-screen.styles";

type Props = {
  setShowWelcomeScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EvaluationSurveyInitialScreen: React.FC<Props> = ({ setShowWelcomeScreen }) => {
  const classes = useStyles();
  const { data: evaluation } = useEvaluationSurveyContext();

  const divRef = useRef<HTMLDivElement>(null);

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setShowWelcomeScreen(false);
    }
  };

  useEffect(() => {
    divRef.current?.focus({ preventScroll: true });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.window} ref={divRef} onKeyPress={handleEnterPress} tabIndex={0}>
        <AcaisoftLogo />
        <Typography className={classes.title}>SURVEY</Typography>
        <Typography className={classes.welcomeTitle}>Hello {evaluation?.recipientEmail}!</Typography>
        <Typography className={classes.text}>
          {/* TODO text */}
          Please fill out this survey.
          <br />
          It will only take a few minutes.
        </Typography>
        <Button
          label="Start"
          className={classes.startButton}
          onClick={() => {
            setShowWelcomeScreen(false);
          }}
        />
        <Typography className={classes.tipTop}>or</Typography>
        <Typography className={classes.tipBottom}>
          Press <span className={classes.tipBottomBold}>ENTER</span>
        </Typography>
      </div>
    </div>
  );
};
export default EvaluationSurveyInitialScreen;
