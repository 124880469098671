import { SvgIconProps, SvgIcon } from "@mui/material";

const CloseCross = (props: SvgIconProps) => (
  <SvgIcon style={{ width: "26", height: "26" }} viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.66699 18.333L18.3337 7.66634" stroke={props.fill || "black"} strokeWidth="2" />
    <path d="M18.333 18.333L7.66634 7.66634" stroke={props.fill || "black"} strokeWidth="2" />
  </SvgIcon>
);

export default CloseCross;
