import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  return {
    queryParams,
  };
};

export default useQueryParams;
