import React from "react";
import { components, MenuListComponentProps } from "react-select";

import { OptionType } from "../select-control";
import { CounterDataType } from "../async-select-control";

import useStyles from "./select-components.styles";

type Props = {
  counterData: CounterDataType;
  selectedCount: number;
} & MenuListComponentProps<OptionType>;

const SelectOptionsWithCounter: React.FC<Props> = (props) => {
  const {
    counterData: { limit, count },
    selectedCount,
  } = props;
  const classes = useStyles();

  const optionsLeftCount = count
    ? props.options.length === selectedCount // if all options from displayed list are selected
      ? count - selectedCount
      : count - limit
    : 0;

  return (
    <components.MenuList {...props}>
      {props.children}

      {Boolean(count && optionsLeftCount && count > limit) && (
        <div className={classes.textWithCounter}>
          Specify precisely what you're lookig for to search from {optionsLeftCount} options left
        </div>
      )}
    </components.MenuList>
  );
};
export default SelectOptionsWithCounter;
