import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing }) => ({
  root: {
    fontSize: "1rem",
    marginBottom: spacing(3),
  },
  centeredError: {
    textAlign: "center",
  },
}));
