import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { Employee } from "modules/employees/graphql/employees.graphql";
import { Role, roleFragment } from "modules/settings/graphql/roles.graphql";

export type User = {
  email: string;
  employeeId?: Employee;
  firstName: string;
  id: string;
  lastName: string;
  photo: string | null;
  roleIds: string[];
};

export const userFragment = gql`
  fragment UserData on User {
    email
    firstName
    id
    lastName
    photo
    roleIds
    employeeId {
      id
      firstName
      lastName
    }
  }
`;

export type GetUsersData = {
  users: {
    data: User[];
    metadata?: Metadata;
  };
};

export type GetUsersVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export const GET_USERS = gql`
  query users($limit: Int!, $page: Int!, $search: String) {
    users(limit: $limit, page: $page, sort: "lastName", search: $search) {
      data {
        ...UserData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${userFragment}
`;

export type GetUsersAndRolesData = {
  users: {
    data: User[];
    metadata?: Metadata;
  };
  roles: {
    data: Role[];
    metadata?: Metadata;
  };
};

export type GetUsersAndRolesVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export const GET_USERS_AND_ROLES = gql`
  query usersWithRoles($limit: Int!, $page: Int!, $sort: String, $search: String) {
    users(limit: $limit, page: $page, sort: $sort, search: $search) {
      data {
        ...UserData
      }
      metadata {
        count
        limit
        page
      }
    }
    roles(limit: 0, page: 1) {
      data {
        ...RoleData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${userFragment}
  ${roleFragment}
`;

export type UpdateUserData = {
  user: User;
};

export type UpdateUserVars = {
  updateUserInput: Pick<User, "id"> & Partial<Omit<User, "id" | "email">>;
};

export const UPDATE_USER = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    user: updateUser(updateUserInput: $updateUserInput) {
      ...UserData
    }
  }
  ${userFragment}
`;

export type CreateUserData = {
  user: User;
};

export type CreateUserVars = {
  createUserInput: Omit<User, "id">;
};

export const CREATE_USER = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    user: createUser(createUserInput: $createUserInput) {
      ...UserData
    }
  }
  ${userFragment}
`;
