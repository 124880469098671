import moment from "moment";

export const BLANK_LABEL = "(Blank)";

export const isDate = (item: any) => {
  if (item instanceof Date) {
    return true;
  }
  if (typeof item === "string") {
    return isNaN(+item) && moment(item, moment.ISO_8601).isValid();
  }
  return false;
};
