import makeStyles from "@mui/styles/makeStyles";

type Params = {
  height: number | string;
  width: number | string;
  classes: any;
  resizable?: boolean;
};

export default makeStyles<CustomTheme, Params>(({ palette, shape, spacing, typography }) => ({
  root: {
    maxHeight: ({ height }) => height,
    width: ({ width }) => width,
    padding: spacing(1),
    fontFamily: typography.fontFamily,
    backgroundColor: palette.background.paper,
    borderRadius: shape.borderRadius,

    "&, & .k-grid-header, & .k-header": {
      border: "none",
      fontSize: typography.pxToRem(12),
      fontWeight: "bold",
      color: palette.text.n2,
      "& .active": {
        backgroundColor: `${palette.primary.light} !important`,
      },
    },
    "& .k-link": {
      border: "none",
      backgroundColor: "transparent",
      paddingLeft: spacing(2),
      color: palette.text.n2,
      fontWeight: 900,
      fontSize: typography.pxToRem(12),
    },
    "& .k-grid-container": {
      flex: "0 1 auto",
      height: "100%",
      "& .k-alt": {
        background: "#F7F7F8",
        width: "80%",
        "& .k-grid-content-sticky": {
          background: "#F7F7F8",
        },
        "&:hover": {
          "& > td": { backgroundColor: palette.background.lightGray },
          background: palette.background.lightGray,
        },
      },
    },
    "& table": {
      width: "100% !important",
    },

    "& th": {
      fontWeight: typography.fontWeightRegular,

      "&:hover": {
        borderRight: ({ resizable }) => (resizable ? `1px solid ${palette.text.default}` : "unset"),
      },
    },
    "& .k-hierarchy-cell": {
      padding: spacing(1.5, 0.5),
      "& .k-icon": {
        padding: spacing(0.75),
        width: "auto",
      },
    },

    // set expand icons as arrows
    "& .k-i-plus:before": {
      content: "'\\E014'",
    },
    "& .k-i-minus:before": {
      content: "'\\E015'",
    },
    "& .k-i-plus, .k-i-minus": {
      "&:hover": {
        borderRadius: "50%",
        backgroundColor: palette.action.hover,
      },
    },

    "& col.k-sorted": {
      background: "unset",
    },
    "& td": {
      padding: spacing(1.5, 2),
      border: "none",
      verticalAlign: "top",
      "&, & p, & span": {
        fontSize: typography.pxToRem(14),
        fontWeight: "normal",
      },
    },
    "& .k-grid-content": {
      overflow: "auto",
    },
    "&.row-clickable": {
      "& tr": {
        cursor: "pointer",
      },
    },
    "& .k-grouping-row": {
      background: palette.primary.light,
      cursor: "auto !important",
    },
    "& .k-grid-header-wrap": {
      border: "unset",

      "& tr, & tr:hover, & tr:hover .k-grid-header-sticky, & tr:not(.k-detail-row):hover": {
        backgroundColor: palette.background.paper,
      },
    },
    "&.has-sidebar": {
      "& .k-grid-container": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  tableContainer: {
    display: "flex",
  },

  filtersContainer: {
    marginBottom: spacing(2),
    background: palette.background.paper,
    padding: spacing(2),
    borderRadius: shape.borderRadius,
  },
  upperFilters: {
    marginBottom: spacing(2),
  },
  leftContainer: {
    display: "flex",
  },
  rightContainer: {
    display: "flex",
  },
  filtersRight: {
    display: "flex",
    marginLeft: spacing(1),
    gap: spacing(1),
  },
  search: {
    marginRight: spacing(1),
  },
}));
