import { gql } from "@apollo/client";

import { TenantTechnology } from "modules/employees/graphql/employees.graphql";

import { CompanyConfiguration } from "./company-config.graphql";
import { ExpirableDocumentType } from "./expirable-document-type-config.graphql";

export type EmployeeStatusConfiguration = {
  grades: Grade[];
  positions: Position[];
  offices: Office[];
  technologies: TenantTechnology[];
  specs: Role[];
  categories: EmployeeCategory[];
  candidateSources: CandidateSource[];
  okrCategories: OkrCategory[];
};

export type ProjectsConfiguration = {
  roles: ProjectRole[];
  statusForNonBillable: StatusForNonBillable[];
  categories: ProjectCategory[];
};

export type StatusForNonBillable = {
  id: string;
  name: string;
};

export type ProjectRole = {
  id: string;
  name: string;
};

export type ProjectCategory = {
  id: string;
  name: string;
};

export type EmployeeCategory = {
  id: string;
  name: string;
  sendSelfAssessmentAlerts?: boolean;
};

export type OkrCategory = {
  id: string;
  name: string;
};

export type CandidateSource = {
  id: string;
  name: string;
};

export type Office = {
  id: string;
  name: string;
};

type Technology = {
  id: string;
  name: string;
  skillsCategory?: SkillsCategory;
};

export type SkillsCategory = {
  id: string;
  name: string;
  order?: number;
};

export type Role = {
  id: string;
  name: string;
};

export type Position = {
  id: string;
  name: string;
};

export type Grade = {
  id: string;
  name: string;
};

export type GetConfigData = {
  getEmployeeStatusConfiguration: EmployeeStatusConfiguration;
  getProjectsConfiguration: ProjectsConfiguration;
  getCompanyConfiguration: CompanyConfiguration[];
  getSkillCategories: SkillsCategory[];
  expirableDocumentTypes: ExpirableDocumentType[];
};
export type GetConfigVars = {
  id: string;
  canExpirableDocumentsTypeRead: boolean;
  canTenantsRead: boolean;
};
export const configFragment = gql`
  fragment ConfigData on EmployeeStatusConfiguration {
    grades {
      id
      name
    }
    offices {
      id
      name
    }
    positions {
      id
      name
    }
    specs {
      id
      name
    }
    categories {
      id
      name
      sendSelfAssessmentAlerts
    }
    okrCategories {
      id
      name
    }
    candidateSources {
      id
      name
    }
    technologies {
      id
      name
      skillsCategory {
        id
        name
        order
      }
    }
  }
`;

export const projectConfig = gql`
  fragment ProjectConfigData on ProjectsConfiguration {
    roles {
      name
      id
    }
    statusForNonBillable {
      name
      id
    }
    categories {
      name
      id
    }
  }
`;

export const GET_TENANT_CONFIG = gql`
  query getTenantConfiguration($canExpirableDocumentsTypeRead: Boolean!, $canTenantsRead: Boolean!) {
    getSkillCategories @include(if: $canTenantsRead) {
      id
      name
      order
    }
    getEmployeeStatusConfiguration {
      ...ConfigData
    }
    getProjectsConfiguration {
      ...ProjectConfigData
    }
    getCompanyConfiguration @include(if: $canTenantsRead) {
      id
      name
    }
    expirableDocumentTypes @include(if: $canExpirableDocumentsTypeRead) {
      id
      name
    }
  }
  ${configFragment}
  ${projectConfig}
`;

export type AddProjectElementVars = {
  UpdateProjectsConfigurationInput: UpdateProjectElementInput;
};

export type AddProjectElementData = {
  addProjectConfigElement: ProjectsConfiguration;
};

export type UpdateProjectElementVars = {
  UpdateProjectsConfigurationInput: UpdateProjectElementInput;
};

export type UpdateProjectElementData = {
  updateProjectConfigElement: ProjectsConfiguration;
};

export type RemoveProjectElementVars = {
  id: string;
};

export type RemoveProjectElementData = {
  removeProjectConfigElement: ProjectsConfiguration;
};

export type DeleteElementVars = {
  id: string;
};

export type AddElementVars = {
  UpdateEmployeeStatusConfigurationInput: UpdateEmployeeStatusConfigurationInput;
};

export type AddElementData = {
  addTenantConfigElement: EmployeeStatusConfiguration;
};

export type UpdateElementData = {
  updateTenantConfigElement: EmployeeStatusConfiguration;
};

export type RemoveElementData = {
  removeTenantConfigElement: EmployeeStatusConfiguration;
};

export type UpdateElementVars = {
  UpdateEmployeeStatusConfigurationInput: UpdateEmployeeStatusConfigurationInput;
};

export type UpdateEmployeeStatusConfigurationInput = {
  grades?: Grade[];
  positions?: Position[];
  specs?: Role[];
  technologies?: Technology[];
  offices?: Office[];
  categories?: EmployeeCategory[];
  okrCategories?: OkrCategory[];
  candidateSources?: CandidateSource[];
};

export type UpdateProjectElementInput = {
  roles?: ProjectRole[];
  statusForNonBillable?: StatusForNonBillable[];
  categories?: ProjectCategory[];
};

export const DELETE_ELEMENT = gql`
  mutation removeTenantConfigElement($id: String!) {
    removeTenantConfigElement(id: $id) {
      ...ConfigData
    }
  }
  ${configFragment}
`;

export const ADD_CONFIG_ELEMENT = gql`
  mutation addTenantConfigElement($UpdateEmployeeStatusConfigurationInput: UpdateEmployeeStatusConfigurationInput!) {
    addTenantConfigElement(UpdateEmployeeStatusConfigurationInput: $UpdateEmployeeStatusConfigurationInput) {
      ...ConfigData
    }
  }
  ${configFragment}
`;

export const UPDATE_CONFIG_ELEMENT = gql`
  mutation updateTenantConfigElement($UpdateEmployeeStatusConfigurationInput: UpdateEmployeeStatusConfigurationInput!) {
    updateTenantConfigElement(UpdateEmployeeStatusConfigurationInput: $UpdateEmployeeStatusConfigurationInput) {
      ...ConfigData
    }
  }
  ${configFragment}
`;

export const ADD_PROJECT_CONFIG_ELEMENT = gql`
  mutation addProjectConfigElement($UpdateProjectsConfigurationInput: UpdateProjectConfigurationInput!) {
    addProjectConfigElement(UpdateProjectsConfigurationInput: $UpdateProjectsConfigurationInput) {
      ...ProjectConfigData
    }
  }
  ${projectConfig}
`;

export const UPDATE_PROJECT_CONFIG_ELEMENT = gql`
  mutation updateProjectConfigElement($UpdateProjectsConfigurationInput: UpdateProjectConfigurationInput!) {
    updateProjectConfigElement(UpdateProjectsConfigurationInput: $UpdateProjectsConfigurationInput) {
      ...ProjectConfigData
    }
  }
  ${projectConfig}
`;

export const REMOVE_PROJECT_CONFIG = gql`
  mutation removeProjectConfigElement($id: String!) {
    removeProjectConfigElement(id: $id) {
      ...ProjectConfigData
    }
  }
  ${projectConfig}
`;
