import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  container: {
    position: "relative",
  },
  moreButton: {},
  outlinedButton: {
    border: `1px solid ${palette.background.brickPinkE1}`,
    color: palette.background.brickPinkE1,
    borderRadius: 4,
    padding: spacing(1.75, 1.4, 0.75, 1.4),
    marginRight: spacing(2),
  },
  moreButtonHidden: {
    position: "absolute",
    right: 4,
    top: 4,
    visibility: "hidden",
  },
  isVisible: { visibility: "visible" },
}));
