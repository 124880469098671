import "react-calendar-timeline/lib/Timeline.css";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import useStyle from "./chart-sidebar.style";

type Props = {
  children?: string;
  getRootProps: (propsToOverride?: { style: React.CSSProperties }) => { style: React.CSSProperties };
  hideIcon?: boolean;
};

const ChartSidebar: React.FC<Props> = ({ children, getRootProps, hideIcon = false }) => {
  const classes = useStyle();

  return (
    <div {...getRootProps?.()} className={classes.leftTopHeaderContent}>
      <span className={classes.leftTopHeaderTitle}>{children}</span>
      {hideIcon ? null : <HelpOutlineIcon className={classes.leftTopHeaderImg} />}
    </div>
  );
};
export default ChartSidebar;
