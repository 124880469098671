import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  root: {
    display: "block",
    margin: spacing(1.5, 0),
  },
  projectNameContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  projectName: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.pxToRem(16),
    marginRight: spacing(1),
  },
  managerNameContainer: {
    display: "flex",
    flexWrap: "wrap",
    lineHeight: 1,
  },
}));
