import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ typography }) => ({
  root: {
    "&:hover": {
      textDecoration: "underline !important",
    },
    lineHeight: 1,
    fontSize: typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
}));
