import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

import { isEqual } from "lodash";

import { ColumnsVisibilityConfig } from "components/kendo-table/types/kendo-table.types";

import { isColumnsConfigArray } from "../utils/utils";
import { isJSON } from "utils/strings";

import { SETTINGS_STORAGE_KEY } from "../constants/columns-filter-modal.constants";

type Params = {
  initConfig: ColumnsVisibilityConfig[];
  onLoad(config: ColumnsVisibilityConfig[]): void;
};

interface UpdateStorageParams {
  savedState: ColumnsVisibilityConfig[];
  currentState: ColumnsVisibilityConfig[];
}

function updateStorageState({ savedState, currentState }: UpdateStorageParams): ColumnsVisibilityConfig[] {
  const newState: ColumnsVisibilityConfig[] = [];

  for (let i = 0; i < currentState.length; i++) {
    const currentItem = currentState[i];
    const savedItem = savedState.find(({ field, title }) => currentItem.field === field && currentItem.title === title);

    if (!savedItem) {
      newState.push(currentItem);
      continue;
    }

    if (savedItem.defaultHidden !== currentItem.defaultHidden) {
      newState.push({
        ...savedItem,
        defaultHidden: currentItem.defaultHidden,
        isVisible: !currentItem.defaultHidden,
      });
      continue;
    }

    newState.push(savedItem);
  }

  return newState;
}

export const useColumnsFilterStorage = ({ initConfig, onLoad }: Params) => {
  const location = useLocation();

  useEffect(() => {
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveConfig = useCallback(
    (newConfig?: ColumnsVisibilityConfig[]) => {
      const savedConfig = localStorage.getItem(SETTINGS_STORAGE_KEY) || "";
      const parsedSavedConfig = isJSON(savedConfig) ? JSON.parse(savedConfig) : {};

      localStorage.setItem(
        SETTINGS_STORAGE_KEY,
        JSON.stringify({
          ...parsedSavedConfig,
          [location.pathname]: newConfig,
        }),
      );
      return;
    },
    [location.pathname],
  );

  const loadConfig = useCallback(() => {
    const savedConfig = localStorage.getItem(SETTINGS_STORAGE_KEY) || "";

    if (isJSON(savedConfig)) {
      const parsedConfig = JSON.parse(savedConfig)[location.pathname];
      if (!isColumnsConfigArray(parsedConfig)) {
        saveConfig(initConfig);
        onLoad(initConfig);
        return;
      }

      const newConfig = updateStorageState({ currentState: initConfig, savedState: parsedConfig });
      if (!isEqual(newConfig, parsedConfig)) saveConfig(newConfig);

      onLoad(newConfig);
      return;
    }

    saveConfig(initConfig);
    onLoad(initConfig);
  }, [location.pathname, onLoad, initConfig, saveConfig]);

  return { loadConfig, saveConfig };
};
