import React from "react";

import { KeyboardArrowDownRounded as ArrowDownIcon } from "@mui/icons-material";

import clsx from "clsx";

import { EmployeeTimelineGroup } from "../../types/types";
import useStyle from "./chart-group-renderer-root.style";

type Props = {
  group: EmployeeTimelineGroup;
  toggleGroup: (groupId: string) => void;
};

const ChartGroupRendererRoot: React.FC<Props> = ({ group, toggleGroup }) => {
  const classes = useStyle();

  return (
    <div onClick={() => toggleGroup(group.id)} className={classes.rootContainer}>
      <ArrowDownIcon className={clsx(classes.expandIcon, group.isExpanded && classes.isExpanded)} />
      <span data-testid="groupRootName" className={classes.rootName}>
        {group.title}
      </span>
    </div>
  );
};

export default ChartGroupRendererRoot;
