import makeStyles from "@mui/styles/makeStyles";

type Params = { isDetailsPage?: boolean; isExpanded?: boolean };

export default makeStyles<CustomTheme, Params>(({ palette, spacing, typography }) => ({
  row: {
    padding: spacing(1.5, 3.5, 1.5, 3.5),
    display: "grid",
    gridTemplateColumns: ({ isDetailsPage }) =>
      isDetailsPage
        ? `${typography.pxToRem(272)} 1fr ${typography.pxToRem(272)}`
        : `${typography.pxToRem(160)} 1fr ${typography.pxToRem(226)} ${typography.pxToRem(226)}`,
    gridGap: typography.pxToRem(56),
    margin: 0,
    background: ({ isExpanded }) => (isExpanded ? palette.primary.light : "none"),
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(50px, 1fr))",
    gridTemplateRows: "1fr 1fr",
    alignItems: "center",
    marginBottom: spacing(1),
  },
  link: {
    fontSize: typography.pxToRem(14),
    color: palette.text.turquoise,
    textDecoration: "unset",
    lineHeight: typography.pxToRem(22),
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkIcon: {
    paddingLeft: spacing(1),
    width: 20,
    height: 20,
  },
  icon: {
    fontSize: 14,
  },
  expandedIcon: {
    transform: "rotate(270deg)",
  },
  centerContainer: {
    placeSelf: "center",
  },
  colSpan: {
    gridColumn: "1/4",
  },
  leftObject: {
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandRow: {
    padding: spacing(1.75, 0),
  },
  colKey: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: spacing(2.5),
    color: palette.text.grey1,
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(19),
  },

  fromItem: {
    color: palette.text.black,
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(19),
    overflow: "hidden",
    overflowWrap: "break-word",
  },

  rowText: {
    color: palette.text.grey7,
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(22),
  },
  changedItem: {
    textDecoration: "line-through",
    background: " rgba(254, 8, 8, 0.06)",
  },
  updatedItem: {
    background: "rgba(42, 254, 8, 0.06)",
  },
  changeContainer: {
    display: "flex",
  },
}));
