import { useEffect, useCallback, useRef, useState } from "react";

import { GridRowClickEvent } from "@progress/kendo-react-grid";

import _debounce from "lodash/debounce";

type Params = {
  onRowClick?: (event: GridRowClickEvent) => void;
};

const MOUSE_DOWN_LOCK_TIME = 500; // Mouse down hold time before, we will lock row click event
const CLICK_AWAIT_TIME = 300; // Time that we wait for second click

const useTableRowActions = <T extends Record<string, any>>({ onRowClick }: Params) => {
  // We need to lock firing row click if user hold mouse down, i.e. selecting some text
  const lockRowClick = useRef(false);
  // We need to lock firing row click if user double click, i.e. selecting some text
  const rowClickCount = useRef(0);
  const timeout = useRef<ReturnType<typeof setTimeout> | number>();
  const [rowMouseDown, setRowMouseDown] = useState(false);

  useEffect(() => {
    if (rowMouseDown) {
      lockRowClick.current = false;
      timeout.current = setTimeout(() => {
        lockRowClick.current = true;
      }, MOUSE_DOWN_LOCK_TIME);
    } else {
      clearTimeout(timeout.current as number);
    }
    return () => {
      clearTimeout(timeout.current as number);
    };
  }, [rowMouseDown]);

  const handleRowMouseDown = useCallback(() => {
    setRowMouseDown(true);
  }, []);

  const handleRowMouseUp = useCallback(() => {
    setRowMouseDown(false);
  }, []);

  const handleRowClick = useCallback(
    (e, dataItem: T) => {
      // stop onRowClick action when user clicks on these elements inside row
      const classList = e.target.classList.toString().toLowerCase();
      const isAvatarClick = classList.includes("avatar") || classList.includes("badge");

      if (e.target.matches("a") || e.target.matches("button") || e.target.matches("img") || isAvatarClick) {
        return;
      }

      rowClickCount.current++;
      const debouncedClick = _debounce(() => {
        if (!lockRowClick.current && rowClickCount.current === 1) {
          onRowClick?.({ dataItem } as GridRowClickEvent);
        }
        rowClickCount.current = 0;
      }, CLICK_AWAIT_TIME);
      debouncedClick();
    },
    [onRowClick],
  );

  return { handleRowClick, handleRowMouseDown, handleRowMouseUp };
};

export default useTableRowActions;
