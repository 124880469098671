import { Dispatch, SetStateAction } from "react";

import { Fade } from "@mui/material";

import AccountSettingsChangePassword from "./change-password/account-settings-change-password";
import AccountSettingsOverview from "./overview/account-settings-overview";
import { SECTIONS } from "./types/account-settings.types";

type Props = {
  visibleSection: SECTIONS;
  setVisibleSection: Dispatch<SetStateAction<SECTIONS>>;
};

const AccountSettings: React.FC<Props> = ({ visibleSection, setVisibleSection }) => {
  return (
    <>
      <Fade in={visibleSection === SECTIONS.overview} timeout={500} unmountOnExit>
        <div style={{ display: visibleSection !== SECTIONS.overview ? "none" : undefined }}>
          <AccountSettingsOverview handleSectionChange={setVisibleSection} />
        </div>
      </Fade>
      <Fade in={visibleSection === SECTIONS.changePassword} timeout={500} unmountOnExit>
        <div style={{ display: visibleSection !== SECTIONS.changePassword ? "none" : undefined }}>
          <AccountSettingsChangePassword handleSectionChange={setVisibleSection} />
        </div>
      </Fade>
    </>
  );
};

export default AccountSettings;
