import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: spacing(5, 0),
    marginTop: spacing(3),
  },
  text: {
    color: palette.hint.main,
  },
}));
