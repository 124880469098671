import { CustomMarker } from "react-calendar-timeline";

import moment from "moment";

import useStyle from "./chart-day-marker.style";

type Props = {
  date?: string;
};

const ChartDayMarker: React.FC<Props> = ({ date }) => {
  const classes = useStyle();

  const displayDate = date
    ? +moment(date)
    : +moment(new Date()).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

  return (
    <CustomMarker date={displayDate}>
      {({ styles }) => (
        <div style={{ ...styles }} className={classes.customMarker}>
          <span className={classes.indicator} />
        </div>
      )}
    </CustomMarker>
  );
};

export default ChartDayMarker;
