import React from "react";

import { ProjectLink } from "components";

import { ProjectTimelineGroup } from "../../types/types";
import useStyle from "./chart-group-renderer-project.style";

type Props = {
  group: ProjectTimelineGroup;
};

const ChartGroupRendererProject: React.FC<Props> = ({ group }) => {
  const classes = useStyle();

  return (
    <div className={classes.groupContainer}>
      <ProjectLink className={classes.groupTitle} project={group} />
      <span className={classes.groupPosition}>{group.position}</span>
    </div>
  );
};

export default ChartGroupRendererProject;
