import { ColumnsVisibilityConfig } from "../../../../types/kendo-table.types";

export function isColumnsConfigItem(e: any): e is ColumnsVisibilityConfig {
  return (
    typeof e?.title === "string" &&
    typeof e?.isVisible === "boolean" &&
    typeof e?.isFilterable === "boolean" &&
    typeof e?.defaultHidden === "boolean" &&
    typeof e?.field === "string"
  );
}

export function isColumnsConfigArray(e: any): e is ColumnsVisibilityConfig[] {
  if (!Array.isArray(e)) return false;
  return e.every((item) => isColumnsConfigItem(item));
}
