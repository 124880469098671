import { useCallback, useMemo, useState } from "react";

import { DataResult, process, State } from "@progress/kendo-data-query";
import { setExpandedState, setGroupIds } from "@progress/kendo-react-data-tools";
import { GridExpandChangeEvent } from "@progress/kendo-react-grid";

import moment from "moment";

import { TableData } from "../../types/kendo-table.types";

type Props<T> = {
  data: T[];
  dataState: State;
};

const processWithGroups = <T,>(data: T[], dataState: State): DataResult => {
  const newDataState = process(data, dataState);
  setGroupIds({ data: newDataState.data, group: dataState.group });

  return newDataState;
};

const setExpandedRow = (row: any, expandedRows: string[]) => {
  const newRow = row;
  newRow.expanded = false;
  if (expandedRows.includes(newRow.id)) {
    newRow.expanded = true;
  }

  return newRow;
};

const expandRows = (data: any[], expandedRows: string[]) => {
  data.map((row) => {
    if (!row.groupId) {
      return setExpandedRow(row, expandedRows);
    } else {
      return expandRows(row.items, expandedRows);
    }
  });

  return data;
};

const useTableCollapse = <T extends TableData>({ data, dataState }: Props<T>) => {
  const [collapsedState, setCollapsedState] = useState<string[]>([]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const onExpandChange = useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        // if row is grouping row
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      } else {
        !item.expanded
          ? setExpandedRows([...expandedRows, item.id])
          : setExpandedRows(expandedRows.filter((row) => row !== item.id));
      }
    },
    [collapsedState, expandedRows],
  );

  const processedData: DataResult = useMemo(() => processWithGroups(data, dataState), [data, dataState]);

  const tableData = useMemo(() => {
    // Kendo column accepts only dates as objects. So to make it works properly (i.e. allow formatting) we need to transform string to Date object
    const newData = processedData.data.reduce((data, nextEl) => {
      const newRow = { ...nextEl };

      Object.keys(newRow).forEach((el) => {
        if (moment(newRow[el], [moment.ISO_8601]).isValid() && isNaN(newRow[el])) {
          newRow[el] = new Date(newRow[el]);
        }
      });

      data.push(newRow);
      return data;
    }, []);

    return setExpandedState({
      data: newData,
      collapsedIds: collapsedState,
    });
  }, [collapsedState, processedData.data]);

  return {
    onExpandChange,
    total: processedData.total,
    tableData: expandRows(tableData, expandedRows),
  };
};

export default useTableCollapse;
