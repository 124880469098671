import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette }) => ({
  link: {
    color: `${palette.text.oceanGreen} !important`,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",

    "&:hover": {
      textDecoration: "underline !important",
    },
  },
}));
