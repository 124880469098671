import { DropdownArrowIcon, CloseCrossIcon } from "assets/images/icons";
import clsx from "clsx";
import moment from "moment";

import { ToggleButtonProps } from "@progress/kendo-react-dateinputs";

import { Box, IconButton } from "@mui/material";

import useGlobalStyles from "../../../kendo-table-filters.style";
import useStyles from "./kendo-table-date-filter.toggler.style";

type Props = {
  value?: string | Date | null;
  placeholder?: string;
  format?: string;
  onChange: (value?: Date | null) => void;
} & ToggleButtonProps;

const CustomToggleButton: React.FC<Props> = ({
  value,
  format,
  placeholder = "Pick a date",
  onChange,
  ...restProps
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles({ hasValue: !!value });

  const handleClear = () => {
    onChange(undefined);
  };

  return (
    <Box {...restProps} className={(clsx("k-button k-link"), globalClasses.filter)}>
      {value ? `${placeholder}: ${moment(value).format(format)}` : placeholder}
      {value && (
        <IconButton onClick={handleClear} className={classes.remove} size="large">
          <CloseCrossIcon />
        </IconButton>
      )}
      <DropdownArrowIcon className={globalClasses.arrow} />
    </Box>
  );
};

export default CustomToggleButton;
