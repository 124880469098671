import { BackArrowIcon } from "assets/images/icons";

import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import useStyles from "./form-container-header.styles";

type Props = {
  title: string;
  handleClose?(): void;
};

const FormContainerHeader: React.FC<Props> = ({ title, handleClose }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <IconButton onClick={handleClose} size="large">
        <BackArrowIcon />
      </IconButton>
      <Typography component="span" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default FormContainerHeader;
