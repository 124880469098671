export enum ASSESSMENT_LEVEL {
  NO_KNOWLEDGE = "No knowledge",
  LOW = "Low",
  RATHER_LOW = "Rather low",
  RATHER_HIGH = "Rather high",
  HIGH = "High",
}

export const assessmentOptions = [
  { label: ASSESSMENT_LEVEL.NO_KNOWLEDGE, value: 0 },
  { label: ASSESSMENT_LEVEL.LOW, value: 1 },
  { label: ASSESSMENT_LEVEL.RATHER_LOW, value: 2 },
  { label: ASSESSMENT_LEVEL.RATHER_HIGH, value: 3 },
  { label: ASSESSMENT_LEVEL.HIGH, value: 4 },
];

export const assessmentLegend = [
  { name: ASSESSMENT_LEVEL.NO_KNOWLEDGE, tip: "I have zero experience in this technology." },
  {
    name: ASSESSMENT_LEVEL.LOW,
    tip: "I have read something about it or made a tutorial or simple solution as a hobby.",
  },
  {
    name: ASSESSMENT_LEVEL.RATHER_LOW,
    tip: "I was using it in a commercial project or I was doing something advanced as a hobby.",
  },
  {
    name: ASSESSMENT_LEVEL.RATHER_HIGH,
    tip: "I am able to plan the use of this technology on my own and know its limitations.",
  },
  { name: ASSESSMENT_LEVEL.HIGH, tip: "I’m an expert, I can share my knowledge with others." },
];
