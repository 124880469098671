import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(() => ({
  root: {
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      height: 7,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      backgroundColor: "rgba(0,0,0,.35)",
    },
  },
}));
