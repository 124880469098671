import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(() => ({
  questionWrapper: {
    position: "relative",

    "& input": {
      paddingRight: 55,
    },
  },
  removeQuestionIcon: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translatey(-50%)",
  },
}));
