import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(() => ({
  columnFiltersRoot: {
    "& .k-button": {
      order: "1 !important", // 'filter' button on right
    },
    "& .k-button:not(.k-primary)": {
      order: "0 !important", // 'clear' button on left
    },
  },
}));
