import React, { forwardRef } from "react";

import { Button as MuiButton, CircularProgress, ButtonProps, ButtonClassKey } from "@mui/material";

import clsx from "clsx";

import useStyles from "./button.styles";

type CustomColor = "error" | "hint";
export type Props = Omit<ButtonProps, "color" | "variant"> & {
  classes?: { [key in ButtonClassKey]?: string };
  component?: React.ComponentType<any> | React.ElementType;
  label: string | React.ReactNode;
  endIcon?: ButtonProps["endIcon"];
  startIcon?: ButtonProps["startIcon"];
  color?: ButtonProps["color"] | CustomColor;
  variant?: ButtonProps["variant"];
  disabled?: boolean;
  isLoading?: boolean;
  [key: string]: any;
};

const customColors = ["error", "hint", "hintDark"];
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      classes: parentClasses,
      endIcon,
      startIcon,
      color,
      variant = "contained",
      disabled = false,
      isLoading = false,
      size = "large",
      ...buttonProps
    },
    ref,
  ) => {
    const classes = useStyles({ classes: parentClasses });

    const isCustomColor = customColors.includes(color as string);
    const buttonColor = isCustomColor ? "inherit" : (color as ButtonProps["color"]);
    return (
      <MuiButton
        ref={ref}
        endIcon={isLoading ? <CircularProgress color="inherit" size={15} /> : endIcon}
        startIcon={startIcon}
        color={buttonColor}
        variant={variant}
        size={size}
        classes={{
          root: clsx(
            classes.root,
            classes[variant],
            isCustomColor && classes[color as CustomColor],
            disabled && classes.disabled,
          ),
          endIcon: classes.endIcon,
        }}
        disabled={disabled}
        {...buttonProps}
      >
        {label}
      </MuiButton>
    );
  },
);

export default Button;
