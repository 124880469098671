import React from "react";

import clsx from "clsx";

import { substringWithDots } from "utils/strings";

import useStyles from "./external-link.styles";

interface Props {
  url: string;
  charsLimit?: number;
  className?: string;
  onClick?: () => void;
}

const ExternalLink: React.FC<Props> = ({ url, charsLimit, className, onClick }) => {
  const classes = useStyles();

  return (
    <a onClick={onClick} className={clsx(classes.link, className)} target="_blank" rel="noopener noreferrer" href={url}>
      {charsLimit ? substringWithDots(url, charsLimit) : url}
    </a>
  );
};

export default ExternalLink;
