import React from "react";
import { Link } from "react-router-dom";

import { EMPLOYEE_DETAILS_TABS, EmployeeDetailsTabsNames } from "modules/employees/constants/employees-tabs";

import useStyles from "./employee-link.style";

type Props = {
  employee: {
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
  };
  tabName?: EmployeeDetailsTabsNames;
};

const EmployeeLink: React.FC<Props> = ({ employee, tabName = EmployeeDetailsTabsNames.OVERVIEW }) => {
  const classes = useStyles();
  const empName = employee.name ?? employee.lastName + " " + employee.firstName;

  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={EMPLOYEE_DETAILS_TABS[tabName].path.replace(":id", employee.id)}
      className={classes.root}
      aria-label={`Open profile of the employee ${empName}`}
    >
      {empName}
    </Link>
  );
};

export default EmployeeLink;
