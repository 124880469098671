import { isIE } from "react-device-detect";

import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ breakpoints, palette, spacing, typography }) => ({
  table: {
    backgroundColor: palette.background.default,
    padding: spacing(0, 4, 2.5),
    boxShadow: `0px 20px 30px ${palette.hint.main + "50"}`,
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  defaultText: {
    maxWidth: isIE ? 800 : "100%",
    whiteSpace: "normal",
    wordBreak: "break-word",
    wordWrap: "break-word",
    display: "block",
  },
  customText: {
    maxWidth: 150,
    position: "relative",
    [breakpoints.up("lg")]: {
      maxWidth: 250,
    },
  },
  readMoreButton: {
    background: "none",
    padding: 0,
    fontSize: typography.pxToRem(10),
    "&:hover": {
      background: "none",
    },
    "&:active": {
      background: "none",
      boxShadow: "none",
    },
  },
  center: { textAlign: "center" },
  loaderContainer: {
    height: 708, // Exact height of datatable with default rows number
    display: "flex",
    justifyContent: "center",

    "& p": {
      fontSize: typography.pxToRem(14),
    },
  },
  dragBlocked: {
    cursor: "no-drop",
    opacity: 0.25,
  },
}));
