import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";

import TextExpand, { TextExpandProps } from "../../../text-expand/text-expand";

type Props = TextExpandProps & Pick<GridCellProps, "className" | "style" | "rowType">;

const KendoTableTextExpandCell: React.FC<Props> = ({ className, style, rowType, ...TextExpandProps }) => {
  if (rowType === "groupHeader") return null;
  return (
    <td className={className} style={style}>
      <TextExpand {...TextExpandProps} />
    </td>
  );
};

export default KendoTableTextExpandCell;
