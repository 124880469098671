import { Typography } from "@mui/material";

import useStyles from "./details-layout.style";

type DetailsTileProps = {
  children?: React.ReactNode;
  label: string;
  emptyMessage?: string;
};

const DetailsTile = ({ children, label, emptyMessage }: DetailsTileProps) => {
  const classes = useStyles();

  return (
    <div className={classes.tile}>
      <Typography className={classes.title}>{label.toUpperCase()}</Typography>
      {emptyMessage ? <Typography className={classes.empty}>{emptyMessage}</Typography> : children}
    </div>
  );
};

export default DetailsTile;
