import { Box, Typography } from "@mui/material";

import { ProjectManagement } from "modules/projects/graphql/projects.graphql";

import { EmployeeLink } from "components";

import useStyles from "./chain-of-command.styles";

type Props = {
  data?: ProjectManagement;
  projectName?: string;
};

const ChainOfCommand: React.FC<Props> = ({ data, projectName }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {projectName ? (
        <Box className={classes.projectNameContainer}>
          <Typography className={classes.projectName}>{projectName}</Typography>
        </Box>
      ) : null}
      <Box className={classes.managerNameContainer}>
        {data?.managerLevel1 ? <EmployeeLink employee={data.managerLevel1} /> : " - "}/
        {data?.managerLevel2 ? <EmployeeLink employee={data.managerLevel2} /> : " - "}/
        {data?.supervisor ? <EmployeeLink employee={data.supervisor} /> : " - "}
      </Box>
    </Box>
  );
};

export default ChainOfCommand;
