import React from "react";

type Props = {
  open: boolean;
  handleOpen(): void;
  handleClose(): void;
};

const HeaderModalContext = React.createContext<Props>({} as Props);

const HeaderModalProvider: React.FC<Props> = ({ open, handleOpen, handleClose, children }) => {
  return (
    <HeaderModalContext.Provider
      {...{
        children,
        value: {
          open,
          handleOpen,
          handleClose,
        },
      }}
    />
  );
};

const useHeaderModalContext = () => React.useContext(HeaderModalContext);

export { HeaderModalProvider, useHeaderModalContext };
