import * as React from "react";
import { Field } from "react-final-form";

import {
  Radio as MuiRadio,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroupProps,
  FormLabel,
  RadioGroup,
} from "@mui/material";

import clsx from "clsx";

import useStyles from "./radio-control.styles";

type Props = {
  label?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  name: string;
  onChange?: (e?: any) => void;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  disabled?: boolean;
  formControlLabelClasses?: FormControlLabelProps["classes"];
  inputLabelClassName?: string;
  radioGroupClasses?: FormGroupProps["classes"];
  radioInputClassName?: string;
};

const RadioControl: React.FC<Props> = ({
  name,
  onChange,
  options,
  label,
  labelPlacement = "end",
  disabled = false,
  formControlLabelClasses,
  radioGroupClasses,
  inputLabelClassName,
  radioInputClassName,
  ...props
}) => {
  const classes = useStyles({ hasLabel: !!label });
  return (
    <FormControl>
      {label && (
        <FormLabel htmlFor={label} id={label} className={clsx(classes.fieldLabel, inputLabelClassName)}>
          {label}
        </FormLabel>
      )}
      <RadioGroup classes={{ root: classes.radioGroup, ...radioGroupClasses }}>
        {options?.map(({ label, value }) => (
          <FormControlLabel
            key={String(value)}
            label={label}
            labelPlacement={labelPlacement}
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
              ...formControlLabelClasses,
            }}
            aria-labelledby={label}
            control={
              <Field
                label={label}
                type="radio"
                name={name}
                value={value}
                component={({ input: { value, checked, onChange: inputOnChange }, ...props }) => (
                  <MuiRadio
                    {...{ value, checked }}
                    onChange={(e) => {
                      inputOnChange(e);
                      if (onChange) {
                        onChange(e);
                      }
                    }}
                    onClick={(e) => {
                      // ability to uncheck answer
                      if (checked) {
                        inputOnChange(undefined);
                        if (onChange) {
                          onChange(e);
                        }
                      }
                    }}
                    className={clsx(classes.radioInput, radioInputClassName)}
                    size="small"
                    color="primary"
                    {...props}
                  />
                )}
                disabled={disabled}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioControl;
