import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ typography, palette, spacing }) => ({
  list: {
    padding: spacing(3, 0, 1),
  },
  listItemLink: {
    padding: spacing(1, 4, 1, 4),
    position: "relative",
    "&:hover": {
      background: palette.primary.light,
    },
  },

  listItemLabel: {
    fontWeight: 500,
    fontSize: typography.pxToRem(16),
  },

  listItemIcon: {
    minWidth: "auto",
    marginRight: spacing(2),
  },

  listItemActive: {
    position: "relative",
    background: palette.primary.light,
    color: palette.primary.main,
    "&:before": {
      content: "''",
      width: 3,
      backgroundColor: palette.primary.main,
      height: 48,
      position: "absolute",
      left: 0,
    },
  },
}));
