import { useAuth } from "services/auth/auth-provider/contexts/AuthContext";
import { PermissionsProp } from "services/auth/auth-provider/types";

const usePermissionsAccess = (permissions: PermissionsProp) => {
  const auth = useAuth();
  const hasAccess = auth?.checkAccess({ permissions });

  return hasAccess || false;
};

export default usePermissionsAccess;
