import * as Sentry from "@sentry/react";

import { AxiosRequestConfig, AxiosResponse } from "axios";
import filesize from "filesize";

import { API } from "services/api";

export const formatFileSize = (size: number) => filesize(size, { base: 10 });

export const mbToBytes = (size: number) => size * 1000 * 1000;

export const checkIfImage = (url: string) => {
  const imageFormats = acceptedFiles.image.types.map((type) => type.replace("image/", "."));
  return imageFormats.some((format) => url.endsWith(format));
};

type UploadFileData = {
  result: {
    url: string; // file url
    originalFileName: string;
  };
};

export const uploadFile = async (
  url: string,
  value?: Blob | File | string,
  config?: AxiosRequestConfig,
): Promise<UploadFileData["result"] | undefined> => {
  if (typeof value !== "object") {
    return;
  }

  const data = new FormData();
  data.append("file", value);

  try {
    const file = await API.post<FormData, AxiosResponse<UploadFileData>>(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
      ...(config || {}),
    });
    return file?.data?.result;
  } catch (ex) {
    Sentry.captureException(ex);
    return;
  }
};

export const downloadBlob = (blob: Blob | null, filename: string) => {
  if (!blob) {
    return;
  }
  // IE download
  // @ts-ignore
  if (window.navigator?.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }
  const invisibleLink = window.document.createElement("a");
  invisibleLink.style.display = "none";
  invisibleLink.href = window.URL.createObjectURL(blob);
  invisibleLink.download = filename;
  document.body.appendChild(invisibleLink);
  invisibleLink.click();
  document.body.removeChild(invisibleLink);
};

export const acceptedFiles = {
  audio: {
    types: ["audio/aac", "audio/ogg", "audio/mpeg", "audio/wav", "audio/webm", "audio/mp3"],
    text: "audio",
  },
  document: {
    types: [
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    text: "pdf, Microsoft Word, Excel, PowerPoint",
  },
  csv: {
    types: [".csv"],
    text: "csv",
  },
  image: {
    types: ["image/jpg", "image/jpeg", "image/png", "image/gif"],
    text: "jpg, jpeg, png, gif",
  },
  video: { types: ["video/*"], text: "video" },
  subtitles: { types: [".vtt", ".srt"], text: "subtitles" },
};
