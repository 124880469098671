import React, { useEffect, useState, useRef } from "react";
import { FieldRenderProps } from "react-final-form";
import Select from "react-select";
import { SelectComponents } from "react-select/src/components";
import { ValueType } from "react-select/src/types";

import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { FormControlProps } from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";

import useStyles, { getStyles } from "./select-control.styles";

export type OptionType = { value: string; label: string; disabled?: boolean };

type Props = FieldRenderProps<string[] | string, HTMLElement> & {
  label: string;
  formControlProps?: FormControlProps;
  allowMultiple?: boolean;
  options: Array<OptionType>;
  isLoading?: boolean;
  isError?: boolean;
  noOptionsMessage?: string;
  placeholder?: string;
  components?: Partial<SelectComponents<OptionType>>;
  isClearable?: boolean;
  displayedOptionsLimit?: number;
  isDisabled?: boolean;
  helperText?: string;
  helperTextPosition?: "right" | "left" | "center";
  inputOnChange?: (arg: ValueType<OptionType>) => void;
  forcedError?: string;
  blurInputOnSelect?: boolean;
};

const SelectControl: React.FC<Props> = ({
  label,
  allowMultiple = false,
  options,
  input: { value, name, onChange, ...restInput },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, touched },
  isLoading = false,
  isError = false,
  noOptionsMessage = "No options",
  placeholder = "",
  components,
  isClearable = true,
  displayedOptionsLimit,
  isDisabled,
  helperText,
  helperTextPosition = "left",
  inputOnChange,
  forcedError,
  blurInputOnSelect = true,
  sortValues = true,
  ...customProps
}) => {
  if (sortValues) {
    options.sort((a, b) => a.label.localeCompare(b.label));
  }
  const error = metaError || forcedError;
  const isDirty = Boolean(touched);
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = isDirty && hasErrors;
  const theme = useTheme();
  const controlRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const classes = useStyles({ isDisabled, showError, isMouseOver: isActive || isMouseOver });
  const styles = getStyles({ theme, showError });

  useEffect(() => {
    const ref = controlRef.current;
    const mouseEnter = () => setIsMouseOver(true);
    const mouseLeave = () => setIsMouseOver(false);
    ref?.addEventListener("mouseenter", mouseEnter);
    ref?.addEventListener("mouseleave", mouseLeave);
    return () => {
      ref?.removeEventListener("mouseenter", mouseEnter);
      ref?.removeEventListener("mouseleave", mouseLeave);
    };
  }, []);

  const selectedValues = (allowMultiple ? (value as string[]) || [] : [value as string])?.map((v) =>
    options?.find((o) => o.value === v),
  ) as Array<OptionType>;

  // Solution for limiting displayed elements https://github.com/JedWatson/react-select/issues/126#issuecomment-478603553
  // Integrating case insensitivity with the limiting mechanism is based on react-select bundled code
  let i = 1;
  return (
    <Box my={1} className={classes.container}>
      <FormControl error={showError}>
        <InputLabel htmlFor={label} className={classes.label} variant="outlined">
          <span>{label}</span>
        </InputLabel>

        <div ref={controlRef}>
          <Select
            inputId={label}
            isMulti={allowMultiple}
            name={name}
            blurInputOnSelect={blurInputOnSelect}
            menuPortalTarget={document.body}
            placeholder={displayedOptionsLimit ? "Start typing to search for options" : placeholder}
            value={selectedValues}
            isClearable={isClearable}
            options={options}
            isDisabled={isDisabled}
            onMenuOpen={() => setIsActive(true)}
            onMenuClose={() => setIsActive(false)}
            filterOption={
              displayedOptionsLimit
                ? (option, input) => {
                    const lowercaseOption = JSON.parse(JSON.stringify(option).toLowerCase());

                    return lowercaseOption.label.indexOf(input.toLowerCase()) >= 0 && i++ < displayedOptionsLimit;
                  }
                : undefined
            }
            onInputChange={() => {
              i = 0;
            }}
            onChange={(selected: ValueType<OptionType>) => {
              inputOnChange?.(selected);
              Array.isArray(selected)
                ? onChange(selected.map((s) => s.value))
                : onChange(selected ? (selected as OptionType).value : undefined);
            }}
            closeMenuOnSelect={!allowMultiple}
            isLoading={isError ? false : isLoading}
            styles={styles}
            noOptionsMessage={() => (isError ? "There is something wrong with data" : noOptionsMessage)}
            components={components}
            classNamePrefix="react-select"
            {...restInput}
            {...customProps}
            onFocus={() => {
              setIsActive(true);
              restInput?.onFocus();
            }}
            onBlur={() => {
              restInput?.onBlur();

              setIsActive(false);
              setIsMouseOver(false);
            }}
          />
        </div>

        {showError && <FormHelperText className={classes.errorText}>{error || submitError}</FormHelperText>}
      </FormControl>
      {helperText && (
        <FormHelperText className={classes.helperText} style={{ textAlign: helperTextPosition }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default SelectControl;
