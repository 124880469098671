import React, { useState } from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";

import clsx from "clsx";

import { ProjectAssignmentTimelineItem, PopupPosition } from "components/gantt/types/types";

import ChartItemPopup from "../chart-item-popup/chart-item-popup";
import useStyle from "./chart-item-renderer.style";

interface IProps {
  onHoveredRoot: (value: boolean) => void;
  itemLabel: string;
  onItemClick?: () => void;
}

const CharItemRenderer: React.FC<ReactCalendarItemRendererProps<ProjectAssignmentTimelineItem> & IProps> = ({
  item,
  getItemProps,
  itemLabel,
  onHoveredRoot,
  onItemClick,
}) => {
  const classes = useStyle({ status: item.billableStatus });
  const employeeName = `${item.employee.lastName} ${item.employee.firstName}`;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(employeeName);
  const [{ x, y }, setPopoverPosition] = useState<PopupPosition>({ x: 0, y: 0 });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTitle("");
    const node = event.target as HTMLElement;
    const x = event.pageX;
    const { y: top, height } = node.getBoundingClientRect();
    const y = top + height;

    onHoveredRoot(true);
    setPopoverPosition({ x, y });
    setIsOpen(true);
  };

  const handlePopoverClose = () => {
    onHoveredRoot(false);
    setTitle(employeeName);
    setIsOpen(false);
  };

  return (
    <div
      {...getItemProps({ className: classes.customItem, ...item.itemProps })}
      title={title}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={onItemClick}
    >
      <div className={clsx("rct-item-content", classes.itemText)}>{itemLabel}</div>
      <ChartItemPopup
        x={x}
        y={y}
        isOpen={isOpen}
        item={item}
        onMouseEnter={() => {
          setIsOpen(true);
          onHoveredRoot(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
          onHoveredRoot(false);
        }}
      />
    </div>
  );
};

export default CharItemRenderer;
