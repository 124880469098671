import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ breakpoints, spacing }) => ({
  paperRoot: {
    padding: spacing(7, 2, 2),
    maxWidth: "unset !important",
    margin: 0,
    position: "relative",
  },
  closeButton: {
    margin: spacing(0, 0, 0.5, "auto"),
    position: "absolute",
    right: spacing(2),
    top: spacing(1),
  },
  imagePreview: {
    minWidth: "calc(100vw - 32px)",
    maxWidth: "calc(100vw - 32px)",

    [breakpoints.up(700)]: {
      minWidth: "40vw",
      maxWidth: "50vw",
    },
  },
}));
