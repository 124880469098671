import EvaluationSurveyContainer from "modules/evaluation/components/evaluation/evaluation-survey-container";
import { EvaluationSurveyProvider } from "modules/evaluation/context/evaluation-survey-context";
import GuestLayout from "modules/guest/components/guest-layout/guest-layout";

const EvaluationSurveyView: React.FC = () => {
  return (
    <EvaluationSurveyProvider>
      <GuestLayout>
        <EvaluationSurveyContainer permission="READ_WRITE" />
      </GuestLayout>
    </EvaluationSurveyProvider>
  );
};

export default EvaluationSurveyView;
