import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { Employee } from "modules/employees/graphql/employees.graphql";
import { Project } from "modules/projects/graphql/projects.graphql";
import { User } from "modules/settings/graphql/users.graphql";

export type Evaluation = {
  id: string;
  type: EvaluationType;
  requester: User;
  evaluatedEmployeeId: string;
  evaluatedEmployee: Employee;
  evaluatedProject: Project;
  recipientEmail: string;
  status: EvaluationStatusType;
  updatedAt: Date;
  createdAt: Date;
  questions: EvaluationQuestion[];
  answers: EvaluationAnswer[];
  addressUrl: string;
  isCompleted: boolean;
  targetProjectId?: string;
  templateName?: string;
};

export enum EvaluationStatusType {
  SENT = "SENT",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export enum EvaluationType {
  HR = "HR",
  PROJECT = "PROJECT",
  TALK_TO_GROW_EMPLOYEE = "TALK_TO_GROW_EMPLOYEE",
  TALK_TO_GROW_MANAGER = "TALK_TO_GROW_MANAGER",
}

export enum EvaluationTypeLabel {
  HR = "HR",
  PROJECT = "Project",
  TALK_TO_GROW_EMPLOYEE = "TalkToGrow Employee",
  TALK_TO_GROW_MANAGER = "TalkToGrow Manager",
}

export type EvaluationMatrixQuestion = {
  id: string;
  name: string;
  selectedOptionId?: string;
};

export type EvaluationMatrixAnswer = {
  matrixQuestionId: string;
  selectedOptionId?: string;
};

export type EvaluationOption = {
  id: string;
  name: string;
};

export type EvaluationQuestion = {
  id: string;
  matrixQuestions?: EvaluationMatrixQuestion[];
  options?: EvaluationOption[];
  question?: string;
  type?: EvaluationQuestionType;
};

export type EvaluationAnswer = {
  id: string;
  answer?: string;
  matrixAnswers?: EvaluationMatrixAnswer[];
  type?: EvaluationQuestionType;
};

export enum EvaluationQuestionType {
  TEXT = "TEXT",
  RADIO = "RADIO",
  MATRIX = "MATRIX",
}

export const evaluationFragment = gql`
  fragment EvaluationData on EvaluationDto {
    viewerToken
    requester {
      id
      firstName
      lastName
    }
    targetProjectId
    evaluatedEmployeeId
    evaluatedEmployee {
      id
      firstName
      lastName
    }
    id
    type
    questions {
      id
      question
    }
    answers {
      id
      answer
    }
    recipientEmail
    status
    updatedAt
    createdAt
    templateName
  }
`;

export type EvaluationsList = {
  data: Evaluation[];
  metadata: Metadata;
};
export type GetEvaluationsData = {
  getEvaluations: EvaluationsList;
};

export type GetEvaluationsVars = {
  evaluatedEmployeeId?: string;
  targetProjectId?: string;
  limit: number;
  recipientEmail?: string;
  search?: string;
  sort?: string;
  types?: EvaluationType[];
};

export const GET_EVALUATIONS = gql`
  query getEvaluations(
    $limit: Int!
    $evaluatedEmployeeId: String
    $targetProjectId: String
    $types: [EvaluationType!]
  ) {
    getEvaluations(
      limit: $limit
      evaluatedEmployeeId: $evaluatedEmployeeId
      targetProjectId: $targetProjectId
      types: $types
    ) {
      data {
        ...EvaluationData
      }
    }
  }
  ${evaluationFragment}
`;

export type RequestEvaluationData = {
  requestEvaluation: Evaluation;
};

export type CreateEvaluationsInput = {
  type: EvaluationType;
  evaluatedEmployeeId?: string;
  targetProjectId?: string;
  recipientEmail: string;
  templateId: string;
  questions: EvaluationQuestion[];
};

export type RequestEvaluationVars = {
  createEvaluationsInput: CreateEvaluationsInput;
};

export const REQUEST_EVALUATION = gql`
  mutation requestEvaluation($createEvaluationsInput: CreateEvaluationsInput!) {
    requestEvaluation(createEvaluationsInput: $createEvaluationsInput) {
      ...EvaluationData
    }
  }
  ${evaluationFragment}
`;

export type DeleteEvaluationData = {
  removeEvaluation: Evaluation;
};

export type DeleteEvaluationVars = {
  id: string;
};

export const DELETE_EVALUATION = gql`
  mutation removeEvaluation($id: String!) {
    removeEvaluation(id: $id) {
      id
    }
  }
`;
