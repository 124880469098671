import * as React from "react";

import { ApolloError } from "@apollo/client";
import { Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

import Placeholder from "./placeholder";
import useStyles from "./placeholder.styles";

type Props = {
  error?: ApolloError;
  message?: string;
  inline?: boolean;
  fullscreen?: boolean;
};

const Error404Placeholder: React.FC<Props> = () => {
  const classes = useStyles();

  const renderPlaceholder = () => (
    <Placeholder
      image={<Error color="primary" fontSize="large" />}
      message={<Typography color="primary">404 - Page not found</Typography>}
    />
  );

  return <div className={classes.fullscreen}>{renderPlaceholder()}</div>;
};

export default Error404Placeholder;
