import React from "react";

import { Box } from "@mui/material";

import { useEvaluationSurveyContext } from "modules/evaluation/context/evaluation-survey-context";

import SurveyFormTopSection from "../assessment/components/form-top-section/survey-form-top-section";
import EvaluationSurveyCompletedScreen from "./components/completed-screen/evaluation-survey-completed-screen";
import EvaluationSurveyForm from "./components/form/evaluation-survey-form";
import EvaluationSurveyInitialScreen from "./components/initial-screen/evaluation-survey-initial-screen";
import { ErrorPlaceholder, LoadingPlaceholder } from "components";
import { EvaluationStatusType } from "components/evaluations/graphql/evaluations-graphql";

type Props = {
  permission: "READ" | "READ_WRITE";
};

const EvaluationSurveyContainer: React.FC<Props> = ({ permission }) => {
  const [isFormCompleted, setIsFormCompleted] = React.useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = React.useState(permission === "READ_WRITE");
  const { data: evaluation, loading, error, veryFirstLoad, permission: setPermission } = useEvaluationSurveyContext();

  React.useEffect(() => {
    setPermission.current = permission;
  }, [permission, setPermission]);

  if (veryFirstLoad.current && loading) {
    return <LoadingPlaceholder />;
  }

  if (error) {
    return <ErrorPlaceholder message={error} />;
  }

  return (
    <>
      {showWelcomeScreen && evaluation?.status === EvaluationStatusType.SENT ? (
        <EvaluationSurveyInitialScreen setShowWelcomeScreen={setShowWelcomeScreen} />
      ) : !isFormCompleted && evaluation ? (
        <Box width="100%">
          <SurveyFormTopSection
            version={evaluation}
            employee={evaluation?.evaluatedEmployee}
            project={evaluation?.evaluatedProject}
          />
          <EvaluationSurveyForm permission={permission} setIsFormCompleted={setIsFormCompleted} />
        </Box>
      ) : (
        <EvaluationSurveyCompletedScreen />
      )}
    </>
  );
};

export default EvaluationSurveyContainer;
