import { orderBy } from "@progress/kendo-data-query";
import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuCheckboxFilterProps,
  GridColumnMenuGroup,
  GridColumnMenuFilter,
  GridColumnMenuProps,
  GridColumnMenuSort,
} from "@progress/kendo-react-grid";

import { ColumnMenuOption, TableData } from "../../types/kendo-table.types";
import useStyles from "./column-menu.style";
import CustomColumnMenuCheckboxFilter from "./filters/custom-checkbox-filter";

type Props = GridColumnMenuProps &
  Omit<GridColumnMenuCheckboxFilterProps, "data"> & {
    data?: TableData[];
    options?: ColumnMenuOption[];
  };

function KendoColumnMenu({ data, options = ["filter"], ...restProps }: Props) {
  const preparedData = orderBy(data ?? [], [{ field: restProps.column.field ?? "", dir: "asc" }]);
  const classes = useStyles();

  return (
    <div className={classes.columnFiltersRoot}>
      {options?.includes("sort") ? <GridColumnMenuSort {...restProps} /> : null}
      {options?.includes("group") ? <GridColumnMenuGroup {...restProps} /> : null}
      {options?.includes("filter") ? <GridColumnMenuFilter {...restProps} expanded={true} /> : null}
      {options?.includes("checkboxFilter") ? (
        <GridColumnMenuCheckboxFilter
          {...restProps}
          data={preparedData}
          searchBoxFilterOperator="contains"
          expanded={true}
        />
      ) : null}

      {/* usable for cell which value is array (field value needs to be joined with commas) */}
      {/* filter adds "(Blank)" values */}
      {options?.includes("checkboxFilterCustom") ? (
        <GridColumnMenuFilter
          {...restProps}
          expanded={true}
          hideSecondFilter
          filterUI={({ firstFilterProps }) => (
            <CustomColumnMenuCheckboxFilter firstFilterProps={firstFilterProps} data={preparedData} />
          )}
        />
      ) : null}
    </div>
  );
}

export default KendoColumnMenu;
