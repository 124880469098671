import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

export type Permission = {
  id: string;
  key: string;
  module: string;
  name: string;
};

export type GetPermissionsData = {
  permissions: {
    data: Permission[];
    metadata?: Metadata;
  };
};

export type GetPermissionsVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export const permissionFragment = gql`
  fragment PermissionData on Permission {
    id
    key
    module
    name
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions($limit: Int!, $page: Int!, $search: String) {
    permissions(limit: $limit, page: $page, sort: "module, name", search: $search) {
      data {
        ...PermissionData
      }
      metadata {
        count
        limit
        page
      }
    }
  }
  ${permissionFragment}
`;

export const REFRESH_PERMISSIONS = gql`
  mutation refreshPermissions($tenantId: String!) {
    refreshPermissions(tenantId: $tenantId)
  }
`;

export type RefreshPermissionsVars = {
  tenantId: string;
};
