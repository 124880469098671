import * as React from "react";

import { ApolloError } from "@apollo/client";
import { Typography } from "@mui/material";
import { Error } from "@mui/icons-material";

import { transformError } from "services/graphql/errorTransformers";

import Placeholder from "./placeholder";
import useStyles from "./placeholder.styles";

type Props = {
  error?: ApolloError;
  message?: string;
  inline?: boolean;
  fullscreen?: boolean;
  fontSize?: number;
};

const ErrorPlaceholder: React.FC<Props> = ({
  error,
  message = "Sorry, something went wrong",
  inline = false,
  fullscreen = false,
  fontSize,
}) => {
  const classes = useStyles();
  const transformedError = error && transformError(error).error;

  const renderPlaceholder = () => (
    <Placeholder
      image={<Error color="error" fontSize={inline ? "small" : "large"} />}
      message={
        <Typography color="error" style={{ fontSize: fontSize ? fontSize : undefined }}>
          {typeof transformedError === "string" ? transformedError : message}
        </Typography>
      }
      inline={inline}
    />
  );

  return fullscreen ? <div className={classes.fullscreen}>{renderPlaceholder()}</div> : renderPlaceholder();
};

export default ErrorPlaceholder;
