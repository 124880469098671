import * as React from "react";
import { useDropzone } from "react-dropzone";
import { FieldRenderProps } from "react-final-form";

import {
  CloudUploadOutlined as CloudUploadIcon,
  DeleteOutline as DeleteIcon,
  PhotoCamera as PhotoIcon,
} from "@mui/icons-material";
import { Box, Typography, FormControl, FormHelperText, Grid, Avatar, IconButton } from "@mui/material";

import clsx from "clsx";

import { Button, LoadingPlaceholder } from "components";

import useStyles from "./file-control.styles";
import { getFileErrorMessage } from "./utils";

type Props = FieldRenderProps<File, HTMLElement> & {
  label?: string;
  acceptTypes?: string[];
  acceptTypesText?: string;
  maxSize?: number;
  iconPlaceholder?: React.ReactNode;
  isLoading?: Boolean;
  variant: "rounded" | "square";
  forcedError?: string;
};

const FileControl: React.FC<Props> = ({
  label = "",
  input: { value, onChange },
  meta: { submitError, dirtySinceLastSubmit, error: metaError, modified },
  acceptTypes,
  acceptTypesText,
  maxSize,
  iconPlaceholder,
  isLoading,
  variant = "square",
  forcedError,
}) => {
  const classes = useStyles();

  const onDropAccepted = (acceptedFiles: File[]) => onChange(acceptedFiles[0]);
  const onDropRejected = () => onChange(value);

  const {
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize,
    multiple: false,
    accept: acceptTypes,
  });

  const error = metaError || forcedError;
  const hasErrors = Boolean((submitError && !dirtySinceLastSubmit) || error);
  const showError = modified && hasErrors;

  // isImage - checking if accepted types are images
  const isImage = acceptTypes?.every((acceptType) => acceptType.includes("image"));

  // checking value type (string or File) to display it's preview correctly
  const imagePreview = typeof value === "string" ? (value as string) : window.URL.createObjectURL(value);

  return (
    <FormControl error={showError}>
      {!isImage && !value && (
        <div {...getRootProps()} className={classes.dropContainer}>
          <input {...getInputProps()} />
          <CloudUploadIcon className={classes.uploadIcon} />

          <div className={classes.dropTextContainer}>
            {isDragActive ? (
              <Typography className={classes.dropText}>Drop the file here</Typography>
            ) : (
              <>
                <Typography className={classes.dropText}>Drag here or</Typography>
                <Button
                  color="secondary"
                  variant="text"
                  className={classes.browseButton}
                  label="browse"
                  size="medium"
                />
                <Typography className={classes.dropText}>to upload file</Typography>
              </>
            )}
          </div>
        </div>
      )}

      {!isImage && value && (
        <div className={classes.container}>
          <Typography color="primary" className={classes.fileName}>
            {value.name ||
              value
                .toString()
                .split("/") // trimming full file url nad displaying only filename
                .pop()}
          </Typography>
          {isLoading ? (
            <LoadingPlaceholder inline />
          ) : (
            <IconButton aria-label="delete file" onClick={() => onChange(undefined)}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      )}

      {isImage && (
        <>
          {label && (
            <Grid container spacing={1} alignItems="center" className={classes.labelContainer}>
              <Grid item>
                <Typography variant="caption">{label}</Typography>
              </Grid>
            </Grid>
          )}

          <div className={classes.tileContainer}>
            <div {...getRootProps()} className={classes.avatarDropContainer}>
              <input {...getInputProps()} />
              <Avatar
                variant={variant}
                classes={{
                  square: classes.square,
                  rounded: classes.roundedAvatar,
                  img: value ? classes.imagePreview : undefined,
                }}
                src={isDragActive ? "" : value ? imagePreview : ""}
              >
                {isDragActive ? (
                  <Typography align="center" className={variant === "rounded" ? classes.roundedText : undefined}>
                    Drop the
                    <br />
                    file here
                  </Typography>
                ) : (
                  !value && iconPlaceholder
                )}
              </Avatar>
            </div>
            <div className={classes.actionButtons}>
              <div className={clsx(classes.actionButton, classes.openButton, variant === "rounded" && classes.rounded)}>
                <IconButton onClick={openFileDialog} size="large" aria-label="add file">
                  <PhotoIcon className={classes.actionIcon} />
                </IconButton>
              </div>
              <div
                className={clsx(classes.actionButton, classes.deleteButton, variant === "rounded" && classes.rounded)}
              >
                <IconButton
                  onClick={() => {
                    onChange(undefined);
                  }}
                  size="large"
                  aria-label="delete file"
                >
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </div>
            </div>
          </div>
        </>
      )}

      {rejectedFiles.length ? (
        <Box my={1}>
          <Typography color="error" variant="h5" className={classes.error}>
            {getFileErrorMessage(rejectedFiles, {
              acceptTypes,
              acceptTypesText,
              maxSize,
            })}
          </Typography>
        </Box>
      ) : (
        showError && (
          <Box my={1}>
            <FormHelperText error>{error || submitError}</FormHelperText>
          </Box>
        )
      )}
    </FormControl>
  );
};

export default FileControl;
