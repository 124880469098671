import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ shape, spacing, typography }) => ({
  root: {
    padding: 0,
    fontWeight: typography.fontWeightMedium,
    borderRadius: shape.borderRadius,
    height: "unset",
    cursor: "default !important",

    "& .k-chip-label": {
      fontSize: typography.pxToRem(12),
    },

    "&$small": {
      lineHeight: typography.pxToRem(16),
      "& .k-chip-content": {
        marginRight: 0,
        padding: spacing(0, 0.5),
      },
    },
    "&$medium": {
      lineHeight: typography.pxToRem(24),
      "& .k-chip-content": {
        marginRight: 0,
      },
    },
  },
  small: {},
  medium: {},
}));
