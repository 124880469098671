import React from "react";

import { Person as PersonIcon } from "@mui/icons-material";
import { Avatar as MuiAvatar } from "@mui/material";

import clsx from "clsx";

import { getInitials } from "utils/strings";

import UserAvatarBadge, { UserAvatarBadgeType } from "./user-avatar-badge/user-avatar-badge";
import UserAvatarPreview from "./user-avatar-preview/user-avatar-preview";
import useStyles from "./user-avatar.styles";

export type Props = {
  imgUrl?: string | null;
  size?: "sm" | "md" | "lg";
  fullName?: string;
  containerClass?: string;
  badge?: UserAvatarBadgeType;
  withPreview?: boolean;
};

const UserAvatar: React.FC<Props> = ({ imgUrl, size = "sm", fullName, containerClass, badge, withPreview = true }) => {
  const classes = useStyles();

  const initials = fullName && getInitials(fullName);

  // Display:
  // user avatar
  // initials when user has no avatar
  // avatar placeholder for edit user form

  const renderAvatar = (
    <UserAvatarBadge badge={badge}>
      <MuiAvatar
        src={imgUrl ?? undefined}
        alt="User avatar"
        title="User avatar"
        className={clsx(
          classes.avatar,
          imgUrl && classes.hasAvatar,
          classes[size],
          initials && !imgUrl && classes.initials,
        )}
      >
        {!initials ? <PersonIcon className={clsx(classes.userIcon, classes[size])} /> : !imgUrl ? initials : null}
      </MuiAvatar>
    </UserAvatarBadge>
  );

  return (
    <div className={clsx(classes.avatarContainer, classes[size], containerClass)}>
      {imgUrl && withPreview ? <UserAvatarPreview imgUrl={imgUrl}>{renderAvatar}</UserAvatarPreview> : renderAvatar}
    </div>
  );
};

export default UserAvatar;
