import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, typography, spacing }) => ({
  tooltip: {
    padding: spacing(2),
    maxWidth: 250,
    background: palette.primary.main,
  },
  visibleList: {
    whiteSpace: "break-spaces",
  },
  title: {
    color: palette.primary.contrastText,
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  divider: {
    margin: spacing(1, 0),
  },
  tooltipContent: {
    color: palette.primary.contrastText,
    fontSize: "0.875rem",
  },
  moreCounter: {
    fontSize: typography.pxToRem(12),
    lineHeight: 1.2,
    color: palette.text.n2,
    whiteSpace: "nowrap",
    cursor: "help",
  },
  moreContainer: {
    border: `1px solid ${palette.text.n2}`,
    padding: spacing(0.25),
    textAlign: "center",
    borderRadius: 6,
    margin: spacing(0.25, 0, 0.25, 1),
    display: "flex",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));
