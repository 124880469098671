import { useState } from "react";

import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";

import { ToolbarMoreListConfig } from "../types/toolbar.types";
import useStyles from "./toolbar-more-list.styles";

type Props = {
  config: ToolbarMoreListConfig[];
};

const ToolbarMoreList: React.FC<Props> = ({ config }) => {
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  if (config.length === 0) return null;
  return (
    <>
      <Button className={classes.moreButton} onClick={handleMenuOpen}>
        <MoreHorizIcon className={classes.moreIcon} />
      </Button>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!menuAnchor}
        onClose={handleMenuClose}
      >
        {config.map(({ label, icon, disabled, onClick }) => (
          <MenuItem
            onClick={() => {
              onClick();
              handleMenuClose();
            }}
            disabled={disabled}
            className={classes.menuItem}
            key={label}
          >
            <span className={classes.menuItemIcon}>{icon}</span>
            <span>{label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ToolbarMoreList;
