import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, typography, spacing }) => ({
  root: {
    margin: spacing(1, 0),
  },
  label: {
    display: "block",
    marginTop: spacing(2),
  },
  input: {
    "& input": {
      paddingRight: 0,
      fontSize: typography.pxToRem(14),
    },
  },
  icon: {
    color: palette.text.n2,
  },
  paperRoot: {
    "& .MuiDialogActions-root": {
      padding: spacing(1, 3, 3),
    },
  },
}));
