import { SvgIconProps, SvgIcon } from "@mui/material";

const BackArrow = (props: SvgIconProps) => (
  <SvgIcon style={{ width: "10", height: "16" }} viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.87988 1.88L3.77322 8L9.87988 14.12L7.99988 16L-0.000116698 8L7.99988 -8.21774e-08L9.87988 1.88Z"
      fill="#131313"
    />
  </SvgIcon>
);

export default BackArrow;
