import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ palette, shadows, spacing, typography }) => ({
  badgePopper: {
    "& > div": {
      padding: spacing(2),
      maxWidth: 250,
      background: palette.background.default,
      color: palette.text.default,
      boxShadow: shadows[3],
    },
  },
  list: {
    color: palette.text.default,
    paddingLeft: spacing(2.5),
    margin: 0,
  },
  text: {
    fontSize: typography.pxToRem(14),
  },
  badgeIcon: {
    backgroundColor: palette.common.white,
    width: 14,
    height: 14,
    borderRadius: "50%",
  },
  bottomIcon: {
    color: palette.text.error,
  },
  badgeIconCrossedLine: {
    position: "absolute",
    "& > line": {
      strokeWidth: 1,
      stroke: palette.text.default,
    },
  },
}));
