import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing }) => ({
  tabsScroller: {
    overflow: "auto !important",
  },
  tab: {
    minHeight: 48,
  },
  icon: {
    borderRadius: 8,
    marginLeft: 4,
    backgroundColor: palette.text.n5,
    fontSize: "0.75rem",
    padding: spacing(0.25, 0.5),
  },
}));
