import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export default makeStyles<CustomTheme>(({ spacing }) =>
  createStyles({
    formControl: {
      margin: spacing(0, 0, 1),
    },
    formGroup: {
      width: "max-content",
    },
  })
);
