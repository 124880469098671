import { Chip, ChipProps } from "@progress/kendo-react-buttons";

import clsx from "clsx";

import useStyles from "./status-chip.style";

export type ChipSize = "small" | "medium";

type Props = {
  variant?: string | "warning" | "error" | "success" | "info" | "none";
  label?: string;
  size?: ChipSize;
} & ChipProps;

const StatusChip: React.FC<Props> = ({ variant, label, size = "medium", ...restProps }) => {
  const classes = useStyles();

  return <Chip className={clsx(classes.root, classes[size])} {...restProps} type={variant} text={label} />;
};

export default StatusChip;
