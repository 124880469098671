import React from "react";

import { Box, Typography } from "@mui/material";

import useStyles from "./form-container.styles";
import FormContainerHeader from "./header/form-container-header";
import { FormContainerSection } from "./types/form-container.types";

type Props = {
  title?: string;
  sections: FormContainerSection[];
  buttonsBox: React.ReactNode;
  width?: string;
  handleClose?(): void;
};

const FormContainer: React.FC<Props> = ({ title, sections, buttonsBox, handleClose, width = "75%" }) => {
  const classes = useStyles({ width });
  return (
    <>
      {title && <FormContainerHeader title={title} handleClose={handleClose} />}
      <Box className={classes.root}>
        {sections.map(
          (section, i) =>
            section && (
              <Box className={classes.section} key={i}>
                {section?.title && <Typography className={classes.title}>{section.title}</Typography>}
                {section?.subtitle && <Typography className={classes.subtitle}>{section.subtitle}</Typography>}
                {section?.content}
              </Box>
            ),
        )}
        <Box className={classes.buttons}>{buttonsBox}</Box>
      </Box>
    </>
  );
};

export default FormContainer;
