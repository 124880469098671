import { ToolbarTab, ToolbarTabs } from "../types/toolbar.types";

export const insertToolbarTabPathParam = (path: string, params: Record<string, string>) => {
  return Object.keys(params).reduce((prevPath, nextParamKey) => {
    const paramValue = params[nextParamKey];
    const newPath = paramValue
      ? prevPath.replace(`:${nextParamKey}`, paramValue)
      : prevPath.replace(`/:${nextParamKey}`, "");

    return newPath.replace("?", "");
  }, path);
};

export const insertToolbarTabPathParams = <T extends string>(tabs: ToolbarTabs<T>, params: Record<string, string>) => {
  const tabsList: ToolbarTab[] = Object.values(tabs);
  return tabsList.map((el) => {
    const path = insertToolbarTabPathParam(el.path, params);

    return { ...el, path };
  });
};

export const getPossibleToolbarTabPaths = (route: string, params: Record<string, string>) =>
  Object.keys(params).reduce(
    (paths, nextParamKey) => {
      paths.push(insertToolbarTabPathParam(paths[paths.length - 1], { [nextParamKey]: params[nextParamKey] }));
      return paths;
    },
    [route],
  );

// reset current counts (set currentCounts as totalCounts)
export const resetTabsCounts = <T extends string>(tabs: ToolbarTabs<T>) => {
  return Object.keys(tabs).reduce((obj, currKey) => {
    const key = currKey as keyof ToolbarTabs<T>;
    return {
      ...obj,
      [key]: { ...tabs[key], currentCount: tabs[key].totalCount },
    };
  }, {} as ToolbarTabs<T>);
};
