import makeStyles from "@mui/styles/makeStyles";

export default makeStyles<CustomTheme>(({ spacing }) => ({
  root: {
    display: "flex",
    gap: spacing(2),
    alignItems: "center",
    "& > button > div": { margin: 0 },
  },
  menuIcon: {
    marginRight: spacing(1.5),
  },
}));
