import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { routes } from "../../config";
import AssessmentSurveyView from "./views/assessment-survey-view";
import EvaluationSurveyView from "./views/evaluation-survey-view";
import ForgotPasswordView from "./views/forgot-password.view";
import LoginView from "./views/login.view";
import SetPasswordView from "./views/set-password.view";

const GuestRouter: React.FC = () => (
  <Switch>
    <Route exact path={routes.auth.login} component={LoginView} />
    <Route exact path={routes.auth.forgotPassword} component={ForgotPasswordView} />
    <Route exact path={routes.auth.setPassword} component={SetPasswordView} />
    <Route exact path={routes.auth.evaluationSurvey} component={EvaluationSurveyView} />
    <Route exact path={routes.auth.assessmentSurvey} component={AssessmentSurveyView} />
    <Redirect to={routes.auth.login} />
  </Switch>
);

export default GuestRouter;
