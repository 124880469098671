import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette }) => ({
  root: {
    lineHeight: 0,
    marginLeft: -3,
    "& .k-rating-item.k-state-selected": {
      color: palette.background.green2,
      padding: 0,
    },
    "& .k-rating-item": {
      color: palette.text.n5,
      padding: 0,
    },
    "& .k-rating-container": {
      margin: 0,
    },
  },
  element: {
    // icon
    fontSize: "14px !important",
  },
}));
