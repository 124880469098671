import React from "react";

import { Typography, List, ListItem, Link } from "@mui/material";

import { Button, Modal } from "components";

import useStyles from "./info-modal.styles";

export type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

const InfoModal: React.FC<Props> = ({ isOpen, setOpen }) => {
  const classes = useStyles();
  return (
    <Modal maxWidth={450} open={isOpen} onClose={() => setOpen(false)}>
      <>
        <div className={classes.dialogTitle}>
          <Typography className={classes.title}>If you see the wrong version number, please try:</Typography>
        </div>

        <div className={classes.content}>
          <List component="div" dense={true} disablePadding={true}>
            <ListItem disableGutters={true}>
              <div className={classes.dot} />
              <span>
                Clear your browser’s cache. To do this, follow the instruction there:{" "}
                <Link
                  href="https://www.digitaltrends.com/computing/how-to-clear-your-browser-cache"
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                >
                  https://www.digitaltrends.com/computing/how-to-clear-your-browser-cache
                </Link>
              </span>
            </ListItem>
            <ListItem disableGutters={true}>
              <div className={classes.dot} />
              Open the admin portal using a different browser
            </ListItem>
            <ListItem disableGutters={true}>
              <div className={classes.dot} />
              Open the admin portal using a private mode
            </ListItem>
          </List>
        </div>
      </>
      <div className={classes.dialogActions}>
        <Button onClick={() => setOpen(false)} label="Ok" color="primary" />
      </div>
    </Modal>
  );
};

export default InfoModal;
