import React from "react";
import { AnyObject, Field } from "react-final-form";

import { Typography } from "@mui/material";

import { RadioControl, TextControl } from "components";
import { EvaluationOption } from "components/evaluations/graphql/evaluations-graphql";
import MatrixRadioControl from "components/forms/matrix-radio-control/matrix-radio-control";

import { EvaluationSurveyQuestionAndAnswerType } from "../../../../../types/evaluation-survey-types";
import useStyles from "./evaluation-survey-form-element-style";

type FormElementProps = {
  question: EvaluationSurveyQuestionAndAnswerType;
  index: number;
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent<Element, Event>, "preventDefault" | "stopPropagation">> | undefined,
  ) => Promise<AnyObject | undefined> | undefined;
  permission?: "READ" | "READ_WRITE";
  isCompleted?: boolean;
};

const FormElement = ({ question, index, handleSubmit, permission, isCompleted }: FormElementProps) => {
  const options =
    question.options?.map((option: EvaluationOption) => {
      return { value: option.id, label: option.name };
    }) || [];

  const classes = useStyles({ optionsCount: options.length });

  function handleFormSubmit() {
    if (isCompleted || permission === "READ") return;
    handleSubmit();
  }

  switch (question.type) {
    case "TEXT":
      return (
        <div>
          <label htmlFor={`answers[${index}].answer`} className={classes.label}>
            {question.question}
          </label>
          <Field
            id={`answers[${index}].answer`}
            name={`answers[${index}].answer`}
            component={TextControl}
            onBlur={handleFormSubmit}
            validateFields={[]}
            multiline
            rows={2}
            onInactivity={handleFormSubmit}
            disabled={isCompleted || permission !== "READ_WRITE"}
          />
        </div>
      );
    case "RADIO":
      return (
        <RadioControl
          name={`answers[${index}].answer`}
          label={question.question}
          onChange={handleFormSubmit}
          formControlLabelClasses={{
            root: classes.controlLabelRoot,
            label: classes.evaluationLabel,
          }}
          inputLabelClassName={classes.evaluationFieldLabel}
          radioGroupClasses={{ root: classes.evaluationRadioGroup }}
          radioInputClassName={classes.radioInput}
          labelPlacement={(question.options?.length || 0) > 2 ? "top" : "end"}
          options={options}
          disabled={isCompleted || permission !== "READ_WRITE"}
        />
      );
    case "MATRIX":
      return (
        <MatrixRadioControl
          name={`answers[${index}].matrixQuestions`}
          label={question.question}
          onChange={handleFormSubmit}
          rowNames={question.matrixQuestions || []}
          options={options}
          disabled={isCompleted || permission !== "READ_WRITE"}
        />
      );
    default:
      return <Typography className={classes.headerText}>{question.question}</Typography>;
  }
};
export default FormElement;
