import MaskedInput from "react-text-mask";

type Props = {
  inputRef: (ref: HTMLInputElement | null) => void;
};

const DurationMask = (props: Props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]} // relates to regex.duration in "utils/strings"
      placeholderChar={"0"}
      keepCharPositions
      showMask
    />
  );
};

export default DurationMask;
