import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";

import useStyles from "./breadcrumbs.styles";

export type BreadcrumbRoute = {
  name: string;
  path?: string;
};

type Props = {
  routes: Array<BreadcrumbRoute>;
};

const Breadcrumbs: React.FC<Props> = ({ routes }) => {
  const classes = useStyles();

  const separator = <span className={classes.separator}>/</span>;

  return (
    <ul className={classes.list}>
      {routes.map((item, index) => (
        <React.Fragment key={`${item.name}-${index}`}>
          {index !== 0 && item.name && separator}

          <li className={classes.listElement}>
            {item.path ? (
              <Link component={RouterLink} to={item.path} underline="hover">
                {item.name}
              </Link>
            ) : (
              <Typography>{item.name}</Typography>
            )}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};
export default Breadcrumbs;
