import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  content: {
    padding: spacing(4, 3),
    color: palette.text.default,
    fontSize: typography.pxToRem(16),
    wordBreak: "break-word",
  },
  dialogTitle: {
    padding: spacing(4, 1.5, 0),
  },
  title: {
    color: palette.text.default,
    fontSize: typography.pxToRem(18),
    fontWeight: typography.fontWeightMedium,
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    margin: spacing(2, 3, 2, 0),
  },
  dot: {
    width: 6,
    height: 6,
    minWidth: 6,
    borderRadius: "50%",
    backgroundColor: palette.primary.main,
    marginRight: spacing(2),
  },
}));
