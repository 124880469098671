import React, { useState } from "react";
import { useHistory } from "react-router";

import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";

import clsx from "clsx";

import ExpandLess from "assets/images/svg/expand-less.svg";
import LinkIcon from "assets/images/svg/link.svg";

import { EmployeeLink } from "components";
import { logEntityRouter } from "components/log-item/constants/log-item-constants";
import { LogType } from "components/log-item/log-item";

import { useCompareObjects } from "hooks";

import { transformObj } from "../../utils/log-item-utils";
import { dateToString } from "utils/dateFormat";
import { camelCaseToWords } from "utils/strings";

import useStyles from "../../log-item.styles";

const UpdateLog: React.FC<LogType> = ({ changes, user, id, isDetailsPage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({ isDetailsPage, isExpanded });
  const history = useHistory();

  const name = `${user.lastName} ${user.firstName}`;
  const parsedTo = JSON.parse(changes?.to);
  const parsedFrom = JSON.parse(changes?.from);
  const { deepDiffMapper } = useCompareObjects();
  const diff = deepDiffMapper.map(parsedFrom, parsedTo);

  const mapFields = () => {
    let fields = [];
    for (let key in diff) {
      if (key !== "updatedAt" && key !== "managementHistory") {
        fields.push({ ...diff[key], key: key });
      }
      if (key === "management") {
        fields.push({ ...diff[key]?.managerLevel1, key: "managerLevel1" });
        fields.push({ ...diff[key]?.managerLevel2, key: "managerLevel2" });
        fields.push({ ...diff[key]?.supervisor, key: "supervisor" });
      }
      if (key === "skills") {
        if (Object.keys(diff[key]).length) {
          Object.keys(diff[key]).map((innerKey: any, index: number) => {
            fields.push({ ...diff[key][innerKey].level, key: `skill${index}Level` });
            fields.push({ ...diff[key][innerKey].name, key: `skill${index}Name ` });
          });
        } else {
          const oldData = diff[key]?.data?.old?.map((it: any) => `${it.name} level: ${it.level}`);
          const newData = diff[key]?.data?.new?.map((it: any) => `${it.name} level: ${it.level}`);

          if (diff[key]?.data?.old) {
            diff[key].data.old = oldData?.join(", ");
          }

          if (diff[key]?.data?.new) {
            diff[key].data.new = newData?.join(", ");
          }
        }
      }
      if (key === "specs") {
        Object.keys(diff[key]).map((innerKey: any, index: number) => {
          fields.push({ ...diff[key][innerKey], key: `spec${index}` });
        });
      }
    }
    return fields;
  };
  const updateDate = parsedTo?.updatedAt;
  const getFields = mapFields();

  return (
    <>
      <div className={classes.row} key={id} onClick={() => setIsExpanded((previousState) => !previousState)}>
        <Box className={clsx(classes.rowText, classes.changeContainer)}>
          <IconButton size="large">
            <img src={ExpandLess} alt="arrow" className={!isExpanded ? classes.expandedIcon : undefined} />
          </IconButton>
          {dateToString(updateDate, "YYYY-MM-DD HH:mm")}
        </Box>
        <div className={classes.rowText}>Update {!isDetailsPage && changes.entity} </div>
        <div className={classes.rowText}>
          {user.employeeId?.id ? <EmployeeLink employee={{ id: user.employeeId.id, name }} /> : name}
        </div>
        {!isDetailsPage && (
          <Box display="flex" alignItems="center">
            <div
              className={classes.link}
              onClick={() => {
                history.push(logEntityRouter[changes.entity]);
              }}
            >
              {camelCaseToWords(changes.entity)}
              <img src={LinkIcon} alt="arrow inside square" className={classes.linkIcon} />
            </div>
          </Box>
        )}
      </div>
      {isExpanded ? (
        <>
          {getFields?.map((field, index) =>
            field.type === "updated" || field.type === "deleted" || field.type === "created" ? (
              <Grid container key={index} className={classes.expandRow}>
                <Grid item xs={3} className={classes.colKey}>
                  {camelCaseToWords(field?.key)}
                </Grid>
                <Grid item xs={2} className={classes.changeContainer}>
                  <Typography className={clsx(classes.fromItem, classes.changedItem)}>
                    {transformObj(field?.data?.new, field.key) && !Array.isArray(field.data?.new)
                      ? transformObj(field?.data?.new, field.key)
                      : `None`}
                  </Typography>
                </Grid>
                <Grid className={classes.iconContainer} item xs={1}>
                  <ArrowForwardIos className={classes.icon} />
                </Grid>
                <Grid item xs={2} className={classes.changeContainer}>
                  <Typography className={clsx(classes.fromItem, classes.updatedItem)}>
                    {transformObj(field?.data?.old, field.key) && !Array.isArray(field.data?.old)
                      ? transformObj(field?.data?.old, field.key)
                      : `None`}
                  </Typography>
                </Grid>
              </Grid>
            ) : null,
          )}
        </>
      ) : null}
    </>
  );
};
export default UpdateLog;
