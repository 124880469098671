import React, { ChangeEvent } from "react";

import { Tab, Tabs } from "@mui/material";

import { ToolbarTabs } from "../types/toolbar.types";
import useStyles from "./content-tabs.styles";

type Props<T extends string> = {
  tabs: ToolbarTabs<T>;
  value: any;
  onChange(e: ChangeEvent<{}>, newValue: number): void;
};

const ContentTabs = <T extends string>({ tabs, value, onChange }: Props<T>) => {
  const classes = useStyles();

  return (
    <Tabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      textColor="inherit"
      classes={{ scroller: classes.tabsScroller }}
    >
      {Object.keys(tabs).map((k) => {
        const key = k as keyof ToolbarTabs<T>;
        return (
          <Tab
            key={tabs[key].path}
            label={tabs[key].label}
            style={tabs[key].hidden ? { display: "none" } : undefined}
            {...(tabs[key].totalCount !== undefined &&
              tabs[key].currentCount !== undefined && {
                icon: <Counter count={tabs[key].currentCount ?? tabs[key].totalCount ?? 0} />,
              })}
            iconPosition="end"
            className={classes.tab}
          />
        );
      })}
    </Tabs>
  );
};

const Counter: React.FC<{ count: number }> = ({ count }) => {
  const classes = useStyles();
  return <span className={classes.icon}>{count}</span>;
};

export default ContentTabs;
