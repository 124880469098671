import React, { Dispatch, SetStateAction, useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router";

import { ToolbarTabs } from "components/toolbar/types/toolbar.types";
import {
  getPossibleToolbarTabPaths,
  insertToolbarTabPathParams,
  resetTabsCounts,
} from "components/toolbar/utils/toolbar.utils";

type SetCountersToTabsType<T> = { tabName: T; totalCount?: number; currentCount: number };

type TabsContextType<T extends string> = {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
  handleTabIndexChange(value: number): void;
  setParams: Dispatch<SetStateAction<Record<string, string>>>;
  tabs: ToolbarTabs<T>;
  setCountersToTabs: (counts: SetCountersToTabsType<T>[]) => void;
  withCounters: boolean;
};

const TabsContext = React.createContext<TabsContextType<any | undefined>>({} as TabsContextType<any>);

type Props<T extends string> = {
  tabs: ToolbarTabs<T>;
  withCounters?: boolean;
};

const TabsProvider = <T extends string>({
  tabs: tabsProps,
  withCounters = false,
  children,
}: React.PropsWithChildren<Props<T>>) => {
  const history = useHistory();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabs, setTabs] = useState<ToolbarTabs<T>>(tabsProps);
  const [params, setParams] = useState<Record<string, string>>({});

  useEffect(() => {
    const routeIndex = Object.values(tabs).findIndex((el: any) =>
      getPossibleToolbarTabPaths(el.path, params).includes(location.pathname),
    );

    if (routeIndex !== -1) {
      setTabIndex(routeIndex);
    }
  }, [location, params, tabs]);

  const handleTabIndexChange = useCallback(
    (newValue: number) => {
      const tabsWithParams = insertToolbarTabPathParams(tabs, params);

      history.push(tabsWithParams[newValue].path);
      setTabs(resetTabsCounts(tabs));
    },
    [history, params, tabs],
  );

  const setCountersToTabs = useCallback(
    (counts: SetCountersToTabsType<T>[]) => {
      const newTabs = counts.reduce((obj, currElem) => {
        const item = tabs[currElem.tabName];
        return {
          ...obj,
          [currElem.tabName]: {
            ...item,
            totalCount: currElem.totalCount ?? item.totalCount,
            currentCount: currElem.currentCount,
          },
        };
      }, {} as ToolbarTabs<T>);

      setTabs((prev) => ({ ...prev, ...newTabs }));
    },
    [tabs],
  );

  return (
    <TabsContext.Provider
      {...{
        children,
        value: {
          withCounters,
          setCountersToTabs,
          tabs,
          tabIndex,
          setTabIndex,
          handleTabIndexChange,
          setParams,
        },
      }}
    />
  );
};

const useTabsContext = () => React.useContext(TabsContext);

export { TabsProvider, useTabsContext };
