import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "react-final-form";

import { InputLabel, FormControl, FormHelperText, Box, Input } from "@mui/material";

import { ReactComponent as ImagePlaceholder } from "assets/images/svg/image-placeholder.svg";

import { FileControl, CropModal } from "components";

import { acceptedFiles, mbToBytes } from "utils/file";

type Props = FieldRenderProps<any, HTMLElement> & {
  modalTitle?: string;
  label?: string;
  variant?: "rounded" | "square";
  customClasses?: string;
  forcedError?: string;
};

const ImageCropControl: React.FC<Props> = ({
  input: { name, value, onChange, error: metaError, ...restInput },
  meta,
  label,
  customClasses,
  modalTitle = "Crop image",
  margin = "normal",
  variant = "square",
  ratio = 1 / 1,
  children,
  forcedError,
}) => {
  const error = metaError || forcedError;
  const showError = Boolean(meta.touched && ((meta.submitError && !meta.dirtySinceLastSubmit) || error));

  const [rawPhotoPath, setRawPhotoPath] = useState<string>("");
  const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
  const [croppedValue, setCroppedValue] = useState(value);

  useEffect(() => {
    onChange(croppedValue);
  }, [croppedValue, onChange]);

  const handlePhotoChange = (file: File) => {
    if (!file) {
      setRawPhotoPath("");
      setCroppedValue("");
      return;
    }

    setIsCropModalOpen(true);
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setRawPhotoPath(reader.result as string);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box>
        <InputLabel>{label}</InputLabel>
        <FormControl>
          <Box className={customClasses}>
            <FileControl
              name={`${name}-toggler`}
              margin={margin}
              component={FileControl}
              variant={variant}
              maxSize={mbToBytes(5)}
              acceptTypes={acceptedFiles.image.types}
              acceptTypesText={acceptedFiles.image.text}
              input={{
                ...restInput,
                name,
                value: value || "",
                onChange: handlePhotoChange,
              }}
              meta={meta}
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              iconPlaceholder={value ? <img alt="Crop image" src={value} /> : <ImagePlaceholder />}
            >
              {children}
            </FileControl>
            <Input name={name} type="hidden" value={value} />
          </Box>
          {showError && <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>}
        </FormControl>
      </Box>

      <CropModal
        open={isCropModalOpen}
        title={modalTitle}
        setOpen={setIsCropModalOpen}
        rawImage={rawPhotoPath}
        ratio={ratio}
        onCrop={(file) => {
          setCroppedValue(file);
        }}
      />
    </>
  );
};

export default ImageCropControl;
