import React, { useState } from "react";
import { useHistory } from "react-router";

import { Box, IconButton } from "@mui/material";

import clsx from "clsx";

import ExpandLess from "assets/images/svg/expand-less.svg";
import LinkIcon from "assets/images/svg/link.svg";

import { EmployeeLink } from "components";
import { logEntityRouter } from "components/log-item/constants/log-item-constants";
import { LogType } from "components/log-item/log-item";

import { dateToString } from "utils/dateFormat";
import { camelCaseToWords } from "utils/strings";

import useStyles from "../../log-item.styles";

const RemoveLog: React.FC<LogType> = ({ user, changes, id, isDetailsPage }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({ isDetailsPage, isExpanded });
  const history = useHistory();

  const name = `${user.lastName} ${user.firstName}`;
  const parsedTo = JSON.parse(changes?.to);
  const updateDate = parsedTo?.updatedAt;

  return (
    <div className={classes.row} key={id}>
      <Box className={clsx(classes.rowText, classes.changeContainer)}>
        <IconButton
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          size="large"
        >
          <img src={ExpandLess} alt="arrow" className={!isExpanded ? classes.expandedIcon : undefined} />
        </IconButton>
        {dateToString(updateDate, "YYYY-MM-DD HH:mm")}
      </Box>
      <div className={classes.rowText}>Removed {camelCaseToWords(changes.entity)}</div>
      <div className={classes.rowText}>
        {user.employeeId?.id ? <EmployeeLink employee={{ id: user.employeeId.id, name }} /> : name}
      </div>
      {!isDetailsPage && (
        <div
          className={classes.link}
          onClick={() => {
            history.push(logEntityRouter[changes.entity]);
          }}
        >
          {camelCaseToWords(changes.entity)}
          <img src={LinkIcon} alt="arrow inside square" className={classes.linkIcon} />
        </div>
      )}
    </div>
  );
};
export default RemoveLog;
