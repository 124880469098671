import React from "react";

import { CircularProgress, Typography } from "@mui/material";

import Placeholder from "./placeholder";
import useStyles from "./placeholder.styles";

type Props = {
  message?: React.ReactNode;
  inline?: boolean;
  fullscreen?: boolean;
  color?: "primary" | "contrast" | undefined;
  fontSize?: number;
};

const LoadingPlaceholder: React.FC<Props> = ({
  message = "Loading...",
  inline = false,
  fullscreen = false,
  color = "primary",
  fontSize,
}) => {
  const classes = useStyles();

  const renderPlaceholder = () => (
    // (BK) => I decided to style CircularProgress and Typography separately in order to make things less complicated and predictable
    <Placeholder
      image={<CircularProgress className={classes[color]} size={inline ? 15 : 40} />}
      message={
        message && (
          <Typography className={classes[color]} style={{ fontSize: fontSize ? fontSize : undefined }}>
            {message}
          </Typography>
        )
      }
      inline={inline}
    />
  );

  return fullscreen ? <div className={classes.fullscreen}>{renderPlaceholder()}</div> : renderPlaceholder();
};

export default LoadingPlaceholder;
