import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(() => ({
  dragItem: {
    transform: "translate(0px, 0px)", // to remove some default background showing up around dragging item
    "&$draggable": {
      cursor: "grab",
    },
    "&$isDragging": {
      cursor: "grabbing",
    },
  },
  draggable: {},
  isDragging: {},
}));
