export enum Tenants {
  QA = "qa.acai-erp.acaisoft.dev",
  TEST = "test.acai-erp.acaisoft.dev",
  STAGE = "erp-stage.acaisoft.dev",
  PROD_TEST = "acai-erp-prod-rh.acaisoft.io",
  PROD = "erp.acaisoft.io",
}

export const tenantsId = {
  qa: "621df2b838a987cd4da57527",
  test: "6192414ebf710987c3b5c357",
  stage: "504338bf3ffd974c673d6f66",
  prod_test: "504338bf3ffd974c673d6f66",
  prod: "504338bf3ffd974c673d6f66",
  default: "514338fd3ffd974c673d6fdc",
};
