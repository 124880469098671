import React, { ReactElement } from "react";

import { GridCellProps } from "@progress/kendo-react-grid";

import { BLANK_LABEL, isDate } from "../../utils/kendo-table.utils";
import { dateToString } from "utils/dateFormat";

type Props = {
  td: ReactElement<HTMLTableCellElement> | null;
  props: GridCellProps;
};

const KendoCellRender: React.FC<Props> = ({ td, props }) => {
  if (!td || !td.props?.children) return td;

  if (props.rowType === "data") {
    const content = td.props?.children;
    let children = <>{content}</>;

    if (props?.field && isDate(props.dataItem[props?.field])) {
      children = <>{dateToString(props.dataItem[props?.field])}</>;
    }

    return React.cloneElement(td, td.props, children);
  }

  if (props.rowType === "groupHeader") {
    const arrow = (td.props.children as any).props?.children?.[0];
    const label = (td.props.children as any).props?.children?.[1];
    const children = (
      <p>
        {arrow}
        {[BLANK_LABEL, undefined, null].includes(props.dataItem.value)
          ? BLANK_LABEL
          : props?.field && isDate(props.dataItem[props?.field])
          ? dateToString(label)
          : label}
        {" ("}
        {props.dataItem.items.length}
        {")"}
      </p>
    );
    return React.cloneElement(td, td.props, children);
  }

  return td;
};

export default KendoCellRender;
