import React from "react";
import { Form, Field } from "react-final-form";
import { NavLink } from "react-router-dom";

import { Box, FormHelperText } from "@mui/material";
import * as Sentry from "@sentry/react";

import { AxiosError } from "axios";
import { FORM_ERROR } from "final-form";

import { routes } from "config";

import { useAuth } from "services/auth";
import { useTenantContext } from "services/tenant/context/tenant.context";

import Content from "../components/content/content";
import GuestLayout from "../components/guest-layout/guest-layout";
import { Button, TextControl, FormError } from "components";

import { createValidator } from "utils/forms";

import useStyles from "../guest.styles";

const validator = createValidator({
  email: { presence: { allowEmpty: false }, email: true },
  password: { presence: { allowEmpty: false } },
});

const initialValues = {
  email: "",
  password: "",
};

const LoginView: React.FC = () => {
  const classes = useStyles();
  const auth = useAuth();
  const tenant = useTenantContext();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await auth?.loginWithEmailAndPassword({
        email: values.email,
        password: values.password,
        tenantId: tenant.data,
      });
    } catch (ex) {
      Sentry.captureException(ex);
      const error: AxiosError = (ex as any).error;
      return {
        [FORM_ERROR]: error?.response?.data?.message ?? "Sorry, something went wrong.",
      };
    }
  };

  return (
    <GuestLayout>
      <Content title="Sign in">
        <Form onSubmit={onSubmit} validate={validator} initialValues={initialValues}>
          {({ handleSubmit, submitting, hasValidationErrors, pristine, submitError }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <FormError>{submitError}</FormError>}

              <Box mb={3}>
                <Field name="email" label="Email address" margin="dense" component={TextControl} />
              </Box>
              <Field
                type="password"
                name="password"
                label="Password"
                margin="dense"
                component={TextControl}
                passwordVisibilityIndicator
              />
              <FormHelperText className={classes.forgotPassword}>
                <NavLink to={routes.auth.forgotPassword}>Forgot Password?</NavLink>
              </FormHelperText>
              <Box textAlign="center">
                <Button
                  type="submit"
                  color="primary"
                  className={classes.submitButton}
                  disabled={submitting || pristine || hasValidationErrors}
                  label="Sign in"
                />
              </Box>
            </form>
          )}
        </Form>
      </Content>
    </GuestLayout>
  );
};

export default LoginView;
