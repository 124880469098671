import { makeStyles } from "@mui/styles";

export default makeStyles<CustomTheme>(({ palette, spacing, typography }) => ({
  list: {
    listStyle: "none",
    paddingLeft: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  listElement: {
    "& > *": {
      color: palette.text.acai,
      fontSize: typography.pxToRem(13),
    },
    "&:last-child > *": {
      color: palette.text.secondary,
      lineHeight: 1,
    },
  },
  separator: {
    color: palette.text.secondary,
    margin: spacing(0, 0.5),
    fontWeight: 700,
  },
}));
