import { useState } from "react";
import { Field, Form } from "react-final-form";

import { Box, Grid } from "@mui/material";

import {
  PASSWORD_VALIDATION_EXPRESSION,
  PASSWORD_VALIDATION_MESSAGE,
} from "services/auth/auth-provider/constants/password.constants";

import useChangePassword from "modules/settings/hooks/useChangePassword";

import { AutoGrid, FormError, TextControl, Button } from "components";
import { useHeaderModalContext } from "components/app-layout/header/context/header-modal.context";

import { useToast } from "hooks";

import { createValidator } from "utils/forms";

import { SECTIONS } from "../types/account-settings.types";

const validator = createValidator({
  oldPassword: {
    presence: { allowEmpty: false },
  },
  newPassword: {
    presence: { allowEmpty: false },
    format: {
      pattern: PASSWORD_VALIDATION_EXPRESSION,
      message: PASSWORD_VALIDATION_MESSAGE,
    },
  },
  confirmNewPassword: {
    presence: {
      allowEmpty: false,
    },
    equality: "newPassword",
  },
});

type Props = {
  handleSectionChange: React.Dispatch<React.SetStateAction<SECTIONS>>;
};

const AccountSettingsChangePassword: React.FC<Props> = ({ handleSectionChange }) => {
  const { showToast } = useToast();
  const { changePassword } = useChangePassword();
  const { handleClose } = useHeaderModalContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleSubmit = async ({ oldPassword, newPassword, confirmNewPassword }: typeof initialValues) => {
    setLoading(true);
    try {
      await changePassword(oldPassword, newPassword, confirmNewPassword);
      showToast(`Your password has been changed`, "success");
      handleClose();
    } catch (ex) {
      setError((ex as any).response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => handleSectionChange(SECTIONS.overview);

  return (
    <>
      {error && <FormError>{error}</FormError>}
      <Form
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        validate={validator}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitError, hasValidationErrors, pristine }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                {submitError && <FormError>{submitError}</FormError>}
                <Grid container justifyContent="center">
                  <Grid xs={6}>
                    <Field
                      name="oldPassword"
                      type="password"
                      margin="dense"
                      label="Password"
                      component={TextControl}
                      validationIndicator
                      passwordVisibilityIndicator
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} justifyContent="center">
                  <Grid xs={6}>
                    <Field
                      name="newPassword"
                      type="password"
                      margin="dense"
                      label="New password"
                      component={TextControl}
                      validationIndicator
                      passwordVisibilityIndicator
                    />
                  </Grid>
                </Grid>
                <Grid container xs={12} justifyContent="center">
                  <Grid xs={6}>
                    <Field
                      name="confirmNewPassword"
                      type="password"
                      margin="dense"
                      label="Confirm New password"
                      component={TextControl}
                      validationIndicator
                      passwordVisibilityIndicator
                    />
                  </Grid>
                </Grid>
                <Box mt={4}>
                  <AutoGrid spacing={2} alignItems="center" wrap="nowrap" justify="center">
                    <Button
                      onClick={goBack}
                      variant="outlined"
                      color="secondary"
                      label="Cancel"
                      size="large"
                      disabled={loading}
                    />
                    <Button
                      type="submit"
                      label="Change Password"
                      size="large"
                      color="secondary"
                      disabled={loading || hasValidationErrors || pristine}
                      isLoading={loading}
                    />
                  </AutoGrid>
                </Box>
              </form>
            </>
          );
        }}
      </Form>
    </>
  );
};

export default AccountSettingsChangePassword;
