import { useRef } from "react";

import { useEventListener } from "../index";

function useKeyPressed(key: string) {
  const isKeyPressedRef = useRef<boolean>(false);

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === key) isKeyPressedRef.current = true;
  });

  useEventListener("keyup", () => {
    isKeyPressedRef.current = false;
  });

  useEventListener("focus", () => {
    isKeyPressedRef.current = false;
  });

  return isKeyPressedRef;
}

export default useKeyPressed;
