import clsx from "clsx";

import { classNames } from "@progress/kendo-react-common";
import { RatingItem, RatingItemProps } from "@progress/kendo-react-inputs";

import useStyle from "../skills-rating.style";

const CustomSkillItem = (props: RatingItemProps) => {
  const classes = useStyle();
  return (
    <RatingItem {...props}>
      {!props.half && (
        <div
          className={clsx(
            classNames("k-icon", {
              "k-i-star": (!props.icon && props.hovered) || (props.selected && !props.hovered),
              "k-i-star-outline": !props.icon && !props.hovered,
              [`${props.icon}`]: props.icon,
            }),
            classes.element,
          )}
        />
      )}
    </RatingItem>
  );
};
export default CustomSkillItem;
