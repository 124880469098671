import { SvgIconProps, SvgIcon } from "@mui/material";

const PersonCircle = (props: SvgIconProps) => (
  <SvgIcon
    style={{ width: "18", height: "18" }}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM4.89175 14.2334C5.25008 13.4834 7.43341 12.7501 9.00008 12.7501C10.5667 12.7501 12.7584 13.4834 13.1084 14.2334C11.9751 15.1334 10.5501 15.6667 9.00008 15.6667C7.45008 15.6667 6.02508 15.1334 4.89175 14.2334ZM14.3001 13.0251C13.1084 11.5751 10.2167 11.0834 9.00008 11.0834C7.78341 11.0834 4.89175 11.5751 3.70008 13.0251C2.85008 11.9084 2.33341 10.5167 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 10.5167 15.1501 11.9084 14.3001 13.0251ZM9.00008 4.00008C7.38342 4.00008 6.08342 5.30008 6.08342 6.91675C6.08342 8.53342 7.38342 9.83342 9.00008 9.83342C10.6167 9.83342 11.9167 8.53342 11.9167 6.91675C11.9167 5.30008 10.6167 4.00008 9.00008 4.00008ZM9.00008 8.16675C8.30841 8.16675 7.75008 7.60842 7.75008 6.91675C7.75008 6.22508 8.30841 5.66675 9.00008 5.66675C9.69175 5.66675 10.2501 6.22508 10.2501 6.91675C10.2501 7.60842 9.69175 8.16675 9.00008 8.16675Z"
      fill={props.fill || "#717171"}
    />
  </SvgIcon>
);

export default PersonCircle;
