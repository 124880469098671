import { useCallback, useState } from "react";

import useStyle from "./chart-gantt-wrapper.style";

type Props = {
  children: (onHovered: (isHovered: boolean) => void) => React.ReactNode;
};

const ChartGanttWrapper = ({ children }: Props) => {
  const classes = useStyle();

  const [isHovered, setHovered] = useState<boolean>(false);
  const onHoveredRoot = useCallback((isHovered: boolean) => setHovered(isHovered), [setHovered]);

  return (
    <div className={classes.rootHovered} data-ishovered={isHovered}>
      {children(onHoveredRoot)}
    </div>
  );
};

export default ChartGanttWrapper;
