import { createRef, FC, useEffect } from "react";

import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import { dateToString } from "utils/dateFormat";

type Props = {
  data: any[];
  columns: any[];
  fileName: string;
};

const ExcelExporter: FC<Props> = ({ data, fileName, columns }) => {
  const today = dateToString(new Date(), "MMM-DD-YYYY_HH-mm");

  const _exporter = createRef<ExcelExport>();

  useEffect(() => {
    if (_exporter.current) {
      _exporter.current.save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExcelExport data={data} collapsible={true} fileName={`${fileName}-${today}.xlsx`} ref={_exporter}>
      {columns.map((column) => (
        <ExcelExportColumn key={column.title} {...column} />
      ))}
    </ExcelExport>
  );
};

export default ExcelExporter;
