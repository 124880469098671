import { gql } from "@apollo/client";

import { Metadata } from "services/graphql/types";

import { Employee, BasicEmployee } from "modules/employees/graphql/employees.graphql";

import { ProjectBillingType, ProjectServiceType, ProjectStatus } from "../types/projects.types";
import { projectFragment } from "./project-details.graphql";

export type GetProjectsVars = {
  page: number;
  limit: number;
  sort?: string;
  search?: string;
};

export type GetProjectsData = {
  projects: {
    data: Project[];
    metadata: Metadata;
  };
};

export const GET_PROJECTS = gql`
  query projects($page: Int, $limit: Int, $sort: String, $search: String) {
    projects(page: $page, limit: $limit, sort: $sort, search: $search) {
      data {
        endDate
        id
        name
        startDate
        headcount
        type
        categoryId
        billingType
        serviceType
        totalEmployeesAssigned
        totalNonBillable
        management {
          managerLevel1 {
            firstName
            id
            lastName
          }
          managerLevel2 {
            firstName
            id
            lastName
          }
          supervisor {
            id
            firstName
            lastName
          }
        }
      }
      metadata {
        count
        limit
        page
      }
    }
  }
`;

export type GetArchivedProjectsVars = GetProjectsVars;
export type GetArchivedProjectsData = {
  archivedProjects: {
    data: Project[];
    metadata: Metadata;
  };
};

export const GET_ARCHIVED_PROJECTS = gql`
  query archivedProjects($page: Int, $limit: Int, $sort: String, $search: String) {
    archivedProjects(page: $page, limit: $limit, sort: $sort, search: $search) {
      data {
        endDate
        id
        name
        startDate
        headcount
        type
        categoryId
        billingType
        serviceType
        totalEmployeesAssigned
        totalNonBillable
        management {
          managerLevel1 {
            firstName
            id
            lastName
          }
          managerLevel2 {
            firstName
            id
            lastName
          }
          supervisor {
            id
            firstName
            lastName
          }
        }
      }
      metadata {
        count
        limit
        page
      }
    }
  }
`;

export type GetProjectsNamesVars = {
  page: number;
  limit: number;
};

export type GetProjectsNamesData = {
  projects: {
    data: Pick<Project, "id" | "name">[];
  };
};

export const GET_PROJECTS_NAMES = gql`
  query projectsNames($page: Int, $limit: Int) {
    projects(page: $page, sort: "name", limit: $limit) {
      data {
        id
        name
      }
    }
  }
`;

export type BasicProject = {
  id: string;
  name: string;
  headcount?: number;
  management?: ProjectManagement;
};

export type Project = BasicProject & {
  startDate: string;
  endDate: string;
  managementHistory: ManagementHistory[];
  serviceType?: ProjectServiceType;
  billingType?: ProjectBillingType;
  type?: ProjectStatus;
  totalEmployeesAssigned?: number;
  totalNonBillable?: number;
  categoryId?: string | null;
};

export type ProjectManagement = {
  managerLevel1: BasicEmployee;
  managerLevel2: BasicEmployee;
  supervisor: BasicEmployee;
};

export type ManagementHistory = {
  startDate?: Date;
  endDate?: Date;
  position: string;
  manager: Employee;
};

export type DeleteProjectData = {
  project: string;
};

export type DeleteProjectVars = {
  id: string;
};

export const DELETE_PROJECT = gql`
  mutation removeProject($id: String!) {
    project: removeProject(id: $id) {
      id
    }
  }
`;

export type CreateProjectData = {
  project: Project;
};

export type CreateProjectVars = {
  createProjectInput: Omit<Project, "id" | "managementHistory" | "indefiniteDate">;
};

export const CREATE_PROJECT = gql`
  mutation createProject($createProjectInput: CreateProjectInput!) {
    project: createProject(createProjectInput: $createProjectInput) {
      ...ProjectData
    }
  }
  ${projectFragment}
`;

export type UpdateProjectVars = {
  updateProjectInput: Omit<Project, "managementHistory" | "indefiniteDate">;
};

export type UpdateProjectData = {
  updateProject: Project;
};

export const UPDATE_PROJECT = gql`
  mutation updateProject($updateProjectInput: UpdateProjectInput!) {
    updateProject(updateProjectInput: $updateProjectInput) {
      ...ProjectData
    }
  }
  ${projectFragment}
`;

export type UpdateProjectStatusVars = {
  updateProjectInput: {
    id: Project["id"];
    type: Project["type"];
  };
};

export type UpdateProjectStatusData = {
  project: Pick<Project, "id" | "type">;
};

export const UPDATE_PROJECT_STATUS = gql`
  mutation updateProject($updateProjectInput: UpdateProjectInput!) {
    updateProject(updateProjectInput: $updateProjectInput) {
      id
      type
    }
  }
`;
