import makeStyles from '@mui/styles/makeStyles';

export default makeStyles<CustomTheme>(({ spacing, palette, typography }) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  imagePreview: {
    marginRight: `${30}px !important`,
    "& .MuiAvatar-root": {
      width: spacing(9.5),
      height: spacing(9.5),
      borderRadius: "50%",
      border: `1px solid ${palette.text.grey3}`,
    },
  },
}));
